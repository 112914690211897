// External library imports
import * as React from 'react';
import { IconButton } from '@mui/material';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon
} from '@mui/icons-material';
import { MuiTablePagination, paginationActionsStyle } from './styles/table';

const TPagination = ({
    data,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFirstPage,
    handlePreviousPage,
    handleNextPage,
    handleLastPage
}) => {

    const paginationActions = () => (
      <div style={paginationActionsStyle}>
        <IconButton onClick={handleFirstPage} disabled={page === 0}>
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={handlePreviousPage} disabled={page === 0}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={handleNextPage}
                    disabled={page >= Math.ceil((data?.length || 0) / { rowsPerPage }) - 1}>
          <KeyboardArrowRight />
        </IconButton>
        <IconButton onClick={handleLastPage}
                    disabled={page >= Math.ceil((data?.length || 0) / { rowsPerPage }) - 1}>
          <LastPageIcon />
        </IconButton>
      </div>
    );

    return (
        <MuiTablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={(data?.length || 0)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={() => (paginationActions())}
        />
    );

};
export default TPagination;
