import { GLOBAL } from "utils/constants";
import { Button, CardActions, styled } from '@mui/material';

export const MuiCardActions = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 106,
  height: 106,
  marginButton: 8,
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  justifyContent: 'center',
  borderRadius: 20,
  border: `1px solid ${GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]}`,
  color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
}));

export const MuiImg = styled('img')(({ theme }) => ({
  objectFit: 'scale-down',
  width: 106,
  height: 106,
}));
