import { useAuth } from '../providers/AuthProvider';

const usePermissions = () => {
  const { user } = useAuth();

  const hasPermissionFor = (permission) => Boolean(user.permissions[permission]);

  return { hasPermissionFor };
};

// eslint-disable-next-line import/prefer-default-export
export { usePermissions };
