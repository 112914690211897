/* eslint-disable camelcase */
import { useQueryClient, useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';

const deleteScheme = (data) => {
  const { scheme_id, user_id } = data;
  return axios
    .post(apiPaths.delete_scenario, { schemes: [scheme_id], user_id }, status200)
    .then((resp) => resp.data);
};

const useDeleteScheme = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => deleteScheme(values), {
    onSuccess: () => {
      queryClient.resetQueries(['schemes']);
    },
    onError: (error) => {
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['schemes']);
    },
  });
};

export default useDeleteScheme;
