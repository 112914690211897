import {styled} from "@mui/material/styles";
import Lottie from "lottie-react";
import * as React from "react";
import {
  Box,
  Grid,
  Backdrop,
  linearProgressClasses,
  LinearProgress,
} from "@mui/material";
import small_loader from '../../../../assets/animations/small_loader.json';
import flowering_tree from '../../../../assets/animations/flowering_tree.json';
import {useSelector} from "react-redux";
import {useState} from "react";
import {
  MuiHeaderMessage, MuiNotificationMessage, MuiProgress,
  MuiTitleMessage
} from '../scenarios/maps/custom/styles/loader';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const Loader = ({loaderType = "progress" , progress, loading, polygonDataLoading,headerMessage,titleMessage, notificationMessage}) => {

  const open = loading || polygonDataLoading || false;
  const layerLoading = useSelector((state) => state.app.layerLoadingState);
  const [accumulatedProgress, setAccumulatedProgress] = useState(0);
  const messages = [
    "Initializing map controls...",
    "Preparing geoespatial data...",
    "Processing geoespatial data...",
    "Rendering map..."
  ]
  const animationOptions = {
    "progress": [
      flowering_tree
    ],
    "infinite": [
      small_loader
    ]
  }
  function getAnimationData(loaderId) {
    const options = animationOptions[loaderId]
    const animationIndex = Math.floor(Math.random() * options.length)
    return options[animationIndex]
  }
  React.useEffect(() => {
    const globalCurrentProgress = Object.values(layerLoading).reduce((total, layer) => {
      return total + (layer.progress || 0);
    }, 0);
    setAccumulatedProgress(prevAccumulatedProgress => prevAccumulatedProgress + globalCurrentProgress);
  }, [layerLoading]);
  function getNotificationMessage(notificationMessage) {
    if (notificationMessage !== '') return notificationMessage
    const globalTotalSize = Object.values(layerLoading).reduce((total, layer) => {
      return total + (layer.size || 0);
    }, 0);
    const progressFraction = globalTotalSize > 0 ? (accumulatedProgress / globalTotalSize) : 0;
    let messageIndex = 0;
    if (progressFraction >= 0.07) {
      messageIndex = 3;
    } else if (progressFraction >= 0.05) {
      messageIndex = 2;
    } else if (progressFraction >= 0.02) {
      messageIndex = 1;
    }
    return messages[messageIndex];
  }

  return (
    <Backdrop
      open={open}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
    >
        <Grid container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ height: '100vh' }}>
          {open && (
            <>
            <Lottie
              animationData={getAnimationData(loaderType)}
              style={{ height: loaderType === 'infinite' ? 135 : 300, width: loaderType === 'infinite' ? 135 : 300 , marginBottom: 8}}
            />
            <Box sx={{width: '80%'}}>
              {loaderType !== 'infinite' ? (
                <>
                <MuiHeaderMessage>
                  {headerMessage} - <MuiProgress>{progress} %</MuiProgress>
                </MuiHeaderMessage>
                <BorderLinearProgress variant="determinate" value={progress}/>
                <MuiTitleMessage>
                  {titleMessage}
                </MuiTitleMessage>
                <MuiNotificationMessage>
                  {notificationMessage}
                </MuiNotificationMessage>
                </>
              ): (
                <>
                  <MuiTitleMessage>
                    {titleMessage}
                  </MuiTitleMessage>
                  <MuiNotificationMessage>
                    {getNotificationMessage(notificationMessage)}
                  </MuiNotificationMessage>
                </>
              )}
            </Box>
            </>
          )}
        </Grid>
    </Backdrop>
  );

}

export default Loader;
