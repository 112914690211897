/* eslint-disable react/require-default-props */
import React, {useMemo, useState, useRef} from 'react';
import {PropTypes} from 'prop-types';
import {useSelector} from 'react-redux';
import {selectSourceById} from 'lib/react-geo-tool/packages/react-redux/src/';
import StatisticsWidgetUI from './StatisticsWidgetUI';
import WrapperWidgetUI from '../WrapperWidgetUI';
import useDataStatisticsWidget
  from '../../../../api/hooks/useDataStatisticsWidget';
import WidgetWithAlert from "../sources-carto/WidgetWithAlert";
import {
  getStatistic
} from 'lib/react-geo-tool/packages/react-widgets/src/models';
import useWidgetFetch
  from 'lib/react-geo-tool/packages/react-widgets/src/hooks/useWidgetFetch';
import {
  getWidgetsWithFiltersByLayerId,
  transformParametersCatalog,
  getPolygonWidgetsWithFilters
} from 'utils/supportComponents';
import axios from 'api/axios/axiosCartoInstance';
import {status200} from 'api/status.utils';
import {useAuthCartoPia} from 'components/providers/CartoPiaAuthProvider';
import { widgetType } from 'utils/constants';

/**
 * Renders a <StatisticsWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {Object} [props.wrapperProps] - Extra props to pass to [WrapperWidgetUI](https://storybook-react.carto.com/?path=/docs/widgets-wrapperwidgetui--default)
 */
function StatisticsWidget(props) {
  const {
    id,
    title,
    datasetName,
    wrapperProps = {},
    dataSource,
    columns,
    global = false,
    noDataAlertProps = {},
    droppingFeaturesAlertProps,
    tooltip = true
  } = props;
  const [dataWithOtherFormat, setDataWithOtherFormat] = useState([]);
  const source = useSelector((state) => selectSourceById(state, dataSource) || {});
  const activeWidget = useSelector((state) => state.app.selectWidgetId);
  const spatialFilters = useSelector((state) => state.app.listFilterPolygonData);
  const widgets = useSelector((state) => state.app.widgets);
  const sources = useSelector((state) => state.carto.dataSources);
  const layers = useSelector((state) => state.app.layers);
  const [operator, setOperator] = useState('and');
  const [spatialFilter, setSpatialFilter] = useState();
  const {filters} = source;
  const widget = useSelector((state) => state.app.widgets.find(w => w.id === id));
  const [formattedUnfilteredData, setFormattedUnfilteredData] = useState([]);
  const parametersCatalog = useSelector((state) => state.app.parametersCatalog);
  const pointsOfReference = useSelector((state) => state.app.pointsOfReference);
  const defaultWidgetParametersValues = useRef(null);

  let localParametersCatalog = null;
  if (parametersCatalog?.length > 0) {
    if ( defaultWidgetParametersValues.current === null ) {
      defaultWidgetParametersValues.current = parametersCatalog.map( pc => ({
        propName: pc.propName,
        value: pc.value,
      }));
    }
    localParametersCatalog = {};
    parametersCatalog.forEach(p => {
      columns.forEach(col => {
        if (col.field.includes(p.propName) || (filters && Object.keys(filters).length >0)) {
          localParametersCatalog[p.propName] = p.value
        }
      })
    });
  }

  const pointsOfReferenceParameters = useMemo(() => {
    return pointsOfReference.find( pr => pr.type === widgetType.PARAMETERS );
  }, [pointsOfReference]);

  const transformedFilters = useMemo(() => {
    if (filters) {
      const newFilters = {...filters};
      Object.keys(filters).forEach(keyProp => {
        if (newFilters[keyProp].between || newFilters[keyProp].in) {
          const type = newFilters[keyProp].between ? 'between' : 'in';
          const column = widgets.find((w) => w.id === newFilters[keyProp][type].owner).params.field;
          if (keyProp !== column) {
            newFilters[column] = newFilters[keyProp];
            delete newFilters[keyProp];
          }
        }
      });
      return newFilters;
    }
    return filters;
  }, [source, widgets]);

  const pointsOfReferenceRelatedWidgets = useMemo(() => {
    let spatialFilterRelatedLayer = null;
    return pointsOfReference.filter( pr => {
      if ( pr.type === widgetType.POLYGON || pr.type === widgetType.RECOMMENDATIONS ) {
        const spatialFilterWidgetReference = widgets.find( w => w.id === pr.widgetId );
        spatialFilterRelatedLayer = layers.find( l => (l.datasetName === spatialFilterWidgetReference?.dataSet?.cartoName || l.datasetName === spatialFilterWidgetReference.params.demandDatasetName) )
      }
      return (pr.layerId ===  widget.layer || spatialFilterRelatedLayer ) && pr.type !== 'parameters';
    });
  }, [pointsOfReference]);

  const columnsToFetch = columns.map(col => {
    if (col.calculator) {
      return {...col, formula: col.field ? col.field: col.formula, operation: col.operation === 'NONE' ? '': col.operation };
    } else if (!col.calculator) {
      return {
        ...col,
        alias: col.uniqueName == undefined ? col.field : col.uniqueName,
        operation: col.operation === 'NONE' ? '': col.operation
      };
    } else {
      return {...col, alias: col.field , operation: col.operation === 'NONE' ? '': col.operation };
    }
  });

  const {
    data: statisticsData,
    isLoading,
  } = useWidgetFetch(getStatistic, {
    id,
    dataSource,
    spatialFilter,
    params: {
      columns: [
        ...columnsToFetch
      ],
      parametersCatalog:localParametersCatalog
    },
    global,
    attemptRemoteCalculation: global,
    enabled: wrapperProps.expand
  });

  const dataStatisticsConverges = () => {
    const tmp = [];
    statisticsData?.forEach((data) => {
      const dataColumns = columnsToFetch?.filter((elem) => elem.calculator ? elem.alias === data.column : elem.alias === data.column);
      const dataComplete = {
        ...dataColumns[0],
        result: data.value,
      };
      tmp.push(dataComplete);
    });
    setDataWithOtherFormat(tmp);
  };

  React.useEffect(() => {
    if (activeWidget) {
      const key = `${activeWidget}-${(location.pathname.split('/'))[6]}`;
      if (spatialFilters && key in spatialFilters) {
        if (widgets.find(w => w.id === activeWidget).type === 'recommendations') {
          setSpatialFilter({
            ...spatialFilters[key],
            filterType: 'recommendation'
          });
        } else if ((widgets.find(w => w.id === activeWidget).type === 'polygon')) {
          setSpatialFilter({...spatialFilters[key], filterType: 'polygon'});
        }
      } else {
        setSpatialFilter();
      }
    } else {
      setSpatialFilter();
    }
  }, [activeWidget, spatialFilters]);

  let areParametersListEquals = React.useMemo( () => {
    const defaultParamsValues =  defaultWidgetParametersValues.current;
    if ( defaultParamsValues === null ) {
      return true;
    }
    for (let i = 0; i < defaultParamsValues.length; i++) {
      if (defaultParamsValues[i].propName !== parametersCatalog[i].propName || defaultParamsValues[i].value !== parametersCatalog[i].value) {
        return false;
      }
    }
    return true;
  }, [defaultWidgetParametersValues.current, parametersCatalog]);

  const parametersCatalogToUse = ( areFiltersBeingUsed ) => {
    if ( !pointsOfReferenceParameters ) {
      if ( areParametersListEquals || areFiltersBeingUsed ) {
        return localParametersCatalog;
      }
      return transformParametersCatalog( defaultWidgetParametersValues.current )
    } else {
      return transformParametersCatalog( pointsOfReferenceParameters.parameters );
    }
  }

  const saveColumnValueWithoutAppliedFilters = async () => {
    const appliedFilters = getWidgetsWithFiltersByLayerId(widget.layer, sources, layers, widgets);
    appliedFilters.push( ...getPolygonWidgetsWithFilters( widgets, spatialFilters ));
    if ((appliedFilters.length > 0 || defaultWidgetParametersValues.current !== null) && wrapperProps.expand ) {
      const response = await axios
        .post(`widgets/statistics?source=${datasetName}`, {
            filters: "",
            columns: [...columnsToFetch],
            parametersCatalog: parametersCatalogToUse( appliedFilters.length > 0 )
          },
          status200);
      const tmp = [];
      response.data?.forEach((data) => {
        const dataColumns = columnsToFetch?.filter((elem) => elem.calculator ? elem.alias === data.column : elem.alias === data.column);
        const dataComplete = {
          ...dataColumns[0],
          result: data.value,
        };
        tmp.push(dataComplete);
      });
      setFormattedUnfilteredData(tmp)
    } else {
      setFormattedUnfilteredData([])
    }
  }

  const getHistogramQueryFormat = filter => {
    const { column, queryLimits, formulaField } = filter;
    const [start, end] = queryLimits.split(' - ').map(Number);
    const queryField = formulaField ? formulaField : column;
    const formattedString = `${queryField} >= ${start} and ${queryField} <= ${end}`;
    return formattedString;
  }

  const formatCategoryQueryItems = el => {
    if ( isNaN( el.trim() )) {
      return `'${el.trim()}'`
    } else {
      return `${el.trim()}`
    }
  }

  const getCategoryQueryFormat = filter => {
    const { column, queryLimits, formulaField } = filter;
    const elements = queryLimits.split(',');
    const formattedString = `${column} in (${elements.map(el => formatCategoryQueryItems(el)).join(', ')})`;
    const query = formulaField ? `${formulaField} in (${elements.map(el => formatCategoryQueryItems(el)).join(', ')})` : formattedString;
    return query;
  }

  const saveColumnValueFromReferencePoint = async () => {
    let query = '';
    let filter;

    pointsOfReferenceRelatedWidgets.forEach((pointOfReference, index) => {
      filter = {
        column: pointOfReference.column,
        queryLimits: pointOfReference.filter,
        formulaField: pointOfReference.formulaField,
      };

      if (pointOfReference.type === widgetType.HISTOGRAM) {
        query += getHistogramQueryFormat(filter);
      } else if (pointOfReference.type === widgetType.CATEGORY) {
        query += getCategoryQueryFormat(filter);
      }

      if ( query ) {
        if ( pointsOfReferenceRelatedWidgets[index + 1] && ( pointsOfReferenceRelatedWidgets[index + 1].type !== widgetType.POLYGON && pointsOfReferenceRelatedWidgets[index + 1].type !== widgetType.RECOMMENDATIONS ) ) {
          query += ` ${pointOfReference.filtersLogicalOperator} `;
        }
      }
    });

    if (pointsOfReferenceRelatedWidgets.length > 0 || pointsOfReferenceParameters) {
      const queryParameters = {
        filters: query,
        columns: [...columnsToFetch],
        parametersCatalog: !pointsOfReferenceParameters ? localParametersCatalog : transformParametersCatalog( pointsOfReferenceParameters.parameters ),
      };
      let spatialFilterUsed = pointsOfReferenceRelatedWidgets.find( pr => pr.type === widgetType.POLYGON || pr.type === widgetType.RECOMMENDATIONS );
      let url = `widgets/statistics?source=${datasetName}`;
      if ( spatialFilterUsed && spatialFilterUsed.type === widgetType.POLYGON ) {
        queryParameters['polygon_id'] = spatialFilterUsed.spatialFilter.polygonsId;
        queryParameters['polygon_id_col'] = 'polygon_id';
        if (spatialFilterUsed.spatialFilter.spatialTable) {
          queryParameters['spatial_table'] = spatialFilterUsed.spatialFilter.spatialTable;
        }
        url += '&filter_type=polygon';
      } else if ( spatialFilterUsed && spatialFilterUsed.type === widgetType.RECOMMENDATIONS ) {
        queryParameters['polygon_id'] = spatialFilterUsed.spatialFilter.polygonsId;
        queryParameters['polygon_id_col'] = 'ido';
        if (spatialFilterUsed.spatialFilter.spatialTable) {
          queryParameters['spatial_table'] = spatialFilterUsed.spatialFilter.spatialTable;
        }
        url += '&filter_type=recommendation';
      }

      try {
        if (wrapperProps.expand) {
          const response = await axios.post(url, queryParameters, status200);
          const tmp = [];
          response.data?.forEach((data) => {
            const dataColumns = columnsToFetch?.filter((elem) => (elem.calculator ? elem.alias === data.column : elem.alias === data.column));
            const dataComplete = {
              ...dataColumns[0],
              result: data.value,
            };
            tmp.push(dataComplete);
          });
          setFormattedUnfilteredData(tmp);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      setFormattedUnfilteredData([]);
    }
  };

  React.useEffect(() => {
    dataStatisticsConverges();
    if ( pointsOfReferenceRelatedWidgets.length > 0 || pointsOfReferenceParameters ) {
      saveColumnValueFromReferencePoint();
    } else  {
      saveColumnValueWithoutAppliedFilters();
    }

  }, [statisticsData, pointsOfReferenceRelatedWidgets, parametersCatalog ]);

  React.useEffect(() => {
    widgets?.forEach((w) => {
      if (w.id == id) {
        if (sources[w.layer]?.filtersLogicalOperator) {
          setOperator(sources[w.layer].filtersLogicalOperator);
        }
      }
    });
  }, [sources]);

  return (
    <WrapperWidgetUI title={title} widgetId={id}
                     isLoading={isLoading} {...{ ...wrapperProps, margin: '12px 14px' }}>
      <WidgetWithAlert
        dataSource={dataSource}
        global={global}
        droppingFeaturesAlertProps={droppingFeaturesAlertProps}
        noDataAlertProps={noDataAlertProps}
      >
        <StatisticsWidgetUI data={dataWithOtherFormat}
                            unfilteredData={formattedUnfilteredData} id={id}/>
      </WidgetWithAlert>
    </WrapperWidgetUI>
  );
}

StatisticsWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.string.isRequired,
  column: PropTypes.string,
  onError: PropTypes.func,
  wrapperProps: PropTypes.object,
  global: PropTypes.bool,
  noDataAlertProps: PropTypes.object,
  droppingFeaturesAlertProps: PropTypes.object,
};

export default StatisticsWidget;
