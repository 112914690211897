import { _executeModel } from 'lib/react-geo-tool/packages/react-api/src/';
import { Methods, executeTask } from '@carto/react-workers';
import { normalizeObjectKeys, wrapModelCall } from './utils';

export function getHistogram(props) {
  return wrapModelCall(props, fromLocal, fromRemote);
}

// From local
function fromLocal(props) {
  const { source, column, operation, ticks } = props;

  return executeTask(source.id, Methods.FEATURES_HISTOGRAM, {
    filters: source.filters,
    filtersLogicalOperator: source.filtersLogicalOperator,
    operation,
    column,
    ticks
  });
}

// From remote
async function fromRemote(props) {
  const { source, spatialFilter, abortController, spatialTable, ...params } = props;
  const { column, operation, ticks,parametersCatalog } = params;

  const data = await _executeModel({
    model: 'histogram',
    source,
    spatialFilter,
    spatialTable,
    params: { column, operation,parametersCatalog },
    opts: { abortController }
  }).then((res) => normalizeObjectKeys(res.rows));

  if (data.length) {
    // const result = Array(ticks.length + 1).fill(0);
    // data.forEach(({ tick, value }) => (result[tick] = value));
    // return result;
    return data;
  }

  return [];
}
