const round = (str, places = 3) => {
  if (typeof str === 'undefined') {
    return 'null';
  }
  if (typeof str === 'string') {
    if (parseFloat(str) !== 'NaN') return str;
    return +`${Math.round(`${str}e+${places}`)}e-${places}`;
  }
  if (typeof str === 'number') {
    return +`${Math.round(`${str}e+${places}`)}e-${places}`;
  }
  return null;
};
export default round;
