import * as React from 'react';
import CustomSkeleton from 'components/common/CustomSkeleton';
import { Paper } from '@mui/material';

import {
  MuiContainer, MuiCustomSkeleton, MuiGridButtons,
  MuiGridContainer,
  MuiGridPaper, MuiGridSkeleton
} from './styles/skeleton';

const Skeletons = () => {

  return (
    <MuiContainer container >
      <MuiGridPaper container component={Paper} >
        <MuiGridContainer container spacing={3} >
          <MuiGridSkeleton item xs={12}>
            <MuiCustomSkeleton
              variant='rect'
              animation='wave'
              height={40}
            />
          </MuiGridSkeleton>
          <MuiGridSkeleton item xs={12} >
            <MuiCustomSkeleton
              variant='rect'
              animation='wave'
              height={40}
            />
          </MuiGridSkeleton>
          <MuiGridSkeleton item xs={12} >
            <MuiCustomSkeleton
              variant='rect'
              animation='wave'
              height={40}
            />
          </MuiGridSkeleton>
        </MuiGridContainer>
        <MuiGridSkeleton item xs={6}>
          <MuiCustomSkeleton
            variant='rect'
            animation='wave'
            height={50}
          />
        </MuiGridSkeleton>
        <MuiGridSkeleton item xs={6} >
          <MuiCustomSkeleton
            variant='rect'
            animation='wave'
            height={50}
          />
        </MuiGridSkeleton>
        <MuiGridButtons item xs={12} >
          <CustomSkeleton
            variant='rect'
            animation='wave'
            style={{ margin: '16px 10px' }}
            height={40}
            width={100}
          />
          <CustomSkeleton
            variant='rect'
            animation='wave'
            style={{ margin: '16px 10px' }}
            height={40}
            width={100}
          />
        </MuiGridButtons>
      </MuiGridPaper>
    </MuiContainer>
  );
};
export default Skeletons;
