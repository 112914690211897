/* eslint-disable camelcase */
import { useQueryClient, useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';

const deleteDataset = async ({ dataset_id }) => {
  return axios
    .delete(`${apiPaths.delete_dataset}${dataset_id}`, status200)
    .then((resp) => resp.data);
};

const useDeleteDataset = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => deleteDataset(values), {
    onSuccess: () => {
      queryClient.resetQueries(['data_set']);
    },
    onError: (error) => {
      
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['data_set']);
    },
  });
};

export default useDeleteDataset;
