/* eslint-disable camelcase */
import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchPublicScenario = ({ user_id, scheme_id, isAuthenticated  }) => {
  if (isAuthenticated) {
    axios.post(apiPaths.publicScenario, { user_id, scheme_id}, status200).then((resp) => resp.data);
  }
}


const usePublicScenario = (data) => {
  return useQuery(['scenario-public', data.scheme_id], () => fetchPublicScenario(data), {
    enabled: data.enabled,
    onError: () => data.onError(),
    retry: false,
  });
}


export default usePublicScenario;
