import {
  MuiToggleButton,
  MuiToggleButtonGroup,
} from './styles/customToggleButton';

const CustomToggleButton = ({ handleValues, values, toggleItems, exclusive=false  }) => {
  return (
      <MuiToggleButtonGroup
      value={values}
      onChange={handleValues}
      exclusive={exclusive}
      >
          { toggleItems.map( btn => (
              <MuiToggleButton value={btn.value}>
                  { btn.icon }
              </MuiToggleButton>
          )) }
      </MuiToggleButtonGroup>
  );
};

export default CustomToggleButton;
