import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchUsage = (id, org, setDatasetsUsage) =>
  axios.get(`${apiPaths.get_dataset_usage}${id}/${org}/usage`, status200).then((resp) => {
    setDatasetsUsage(resp.data);
    return resp.data;
  });

const useDatasetUsage = (id, org, setDatasetsUsage) =>
  useQuery('users', () => fetchUsage(id, org, setDatasetsUsage), {
    enabled: Boolean(id),
  });

export default useDatasetUsage;
