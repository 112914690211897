import * as React from 'react';
import swap from 'lodash-move';
import {
  Grid,
  Typography,
  Checkbox,
  Tooltip
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
  dragWidgetListStyles,
  fontStyles, inputStyles,
  MuiGridContent,
  MuiGridInput,
  MuiGridRowContainer,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon,
  MuiEditIcon, typographyCommonStyles, OutlinedInput
} from '../styles/itemSortList';
import { MuiDeleteIcon } from '../../../layer/styles/layersSort';
import {parseFormula} from "../../../../../../../../utils/supportComponents";

const DragHandle = SortableHandle(() => (
  <DragHandleIcon color='primary' style={dragWidgetListStyles} />
));

const SortableItem = SortableElement(
  ({ item, index, handleDeleteItem,calculator, setVisibility,
                     handleEditName, formula, getFormula, action }) => {

    const matchedFormula = formula.find(formula => formula.alias === item.field);

    return (
     <MuiGridRowContainer container justifyContent="center" >
        <DragHandle />
        <MuiGridContent item xs={11}>
          <Grid item xs={7}>
            {matchedFormula ? (
              <Tooltip title={matchedFormula.formula ? matchedFormula.formula : matchedFormula.field}
                       placement="top"
                       arrow>
                <Typography noWrap style={typographyCommonStyles}>{matchedFormula.alias}</Typography>
              </Tooltip>
            ) : (
              <Typography noWrap style={typographyCommonStyles} >{item.field}</Typography>
            )}
          </Grid>
          <MuiGridInput item xs={6}>
            <OutlinedInput
              variant="outlined"
              type="text"
              defaultValue={item.name}
              sx={fontStyles}
              style={inputStyles}
              onBlur={(e) => handleEditName(item.id, e.target.value)}
            />
          </MuiGridInput>
          <Grid item xs={3}>
            <div style={{display:'flex'}}>
              <Checkbox
                checked={item.visible}
                name="visibility"
                onClick={(e) => setVisibility(e.target.checked, item.id)}
                color="primary"
                icon={<MuiVisibilityOffIcon/>}
                checkedIcon={<MuiVisibilityIcon />}
              />
              {calculator && action === 'update' && matchedFormula?.alias.includes('formula') && (
                <MuiEditIcon onClick={() => getFormula(matchedFormula.formula, item.id)} />
              )}
              <MuiDeleteIcon onClick={() => handleDeleteItem(item.id)} />
            </div>
          </Grid>
        </MuiGridContent>
    </MuiGridRowContainer>
  );
});

const SortableList = SortableContainer(
  ({ items, handleDeleteItem, setVisibility, handleEditName,
                    formula, calculator, getFormula, action }) => {

    return (
      <Grid container >
        {items?.map((item, index) => (
          <SortableItem
            key={item.id}
            index={index}
            item={item}
            formula={formula}
            handleDeleteItem={handleDeleteItem}
            setVisibility={setVisibility}
            handleEditName={handleEditName}
            calculator={calculator}
            action={action}
            getFormula={getFormula}
          />
        ))}
      </Grid>
    );
  }
);

const ItemSortList = ({ items, setItems, formula, calculator, action,
                        setSelectedValues, setIdForm , columns}) => {

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(swap(items, oldIndex, newIndex));
  };
  const setVisibility = (visible, id) => {
    setItems((prevVal) => prevVal.map((item) => (item.id === id ? { ...item, visible } : item)));
  };

  const handleDeleteItem = (id) => {
    setItems((prevVal) => prevVal.filter((item) => item.id !== id));
  };

  const handleEditName = (id, name) => {
    setItems((prevVal) => prevVal.map((item) => (item.id === id ? { ...item, name } : item)));
  };

  const getFormula = (formula, id) => {
    const newValue = parseFormula(formula, columns);
    setIdForm(id)
    setSelectedValues(newValue)
  }

  return (
    <SortableList
      items={items}
      onSortEnd={onSortEnd}
      useDragHandle
      formula={formula}
      helperClass='helper-class'
      handleDeleteItem={handleDeleteItem}
      setVisibility={setVisibility}
      handleEditName={handleEditName}
      getFormula={getFormula}
      calculator={calculator}
      action={action}
    />
  );
};

export default ItemSortList;
