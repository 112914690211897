const useGenerateDynamicRecommendationsName = ( isWidgetGlobal ) => {
    let dynamicRecommendationsName = '';
    const org_id = location.pathname.split('/')[2];
    const scheme_id = (location.pathname.split('/'))[6];
    const userId = JSON.parse(localStorage.getItem('user')).id;

    if ( !(scheme_id && org_id) || !(scheme_id && userId) ) {
      return '';
    }

    if (isWidgetGlobal || isWidgetGlobal === null || isWidgetGlobal === undefined) {
      dynamicRecommendationsName = scheme_id + '_' + org_id;
    } else {
      dynamicRecommendationsName = scheme_id + '_' + userId;
    }
    return dynamicRecommendationsName; 
  };
  
  export default useGenerateDynamicRecommendationsName;