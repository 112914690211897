import { GLOBAL } from 'utils/constants';
import { Button, Grid, styled, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';

export const MuiButton = styled(Button)(({ theme }) => ({
  justifySelf: 'end',
  borderRadius: 20,
  backgroundColor: '#ffffff',
  borderColor:lighten('#00BBD4', 0.1),
  color: lighten('#00BBD4', 0.1),
  '& svg': {
    fill: lighten('#00BBD4', 0.2),
  },
  '&:hover': {
    backgroundColor: lighten('#00BBD4', 0.9),
    borderColor:lighten('#00BBD4', 0.1),
  },
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  marginTop: 56,
  marginLeft:'-1.5rem'
}));

export const MuiCards = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignContent: 'center',
  margin: 'auto',
}));

export const MuiButtonTypography = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontWeight:500,
  fontSize:'14px'
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  fontFamily:'Raleway',
  fontWeight:700,
  fontSize:'29px',
  color:'#212121'
}));

export const MuiSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily:'Raleway',
  fontWeight:400,
  fontSize:'16px',
  color:'#212121'
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  marginTop: 30,
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '23px',
  width: '100%',
  '@media (min-width: 1920px)': {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  '@media (min-width: 1280px) and (max-width: 1919px)': {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  '@media (min-width: 960px) and (max-width: 1279px)': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  '@media (min-width: 600px) and (max-width: 959px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media (max-width: 599px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

export const containerStyle = {
  paddingLeft:124,
  paddingRight: 124,
};

export const notPaddingStyle = {
  paddingLeft:0,
  paddingRight: 0,
};
