import { useState } from 'react';
import {
  Grid,
  Link as MaterialLink,
  Menu,
  MenuItem,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import TranslateIcon from '@mui/icons-material/Translate';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import { GLOBAL } from 'utils/constants';
import {
  MuiAvatar,
  MuiCommonText, MuiPermissionText,
  MuiUserInfo,
  MuiUserText
} from './styles/userMenu';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { logout, user } = useAuth();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    logout();
    handleClose();
  };
  const handleTranslation = () => {
    navigate('/translations');
    handleClose();
  };

  const userFriendlyRoleName = () => {
    if ( user.type === GLOBAL.ROLES.SUPERADMIN || user.type === GLOBAL.ROLES.ADMIN ) {
      return t(`user_role_${user.type}`);
    }
    const userRoleName = user.type;
    return `${t('user_role_level')} ${ userRoleName.charAt(userRoleName.length - 1) }`;
  }

  return (
    <>
      <MaterialLink
        edge='end'
        aria-label='account of current user'
        aria-controls='menu-login'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
        data-cy="userMenu"
        underline="none"
      >
        <Grid container alignItems='center' item wrap='nowrap'>
         <MuiAvatar>
            <PersonIcon />
         </MuiAvatar>
         <MuiUserInfo>
            <MuiUserText
              variant='body1'
              color='inherit'
              noWrap
              data-cy="userInfo"
            >
                {user.name}
            </MuiUserText>
            <MuiPermissionText
              variant='caption'
              color='inherit'
              noWrap
              data-cy="userInfo"
            >
                { userFriendlyRoleName() }
            </MuiPermissionText>
         </MuiUserInfo>
        </Grid>
      </MaterialLink>
      <Menu
        id='menu-login'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {user.type === 'super_admin' && (
          <MenuItem divider onClick={handleTranslation} data-cy="manageTranslations" >
            <TranslateIcon sx={{color:'#616161'}}/>
            <MuiCommonText variant='body1'>
              {t('translations')}
            </MuiCommonText>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{color:'#616161'}}/>
          <MuiCommonText variant='body1'>
            {t('log_out')}
          </MuiCommonText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
