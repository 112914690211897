import { useQueryClient, useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';

const schemeBulkWidgets = async (
  { userId, scheme, jsonFileContent },
  setStateAfterBulkWidgets,
  setErrors
) => {
  await axios
    .post(apiPaths.schemesBulkWidgets, { user_id: userId, scheme, jsonFileContent }, status200)
    .then((resp) => {
      setStateAfterBulkWidgets(resp.data.scheme);
      return resp.data;
    })
    .catch((e) => {
      setErrors(e.data.error);
    });
};

const useSchemeBulkWidgets = (setStateAfterBulkWidgets, setErrors) => {
  const queryClient = useQueryClient();
  return useMutation((values) => schemeBulkWidgets(values, setStateAfterBulkWidgets, setErrors), {
    onSuccess: () => {
      queryClient.resetQueries(['scheme_bulk_widgets']);
    },
    onError: (error) => {
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['scheme_bulk_widgets']);
    },
  });
};

export default useSchemeBulkWidgets;
