import { useSelector } from 'react-redux';
import { OPERATION } from '@deck.gl/core';
import { MASK_ID } from 'lib/react-geo-tool/packages/react-core/src//';
import { selectSpatialFilter } from 'lib/react-geo-tool/packages/react-redux/src//';
import { PolygonLayer } from '@deck.gl/layers';

export default function MaskLayer() {
  const spatialFilterGeometry = useSelector((state) => selectSpatialFilter(state));
  const maskData = !!spatialFilterGeometry
    ? spatialFilterGeometry?.geometry?.coordinates
    : [];
  
return new PolygonLayer({
    id: MASK_ID,
    operation: OPERATION.MASK,
    data: maskData,
    getPolygon: d => d,
    getFillColor: [255, 255, 255, 255]
  });
}
