import { GLOBAL } from '../../../utils/constants';
import { MuiButtonTypography } from '../../views/organizations/styles/organizations';

export const btnStyle = {
  marginLeft: 0,
  backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[200] ,
  transform: 'scale(0.7)',
  '&:hover, &.Mui-focusVisible': { backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE['100'] },
};

export const popoverContainerStyle = {
  '& .MuiPopover-paper': {
    display: 'inline-table',
    padding: 2,
    width: 380
  },
};

export const typographyStyle = {
  fontFamily:'Montserrat',
  fontWeight:500,
  textAlign:'justify',
  fontSize:'16px'
};
