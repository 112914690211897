import * as React from 'react';
import { Grid, Chip } from '@material-ui/core';

import { hexToRgb } from 'utils/colorConverter';

const TagCell = ({ row, tagsColor }) => (
  <Grid
    key={row.id}
    container
    style={{
      overflowX: 'clip',
      overflowY: 'auto',
      maxHeight: 50,
      maxWidth: 185,
      lineHeight: 1,
      padding: 0,
      margin: 0,
    }}
  >
    {row.tags.map((t, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid key={`${t}${row.id}${i}`} item style={{ margin: '1px 4px 1px 0px' }}>
        <Chip
          label={`${t.charAt(0).toUpperCase()}${t.slice(1)}`}
          style={{
            height: 13,
            fontSize: 11,
            fontWeight: 500,
            background: `rgb(${hexToRgb(tagsColor[t]).join(', ')},0.5)`,
            textOverflow: 'ellipsis',
            maxWidth: 180,
          }}
        />
      </Grid>
    ))}
  </Grid>
);
export default TagCell;
