import { useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';

const generateRecommendedOffers = (data) => {
    return axios
        .post(apiPaths.generateRecommendedOffers, data , status200)
        .then( resp => resp.data ).catch( error => error);
};

const useGenerateRecommendedOffers = () => {
    return useMutation((values) => generateRecommendedOffers(values), {
      onSuccess: data => data,
      onError: error => error
    });
  };
  
  export default useGenerateRecommendedOffers;