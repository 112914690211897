import { styled } from '@mui/material';
import { Grid } from '@material-ui/core';

export const MuiGridRowContainer = styled(Grid)(({ theme }) => ({
  borderBottom:'1px solid #E0E0E0',
  height:'52px',
  paddingTop: '5px',
  paddingBottom: '5px'
}));

export const MuiGridInput = styled(Grid)(({ theme }) => ({
  display: 'grid',
  alignItems: 'center'
}));

export const MuiGridContent = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between'
}));
