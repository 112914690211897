import * as React from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { GLOBAL } from 'utils/constants';

import { useAuth } from 'components/providers/AuthProvider';
 import { secureRoutes, routes } from '../../routes';

const Views = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isAuthenticated, user } = useAuth();

  const data = {
    email: process.env.REACT_APP_USER_PUBLIC,
    password: process.env.REACT_APP_USER_PASSWORD
  }

  React.useEffect(() => {
    if (isAuthenticated && location?.pathname.includes('public') && user.type === 'level_0') {
      navigate(location.pathname);
    }
    else if (isAuthenticated  && !location?.pathname.includes('public') && user.type === 'level_0'){
      navigate('/login');
      return;
    }
    if (isAuthenticated) {
        if(location.pathname === '/' || location.pathname === '/login'){
          navigate(user.type === GLOBAL.ROLES.ADMIN || user.type === GLOBAL.ROLES.SUPERADMIN ? '/orgs' : `/orgs/${user.org_id}/maps`);
        } else {
          navigate(location.pathname);
        }
      }
    else {
        if (location?.pathname !== '/reset' && !location?.pathname.includes('public'))
            navigate('/login');
        if (location?.pathname.includes('public')) {
          login(data, location?.pathname, null);
        }
       }
  }, []);

  const selectedRoutes = useRoutes(
    isAuthenticated && user.type !== 'level_0'? secureRoutes(user.type, user.org_id, user.admin_rights, user.non_admin_users_orgs_ids_access_permissions) : routes
  );

  return <>{selectedRoutes}</>;
};
export default Views;
