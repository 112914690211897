import { Grid, Button, Container, styled } from '@mui/material';
import {GLOBAL} from "../../../../utils/constants";

export const MuiGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(2),
  justifyContent: 'flex-end',
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  borderColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400],
  padding:'10px 24px 10px 16px',
  height: '32px',
  '&:hover': {
    backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[100],
    borderColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400],
  }
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  maxWidth: '1051px !important',
  minHeight: '715px !important',
  backgroundColor:'#FAFAFA',
  borderRadius:'28px',
  '& .MuiContainer-root': {
    maxWidth: '1360px !important',
  },
}));
