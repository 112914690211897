import { styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../utils/constants';

export const MuiInfoCard = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[200],
  padding: 10,
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

export const MuiInfoCardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

export const MuiInfoCardData = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  overflow: 'hidden'
}));

export const MuiFilterInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const MuiVariableLabel = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: 'Montserrat'
}));

export const MuiVariableName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Montserrat',
}));

export const MuiFilterValue = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '14.63px',
  fontFamily: 'Montserrat',
}));

export const MuiFilterUnit = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  marginLeft: 5,
  fontFamily: 'Montserrat'
}));

export const MuiVariableValue = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
  fontSize: 21,
  letterSpacing: 0.15,
  fontWeight: 500,
  marginRight: 5,
  fontFamily: 'Montserrat',
}));

export const filterDifferenceContainerStyle = {
  width: 40,
  height: 14,
  borderRadius: 12,
  padding: '1px 6px',
};

export const positivePercentageStyle = {
  backgroundColor: '#10B981',
};

export const negativePercentageStyle = {
  backgroundColor: '#D7271D',
};

export const filterDifferenceStyle = {
  fontWeight: 500,
  fontSize: '10px !important',
  color: GLOBAL.COLOR.WHITE
};

export const baseLineAlign = {
  alignSelf: 'baseline'
}

export const prefixFilter = {
  marginRight: '5px',
  marginLeft: 0,
}
