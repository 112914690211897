/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import useDeleteDataset from 'api/hooks/useDeleteDataset';
import { useTranslation } from 'components/providers/TranslationProvider';
import useDatasetUsage from 'api/hooks/useDatasetUsage';
import ModalDelete from '../common/ModalDelete';
import { MuiTypographyContent } from '../common/styles/modalDelete';

const DeleteDataset = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const orgId = location.pathname.split('/')[2];
  const [datasetsUsage, setDatasetsUsage] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const { isLoading } = useDatasetUsage(location.state?._id, orgId, setDatasetsUsage);

  const deleteMutation = useDeleteDataset();

  const handleSubmit = () => {
    const data = {
      dataset_id: location.state?._id,
      name: location.state.name,
    };
    deleteMutation.mutateAsync(data);
  };

  const content = (
    <>
      {datasetsUsage.length > 0 ? (
        <>
          <MuiTypographyContent>
            {t('delete_dataset_scenario')}
          </MuiTypographyContent>
          <p style={{ textAlign: 'center', marginTop: '-10px' }}>
            <strong>{location.state?.prosperiaName}</strong>
          </p>
          <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
            <ul>
              {datasetsUsage.map(name => (
                <li key={name} style={{ fontSize: '12px' }}>
                  <strong>{name}</strong>
                </li>
              ))}
            </ul>
          </div>
          <p>{t('delete_dataset_related_layers')}</p>
        </>
      ) : (
        <>
          <MuiTypographyContent>
            {t('delete_dataset_without_scenario')}
          </MuiTypographyContent>
          <p style={{ textAlign: 'center', marginTop: '-10px' }}>
            <strong>{location.state?.prosperiaName}</strong>
          </p>
        </>
      )}
    </>
  );


  return (
    <>
      {!isLoading && (
        <ModalDelete
          deleteMutation={deleteMutation}
          onSubmit={handleSubmit}
          content={content}
          title={t('delete_dataset_title')}
          name={location.state?.prosperiaName}
          hideConfirmationField={ datasetsUsage.length > 0 }
          hideConfirmationButton={ datasetsUsage.length > 0 }
        />
      )}
    </>
  );
};

export default DeleteDataset;
