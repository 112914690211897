import { hexToHsv, hsvToHex, rgbToHsv } from './colorConverter';
import { lighten, darken } from '@mui/material/styles';

const error = (str) => {
  throw new Error(str);
};

const colorConvertByType = (type, color) => {
  const conversions = {
    rgb: rgbToHsv(color),
    hsv: color,
    hex: hexToHsv(color),
    default: () => error(`The type ${type} is not permitted`),
  };
  return conversions[type] || conversions.default();
};

/**
 * @param {(string|number[])} color - Input color
 * @param {number} count - Number of array colors that you need 2-11
 * @param {string} type - Input type of colors one of ['rgb','hsv','hex']
 * @return {Array} Number of colors in rgb that you defined
 */
export const createGradient = (color, count = 0, type) => {
  if (count < 2) error('The count is not should be < 2 ');
  else if (count > 11) error('The count is not should be > 11 ');

  const colorHsv = colorConvertByType(type, color);

  const [h] = colorHsv;
  const incrementS = Math.round(80 / (count - 1));
  const incrementV = Math.round(10 / (count - 1));
  const colors = [];

  Array.from(Array(count).keys()).forEach((i) => {
    colors.push([h, 20 + incrementS * i, 100 - incrementV * i]);
  });

  return colors.map((c) => {
    return hsvToHex(c);
  });
};

/**
 * @param {(string|number[])} color1 - Input color1
 * @param {(string|number[])} color2 - Input color2
 * @param {number} count - Number of array colors that you need 2-11
 * @param {string} type - Input type of colors one of ['rgb','hsv','hex']
 * @return {Array} Number of colors in rgb that you defined
 */
export const createGradientTwoColors = (color1, color2, count = 0, type) => {
  if (count < 2) error('The count is not should be < 2 ');
  else if (count > 11) error('The count is not should be > 11 ');

  const color1Hsv = colorConvertByType(type, color1);
  const color2Hsv = colorConvertByType(type, color2);

  const [h1] = color1Hsv;
  const [h2] = color2Hsv;
  const incrementS = Math.round(100 / (count / 2));
  const colors = [];

  Array.from(Array(count).keys()).forEach((i) => {
    if (i < count / 2) {
      colors.push([h1, 100 - incrementS * i, 100]);
    } else {
      colors.push([h2, 100 - incrementS * (count - i - 1), 100]);
    }
  });

  return colors.map((c) => {
    return hsvToHex(c);
  });
};

export const generateDynamicShades = (color) => {
  return {
    50: lighten(color, 0.5),
    100: lighten(color, 0.4),
    200: lighten(color, 0.3),
    300: lighten(color, 0.2),
    400: lighten(color, 0.1),
    500: color, // Base color
    600: darken(color, 0.1),
    700: darken(color, 0.2),
    800: darken(color, 0.3),
    900: darken(color, 0.4),
  };
}
