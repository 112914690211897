import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { TextField } from 'components/common/ReactHooksFormFields';
import { useTranslation } from 'components/providers/TranslationProvider';
import Modal from 'components/common/Modal';
import { setTab, updateTab } from 'store/appSlice';
import Toast from 'components/common/Toast';
import useRandomId from 'components/hooks/useRandomId';
import { MuiButtonClose } from '../../../../../common/styles/modalDelete';
import { MuiBtnAction } from '../../../../../dataset/styles/formModal';
import { inputStyle, labelInputStyle } from '../../form/styles/widgetForm';

const schema = yup.object().shape({
  name: yup.string().min(4, 'min_4_characters').max(80, 'max_80_characters').required('required'),
});

const WidgetForm = ({ action, open, onClose }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const randomId = useRandomId();

  const tabs = useSelector((state) => state.app.tabs);

  const [error, setError] = React.useState('');
  const [openToast, setOpenToast] = React.useState(false);

  const tab = location.state;

  const getDefaultValues = () => {
    return tab
      ? {
          name: tab?.name,
        }
      : {};
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods;

  const findRepeatNames = (name, id) => {
    const foundWidgetName = _.find(tabs, { name });
    if (foundWidgetName?.id === id) return false;
    return foundWidgetName;
  };

  const addTab = (data) => {
    if (_.find(tabs, { name: data.name })?.name) {
      setError(t('error_adding_new_widget_group'));
      setOpenToast(true);
      return;
    }
    dispatch(setTab({ id: randomId, ...data }));
    onClose();
  };

  const updTab = (data) => {
    if (findRepeatNames(data.name, tab.id)) {
      setError(t('error_adding_new_widget_group'));
      setOpenToast(true);
      return;
    }
    dispatch(updateTab({ id: tab.id, ...data }));
    onClose();
  };

  const onSubmit = handleSubmit((data) => (action === 'create' ? addTab(data) : updTab(data)));

  const handleCloseToast = () => setOpenToast(false);

  const actions = (
    <>
      <MuiButtonClose
        onClick={onClose}
        color='primary'
        variant='text'
      >
        <Typography
          fontFamily='Montserrat'
          fontSize={14}
          letterSpacing={0.25}>
          {t('cancel_btn')}
        </Typography>
      </MuiButtonClose>
      <MuiBtnAction
        onClick={onSubmit}
        variant='outlined'
        color='primary'
        disabled={!isValid || !isDirty} data-cy={'save_group'}>
        <Typography  fontFamily='Montserrat' fontSize={14}  letterSpacing={0.25}>
          {t('save_changes')}
        </Typography>
      </MuiBtnAction>
    </>
  );

  const groupForm = (
    <>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Grid container>
        <TextField
          name='name'
          margin='dense'
          type='text'
          label={t('group_name')}
          variant='outlined'
          fullWidth
          error={t(errors.name?.message)}
          autoFocus
          data-cy={'name_group'}
          InputLabelProps={{ style: labelInputStyle }}
          sx={inputStyle}
        />
      </Grid>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%' }}>
        <Modal
          open={open}
          onClose={onClose}
          title={
            action === 'create' ? `${t('add_new_widget_group')}` : `${t('update')}: ${tab?.name}`
          }
          actions={actions}
        >
          {groupForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default WidgetForm;
