import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setViewState} from 'lib/react-geo-tool/packages/react-redux/src/';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {useState} from "react";
import {setLayerMarker} from "store/appSlice";
import Divider from "@mui/material/Divider";
import {
  imgContainerStyle,
  imgGoogleMapsStyle,
  MuiDivRoot, MuiIconButton, MuiIconButtonSearch, searchInputStyle
} from './styles/searchPlace';

function SearchPlace ({ basemap }) {
  return <SearchPlaceAutocomplete basemap={basemap}/>
}

function SearchPlaceAutocomplete({ basemap }) {
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState(false);
  const zoomLevel = useSelector((state) => Math.floor(state.carto.viewState.zoom));

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete();

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (description ) => {
    setValue(description , false);
    clearSuggestions();
    dispatch(setLayerMarker(null));
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      let counter = 1;

      if (zoomLevel > 13) {
        dispatch(
          setViewState({
            zoom: 13,
          })
        );
      }

      if (zoomLevel > 11) {
        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 11
            })
          );
        }, counter * 600);
        counter += 1;
      }

      if (zoomLevel > 9) {
        setTimeout(() => {
          dispatch(
            setViewState({
              zoom: 9
            })
          );
        }, counter * 620);
        counter += 1;
      }

      setTimeout(() => {
        dispatch(
          setViewState({
            zoom: 9,
            latitude: lat,
            longitude: lng,
          })
        );
        dispatch(setLayerMarker({latitude: lat, longitude: lng, place: description }));
      }, counter * 645);
      counter += 1;

      setTimeout(() => {
        dispatch(
          setViewState({
            zoom: 11,
            latitude: lat,
            longitude: lng,
          })
        );
      }, counter * 665);
      counter += 1;

      setTimeout(() => {
        dispatch(
          setViewState({
            zoom: 13,
            latitude: lat,
            longitude: lng,
          })
        );
        dispatch(setLayerMarker({latitude: lat, longitude: lng, place: description }));
      }, counter * 690);
      // }
    });
  };

  const cleanInput = () => {
    setValue('');
    setShowSearch(false);
    dispatch(setLayerMarker(null));
  }

  const showSearchHandle = () => {
    setShowSearch(true);
  }

  const renderSuggestions = () => {
    const suggestions = data.map(({ place_id, description }) => (
      <div>
        <Divider />
        {/*{/*children={<RoomIcon/>}*/}
        <ComboboxOption key={place_id} value={description}  />
      </div>
    ));

    return (
      <>
        {suggestions}
        <li style={imgContainerStyle}>
          <img style={imgGoogleMapsStyle}
            src="https://www.google.com/images/branding/lockups/1x/lockup_maps_color_131x24dp.png"
            alt="Powered by Google"
          />
        </li>
      </>
    );
  };

  return (
    <>
    { showSearch ? (
        <MuiDivRoot>
          <Combobox onSelect={handleSelect} aria-labelledby="demo">
            <ComboboxInput
              style={searchInputStyle}
              value={value}
              onChange={handleInput}
              disabled={!ready}
            />
            <ComboboxPopover>
              <ComboboxList>{status === "OK" && renderSuggestions()}</ComboboxList>
            </ComboboxPopover>
          </Combobox>
            <MuiIconButton
              aria-label="close"
              size="medium"
              onClick={() => cleanInput()}
              variant='contained'
            >
              <CloseIcon fontSize="inherit" />
            </MuiIconButton>
        </MuiDivRoot>
      ) :
      (<MuiIconButtonSearch
        variant="contained"
        onClick={() => showSearchHandle()}
        size="medium"
      >
        <SearchIcon fontSize="inherit" />
      </MuiIconButtonSearch>)
    }
    </>
  );
}

export default SearchPlace;

