const useCanManageRecomendationWidgetsFilter = (params) => {
  if ( params.widget.type !== 'recommendations' ) {
    return false;
  }
  if ( params.widget.params.isGlobal && ( params.widget.params.userOwner === params.user.id || (params.user.type === 'admin' && params.user.admin_rights.includes(params.idOrganization)) || params.user.type === 'super_admin' )) {
    return true;
  }
  return !params.widget.params.isGlobal && ( params.widget.params.userOwner === params.user.id || (params.user.type === 'admin' && params.user.admin_rights.includes(params.idOrganization)) || params.user.type === 'super_admin' );

};

export default useCanManageRecomendationWidgetsFilter;