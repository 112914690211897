// CEIL, FLOOR, DECIMAL1, DECIMAL2, DECIMAL3

// eslint-disable-next-line import/prefer-default-export
export const Formatter = {
  ROUND: { text: 'ROUND', value: 'ROUND' },
  CEIL: { text: 'CEIL', value: 'CEIL' },
  FLOOR: { text: 'FLOOR', value: 'FLOOR' },
  DECIMAL1: { text: 'DECIMAL1', value: 'DECIMAL1' },
  DECIMAL2: { text: 'DECIMAL2', value: 'DECIMAL2' },
  DECIMAL3: { text: 'DECIMAL3', value: 'DECIMAL3' },
};
