import { createTheme } from '@material-ui/core/styles';
import { cartoThemeOptions } from 'lib/react-geo-tool/packages/react-ui/src/';
import { GLOBAL } from 'utils/constants';

const themeIncluia = createTheme({
    ...cartoThemeOptions,
    palette: {
      ...cartoThemeOptions.palette,
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      h1: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 300,
        fontSize: 99,
        letterSpacing: '-1.5px'
      },
      h2: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 300,
        fontSize: 62,
        letterSpacing: '-0.5px'
      },
      h3: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 400,
        fontSize: 49,
        letterSpacing: '0px'
      },
      h4: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 400,
        fontSize: 35,
        letterSpacing: '0.25px'
      },
      h5: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 400,
        fontSize: 25,
        letterSpacing: '0px'
      },
      h6: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 500,
        fontSize: 21,
        letterSpacing: '0.15px'
      },
      h7: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 700,
        fontSize: 29,
        letterSpacing: 0
      },
      subtitle1: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '0.15px'
      },
      subtitle1Heavy: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: '0.5px'
      },
      subtitle2: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: '0.1px'
      },
      body1: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '0.5px'
      },
      body2: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '0.5px'
      },
      caption: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '0.4px'
      },
      overline: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: 10,
        letterSpacing: '1.5px'
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            '& span': {
              fontFamily: 'Montserrat',
            },
          },
        },
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          '& $notchedOutline': {
            borderColor: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[700],
          },
          '&:hover $notchedOutline': {
            borderColor: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[700],
          },
          '&$focused $notchedOutline': {
            borderColor: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[700],
          },
        },
        notchedOutline: {},
      },
      MuiInputLabel: {
        root: {
          color: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[500],
          '&$focused': {
            color: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[700],
          },
          '&$error': {
            color: '#C1300B',
          },
        },
      },
      MuiInputBase: {
        input: {
          color: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[900],
        },
      },
    },
  });

export default themeIncluia;
