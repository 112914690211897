import { checkCredentials, getFiltersToString, getPolygonsParametersToQuery, makeCall } from './common';
import { MAP_TYPES, API_VERSIONS } from '@deck.gl/carto';
import { _assert as assert } from 'lib/react-geo-tool/packages/react-core/src/';
import { useSelector } from 'react-redux';

const URL_LENGTH = 2048;

const AVAILABLE_MODELS = ['category', 'histogram', 'formula', 'timeseries', 'range', 'list', 'statistics', 'polygon'];

/**
 * Execute a SQL model request.
 *
 * @typedef { import('geojson').Polygon | import('geojson').MultiPolygon } SpatialFilter
 * @param { object } props
 * @param { string } props.model - widget's model that we want to get the data for
 * @param { object } props.source - source that owns the column
 * @param { object } props.params - widget's props
 * @param { SpatialFilter= } props.spatialFilter - restrict widget calculation to an area
 * @param { string } props.spatialTable - model that we want to get the data for calculation for an area
 * @param { object= } props.opts - Additional options for the HTTP request
 */
export function executeModel(props) {
  assert(props.source, 'executeModel: missing source');
  assert(props.model, 'executeModel: missing model');
  assert(props.params, 'executeModel: missing params');

  assert(
    AVAILABLE_MODELS.indexOf(props.model) !== -1,
    `executeModel: model provided isn't valid. Available models: ${AVAILABLE_MODELS.join(
      ', '
    )}`
  );

  const { source, model, params, spatialFilter, opts, spatialTable } = props;
  checkCredentials(source.credentials);

  let url = `${source.credentials.apiBaseUrl}widgets/${model}?source=${source.datasetName}`;


  const { filters, filtersLogicalOperator, data, type } = source;
  const queryParameters = source.queryParameters
    ? JSON.stringify(source.queryParameters)
    : '';
  let queryParams = {
    type,
    source: data,
    params: JSON.stringify(params),
    queryParameters,
    filters: JSON.stringify(filters),
    filtersLogicalOperator
  };


  const isGet = url.length + JSON.stringify(queryParams).length <= URL_LENGTH;
  if (isGet) {
    // url += '?' + new URLSearchParams(queryParams).toString();
  } else {
    // undo the JSON.stringify, @todo find a better pattern
    queryParams.params = params;
    queryParams.filters = filters;
    queryParams.queryParameters = source.queryParameters;
  }

  const filter = getFiltersToString(source);
  let body = {
    filters: filter,
    variable: params.column,
  }

  if ( props.model === 'polygon' ) {
    delete body[filters];
    body['user_id'] = params.user_id;
  }

  if ( props.model ===  'histogram' ) {
    body['tick_size'] = 0;
  }

  if (params.columns) {
    body['columns'] = params.columns
  }

  if (params.parametersCatalog){
    body['parametersCatalog']=params.parametersCatalog;
  }

  if (spatialFilter) {
    const filter_type = spatialTable.includes('polygon') ? 'polygon':'recommendation';
    url +=`&filter_type=${filter_type}`;
    body['spatial_table'] = spatialTable;
    body['polygon_id'] = spatialFilter;
    body['polygon_id_col'] = filter_type === 'polygon' ? 'polygon_id' : 'ido'
  }

  return makeCall({
    url,
    credentials: source.credentials,
    opts: {
      ...opts,
      method: 'POST',
      body: JSON.stringify(body),
    }
  });
}
