import { OutlinedInput as MuiOutlinedInput, styled } from '@mui/material';
import { GLOBAL } from '../../../utils/constants';

export const OutlinedInput = styled(MuiOutlinedInput)(({ theme }) => ({
  height: 20,
  width: 70,
  borderRadius: '4px',
  border:'1px solid',
  borderColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300],
  overflow: 'hidden',
  marginRight: 8,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  input: {
    padding: 0,
    height: '100%',
    '&::-webkit-color-swatch-wrapper': {
      padding: 0,
    },
    '&::-webkit-color-swatch': {
      border: 'none',
    },
  },
}));
