import { styled, Switch, Typography } from '@mui/material';
import {GLOBAL} from "../../../../../../../../../utils/constants";

export const MuiSwitch = styled(Switch)(({ theme  }) => ({
  width: 52,
  height: 32,
  marginLeft: 8,
  marginTop: -5,
  padding: 0,
  display: 'flex',
  '&:active .MuiSwitch-thumb': {
    width: 12,
  },
  '&:active .Mui-checked': {
    transform: 'translateX(9px)',
  },
  '.MuiSwitch-switchBase': {
    color: '#4D8094',
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        border: 'none !important',
        opacity: 1,
        backgroundColor: GLOBAL.COLOR_THEME.PRIMARY,
      },
    },
  },
  '.MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    marginTop: 6,
    marginLeft: 4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '.MuiSwitch-track': {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: 20,
    border: '2px solid',
    borderColor: '#4D8094',
    boxSizing: 'border-box',
  },
  '.MuiSwitch-thumbChecked': {
    color: '#FFFFFF',
  },
}));

export const MuiSelectGradient = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:12,
  color:'#000000'
}));

export const MuiInvertColorPallete = styled(Typography)(({ theme }) => ({
  marginLeft: 12,
  fontSize:16,
  fontWeight:400,
  fontFamily:'Montserrat',
  color:'#000000'
}));

export const MuiDivider = styled('span')(({ theme }) => ({
  marginLeft:'5px',
  fontSize:'16px',
  marginRight:'5px'
}));

export const keyStyle = {
  fontSize:16,
  fontWeight:400,
  fontFamily:'Montserrat'
}

export const divContainerStyle = {
  display:'flex',
  marginLeft:16
}
