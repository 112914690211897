import { Typography, styled } from '@mui/material';


export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:16,
  color:'#000000'
}));

export const MuiTypographySubTitle = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:12,
  color:'#000000'
}));

export const gridColumnStyle = {
  display: 'flex',
  flexDirection: 'column'
}

export const popoverContainerStyle = {
  p: 2,
  position: 'relative',
  width: '433px'
}
