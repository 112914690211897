import { _executeModel } from 'lib/react-geo-tool/packages/react-api/src/';
import { normalizeObjectKeys, wrapModelCall } from './utils';

export function getStatistic(props) {
  return wrapModelCall(props, null, fromRemote);
}

async function fromRemote(props) {
    const { source, spatialFilter, abortController, spatialTable, ...params } = props;
  
    return await _executeModel({
      model: 'statistics',
      source,
      spatialFilter,
      spatialTable,
      params,
      opts: { abortController }
    }).then((res) => normalizeObjectKeys(res));
  }
