/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router';

import useCreateOrganization from 'api/hooks/useCreateOrganization';
import useUpdateOrganization from 'api/hooks/useUpdateOrganization';
import useUploadImage from 'api/hooks/useUploadImage';
import { useTranslation } from 'components/providers/TranslationProvider';
import { usePermissions } from 'components/hooks/usePermissions';
import Toast from 'components/common/Toast';
import { ColorField, NumericSelect, TextField } from 'components/common/ReactHooksFormFields';
import Modal from 'components/common/Modal';

import {
  colorCommonStyle,
  customDividerStyle,
  gridDividerStyle,
  inputStyleColor,
  MuiColorPickers,
  MuiGridCommon,
  nameFieldStyle,
  shortNameFieldStyle
} from './styles/formModal';
import UploadImage from './UploadImage';
import { MuiBtnAction, MuiBtnClose } from './dataset/styles/formModal';
import {
  labelInputStyle, inputStyle
} from "./scenarios/mapsHandlers/widget/form/styles/widgetForm";

const positiveRequiredNumber = yup
  .number()
  .integer('number_integer')
  .positive('number_positive')
  .typeError('field_number')
  .min(1, 'field_more_that_0')
  .required('required');

const schema = yup.object().shape({
  name: yup.string().required('required'),
  shortName: yup
    .string()
    .min(2, 'min_2_characters')
    .required('required'),
  primaryColor: yup.string(),
  secondaryColor: yup.string(),
  maxNumbUsers: positiveRequiredNumber,
  maxDataSetSize: positiveRequiredNumber,
  maxNumbDataSet: positiveRequiredNumber,
  maxNumbScenarios: positiveRequiredNumber,
});

const FormModal = ({ action, open, onClose }) => {
  const { t } = useTranslation();
  const { hasPermissionFor } = usePermissions();

  const location = useLocation();

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');

  const organization = location.state;

  const defaultProsperiaImage = 'https://prosperia-ds-platform.s3.amazonaws.com/1622640762526-logo.0c7a85ba.png';

  const [organizationLogo, setOrganizationLogo] = React.useState(
    organization ? organization.attrs.logo_url : defaultProsperiaImage
  );

  const [previousOrganizationLogo, setPreviousOrganizationLogo] = React.useState('');
  const [previousOrganizationProfileImage, setPreviousOrganizationProfileImage] = React.useState('');

  React.useEffect(() => {
    if (previousOrganizationLogo === '') {
      setPreviousOrganizationLogo(organizationLogo);
    }
    if (previousOrganizationProfileImage === '') {
      setPreviousOrganizationProfileImage(organizationProfileImage);
    }
  }, []);

  const [organizationProfileImage, setOrganizationProfileImage] = React.useState(
    organization ? organization.attrs.profile_img_url : defaultProsperiaImage
  );

  const { mutateAsync: uploadImage, isError: uploadIsError, error: uploadError } = useUploadImage();

  const {
    mutateAsync: updateOrganization,
    isSuccess: updateSuccess,
    isError: updateIsError,
    error: updateError,
    isLoading: updateIsLoading,
  } = useUpdateOrganization();
  const {
    mutateAsync: createOrganization,
    isSuccess: createSuccess,
    isError: createIsError,
    error: createError,
    isLoading: createIsLoading,
  } = useCreateOrganization();

  const getDefaultValues = () => {
    return organization
      ? {
          name: organization.name,
          shortName: organization.shortName,
          primaryColor: organization.attrs.primary_color,
          secondaryColor: organization.attrs.secondary_color,
          maxNumbUsers: organization.attrs.max_users,
          maxDataSetSize: organization.attrs.max_dataset_size,
          maxNumbDataSet: organization.attrs.max_datasets,
          maxNumbScenarios: organization.attrs.max_users,
        }
      : {
          maxNumbUsers: 10,
          maxDataSetSize: 50,
          maxNumbDataSet: 10,
          maxNumbScenarios: 10,
          primaryColor: '#000000',
          secondaryColor: '#ffffff',
        };
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleUploadLogo = async (e) => {
    const file = new FormData();
    file.append('image_file', e.target.files[0]);
    const imageUrl = await uploadImage(file);
    setOrganizationLogo(imageUrl);
  };
  const handleUploadProfileImage = async (e) => {
    const file = new FormData();
    file.append('image_file', e.target.files[0]);
    const imageUrl = await uploadImage(file);
    setOrganizationProfileImage(imageUrl);
  };

  const updateOrg = async (data) => {
    const org = {
      name: data.name,
      shortName: data.shortName,
      org_id: organization._id,
      _id: organization._id,
      attrs: {
        logo_url: organizationLogo,
        profile_img_url: organizationProfileImage,
        primary_color: data.primaryColor,
        secondary_color: data.secondaryColor,
        max_dataset_size: data.maxDataSetSize,
        max_datasets: data.maxNumbDataSet,
        max_targeting_schemes: data.maxNumbScenarios,
        max_users: data.maxNumbUsers,
      },
    };
    await updateOrganization(org);
  };

  const newOrg = async (data) => {
    const defaultImg =
      'https://prosperia-ds-platform.s3.amazonaws.com/1622640762526-logo.0c7a85ba.png';
    const org = {
      name: data.name,
      shortName: data.shortName,
      attrs: {
        logo_url: organizationLogo || defaultImg,
        profile_img_url: organizationProfileImage || defaultImg,
        primary_color: data.primaryColor,
        secondary_color: data.secondaryColor,
        max_dataset_size: data.maxDataSetSize,
        max_datasets: data.maxNumbDataSet,
        max_targeting_schemes: data.maxNumbScenarios,
        max_users: data.maxNumbUsers,
      },
    };
    await createOrganization(org);
  };

  React.useEffect(() => {
    if (updateSuccess) {
      onClose();
    }
    if (updateIsError) {
      setError(t(updateError?.data?.error));
      setOpenToast(true);
    }
  }, [updateIsError, updateSuccess, onClose, updateError?.data?.error, t]);

  React.useEffect(() => {
    if (createSuccess) {
      onClose();
    }
    if (createIsError) {
      setError(t(createError?.data?.error));
      setOpenToast(true);
    }
  }, [createIsError, createSuccess, onClose, createError?.data?.error, t]);

  React.useEffect(() => {
    if (uploadIsError) {
      setError(t(uploadError?.data?.error));
      setOpenToast(true);
    }
  }, [uploadIsError, uploadError?.data?.error, t]);

  const areImagesChanged = () => {
    return (previousOrganizationLogo !== organizationLogo) || (previousOrganizationProfileImage !== organizationProfileImage)
  }

  const onSubmit = handleSubmit((data) => (action === 'update' ? updateOrg(data) : newOrg(data)));

  const actions = (
    <>
      <MuiBtnClose onClick={onClose} variant='text'>
        {t('cancel_btn')}
      </MuiBtnClose>
      <MuiBtnAction
        onClick={onSubmit}
        type='submit'
        loading={createIsLoading || updateIsLoading}
        disabled={!isValid || createIsLoading || updateIsLoading || (!isDirty && !areImagesChanged())  }
        variant='outlined'
      >
        {t(action)}
      </MuiBtnAction>
    </>
  );

  const organizationForm = (
    <>
      <Toast
        message={error}
        vertical='top'
        horizontal='center'
        severity='error'
        open={openToast}
        handleClose={handleCloseToast}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            style={nameFieldStyle}
            name='name'
            margin='dense'
            type='text'
            label={t('org_name')}
            variant='outlined'
            fullWidth
            error={t(errors.name?.message)}
            InputLabelProps={{
              style: labelInputStyle
            }}
            sx={inputStyle}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='shortName'
            style={shortNameFieldStyle}
            margin='dense'
            type='text'
            label={t('org_shortName')}
            variant='outlined'
            fullWidth
            error={t(errors.shortName?.message)}
            InputLabelProps={{
              style: labelInputStyle
            }}
            sx={inputStyle}
          />
        </Grid>
        <Grid item xs={12} style={gridDividerStyle}>
          <hr style={customDividerStyle}/>
        </Grid>
        <Grid container justifyContent='center' item xs={6} direction='column'>
          <MuiGridCommon>
            <Typography variant="caption" style={colorCommonStyle} align='center'>{t('org_logo')}</Typography>
          </MuiGridCommon>
          <Grid item>
            <UploadImage
              handleChange={handleUploadLogo}
              image={organizationLogo}
              name='organizationLogo'
            />
          </Grid>
        </Grid>
        <Grid container justifyContent='center' item xs={6}>
          <MuiGridCommon item >
            <Typography variant="caption" style={colorCommonStyle}>{t('org_image')}</Typography>
          </MuiGridCommon>
          <Grid item>
            <UploadImage
              handleChange={handleUploadProfileImage}
              image={organizationProfileImage}
              name='organizationProfileImage'
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr style={customDividerStyle}/>
        </Grid>
        <Grid style={{ paddingTop: 10 }} item xs={6}>
          <MuiColorPickers item xs={12} >
            <Grid container alignContent='center' item style={{ height: 24 }} xs={10}>
              <Typography variant="caption"  sx={{fontFamily: 'Montserrat'}}>{t('primary_color')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <ColorField name='primaryColor' fullWidth style={inputStyleColor} />
            </Grid>
          </MuiColorPickers>
        </Grid>
        <Grid style={{ paddingTop: 10 }} item xs={6}>
          <MuiColorPickers item xs={12} >
            <Grid container alignContent='center' style={{ height: 24 }} item xs={10}>
              <Typography variant="caption"  sx={{fontFamily: 'Montserrat'}}>{t('secondary_color')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <ColorField name='secondaryColor' fullWidth style={inputStyleColor} />
            </Grid>
          </MuiColorPickers>
        </Grid>
        {hasPermissionFor('create_org') && (
          <>
            <Grid item xs={6}>
              <NumericSelect
                fullWidth
                name='maxNumbUsers'
                label={t('max_users_label')}
                error={t(errors.maxNumbUsers?.message)}
              />
            </Grid>
            <Grid item xs={6}>
              <NumericSelect
                fullWidth
                name='maxDataSetSize'
                label={t('max_dataset_size_label')}
                error={t(errors.maxDataSetSize?.message)}
              />
            </Grid>
            <Grid item xs={6}>
              <NumericSelect
                fullWidth
                name='maxNumbDataSet'
                label={t('max_datasets_label')}
                error={t(errors.maxNumbDataSet?.message)}
              />
            </Grid>
            <Grid item xs={6}>
              <NumericSelect
                fullWidth
                name='maxNumbScenarios'
                label={t('max_scenarios_label')}
                error={t(errors.maxNumbScenarios?.message)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );

  return (
    <FormProvider FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          // onClose={onClose}
          title={
            action === 'update' ? `${t('update_org')}` : `${t('create_new_org')}`
          }
          actions={actions}
          widthInPixels={380}
        >
          {organizationForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default FormModal;
