import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'components/providers/TranslationProvider';

const fetchDataSetsOrg = (orgId) =>
  axios.get(`${apiPaths.dataSetCarto}/${orgId}`, status200).then((resp) => resp.data);

const useDataSetsOrg = (orgId) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery(
    ['data_set', orgId],
    () => fetchDataSetsOrg(orgId),
    { 
      refetchOnWindowFocus: false,
      onError: (error) => {
        dispatch(setOpenToast(true));
        dispatch(setMessageToast(t(error.data.error)));
        return error;
      },
    }
  )
};

export default useDataSetsOrg;
