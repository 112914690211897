import { Grid, IconButton, styled } from '@mui/material';
import { lighten } from '@mui/material/styles';

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  color: 'rgb(35, 127, 158)',
}));

export const MuiGridLink = styled(Grid)(({ theme }) => ({
  display: 'flex',
  background: lighten( localStorage.getItem('colorPrimary'), 0.9),
  borderRadius: theme.spacing(1.5),
  justifySelf: 'center',
  paddingLeft: theme.spacing(1),
  margin: theme.spacing(0),
  maxWidth: 700,
  maxHeight: theme.spacing(4.1),
}));
