import { styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../utils/constants';

export const MuiImg = styled('img')(({ theme }) => ({
  height: 40,
  objectFit: GLOBAL.POSITION.SCALEDOWN,
  alignSelf: 'center'
}));

export const MuiImgLogo = styled('img')(({ theme }) => ({
  width: 33,
  height: 32,
  objectFit: GLOBAL.POSITION.SCALEDOWN,
  alignSelf: 'center'
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  marginLeft: 4,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: GLOBAL.COLOR.WHITE,
}));

export const MuiTypographyName = styled(Typography)(({ theme }) => ({
  color: GLOBAL.COLOR.WHITE,
  alignSelf: 'center',
  marginLeft: 8
}));

export const containerStyle = {
  maxWidth: '400px'
};

export const organizationlogoNameContainer = {
  display: 'flex',
};
