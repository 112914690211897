import { Button, Grid, styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../../utils/constants';

const styles = (theme) => ({
  navBar: {
    boxShadow: 'none',
    overflow: 'hidden',
    marginBottom: 40,
  },
  toolBar: {
    paddingLeft: '65vh',
    backgroundColor: 'white',
  },
  img: {
    width: 240,
    height: 300,
    objectFit: 'scale-down',
  },
  imageContainer: {
    textAlign: 'center',
  },
  typography: {
    width: '100vh',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  paper: {
    justifyContent: 'center',
  },
  container: {
    marginTop: theme.spacing(2),
    alignItems: 'left',
  },
  buttonsContainer: {
    display: 'flex',
    margin: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  tableContainer: {
    paddingLeft: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(3),
    justifySelf: 'end',
    borderRadius:'16px',
    fontFamily:'Montserrat',
    fontSize:14,
    color: '#616161',
    borderColor: '#BDBDBD',
    padding:'10px 24px 10px 16px',
    height: '32px'
  },
});
export const MuiButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(2),
  justifyContent: 'flex-end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  borderColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400],
  padding:'10px 24px 10px 16px',
  height: '32px',
  '&:hover': {
    backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[100],
    borderColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400],
  }
}));

export const MuiGridImgContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));
export const MuiGridPaper = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
}));

export const MuiImg = styled("img")(({ theme }) => ({
  width: 248,
  backgroundColor: 'white',
  height: 248,
  borderRadius:'19px',
  border:'3px solid',
  borderColor: theme.palette.primary.main,
  objectFit: 'scale-down'
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  alignItems: 'left',
  backgroundColor:'#FAFAFA',
  borderRadius:'28px',
  '& .MuiContainer-root': {
    maxWidth: '1360px !important',
  },
}));
export const MuiGridButtonsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginTop: '15px !important',
  marginBottom: '10px',
  justifyContent: 'flex-end',
}));

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(-3),
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  textAlign: 'start',
  width: 'auto',
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(-4),
}));

export default styles;
