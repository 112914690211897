import * as React from 'react';
import { Grid, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { MuiDivLayout, MuiGrid, MuiGridLayout } from './styles/login';
import LanguageSelector from 'components/common/LanguageSelector';

const LoginLayout = () => {

  return (
    <MuiGridLayout container component='main'>
      <Grid item xs={false} sm={false} md={7}>
        <MuiDivLayout/>
      </Grid>
      <Grid item xs={12} sm={12} md={5} component={Paper} sx={{height: 'inherit'}}>
        <MuiGrid>
          <LanguageSelector />
        </MuiGrid>
        <Outlet />
      </Grid>
    </MuiGridLayout>
  );
};

export default LoginLayout;
