import { useQueryClient, useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import { useDispatch } from 'react-redux';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useTranslation } from 'components/providers/TranslationProvider';

const deleteOrganization = (org) => {
  return axios.delete(apiPaths.orgs, { data: org.data }, status200).then((resp) => resp.data);
};

const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation((values) => deleteOrganization(values), {
    onSuccess: () => {
      queryClient.resetQueries(['organizations']);
    },
    onError: (error) => {
      dispatch(setOpenToast(true));
      dispatch(setMessageToast(t(error.data.error)));
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['organizations']);
    },
  });
};

export default useDeleteOrganization;
