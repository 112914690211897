import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchCartoPiaToken = () =>
  axios.get(apiPaths.refreshCartoPiaToken, status200).then((resp) => resp.data);

const useCartoPiaToken = () =>
  useQuery('carto-pia-token', () => fetchCartoPiaToken(), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

export default useCartoPiaToken;
