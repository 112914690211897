/* eslint-disable one-var */
/* eslint-disable prefer-const */
/* eslint-disable no-use-before-define */
/* eslint-disable no-var */
/* eslint-disable prefer-template */
const RGB_MAX = 255;
const HUE_MAX = 360;
const SV_MAX = 100;

const rgbToHsv = (color) => {
  let [r, g, b] = color;

  // It converts [0,255] format, to [0,1]
  r = r === RGB_MAX ? 1 : (r % RGB_MAX) / parseFloat(RGB_MAX);
  g = g === RGB_MAX ? 1 : (g % RGB_MAX) / parseFloat(RGB_MAX);
  b = b === RGB_MAX ? 1 : (b % RGB_MAX) / parseFloat(RGB_MAX);

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    v = max;

  const d = max - min;

  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  return [Math.round(h * HUE_MAX), Math.round(s * SV_MAX), Math.round(v * SV_MAX)];
};

const hsvToRgb = (color) => {
  let [h, s, v] = color;

  h = normalizeAngle(h);
  h = h === HUE_MAX ? 1 : ((h % HUE_MAX) / parseFloat(HUE_MAX)) * 6;
  s = s === SV_MAX ? 1 : (s % SV_MAX) / parseFloat(SV_MAX);
  v = v === SV_MAX ? 1 : (v % SV_MAX) / parseFloat(SV_MAX);

  const i = Math.floor(h);
  const f = h - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  const mod = i % 6;
  const r = [v, q, p, p, t, v][mod];
  const g = [t, v, v, q, p, p][mod];
  const b = [p, p, t, v, v, q][mod];

  return [Math.floor(r * RGB_MAX), Math.floor(g * RGB_MAX), Math.floor(b * RGB_MAX)];
};

const hexToRgb = (color) => {
  // Manage shorthand hexadecimal form
  let result = /^#?([a-f\d])([a-f\d])([a-f\d])$/i.exec(color);
  if (result) {
    return [
      parseInt(`${result[1]}${result[1]}`, 16),
      parseInt(`${result[2]}${result[2]}`, 16),
      parseInt(`${result[3]}${result[3]}`, 16),
    ];
  }

  // Manage hexadecimal form
  result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  if (result) {
    return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
  }

  return null;
};

const rgbToHex = (color) => {
  let [r, g, b] = color;
  r = Math.round(r).toString(16);
  g = Math.round(g).toString(16);
  b = Math.round(b).toString(16);

  r = r.length === 1 ? '0' + r : r;
  g = g.length === 1 ? '0' + g : g;
  b = b.length === 1 ? '0' + b : b;

  return '#' + r + g + b;
};

const hsvToHex = (color) => {
  const rgb = hsvToRgb(color);
  return rgbToHex(rgb);
};

const hexToHsv = (color) => {
  const rgb = hexToRgb(color);
  return rgbToHsv(rgb);
};

const normalizeAngle = (degrees) => {
  return ((degrees % 360) + 360) % 360;
};

export { hexToRgb, hexToHsv, rgbToHex, hsvToHex, hsvToRgb, rgbToHsv };
