import { useMutation, useQueryClient } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const saveMapState = (data, setOpenToast = undefined) => {
  axios
    .post(apiPaths.save_map_state, { ...data }, status200)
    .then((resp) => resp.data)
    .catch(() => {
      if ( setOpenToast ) {
        setOpenToast(true)
      }
    });
};

const useChangeMapState = (setOpenToast = undefined) => {
  const queryClient = useQueryClient();
  // eslint-disable-next-line no-return-await
  return useMutation(async (values) => await saveMapState(values, setOpenToast), {
    onSuccess: () => {
      queryClient.resetQueries('map-state');
    },
    onError: (error) => {
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries('map-state');
    },
  });
};
export default useChangeMapState;
