import { Button, styled } from '@mui/material';
import { GLOBAL } from '../../../../../utils/constants';

export const MuiBtnUpload = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  justifyContent: 'center',
  borderRadius: 20,
  border: `1px solid ${GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]}`,
  color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
}));

export const gridStyle = {
  justifyContent: 'center'
}

export const inputStyle = {
  display: 'none',
}
