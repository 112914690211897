/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';


const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    forceOAuthLogin: false, // enable for an initial Login screen
    layers: [],
    polygonLayers: [],
    widgets: [],
    tabs: [],
    layerLoadingState: {},
    polygonDataLoading: false,
    importJobErrors: {},
    mapInfoBeforeReload: {},
    maxVisibleWidgets: 0,
    helpImpar: 0,
    sendMapStateToServer: false,
    isValidDescriptiveScenario: false,
    isValidLayerForm: false,
    widgetColor: {},
    isTooltipOnClick: true,
    onClick: null,
    privateLoading: false,
    refreshOrgInfoPublicEmbeddedMap: 0,
    richDescription: '',
    accessToken: '',
    layerMarker: null,
    layerIsochrones: {},
    enabledSelectPoint: false,
    allowUpgradeOperator: false,
    showActionCatalog: false,
    showDownloadLayer: false,
    scenarioVisibility: false,
    updatePolygonWidget: false,
    updateRecommendationLayerFromWidget: false,
    selectedPointCoordinate: null,
    selectedListWidget: {},
    categoryWidgets: [],
    listPolygonWidgetForDelete: {},
    drawedGeometry: null,
    deletedPolygon: false,
    deletedRecommendation: false,
    openToast: false,
    messageToast: '',
    severityToast: 'error',
    initialViewPort: [],
    listFilterPolygonData: {},
    selectWidgetId: null,
    widgetsPrevState: [],
    layerRecommendations: {},
    listRecommendationsWidgetForDelete: {},
    userHiddenLayers: {},
    recomputedRecommendationsWidgets: [],
    widgetFiltersForTargetDemand: [],
    widgetFiltersForCurrentOffer: [],
    parametersCatalog: [],
    pointsOfReference: [],
    activeNotification: 'none',
    hasFetchedLayerData:{},
    isWidgetsPanelVisible: true,
  },
  reducers: {
    setPrivateLoading: (state, action) => {
      state.privateLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setOpenToast: (state, {payload}) => {
      state.openToast = payload;
    },
    setMessageToast: (state, {payload}) => {
      state.messageToast = payload;
    },
    setSeverityToast: (state, {payload}) => {
      state.severityToast = payload;
    },
    setInitialViewPort: (state, {payload}) => {
      state.initialViewPort = payload;
    },

    setSelectWidgetId: (state, {payload}) => {
      state.selectWidgetId = payload;
    },

    setLayers: (state, {payload}) => {
      state.layers = payload;
    },

    setPolygonLayers: (state, {payload}) => {
      state.polygonLayers = payload;
    },

    setAsDeletedPolygon: (state, {payload}) => {
      state.deletedPolygon = payload;
    },
    setAsDeletedRecommendation: (state, {payload}) => {
      state.deletedRecommendation = payload;
    },
    setLayerMarker: (state, {payload}) => {
      state.layerMarker = payload;
    },

    setLayerIsochrones: (state, {payload}) => {
      if (payload.drawedGeometry.length === 0) {
        let updatedState = {...state.layerIsochrones};
        delete updatedState[payload.id];
        state.layerIsochrones = updatedState;
      } else {
        state.layerIsochrones = {
          ...state.layerIsochrones,
          [payload.id]: {
            drawedGeometry: payload.drawedGeometry,
            layerColor: payload.layerColor
          }
        };
      }
    },
    deleteLayerIsochrones: (state, {payload}) => {
      let newState = {...state.layerIsochrones};
      payload.forEach(id => {
        delete newState[id];
      });
      state.layerIsochrones = {...newState};
    },
    setDrawedGeometry: (state, {payload}) => {
      state.drawedGeometry = payload;
    },
    setLayerRecommendations: (state, {payload}) => {
      state.layerRecommendations[payload.id] = payload;
    },
    setEnabledSelectPoint: (state, {payload}) => {
      state.enabledSelectPoint = payload;
    },

    setParametersCatalog: (state, {payload}) => {
      state.parametersCatalog = payload;
    },

    setAllowUpgradeOperator: (state, {payload}) => {
      state.allowUpgradeOperator = payload;
    },
    setShowActionCatalog: (state, {payload}) => {
      state.showActionCatalog = payload;
    },
    setScenarioVisibility: (state, {payload}) => {
      state.scenarioVisibility = payload;
    },
    setShowDownloadLayer: (state, {payload}) => {
      state.showDownloadLayer = payload;
    },

    setWidgetsPrevState: (state, {payload}) => {
      if (payload.condition) {
        state.widgetsPrevState = payload.id
          ? [...state.widgetsPrevState.filter(w => w.id !== payload.id), ...state.widgets.filter(w => w.id === payload.id)]
          : state.widgets;
      }
    },

    setUpdatePolygonWidget: (state, {payload}) => {
      state.updatePolygonWidget = payload;
    },

    setUpdateRecommendationLayerFromWidget: (state, {payload}) => {
      state.updateRecommendationLayerFromWidget = payload;
    },

    setSelectedPointCoordinate: (state, {payload}) => {
      state.selectedPointCoordinate = payload;
    },

    changeLayerVisibility: (state, {
      payload: {
        id,
        visible,
        triggeredByUser = false
      }
    }) => {
      state.layers = state.layers.map((l) => (l.id === id ? {
        ...l,
        visible,
        hide: visible
      } : l));
      state.sendMapStateToServer = true;
      if (triggeredByUser) {
        state.userHiddenLayers[id] = {id, visible};
      } else if (state.userHiddenLayers[id]) {
        delete state.userHiddenLayers[id];
      }
    },

    changeRecommendationAreaLayerVisibility: (state, {
      payload: {
        id,
        visible
      }
    }) => {
      if (state.layerRecommendations[id]) {
        state.layerRecommendations[id] = {
          ...state.layerRecommendations[id],
          visible: visible
        };
      }
    },

    changeLayerHide: (state, {payload: {id, hide}}) => {
      state.layers = state.layers.map((l) => (l.id === id ? {
        ...l,
        hide,
        visible: hide
      } : l));
    },
    updateLayer: (state, {payload}) => {
      state.layers = state.layers.map((l) => (l.id === payload.id ? payload : l));
      state.isValidDescriptiveScenario = true;
    },
    setLayer: (state, action) => {
      state.layers = [...state.layers, action.payload];
      state.isValidDescriptiveScenario = true;
    },
    deleteLayer: (state, {payload}) => {
      state.layers = state.layers.filter((l) => l.id !== payload.id);
      state.widgets = state.widgets.filter((w) => w.layer !== payload.id);
      state.isValidDescriptiveScenario = true;
    },
    changePolygonLayerVisibility: (state, {payload: {id, visible}}) => {
      state.polygonLayers = state.polygonLayers.map((l) => (l.id === id ? {
        ...l,
        visible,
        hide: visible
      } : l));
    },
    changePolygonLayerHide: (state, {payload: {id, hide}}) => {
      state.polygonlayers = state.polygonlayers.map((l) => (l.id === id ? {
        ...l,
        hide
      } : l));
    },
    updatePolygonLayer: (state, {payload}) => {
      state.polygonLayers = state.layers.map((l) => (l.id === payload.id ? payload : l));
    },
    setPolygonLayer: (state, action) => {
      state.polygonLayers = [...state.polygonLayers, action.payload];
    },
    deletePolygonLayer: (state, {payload}) => {
      state.polygonLayers = state.polygonLayers.filter((l) => l.id !== payload.id);
    },
    deleteTooltip: (state, {payload: {layerId}}) => {
      state.layers = state.layers.map((l) => (l.id === layerId ? {
        ...l,
        tooltip: null
      } : l));
    },

    setTab: (state, {payload}) => {
      state.tabs = [payload, ...state.tabs];
      state.isValidDescriptiveScenario = true;
    },
    setAllTabs: (state, {payload}) => {
      state.tabs = payload;
    },
    updateTab: (state, {payload}) => {
      state.tabs = state.tabs.map((t) => (t.id === payload.id ? payload : t));
      state.isValidDescriptiveScenario = true;
    },
    deleteTab: (state, {payload}) => {
      state.tabs = state.tabs.filter((w) => w.id !== payload);
      state.widgets = state.widgets.filter((w) => w.default_tab !== payload);
    },

    setWidgets: (state, {payload}) => {
      state.widgets = payload;
    },
    setWidget: (state, {payload}) => {
      state.widgets = [...state.widgets, payload];
      state.isValidDescriptiveScenario = true;
    },
    setWidgetFoldedState: (state, {payload: {widgetId, foldedState}}) => {
      state.widgets = state.widgets.map((w) => {
        const retorno = w.id === widgetId ? {
          ...w,
          folded_state: foldedState
        } : w
        return retorno;
      });
      state.sendMapStateToServer = true;
    },
    updateWidget: (state, {payload}) => {
      state.widgets = state.widgets.map((w) => (payload.id === w.id ? {...payload} : w));
      state.isValidDescriptiveScenario = true;
    },
    deleteWidget: (state, {payload}) => {
      state.widgets = state.widgets.filter((d) => d.id !== payload);
      state.isValidDescriptiveScenario = true;
    },
    deleteWidgetByLayerId: (state, {payload}) => {
      state.widgets = state.widgets.filter((w) => w.layer !== payload);
      state.isValidDescriptiveScenario = true;
    },
    cleanSchemeState: (state) => {
      state.layers = [];
      state.polygonLayers = [];
      state.widgets = [];
      state.tabs = [];
      state.layerLoadingState = {};
      state.maxVisibleWidgets = 0;
      state.isValidDescriptiveScenario = false;
      state.isValidLayerForm = false;
      state.widgetColor = {}
      state.isTooltipOnClick = true;
      state.onClick = null;
      state.refreshOrgInfoPublicEmbeddedMap = 0;
      state.layerRecommendations = {};
      state.listRecommendationsWidgetForDelete = {};
      state.listPolygonWidgetForDelete = {};
      state.listFilterPolygonData = {};
      state.userHiddenLayers = {};
      state.hasFetchedLayerData= {};
    },

    cleanMapInfoBeforeReload: (state) => {
      state.mapInfoBeforeReload = {};
      state.helpImpar = 0;
    },

    cleanImportJobErrors: (state) => {
      state.importJobErrors = {};
    },

    setIsValidDescriptiveScenario: (state, action) => {
      state.isValidDescriptiveScenario = action.payload;
    },

    setIsValidLayerForm: (state, action) => {
      state.isValidLayerForm = action.payload;
    },

    setWidgetColor: (state, {payload: {layer, widgetColor}}) => {
      let wColor;
      if (state.widgetColor && state.widgetColor?.widgetId === widgetColor?.widgetId) {
        wColor = undefined;
      } else {
        wColor = {...widgetColor, layer: layer};
      }
      state.layers = state.layers.map((l) => {
        if (l.id === layer) {
          if (wColor === undefined) {
            const {widgetColor, ...rest} = l;
            return rest;
          } else {
            return {...l, widgetColor: wColor};
          }
        } else {
          return l;
        }
      });
      if (wColor === undefined) {
        delete state.widgetColor;
      } else {
        state.widgetColor = wColor;
      }
    },
    clearWidgetColors: (state, action) => {
      state.widgetColor = {}
      state.layers = state.layers.map(({widgetColor, ...layer}) => layer);
    },

    setIsTooltipOnClick: (state, action) => {
      state.isTooltipOnClick = action.payload;
    },
    setOnClick: (state, action) => {
      state.onClick = action.payload;
    },
    setRefreshOrgInfoPublicEmbeddedMap: (state, {payload}) => {
      state.refreshOrgInfoPublicEmbeddedMap = payload;
    },

    setSendMapStateToServer: (state, {payload}) => {
      state.sendMapStateToServer = payload;
    },
    setLayerLoadingState: (state, {payload: {layerId, loadingState, size, downloaded}}) => {
      if (layerId){
        state.layerLoadingState = {
          ...state.layerLoadingState,
          [layerId]: { loadingState: loadingState , size: size , progress: downloaded}
        }
      }
    },
    setHasFetchedLayerData: (state, {payload: {layerId}}) => {
      if (layerId){
        state.hasFetchedLayerData = {
          ...state.hasFetchedLayerData,
          [layerId]: true
        }
      }
    },
    setPolygonDataLoading: (state, {payload}) => {
      state.polygonDataLoading = payload;
    },
    setMapInfoBeforeReload: (state, {payload}) => {
      state.mapInfoBeforeReload = payload;
    },
    setImportJobErrors: (state, {payload}) => {
      state.importJobErrors = payload;
    },
    setMaxVisibleWidgets: (state, {payload}) => {
      state.maxVisibleWidgets = payload;
    },
    setRichDescription: (state, {payload}) => {
      state.richDescription = payload;
    },
    setAccessToken: (state, {payload}) => {
      state.accessToken = payload;
    },
    setSelectedListWidget: (state, {payload}) => {
      state.selectedListWidget = payload;
    },
    setCategoryWidgets: (state, {payload}) => {
      const widgetIndex = state.categoryWidgets.findIndex(({widgetId}) => {
        return widgetId === payload.widgetId;
      });
      if (widgetIndex < 0) {
        state.categoryWidgets = [...state.categoryWidgets, payload];
      }
    },
    setListPolygonWidgetForDelete: (state, {payload}) => {
      if (Object.keys(payload).length === 0) {
        state.listPolygonWidgetForDelete = {};
      } else if (payload.selection.length === 0) {
        let updatedState = {...state.listPolygonWidgetForDelete};
        delete updatedState[payload.id];
        state.listPolygonWidgetForDelete = updatedState;
      } else {
        state.listPolygonWidgetForDelete = {
          ...state.listPolygonWidgetForDelete,
          [payload.id]: payload.selection
        };
      }
    },
    setListFilterPolygonData: (state, {payload}) => {
      state.listFilterPolygonData = payload;
    },
    setListRecommendationsWidgetForDelete: (state, {payload}) => {
      if (Object.keys(payload).length === 0) {
        state.listRecommendationsWidgetForDelete = {};
      } else if (payload.selection.length === 0) {
        let updatedState = {...state.listRecommendationsWidgetForDelete};
        delete updatedState[payload.id];
        state.listRecommendationsWidgetForDelete = updatedState;
      } else {
        state.listRecommendationsWidgetForDelete = {
          ...state.listRecommendationsWidgetForDelete,
          [payload.id]: payload.selection
        };
      }
    },
    setRecomputedRecommendationsWidgets: (state, {payload}) => {
      const wasRecentlyRecalculated = state.recomputedRecommendationsWidgets.some(w => w.id === payload.id);
      if (wasRecentlyRecalculated) {
        state.recomputedRecommendationsWidgets = [...state.recomputedRecommendationsWidgets.filter(w => w.id !== payload.id), payload];
      } else {
        state.recomputedRecommendationsWidgets = [...state.recomputedRecommendationsWidgets, payload];
      }
    },
    setWidgetFiltersForTargetDemand: (state, {payload}) => {
      state.widgetFiltersForTargetDemand = payload.map(obj => ({...obj}));
    },
    setWidgetFiltersForCurrentOffer: (state, {payload}) => {
      state.widgetFiltersForCurrentOffer = payload.map(obj => ({...obj}));
    },
    setPointsOfReference: (state, {payload}) => {
      state.pointsOfReference = payload
    },
    setActiveNotification: (state, {payload}) => {
      state.activeNotification = payload
    },
    setIsWidgetsPanelVisible: (state, {payload}) => {
      state.isWidgetsPanelVisible = payload;
    },
  },
});

export default slice.reducer;
export const setError = (payload) => ({type: 'app/setError', payload});
export const setPrivateLoading = (payload) => ({
  type: 'app/setPrivateLoading',
  payload
});
export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});
export const setLayers = (payload) => ({type: 'app/setLayers', payload});
export const setPolygonLayers = (payload) => ({
  type: 'app/setPolygonLayers',
  payload
});
export const updateLayer = (payload) => ({type: 'app/updateLayer', payload});
export const updatePolygonLayer = (payload) => ({
  type: 'app/updatePolygonLayer',
  payload
});
export const setLayer = (payload) => ({type: 'app/setLayer', payload});
export const setPolygonLayer = (payload) => ({
  type: 'app/setPolygonLayer',
  payload
});
export const setLayerMarker = (payload) => ({
  type: 'app/setLayerMarker',
  payload
});
export const setLayerIsochrones = (payload) => ({
  type: 'app/setLayerIsochrones',
  payload
});
export const setEnabledSelectPoint = (payload) => ({
  type: 'app/setEnabledSelectPoint',
  payload
});
export const setAllowUpgradeOperator = (payload) => ({
  type: 'app/setAllowUpgradeOperator',
  payload
});
export const setShowActionCatalog = (payload) => ({
  type: 'app/setShowActionCatalog',
  payload
});
export const setScenarioVisibility = (payload) => ({
  type: 'app/setScenarioVisibility',
  payload
});
export const setShowDownloadLayer = (payload) => ({
  type: 'app/setShowDownloadLayer',
  payload
});
export const setUpdatePolygonWidget = (payload) => ({
  type: 'app/setUpdatePolygonWidget',
  payload
});
export const setUpdateRecommendationLayerFromWidget = (payload) => ({
  type: 'app/setUpdateRecommendationLayerFromWidget',
  payload
});
export const setSelectedPointCoordinate = (payload) => ({
  type: 'app/setSelectedPointCoordinate',
  payload
});
export const deleteLayer = (payload) => ({type: 'app/deleteLayer', payload});
export const changeLayerVisibility = (payload) => ({
  type: 'app/changeLayerVisibility',
  payload
});
export const changeRecommendationAreaLayerVisibility = (payload) => ({
  type: 'app/changeRecommendationAreaLayerVisibility',
  payload
});
export const setAllTabs = (payload) => ({type: 'app/setAllTabs', payload});
export const setTab = (payload) => ({type: 'app/setTab', payload});
export const updateTab = (payload) => ({type: 'app/updateTab', payload});
export const deleteTab = (payload) => ({type: 'app/deleteTab', payload});
export const setWidgets = (payload) => ({type: 'app/setWidgets', payload});
export const setWidgetFoldedState = (payload) => ({
  type: 'app/setWidgetFoldedState',
  payload
});
export const updateWidget = (payload) => ({type: 'app/updateWidget', payload});
export const setWidget = (payload) => ({type: 'app/setWidget', payload});
export const deleteWidget = (payload) => ({type: 'app/deleteWidget', payload});
export const deleteWidgetByLayerId = (payload) => ({
  type: 'app/deleteWidgetByLayerId',
  payload
});
export const cleanSchemeState = (payload) => ({
  type: 'app/cleanSchemeState',
  payload
});
export const clearWidgetColors = (payload) => ({
  type: 'app/clearWidgetColors',
  payload
})
export const cleanMapInfoBeforeReload = (payload) => ({
  type: 'app/cleanMapInfoBeforeReload',
  payload
});
export const cleanImportJobErrors = (payload) => ({
  type: 'app/cleanImportJobErrors',
  payload
});
export const setIsValidDescriptiveScenario = (payload) => ({
  type: 'app/setIsValidDescriptiveScenario',
  payload,
});
export const setIsValidLayerForm = (payload) => ({
  type: 'app/setIsValidLayerForm',
  payload
});
export const setWidgetColor = (payload) => ({
  type: 'app/setWidgetColor',
  payload
});
export const deleteTooltip = (payload) => ({
  type: 'app/deleteTooltip',
  payload
});
export const setIsTooltipOnClick = (payload) => ({
  type: 'app/setIsTooltipOnClick',
  payload
});
export const setOnClick = (payload) => ({type: 'app/setOnClick', payload});
export const changeLayerHide = (payload) => ({
  type: 'app/changeLayerHide',
  payload
});
export const setRefreshOrgInfoPublicEmbeddedMap = (payload) => ({
  type: 'app/setRefreshOrgInfoPublicEmbeddedMap',
  payload,
});
export const setSendMapStateToServer = (payload) => ({
  type: 'app/setSendMapStateToServer',
  payload,
});
export const setLayerLoadingState = (payload) => ({
  type: 'app/setLayerLoadingState',
  payload
});
export const setPolygonDataLoading = (payload) => ({
  type: 'app/setPolygonDataLoading',
  payload
});
export const setMapInfoBeforeReload = (payload) => ({
  type: 'app/setMapInfoBeforeReload',
  payload
});
export const setImportJobErrors = (payload) => ({
  type: 'app/setImportJobErrors',
  payload
});
export const setMaxVisibleWidgets = (payload) => ({
  type: 'app/setMaxVisibleWidgets',
  payload
});
export const setRichDescription = (payload) => ({
  type: 'app/setRichDescription',
  payload
});
export const setAccessToken = (payload) => ({
  type: 'app/setAccessToken',
  payload
});
export const setSelectedListWidget = (payload) => ({
  type: 'app/setSelectedListWidget',
  payload
});
export const setCategoryWidgets = (payload) => ({
  type: 'app/setCategoryWidgets',
  payload
});
export const setListPolygonWidgetForDelete = (payload) => ({
  type: 'app/setListPolygonWidgetForDelete',
  payload
});
export const setListRecommendationsWidgetForDelete = (payload) => ({
  type: 'app/setListRecommendationsWidgetForDelete',
  payload
});
export const setDrawedGeometry = (payload) => ({
  type: 'app/setDrawedGeometry',
  payload
});
export const setAsDeletedPolygon = (payload) => ({
  type: 'app/setAsDeletedPolygon',
  payload
});
export const setAsDeletedRecommendation = (payload) => ({
  type: 'app/setAsDeletedRecommendation',
  payload
});
export const setOpenToast = (payload) => ({type: 'app/setOpenToast', payload});
export const setMessageToast = (payload) => ({
  type: 'app/setMessageToast',
  payload
});
export const setSeverityToast = (payload) => ({
  type: 'app/setSeverityToast',
  payload
});
export const setInitialViewPort = (payload) => ({
  type: 'app/setInitialViewPort',
  payload
});
export const setListFilterPolygonData = (payload) => ({
  type: 'app/setListFilterPolygonData',
  payload
});
export const setSelectWidgetId = (payload) => ({
  type: 'app/setSelectWidgetId',
  payload
});
export const deleteLayerIsochrones = (payload) => ({
  type: 'app/deleteLayerIsochrones',
  payload
});
export const setWidgetsPrevState = (payload) => ({
  type: 'app/setWidgetsPrevState',
  payload
});
export const setLayerRecommendations = (payload) => ({
  type: 'app/setLayerRecommendations',
  payload
});
export const setRecomputedRecommendationsWidgets = (payload) => ({
  type: 'app/setRecomputedRecommendationsWidgets',
  payload
});
export const setWidgetFiltersForTargetDemand = (payload) => ({
  type: 'app/setWidgetFiltersForTargetDemand',
  payload
});
export const setWidgetFiltersForCurrentOffer = (payload) => ({
  type: 'app/setWidgetFiltersForCurrentOffer',
  payload
});

export const setParametersCatalog = (payload) => ({
  type: 'app/setParametersCatalog',
  payload
});

export const setPointsOfReference = (payload)=> ({
  type: 'app/setPointsOfReference',
  payload
});

export const setActiveNotification = (payload) => ({
  type: 'app/setActiveNotification',
  payload
})

export const setHasFetchedLayerData = (payload) => ({
  type: 'app/setHasFetchedLayerData',
  payload
});

export const setIsWidgetsPanelVisible = (payload) => ({
  type: 'app/setIsWidgetsPanelVisible',
  payload
});
