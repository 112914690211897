import { Grid, styled, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { lighten } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon
  from '@mui/icons-material/VisibilityOffOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GLOBAL } from '../../../../../../../utils/constants';

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-start',
  margin: '0px  0px',
  padding: 16,
  height: 280,
  alignContent: 'baseline',
  borderRadius:'12px',
  overflow: 'auto',
  backgroundColor:'#FAFAFA'
}));

export const MuiDeleteIcon = styled(DeleteOutlinedIcon)(({ theme }) => ({
  color: '#F63B2B',
  marginTop: '9px',
  marginLeft: '4px',
  minWidth: 25,
  width: '26px',
  height: '26px',
  padding: 3,
  borderRadius:'50%',
  border: `1px solid ${lighten('#F63B2B', 0.6)}`,
  align: 'center',
  cursor:'pointer',
  '&:hover': {
    backgroundColor: lighten('#F63B2B', 0.9),
  },
  backgroundColor: 'transparent'
}));

export const MuiEditIcon = styled(EditIcon)(({ theme }) => ({
  color: localStorage.getItem('colorPrimary'),
  minWidth: 25,
  marginTop: '9px',
  marginLeft: '-3px',
  width: '26px',
  height: '26px',
  padding: 3,
  border: `1px solid ${lighten(localStorage.getItem('colorPrimary'), 0.6)}`,
  borderRadius:'50%',
  align: 'center',
  cursor:'pointer',
  '&:hover': {
    backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
  },
}));

export const MuiVisibilityIcon = styled(VisibilityOutlinedIcon)(({ theme }) => ({
  color:localStorage.getItem('colorPrimary'),
  minWidth: 25,
  width: '26px',
  height: '26px',
  padding: 3,
  border: `1px solid ${lighten(localStorage.getItem('colorPrimary'), 0.6)}`,
  borderRadius:'50%',
  align: 'center'
}));

export const MuiVisibilityOffIcon = styled(VisibilityOffOutlinedIcon)(({ theme }) => ({
  color:localStorage.getItem('colorPrimary'),
  minWidth: 25,
  width: '26px',
  height: '26px',
  padding: 3,
  border: `1px solid ${lighten(localStorage.getItem('colorPrimary'), 0.6)}`,
  borderRadius:'50%',
  align: 'center'
}));

export const MuiExpandLess = styled(ExpandLess)(({theme}) => ({
  marginTop:'5px',
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: theme.palette.text.secondary
}));

export const MuiExpandMore = styled(ExpandMore)(({theme}) => ({
  marginTop:'5px',
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: theme.palette.text.secondary
}));

export const MuiTypographyLayerName = styled(Typography)(({ theme }) => ({
  marginTop:'13px',
  color:'#212121',
  textTransform: 'initial',
  fontFamily: 'Montserrat',
  fontWeight:400,
  fontSize:12
}));

export const MuiMoreVertIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: '#757575',
  height: '26px',
  width: '26px',
  padding: 3,
  border: `1px solid ${lighten('#757575', 0.7)}`,
  borderRadius:'50%',
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  marginLeft: 59,
  marginTop:'-3px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
}));

export const dragHandleIconStyles = {
  cursor: 'row-resize',
  marginRight: 8,
  marginTop:'2px',
  color:'#757575'
}

export const layerPointBoxStyles = {
  height: 20,
  width: 20,
  marginRight: 8,
  background: '#9E9E9E',
  borderRadius:'50%',
  marginTop:'2px'
}

export const boxStyles = {
  height: 20,
  width: 20,
  marginRight: 8,
  marginTop: 14
}

export const expandedButtonStyles = {
  marginTop:'-10px',
}

export const legendContainerStyles = {
  marginLeft: '45px',
  backgroundColor: '#FCFCFC'
}

export const listItemIconStyles = {
  minWidth: '4%'
}

export const legendsBoxStyles = {
  borderRadius: '50%'
}

export const collapseStyles = {
  width: '100%'
}

export const legendsIconStyles = {
  width: 20,
  height: 20,
  marginTop: 1,
  marginLeft: 1
}

export const primaryTypographyStyles = {
  color: '#212121',
  textTransform: 'initial',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: 12
}

export const checkStyles = {
  '&:hover': {
    backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
  }
}
