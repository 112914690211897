import * as React from 'react';
import swap from 'lodash-move';
import {
  Grid,
  Typography,
  Checkbox,
  MenuItem,
  Tooltip
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { AggregationTypes } from 'lib/react-geo-tool/packages/react-core/src/';
import { SelectField } from '../../../../../../../common/ReactHooksFormFields';
import { Formatter } from '../../../../../../../../data/sources/Formatter';
import {
  dragWidgetListStyles,
  fontStyles,
  MuiEditIcon,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon,
  typographyCommonStyles
} from '../styles/itemSortList';
import {
  MuiGridContent,
  MuiGridInput,
  MuiGridRowContainer
} from '../styles/itemSortStats';
import { MuiDeleteIcon } from '../../../layer/styles/layersSort';
import {parseFormula} from "../../../../../../../../utils/supportComponents";
import { OutlinedInput } from '../styles/stat';


const DragHandle = SortableHandle(() => (
  <DragHandleIcon color='primary' style={{...dragWidgetListStyles, marginLeft:5}} />
));

const SortableItemStats = SortableElement(
  ({
    item,
    formula,
    handleDeleteItem,
    setVisibility,
    handleEditName,
    handleEditPrefix,
    handleEditSuffix,
    handleChangeOperation,
    handleChangeFormatter,
    calculator, getFormula, action
  }) => {
    const matchedFormula = formula.find(formula => formula.alias === item.field);

    return (
      <MuiGridRowContainer container >
        <DragHandle />
        <MuiGridContent item xs={11}>
          <Grid item xs={2} justifyContent='flex-start' style={{ display: 'flex' }}>
            {matchedFormula ? (
              <Tooltip title={matchedFormula.formula ? matchedFormula.formula : matchedFormula.field}
                       placement="top"
                       arrow>
                <Typography noWrap style={typographyCommonStyles}>{matchedFormula.alias}</Typography>
              </Tooltip>
            ) : (
              <Typography noWrap style={typographyCommonStyles} >{item.field}</Typography>
            )}
          </Grid>
          <Grid container justifyContent='space-between'>
            <MuiGridInput item >
              <OutlinedInput
                variant='outlined'
                type='text'
                defaultValue={item.name}
                sx={fontStyles}
                style={{ width: '170px', height: '40px' }}
                onBlur={(e) => handleEditName(item.id, e.target.value)}
              />
            </MuiGridInput>
            <MuiGridInput item>
              <OutlinedInput
                variant='outlined'
                type='text'
                defaultValue={item.prefix}
                placeholder='preffix'
                sx={fontStyles}
                style={{ width: '70px', height: '40px' }}
                onBlur={(e) => handleEditPrefix(item.id, e.target.value)}
              />
            </MuiGridInput>
            <MuiGridInput>
              <SelectField
                name={`operationName_${item.id}`}
                /* error={t(errors.operation?.message)} */
                variant='outlined'
                label={'Operation'}
                size="small"
                InputLabelProps={{ style: { color: '#424242' }}}
                style={{ width: '135px', height: '40px' }}
                id={`operation_${item.id}`}
                value={item.operation ? item.operation : ''}
                onChange={(e) => handleChangeOperation(item.id, e.target.value)}
                data-cy={'operationSelect'}
              >
                {Object.keys(AggregationTypes).map((o, index) => (
                  <MenuItem key={o} value={o} data-cy={'operation_'+index}>
                    {o}
                  </MenuItem>
                ))}
              </SelectField>
            </MuiGridInput>
            <MuiGridInput>
              <SelectField
                name={`formatterName${item.id}`}
                /* error={t(errors.operation?.message)} */
                variant='outlined'
                label={'Formatter'}
                size="small"
                InputLabelProps={{ style: { color: '#424242' }}}
                style={{ width: '135px', height: '40px' }}
                id={`formatter${item.id}`}
                value={item.formatter ? item.formatter : ''}
                onChange={(e) => handleChangeFormatter(item.id, e.target.value)}
              >
                {Object.keys(Formatter).map((f) => (
                  <MenuItem key={f} value={f}>
                    {f}
                  </MenuItem>
                ))}
              </SelectField>
            </MuiGridInput>
            <MuiGridInput item>
              <OutlinedInput
                variant='outlined'
                type='text'
                defaultValue={item.suffix}
                placeholder='suffix'
                sx={fontStyles}
                style={{ width: '60px', height: '40px' }}
                onBlur={(e) => handleEditSuffix(item.id, e.target.value)}
              />
            </MuiGridInput>
          </Grid>
          <Grid item xs={1}>
            <div style={{display:'flex'}}>
              <Checkbox
                checked={item.visible}
                name="visibility"
                onClick={(e) => setVisibility(e.target.checked, item.id)}
                color="primary"
                icon={<MuiVisibilityOffIcon/>}
                checkedIcon={<MuiVisibilityIcon />}
              />
              {calculator && action === 'update' && matchedFormula?.alias.includes('formula') && (
                <MuiEditIcon onClick={() => getFormula(matchedFormula.formula, item.id)} />
              )}
              <MuiDeleteIcon onClick={() => handleDeleteItem(item.id)} />
            </div>
          </Grid>
        </MuiGridContent>
      </MuiGridRowContainer>
    );
  }
);

const SortableListStats = SortableContainer(
  ({
    items,
    handleDeleteItem,
    setVisibility,
    handleEditName,
    handleEditPrefix,
    handleEditSuffix,
    handleChangeOperation,
    handleChangeFormatter,
    formula,
    calculator, getFormula, action
  }) => {

    return (
      <Grid container >
        {items?.map((item, index) => (
          <SortableItemStats
            key={item.id}
            index={index}
            item={item}
            formula={formula}
            handleDeleteItem={handleDeleteItem}
            setVisibility={setVisibility}
            handleEditName={handleEditName}
            handleEditPrefix={handleEditPrefix}
            handleEditSuffix={handleEditSuffix}
            handleChangeOperation={handleChangeOperation}
            handleChangeFormatter={handleChangeFormatter}
            calculator={calculator}
            action={action}
            getFormula={getFormula}
          />
        ))}
      </Grid>
    );
  }
);

const ItemSortStats = ({ items, setItems, formula, action, setSelectedValues,
                         setIdForm, calculator, columns }) => {

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(swap(items, oldIndex, newIndex));
  };

  const setVisibility = (visible, id) => {
    setItems((prevVal) => prevVal.map((item) => (item.id === id ? { ...item, visible } : item)));
  };

  const handleDeleteItem = (id) => {
    setItems((prevVal) => prevVal.filter((item) => item.id !== id));
  };

  const handleEditName = (id, name) => {
    setItems((prevVal) => prevVal.map((item) => (item.id === id ? { ...item, name } : item)));
  };

  const handleEditPrefix = (id, prefix) => {
    setItems((prevVal) => prevVal.map((item) => (item.id === id ? { ...item, prefix } : item)));
  };

  const handleEditSuffix = (id, suffix) => {
    setItems((prevVal) => prevVal.map((item) => (item.id === id ? { ...item, suffix } : item)));
  };

  const handleChangeOperation = (id, operation) => {
    setItems((prevVal) => prevVal.map((item) => (item.id === id ? { ...item, operation } : item)));
  };

  const handleChangeFormatter = (id, formatter) => {
    setItems((prevVal) => prevVal.map((item) => (item.id === id ? { ...item, formatter } : item)));
  };

  const getFormula = (formula, id) => {
    const newValue = parseFormula(formula, columns);
    setIdForm(id)
    setSelectedValues(newValue)
  }

  return (
    <SortableListStats
      items={items}
      formula={formula}
      onSortEnd={onSortEnd}
      useDragHandle
      helperClass='helper-class'
      handleDeleteItem={handleDeleteItem}
      setVisibility={setVisibility}
      handleEditName={handleEditName}
      handleEditPrefix={handleEditPrefix}
      handleEditSuffix={handleEditSuffix}
      handleChangeOperation={handleChangeOperation}
      handleChangeFormatter={handleChangeFormatter}
      getFormula={getFormula}
      calculator={calculator}
      action={action}
    />
  );
};

export default ItemSortStats;
