import { iconsName } from '../components/views/organizations/scenarios/mapsHandlers/layer/form/iconsName';
import * as React from 'react';

export default function generateRandomIcons(number) {
   const iconsNameRandom = [];
   iconsName.map((key, i) => {
     iconsNameRandom.push(key.name + '.svg');
     if (i >= number ) {
         return iconsNameRandom;
     }
   });
  return iconsNameRandom;
}
