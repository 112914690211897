import { Grid, styled, Typography } from '@mui/material';

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  marginTop:'18px',
  marginBottom:'5px'
}));

export const MuiGridPaper = styled(Grid)(({ theme }) => ({
  overflow: 'scroll',
  marginLeft: 0,
  border: '1px solid',
  borderRadius: '12px',
  borderColor: '#E0E0E0',
  overflowY: 'auto',
  height: 200
}));

export const MuiTypographyName = styled(Typography)(({ theme }) => ({
  marginLeft:'3rem',
  color:'#000000'
}));

export const MuiTypographyIcon = styled(Typography)(({ theme }) => ({
  marginLeft:'-20px',
  color:'#000000'
}));

export const MuiTypographyBorderColor = styled(Typography)(({ theme }) => ({
  marginLeft:'-15px',
  color:'#000000'
}));
