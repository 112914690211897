import * as React from 'react';
import { useMemo } from 'react';

import {
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Tune } from '@mui/icons-material';
import { useTranslation } from '../../../../../../../providers/TranslationProvider';
import { MuiDeleteIcon } from '../../../layer/styles/layersSort';
import {
  commonFieldStyle,
  dragStyles,
  formTypeStyle,
  formValueStyle,
  labelStyle, MuiIconButton,
  MuiNewParameter, MuiSwitch, MuiSwitchType,
  MuiTypographyCommon,
  nameFieldStyle, OutlinedContainer,
  outlinedStyle,
  parameterTypeStyle,
  proNameFieldStyle
} from '../styles/parameters';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import swap from 'lodash-move';
import { useSelector } from 'react-redux';
import { widgetType } from 'utils/constants';

const DragHandle = SortableHandle(() => (
  <DragHandleIcon color='primary' style={dragStyles} />
));

const SortableParameter = SortableElement(
  ({
    parameter,
    updateParameter,
    deleteParameter,
    id,
    mode = 'create',
    isParameterBeingUsed
  }) => {

  const {t} = useTranslation();

  return (
    <OutlinedContainer container item direction='row' alignItems='center'
                       spacing={1}>
      <Grid item style={proNameFieldStyle}>
        <DragHandle />
        <Typography variant='body1' style={{marginTop:3}}>
          {parameter.propName}
        </Typography>
      </Grid>
      <Grid item style={nameFieldStyle}>
        <FormControl>
          <TextField
            label='Param. Name'
            placeholder='Enter a user-friendly name for the parameter'
            variant='outlined'
            value={parameter.label}
            onChange={(event) => {
              if (event.target.value) {
                const updatedParameter = {...parameter};
                updatedParameter.label = event.target.value;
                updateParameter(updatedParameter);
              }
            }
            }
            InputLabelProps={{ style: labelStyle }}
          />
        </FormControl>
      </Grid>
      <Grid item style={parameterTypeStyle}>
        <FormControl style={formTypeStyle}>
          <MuiTypographyCommon variant="caption" gutterBottom >
            {t('parameter_type')}
          </MuiTypographyCommon>
          <FormControlLabel
            control={
              <MuiSwitchType
                translation={t}
                type={parameter.type}
                name={`type-switch-${id}`}
                checked={parameter.type === "Boolean"}
                onChange={(event) => {
                  const updatedParameter = {...parameter};
                  let type = event.target.checked ? "Boolean" : "Numeric";
                  updatedParameter.type = type;
                  if (type === "Boolean") {
                    updatedParameter.minValue = 0;
                    updatedParameter.maxValue = 1;
                    if (updatedParameter.value !== 0 && updatedParameter.value !== 1) {
                      updatedParameter.value = 0;
                    }
                  }
                  updateParameter(updatedParameter);
                }}
                inputProps={{'aria-label': 'controlled'}}
              />
            }
            labelPlacement="end"
          />
        </FormControl>
      </Grid>
      {parameter.type === "Numeric" ? (
        <>
          <Grid item style={commonFieldStyle}>
            <FormControl>
              <TextField
                type='number'
                label={'Min. value'}
                style={{width: '135px'}}
                variant='outlined'
                value={parseFloat(parameter.minValue)}
                inputProps={{
                  max: parameter.maxValue,
                  step: 10
                }}
                onChange={(event) => {
                  const updatedParameter = {...parameter}
                  updatedParameter.minValue = parseFloat(event.target.value);
                  updateParameter(updatedParameter);
                }}
                InputLabelProps={{ style: labelStyle }}
              />
            </FormControl>
          </Grid>
          <Grid item style={commonFieldStyle}>
            <FormControl>
              <TextField
                label={'Max. value'}
                style={{width: '135px'}}
                variant='outlined'
                value={parseFloat(parameter.maxValue)}
                inputProps={{
                  min: parseFloat(parameter.minValue),
                  step: 10,
                  type:'number'
                }}
                onChange={(event) => {
                  const updatedParameter = {...parameter}
                  updatedParameter.maxValue = parseFloat(event.target.value);
                  updateParameter(updatedParameter);
                }}
                InputLabelProps={{ style: labelStyle }}
              />
            </FormControl>
          </Grid>
          <Grid item style={commonFieldStyle}>
            <FormControl>
              <TextField
                type='number'
                label={'Default value'}
                style={{width: '135px'}}
                variant='outlined'
                value={parseFloat(parameter.value)}
                inputProps={{
                  min: parameter.minValue,
                  max: parameter.maxValue,
                  step: parameter.type === "Boolean" ? 1 : ((parameter.maxValue - parameter.minValue) / 10)
                }}
                onChange={(event)=>{
                  const updatedParameter = {...parameter}
                  updatedParameter.value = parseFloat(event.target.value);
                  updateParameter(updatedParameter);
                }}
                InputLabelProps={{ style: labelStyle }}
              />
            </FormControl>
          </Grid>
        </>
      ) : (
        <Grid item>
          <FormControl style={formValueStyle}>
            <MuiTypographyCommon variant="caption" gutterBottom >
              {t('default_value')}
            </MuiTypographyCommon>
            <FormControlLabel
              control={
                <MuiSwitch
                  translation={t}
                  index={parameter.value}
                  name={`value-switch-${id}`}
                  checked={parameter.value === 1}
                  onChange={(event) => {
                    const updatedParameter = {...parameter};
                    updatedParameter.value = event.target.checked ? 1 : 0;
                    updateParameter(updatedParameter);
                  }}
                  inputProps={{'aria-label': 'controlled'}}
                  color="default"
                />
              }
              labelPlacement="end"
            />
          </FormControl>
        </Grid>
      )}
      { !isParameterBeingUsed( parameter.propName ) && (
        <Grid item>
          <MuiDeleteIcon onClick={() => deleteParameter(parameter.propName)} />
        </Grid>
      )}
    </OutlinedContainer>
  )
});

const SortableParametersList = SortableContainer(
  ({items, addParameter, updateParameter, deleteParameter,
                   mode = 'create', isParameterBeingUsed}) => {

  const {t} = useTranslation();

  return (
    <Grid container justifyContent='center' direction='column' alignContent='top'
          spacing={2}>
      <Grid container item direction='column'>
        {items?.map((item, i) => {
          return (
            <SortableParameter
              key={item.propName}
              index={i}
              parameter={item}
              updateParameter={updateParameter}
              deleteParameter={deleteParameter}
              id={i}
              mode={mode}
              isParameterBeingUsed={isParameterBeingUsed}/>
          )
        })}
      </Grid>
      <OutlinedContainer container justifyContent='center' alignContent='center'
                         style={outlinedStyle}>
          <Grid item>
            <MuiIconButton onClick={()=>addParameter()}>
              <Tune sx={{ color:'#757575' }}/>
              <MuiNewParameter variant='h6'>
                {t('new_parameter')}
              </MuiNewParameter>
            </MuiIconButton>
          </Grid>
      </OutlinedContainer>
    </Grid>
  )

});

const Parameters = ({parameters, updateParameters, mode = 'create'}) => {
  const widgets = useSelector((state) => state.app.widgets);

  const parametersBeingUsed = useMemo(() => {
    const referencedParameters = [];
    let founded = false;
    let generatedRegex = null;
    parameters?.forEach(p => {
      const widgetUsingCurrentParameter = widgets.find(w => {
        if ( w.params?.calculator || w.type !== widgetType.RECOMMENDATIONS || w.type !== widgetType.STATISTICS || w.type !== widgetType.LIST ) {
          if ( w.type === widgetType.HISTOGRAM ) {
            generatedRegex = generateRegexForVariable(p.propName);
            founded = w.params.field.match(generatedRegex);
            return !!founded;
          } else if( w.type === widgetType.LIST ) {
            founded = false;
            const parameterUsedInListWidget = w.params.list.find( wItem => {
              if (wItem.calculator) {
                generatedRegex = generateRegexForVariable(p.propName);
                founded = wItem.field.match(generatedRegex);
              }
              return !!founded;
            });
            return !!parameterUsedInListWidget;
          } else if( w.type === widgetType.STATISTICS ) {
            const parameterUsedInStatsWidget = w.params.fields.find( wItem => {
              generatedRegex = generateRegexForVariable(p.propName);
              founded = wItem.field.match(generatedRegex);
              return !!founded;
            });
            return !!parameterUsedInStatsWidget;
          } else if( w.type === widgetType.RECOMMENDATIONS ) {
            generatedRegex = generateRegexForVariable(p.propName);
            founded = w.params?.targetDemands?.match(generatedRegex);
            if ( !founded ) {
              generatedRegex = generateRegexForVariable(p.propName);
              founded = w.params?.formulaWelfare?.match(generatedRegex);
              return !!founded;
            }
            return !!founded;
          }
        }
      });
      if ( widgetUsingCurrentParameter ) {
        referencedParameters.push(
          {
            widgetName: widgetUsingCurrentParameter.name,
            parameter: p.propName,
          }
        )
      }
    });
    return referencedParameters;
  }, [
    parameters, widgets
  ]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    updateParameters(swap(parameters, oldIndex, newIndex));
  };

  const updateParameter = (parameter) => {
    const toUpdate = parameters?.findIndex(p => p.propName === parameter.propName);
    const updatedList = [...parameters];
    if (toUpdate >= 0) {
      updatedList[toUpdate] = parameter;
      updateParameters(updatedList);
    }
  }

  const addParameter=()=>{
    const paramIndex=parameters?.length+1||1;
    const newParam = {
      propName: `P${paramIndex}`,
      label: "Parameter " + paramIndex,
      type: "Numeric",
      minValue: 0,
      maxValue: 100,
      value: 0
    }
    updateParameters([...parameters, newParam]);
  }

  function generateRegexForVariable(variable) {
    return new RegExp(`\\b${variable}\\b`, 'g');
  }

  const isParameterBeingUsed = ( parameterName ) => {
    return parametersBeingUsed.some((p) => {
      return p.parameter === parameterName;
    })
  }

  const deleteParameter = (propName) =>
    updateParameters(parameters?.filter(p => p.propName !== propName));

  return (
    <SortableParametersList
      useDragHandle
      items={parameters}
      onSortEnd={onSortEnd}
      addParameter={addParameter}
      helperClass='helper-class'
      updateParameter={updateParameter}
      deleteParameter={deleteParameter}
      mode={mode}
      isParameterBeingUsed = {isParameterBeingUsed}
    />
  );
}
export default Parameters;
