import { useMemo } from 'react';
import {Alert, Snackbar, Typography, Slide} from "@mui/material";
import { notificationsType } from 'utils/constants';

const SlideTransition = (props) => {
  return <Slide {...props} direction="down" />;
};

const Toast2 = ({icon, headerText, textMessage, handleClose, severity, open = false, showTime = 6000, title = '', activeNotification }) => {

  const isReferencePointTypeNotification = useMemo(
    () => activeNotification === notificationsType.REFERENCE_POINT,
    [activeNotification]
  );

  return (
    <Snackbar open={open}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              sx={{marginTop: '38px', width: '500px'}}
              TransitionComponent={ isReferencePointTypeNotification ? SlideTransition : undefined}
    >
      <Alert
        severity="info"
        iconMapping={{
          info: icon
        }}
        sx={{
          backgroundColor: '#fff',
          borderRadius: '12px',
          color: '#000',
          display: 'flex',
          alignItems: 'center',
          '.MuiAlert-icon': {
            color: '#5c6bc0',
          }
        }}>
        <Typography variant='subtitle2' sx={{fontFamily: 'Montserrat'}}>{headerText}</Typography>
        <Typography variant='body2' sx={{fontFamily: 'Montserrat' , width: '328px'}}>{textMessage}</Typography>
      </Alert>
    </Snackbar>
  );

};
export default Toast2;
