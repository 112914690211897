import { lighten } from '@mui/material/styles';
import { Grid, styled, Typography } from '@mui/material';
import { Refresh } from '@material-ui/icons';
import {GLOBAL} from "../../../../utils/constants";

export const MuiLabelTypography = styled(Typography)(({ theme }) => ({
  color: '#424242',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontWeight: 600,
  width: '100%',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  textAlign: 'left'
}));

export const MuiGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  padding: '0 !important',
}));

export const MuiRefreshIcon = styled(Refresh, {
  shouldForwardProp: (prop) => prop !== 'loading'
})(({ theme,loading }) => ({
  width: '26px',
  height: '26px',
  marginRight:'5px',
  padding: 3,
  borderRadius:'50%',
  border: `1px solid ${lighten(theme.palette.primary.main, 0.6)}`,
  align: 'center',
  cursor:'pointer',
  backgroundColor: 'transparent',
  color:theme.palette.primary.main,
  animation: loading ? 'rotate 2s linear infinite' : 'none',
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

export const toggleButtonGroupStyle = {
  width: '100%',
  height: '32px',
  borderRadius: '16px'
};

export const switchEnabledStyle = {
  backgroundColor: localStorage.getItem('colorPrimary'),
  color: 'white',
  '&:hover': {
    backgroundColor: localStorage.getItem('colorPrimary'),
  }
}

export const switchDisabledStyle = {
  color: localStorage.getItem('colorPrimary'),
}
