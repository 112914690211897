import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useTranslation } from 'components/providers/TranslationProvider';
import { TextField } from 'components/common/ReactHooksFormFields';
import useUpdateScheme from 'api/hooks/useUpdateScheme';
import useCreateScheme from 'api/hooks/useCreateScheme';
import Toast from 'components/common/Toast';
import useRandomId from 'components/hooks/useRandomId';

import Skeleton from './Skeleton';
import Actions from './Actions';
import { MuiGridField, MuiSwitchField } from './styles/embeddedMap';
import CopyToClipBoard from './CopyToClipBoard';

const schema = yup.object().shape({
  name: yup.string().min(4, 'min_4_characters').max(80, 'max_80_characters').required('required'),
  map: yup.string().required('required'),
  scenarioVisibility: yup.boolean(),
});

const EmbeddedMap = ({ type, isCreate, scenario, id, schemeId }) => {
  const { t } = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [actionSave, setActionSave] = React.useState(null);
  const [actionSaveAndView, setActionSaveAndView] = React.useState(null);
  const [createId, setCreateId] = React.useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const randomId = useRandomId();

  const {
    mutateAsync: updateScheme,
    isError: updateIsError,
    isSuccess: updateIsSuccess,
    error: updateError,
    isLoading: updateIsLoading,
  } = useUpdateScheme();
  const {
    mutateAsync: createScheme,
    isError: createIsError,
    isSuccess: createIsSuccess,
    error: createError,
    isLoading: createIsLoading,
  } = useCreateScheme();

  const getDefaultValues = () => {
    return location.state
      ? {
          name: location.state.name,
          map: location.state.map,
          scenarioVisibility: location.state.scenarioVisibility,
        }
      : {};
  };
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });
  const {
    watch,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = methods;
  const watchScenarioVisibility = watch('scenarioVisibility');

  const updScheme = (data) => {
    const schemeToUpdate = {
      scheme: {
        id: location.pathname.split('/')[4],
        name: data.name,
        map: data.map,
        scenarioVisibility: data.scenarioVisibility,
        type,
      },
      userId: id,
    };
    updateScheme(schemeToUpdate);
  };

  const newScheme = (data) => {
    const schemeToCreate = {
      scheme: {
        id: randomId,
        name: data.name,
        map: data.map,
        scenarioVisibility: data.scenarioVisibility,
        type,
      },
      userId: id,
    };
    createScheme(schemeToCreate);
    setCreateId(randomId);
  };

  const onSubmit = handleSubmit((data) => (isCreate ? newScheme(data) : updScheme(data)));

  const handleSave = async () => {
    onSubmit();
    setActionSaveAndView(false);
    setActionSave(true);
  };

  const handleSaveAndView = () => {
    if (isDirty) onSubmit();
    else
      navigate(
        `${location.pathname.split('/').splice(0, 4).join('/')}/view/public/${
          location.pathname.split('/')[4]
        }`
      );
    setActionSaveAndView(true);
    setActionSave(false);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  React.useEffect(() => {
    if (actionSaveAndView && (updateIsSuccess || createIsSuccess)) {
      if (isCreate) {
        navigate(`${location.pathname.split('/').splice(0, 4).join('/')}/view/public/${createId}`);
      } else {
        navigate(
          `${location.pathname.split('/').splice(0, 4).join('/')}/view/public/${
            location.pathname.split('/')[4]
          }`
        );
      }
    }

    if (actionSave && (updateIsSuccess || createIsSuccess)) {
      navigate(location.pathname.split('/').splice(0, 4).join('/'));
    }
  }, [
    updateIsSuccess,
    createIsSuccess,
    actionSaveAndView,
    actionSave,
    location.pathname,
    navigate,
    isCreate,
    createId,
  ]);

  React.useEffect(() => {
    if (updateIsError) {
      setError(t(updateError.data.error));
      setOpenToast(true);
    }
  }, [updateIsError, updateError?.data.error, t]);

  React.useEffect(() => {
    if (createIsError) {
      setError(t(createError.data.error));
      setOpenToast(true);
    }
  }, [createIsError, createError?.data.error, t]);

  React.useEffect(() => {
    if (scenario.isSuccess && !isCreate) {
      reset({
        name: scenario.data.scheme.name,
        map: scenario.data.scheme.map,
        scenarioVisibility: scenario.data.scheme.scenarioVisibility,
      });
    }
  }, [scenario.isSuccess, isCreate, scenario?.data, reset, location.state]);

  React.useEffect(() => {
    if (isCreate) scenario.remove();
    else scenario.refetch();
    return () => scenario.remove();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container style={{ paddingTop: 24 }}>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      {!isCreate && scenario.isLoading ? (
        <Skeleton />
      ) : (
        <FormProvider {...methods}>
          <form>
            <Grid container style={{width: '100%'}}>
              <MuiGridField item xs={12} >
                <TextField
                  autoFocus
                  name='name'
                  margin='dense'
                  type='text'
                  label={t('scenario_name')}
                  variant='outlined'
                  fullWidth
                  error={t(errors.name?.message)}
                  style={{padding:'0px !important'}}
                />
              </MuiGridField>
              <MuiGridField item xs={12} >
                <TextField
                  name='map'
                  error={t(errors.map?.message)}
                  variant='outlined'
                  label={t('url_carto_public')}
                  fullWidth
                  style={{ marginTop: 12 }}
                />
              </MuiGridField>
              <Grid container style={{ minHeight: 130 }}>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', maxHeight: 40, alignItems: 'center', marginLeft: 16 }}
                >
                  <Typography>{t('set_public_private_scenario')}</Typography>
                  <MuiSwitchField name='scenarioVisibility' />
                </Grid>
                {schemeId !== 'create' && watchScenarioVisibility && (
                  <CopyToClipBoard id={id} schemeId={schemeId} type='public' />
                )}
              </Grid>
              <Grid item xs={12} style={{ margin: '24px 0px' }}>
                <Actions
                  isLoading={createIsLoading || updateIsLoading}
                  isValid={isValid}
                  isDirty={isDirty}
                  handleSave={handleSave}
                  handleSaveAndView={handleSaveAndView}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </Grid>
  );
};

export default EmbeddedMap;
