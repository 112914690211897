import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText, DialogTitle, Typography
} from "@mui/material";
import {lighten} from "@mui/material/styles";
import {useTranslation} from "../../../providers/TranslationProvider";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const DeleteIsochroneModal = ({ open, onClose, onConfirm, count }) =>{
  const { t } = useTranslation();
    return (
        <Dialog
          open={open}
          onClose={onClose}
          sx={dialodStyle}
        >
          <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
            <DeleteOutlinedIcon color="error" />
            <Typography component="div" sx={{ color: '#212121', fontFamily: 'Raleway', fontSize: '25px !important' }}>
              {t('delete_isocrones')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant='body2' sx={{fontFamily: 'Montserrat'}}>
                {`${t('delete_isocrones_content1')} ${count} ${t('delete_isocrones_content2')}`}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              color="primary"
              sx={btnCancel}>
              {t('cancel_btn')}
            </Button>
            <Button
              onClick={onConfirm}
              autoFocus
              variant='outlined'
              sx={btnDelete}
            >
              {t('delete')}
            </Button>
          </DialogActions>
        </Dialog>
    );
}
const dialodStyle ={
  '& .MuiPaper-rounded' : {
      borderRadius: '28px !important',
      width: '320px !important',
      height: '258px !important',
  }
}
export const btnCancel={
  color:'#005F64 !important',
  borderRadius: '20px !important',
  borderColor:'#005F64',
  width:'120px',
  height:'37px',
  '&:hover':{
    backgroundColor: lighten('#005F64', 0.8)
  }
}
const btnDelete = {
  padding:'10px 16px 10px 16px',
  borderRadius: '20px !important',
  width:'120px',
  height:'37px',
  borderColor:'#C71A0F !important',
  color: '#C71A0F !important',
  '&:hover':{
    backgroundColor: `${lighten('#F63B2B', 0.8) } !important`,
    borderColor:'#C71A0F',
  }
}

export default DeleteIsochroneModal;
