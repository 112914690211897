import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles/mapbox-gl-incluia.css';
import {
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { setViewState , selectSourceById } from 'lib/react-geo-tool/packages/react-redux/src';
import { BASEMAPS, GoogleMap } from 'lib/react-geo-tool/packages/react-basemaps/src';
import CloseIcon from 'components/views/organizations/common/CloseIcon';
import { setOnClick, setActiveNotification } from 'store/appSlice';
import round from 'utils/roundIfNecessary';
import { MuiDiv, MuiPopover } from './styles/map';
import {IconLayer, PolygonLayer} from '@deck.gl/layers';
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl';
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';
import {Octokit} from '@octokit/rest';
import { setSelectedPointCoordinate } from '../../store/appSlice';
import { useCartoLayerProps } from 'lib/react-geo-tool/packages/react-api/src';
import { BingMap } from 'lib/react-geo-tool/packages/react-basemaps/src/basemaps/BingMap';
import { useLayers } from 'components/providers/LayersProvider';
import { useTranslation } from 'components/providers/TranslationProvider';
import Toast2 from "./Toast2";
import {Avatar} from "@mui/material";
import DrawIcon from "@mui/icons-material/Draw";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { notificationsType } from 'utils/constants';

const octokit = new Octokit();
maplibregl.workerClass = maplibreglWorker;

function MapCustom({ layers }) {
  const isWidgetsPanelVisible = useSelector((state) => state.app.isWidgetsPanelVisible);
  const dispatch = useDispatch();
  const { polygLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, polygLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source });
  const {resetLayers } = useLayers();
  const { t } = useTranslation();

  const viewState = useSelector((state) => state.carto.viewState);
  const basemap = useSelector((state) => BASEMAPS[state.carto.basemap]);
  const selectedMap = useSelector((state) => state.carto.basemap);

  const googleApiKey = useSelector((state) => state.carto.googleApiKey);
  const bingApiKey = useSelector((state) => state.carto.bingApiKey);
  const showTooltipOnClick = useSelector((state) => state.app.isTooltipOnClick);
  const position = useSelector((state) => state.app.layerMarker);
  const recommendations = useSelector((state) => state.app.layerRecommendations);
  const [reorderedLayers, setReorderedLayers] = React.useState([]);

  const selectionEnabled = useSelector((state) => state.carto.featureSelectionEnabled);
  const enabledSelectPoint = useSelector((state) => state.app.enabledSelectPoint);
  const pointCordenate = useSelector((state) => state.app.selectedPointCoordinate);
  const selectedMode = useSelector((state) => state.carto.featureSelectionMode);
  const activeNotification = useSelector((state) => state.app.activeNotification);

  const pickable = useSelector((state) => state.app.onClick);
  const [open, setOpen] = React.useState(false);
  const [customLayer, setCustomLayer] = React.useState(null);

  const [openToast, setOpenToast] = React.useState(false);
  const handleCloseToast = () => setOpenToast(false);

  React.useEffect(() => {
    if (pickable?.object && showTooltipOnClick) {
      setOpen(true);
    }
  }, [pickable, showTooltipOnClick]);

  React.useEffect(() => {
    if ( activeNotification === notificationsType.REFERENCE_POINT ) {
      setOpenToast(true);
      setTimeout(() => {
        dispatch( setActiveNotification( notificationsType.NONE ) );
        setOpenToast(false);
      }, 1500);
    } else {
      if (enabledSelectPoint && selectionEnabled){
        if (pointCordenate)  setOpenToast(false);
        else setOpenToast(true);
      }
      else if (selectionEnabled){
        setOpenToast(true);
      }
      else{
        setOpenToast(false);
      }
    }
  }, [enabledSelectPoint, selectionEnabled, pointCordenate, activeNotification]);

  React.useEffect(() => {
    let layersToSort = [];
    let layerMask = [];

    if (typeof customLayer === 'object' && customLayer !== null) {
      if (Array.isArray(customLayer)) {
        layersToSort.push(...customLayer);
      } else {
        layersToSort.push(customLayer);
      }
    }
    layers.forEach(layer => {
      if (layer && typeof layer === 'object') {
        if (Array.isArray(layer)) {
          layerMask.push(...layer);
        } else {
          layersToSort.push(layer);
        }
      }
    });

    layersToSort = layersToSort.filter(Boolean);
    const prioritizedLayers = layersToSort.reduce((acc, layer) => {
      const isCircleLayer = layer.id.includes('-pointCircle');
      const isPolygonLayer = layer.props.geometry === 'Polygon' || layer.props.geometry === 'MultiPolygon';
      const isPointLayer = !isCircleLayer && !isPolygonLayer;

      if (isPolygonLayer) {
        acc.polygons.push(layer);
      } else if (isCircleLayer) {
        acc.circles.push(layer);
      } else if (isPointLayer) {
        acc.points.push(layer);
      }
      return acc;
    }, { polygons: [], circles: [], points: [] });

    const sortedLayers = [
      ...prioritizedLayers.polygons,
      ...prioritizedLayers.circles,
      ...prioritizedLayers.points,
      ...layerMask,
    ];
    setReorderedLayers(sortedLayers);

  }, [ layers, customLayer ]);

  const handlePopoverClose = () => {
    dispatch(setOnClick({}));
    setOpen(false);
  };

  const handleViewStateChange = ({ viewState }) => {
    dispatch(setViewState(viewState));
  }

  const handleSizeChange = ({ width, height }) => {
    dispatch(setViewState({ width, height }));
  }

  const handleClick = ({latitude, longitude}) => {
    dispatch(setSelectedPointCoordinate({lat: latitude, lon: longitude}));
  }

  const ICON_MAPPING = {
    marker: {x: 0, y: 0, width: 24, height: 24, mask: true}
  };

  function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  }
  React.useEffect(() => {
    resetLayers();
  },[basemap.type]);

  React.useEffect(() => {
    if (position) {
        const markerIcon = process.env.PUBLIC_URL + '/maki-icons/marker.svg';
        const markerL = new IconLayer({
          id: 'icon-layer',
          data: [{name: position?.place, address: position?.place, coordinates: [ position?.longitude, position?.latitude ]}],
          getColor: [30, 56, 192, 255],
          getIcon: d => 'marker',
          getPosition: d => d.coordinates,
          getSize: d => 30,
          pickable: false,
          iconAtlas: markerIcon,
          iconMapping: ICON_MAPPING,
          sizeScale: 1,
          stroked: true
        });
      setCustomLayer(markerL);
    }
    else if (recommendations) {
      let recommendationsL = [] , tempL = null;
      let polygons =[];
      Object.keys(recommendations).map(id => {
          if (recommendations[id].visible){
            polygons = recommendations[id].geometries;
            tempL = new PolygonLayer({
              ...cartoLayerProps,
              id: id+ '_recommendations-layer',
              data: polygons,
              getElevation: 0,
              opacity: recommendations[id].layerOpacity,
              getFillColor: hexToRgb(recommendations[id].layerAreaColor),
              getLineColor: hexToRgb(recommendations[id].layerAreaColor),
              getLineWidth: 1,
              getPolygon: d => d.coordinates,
              lineWidthMinPixels: 1,
              stroked: true,
              geometry: 'MultiPolygon',
              pickable: true,
              onClick: (info) => {
                const newColumns = ['ido', 'w_var_covered', 'quant_var_covered'];
                if (info?.object) {
                  info.object = {
                    feature: info.object,
                    title: t('recommendations_header'),
                    label: '',
                    columns: newColumns,
                  };
                  dispatch(
                    setOnClick({
                      coordinate: info.coordinate,
                      pixel: info.pixel,
                      object: info.object
                    })
                  );
                }
              },
            });
            recommendationsL.push(tempL);
          }
          setCustomLayer(recommendationsL);
        });
      }
    else {
      setCustomLayer(null);
    }
  }, [position , recommendations, basemap.type]);
const getIcon = () => {
  return (
    <Avatar>{selectedMode === 'IsochroneFromMapMode' ? <AddLocationAltIcon/> : <DrawIcon />} </Avatar>
  )
}
const getMessage = () => {
  if ( activeNotification === notificationsType.REFERENCE_POINT ) {
    return '';
  } else if ( selectionEnabled ) {
    return(selectedMode === 'IsochroneFromMapMode' ? t('isocrone_text') : t('drawn_text'))
  }
}
const getHeader = () => {
  if ( activeNotification === notificationsType.REFERENCE_POINT ) {
    return t('new_reference_created');
  } else if ( selectionEnabled ) {
    return(selectedMode === 'IsochroneFromMapMode' ? t('isocrone_header') : t('drawn_header') )
  }
}

  const popoverCommon = () => {
   return  pickable?.object && (
      <MuiPopover
        anchorReference='anchorPosition'
        anchorPosition={{ top: pickable?.pixel[1] + 40, left: pickable?.pixel[0] + 75 }}
        open={open}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Grid
          container
          style={{
            width: 190,
          }}
        >
          <PopupContent
            title={pickable.object.title}
            label={pickable.object.label}
            columns={pickable.object.columns}
            properties={pickable.object.feature.properties}
            handleClose={handlePopoverClose}
            headers={pickable.object.feature.headers}
          />
          <div
            style={{
              width: 0,
              height: 0,
              borderRight: '8px solid transparent',
              borderTop: '8px solid #fff',
              position: 'absolute',
              bottom: -8,
              left: '10%',
            }}
          />
        </Grid>
      </MuiPopover>
    )
  }

  return (
    <MuiDiv isWidgetsPanelVisible={isWidgetsPanelVisible}>
      <Toast2
        textMessage={getMessage()}
        headerText={getHeader()}
        handleClose={handleCloseToast}
        icon={getIcon()}
        severity='info'
        horizontal='center'
        vertical='top'
        open={openToast}
        activeNotification={activeNotification}
      />
      {basemap.type === 'gmaps' &&  (
        <>
          <GoogleMap
            basemap={basemap}
            apiKey={googleApiKey}
            mapId={'523687cb0b33c747'}
            viewState={ viewState }
            layers={reorderedLayers}
            onViewStateChange={handleViewStateChange}
            onResize={handleSizeChange}
            onClick={handleClick}
            type={selectedMap}
          />
          {popoverCommon()}
        </>)
      }
      {basemap.type === 'bingmaps' &&  (
        <>
          <BingMap
            basemap={basemap}
            apiKey={bingApiKey}
            mapId={'523687cb0b33c747'}
            viewState={ viewState }
            layers={reorderedLayers}
            onViewStateChange={handleViewStateChange}
            onResize={handleSizeChange}
            onClick={handleClick}
            type={selectedMap}
          />
          {popoverCommon()}
        </>)
      }
      {basemap.type === undefined && <div>Not a valid map provider</div>}
    </MuiDiv>
  );
}

const PopupContent = ({ title, label, columns, properties, handleClose, headers }) => {
  const { t } = useTranslation();
  return (
  <>
    <Grid
      item
      xs={12}
      style={{
        background: localStorage.getItem('colorPrimary'),
        color: '#fff',
        padding: '10px 16px',
        borderRadius: '4px 0 0 0',
      }}
    >
      <CloseIcon
        onClose={handleClose}
        style={{
          borderRadius: 25,
          position: 'absolute',
          right: -10,
          top: -10,
          transform: 'scale(0.7)',
        }}
      />
      <Grid item xs={12}>
        <Typography style={{ fontSize: 12, color: '#fff', fontWeight: 700 }}>{title}</Typography>
        <Typography style={{ fontSize: 10, color: '#fff', fontWeight: 700 }}>{label}</Typography>
      </Grid>
    </Grid>
    <Divider />
    <Grid
      container
      style={{
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
        overflow: 'scroll',
        maxHeight: 150,
      }}
    >
      {columns.map((c, index) => {
        return (
          <Grid key={c} container style={{ padding: 0, marginBottom: 5 }}>
            <Grid item xs={12}>
              <Typography style={{ fontSize: 10, fontWeight: 300, color: 'rgba(0,0,0,.75)' }}>
                { headers && index < headers.length ? t(headers[index]) :
                  c
                    .split('_')
                    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                    .join(' ')
                  }
                :
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: 10, fontWeight: 900 }}>
                {round(properties[Object.keys(properties)[index]])}
              </Typography>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  </>
);
    }

export default MapCustom;
