import * as React from 'react';
import { Grid, Card} from '@mui/material';
import { useTranslation } from '../../providers/TranslationProvider';
import {
  MuiButton,
  MuiCardActions,
  MuiImg
} from './styles/uploadImage';

import UploadIcon from '../../common/UploadIcon';
import { GLOBAL } from 'utils/constants';

const UploadImage = ({ handleChange, image, name }) => {
  const { t } = useTranslation();
  return (
    <Grid container style={{display:'flex', justifyContent:'center'}}>
      <Card
        elevation={0}
        style= {{ width: 106, height: 106, borderRadius: 12, border: `1px solid #E0E0E0` }}>
        <MuiCardActions>
          <MuiImg
            src={image.type ? URL.createObjectURL(image) : image}
            alt='img'
          />
        </MuiCardActions>
      </Card>
      <Grid container style={{display:'flex', justifyContent:'center'}}>
        <input
          name={name}
          accept='image/*'
          id={`contained-button-file-logo-${name}`}
          style={{display: 'none'}}
          type='file'
          onChange={handleChange}
        />
        <label htmlFor={`contained-button-file-logo-${name}`}>
          <MuiButton
            variant='outlined'
            component='span'
            startIcon={<UploadIcon
              color={GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]} size={18}/>}>
            {t('upload')}
          </MuiButton>
        </label>
      </Grid>
    </Grid>
  );
};

export default UploadImage;
