import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  CustomColorGradientField,
} from 'components/common/ReactHooksFormFields';
import { useTranslation } from 'components/providers/TranslationProvider';
import useDefaultGradients from 'api/hooks/useDefaultGradients';
import CustomSkeleton from 'components/common/CustomSkeleton';
import SelectorGradient from 'components/common/SelectorGradient';
import {
  divContainerStyle,
  keyStyle,
  MuiDivider
} from '../histogram/styles/histogram';
import { useTheme } from '../../../../../../../providers/CustomThemeProvider';
import {
  colorMapStyle,
  divColorMapStyle,
  MuiCustomColorGradientField, MuiGrid,
  MuiGridColorMap,
  MuiGridInvertColor,
  MuiGridItem,
  MuiGridSelectColor,
  MuiGridSelectorGradient, MuiSwitchCommon,
  MuiSwitchField,
  MuiTypographyCommon,
  switchColorTypeStyle,
} from '../styles/category';

const Category = ({
  watch,  setValue, gradient, setGradient,
  selectedGradientId, palette_reverse, setPaletteReverse,
  colorInMap, setColorInMap }) => {
  const { t } = useTranslation();
  const {theme} = useTheme()

  const [checked, setChecked] = React.useState(palette_reverse);
  const [checkedColor, setCheckedColor] = React.useState(colorInMap)
  const [checkedColorType, setCheckedColorType] = React.useState(watch('colorType') === 'by_value');

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setPaletteReverse(event.target.checked);
  };
  const handleColor = (event) => {
    setCheckedColor(event.target.checked);
    setColorInMap(event.target.checked);
  }

  const handleSwitchChange = (event) => setCheckedColorType(event.target.checked);

  const { data: gradients, isSuccess } = useDefaultGradients();
  const [, setUpt]= React.useState(false);

  React.useEffect(() => {
    if (selectedGradientId && Object.keys(gradient).length === 0 && isSuccess) {
      if (gradients.find((g) => g.id === selectedGradientId)){
        setGradient(gradients.find((g) => g.id === selectedGradientId));
      }else{
        setGradient(gradients.find((g) => g.name === 'Gradient1'));
      }
    } else if (Object.keys(gradient).length === 0 && isSuccess) {
      setGradient(gradients[0]);
    }
  }, [isSuccess]);

  React.useEffect(() => {
      setPaletteReverse(checked);
  }, [checked]);

  const setColoring  = (data) => {
    setUpt(true);
    setGradient(data);
    selectedGradientId=data.id;
  }

  React.useEffect(() => {
    setValue("colorType", checkedColorType ? 'by_value' : 'simple')
  }, [checkedColorType])

  return (
    <Grid container
          justifyContent={'center'}
          direction={'row'}
          wrap={'wrap'}
          style={{marginTop: 24}}>
      <MuiGrid item xs={3}>
        <MuiTypographyCommon variant="caption" >
          {t('color_type')}
        </MuiTypographyCommon>
        <div style={switchColorTypeStyle}>
          <MuiSwitchField
            translation={t}
            checked={checkedColorType}
            onChange={handleSwitchChange}
            name="colorType"
            color='secondary'
          />
        </div>
      </MuiGrid>
      <MuiGridColorMap item xs={9}>
          <MuiTypographyCommon variant='caption' style={colorMapStyle}>
            {t('color_in_map')}
          </MuiTypographyCommon>
        <div style={divColorMapStyle}>
          <Grid style={{ marginLeft: 8}}>
            <MuiSwitchCommon
              checked={checkedColor}
              onChange={handleColor}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>
          <Grid style={{ marginTop:24 }}>
            <div style={{...divContainerStyle, marginTop:-17}}>
              <Typography
                style={{
                  ...keyStyle,
                  color: !checkedColor ? theme.palette.primary.main : '#4D8094',
                }}
              >
                {t('key_no')}
              </Typography>
              <MuiDivider>/</MuiDivider>
              <Typography
                style={{
                  ...keyStyle,
                  color: checkedColor ? theme.palette.primary.main : '#4D8094',
                }}
              >
                {t('key_yes')}
              </Typography>
            </div>
          </Grid>
        </div>
    </MuiGridColorMap>
      {watch('colorType') === 'simple' && (
        <MuiGrid item xs={12}>
          <MuiGridSelectColor item xs={6}>
            <MuiTypographyCommon variant='caption'>
              {t('select_color')}
            </MuiTypographyCommon>
          </MuiGridSelectColor>
          <MuiCustomColorGradientField item xs={3}>
            <CustomColorGradientField
              name='categoryColor'
              defaultValue={watch('categoryColor')}
            />
          </MuiCustomColorGradientField>
        </MuiGrid>
      )}

      {watch('colorType') === 'by_value' && (
        <>
            <Grid container xs={12} style={{ margin: '16px 0' }}>
              <MuiGridItem item xs={3}>
                <MuiTypographyCommon variant='caption'>
                  {t('select_gradient_color')}
                </MuiTypographyCommon>
                <MuiGridSelectorGradient >
                  {isSuccess ? (
                    <SelectorGradient
                      name={'Defaults Gradients' || t('category_gradient')}
                      checked={checked}
                      value={Object.keys(gradient).length !== 0 ? gradient : gradients[0]}
                      gradients={gradients}
                      onChange={(e) => setColoring(e.target.value)}
                    />
                  ) : (
                    <CustomSkeleton height={28} width={220} />
                  )}
                </MuiGridSelectorGradient>
              </MuiGridItem>
              <MuiGridItem item xs={9}>
                <MuiTypographyCommon variant='caption' style={{ marginLeft: 12 }}>
                  {t('invert_color_palette')}
                </MuiTypographyCommon>
                <MuiGridInvertColor>
                  <MuiSwitchCommon
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Grid style={{ marginTop:24 }}>
                    <div style={{...divContainerStyle, marginTop:-17}}>
                      <Typography
                        style={{
                          ...keyStyle,
                          color: !checked ? theme.palette.primary.main : '#4D8094',
                        }}
                      >
                        {t('key_no')}
                      </Typography>
                      <MuiDivider>/</MuiDivider>
                      <Typography
                        style={{
                          ...keyStyle,
                          color: checked ? theme.palette.primary.main : '#4D8094',
                        }}
                      >
                        {t('key_yes')}
                      </Typography>
                    </div>
                  </Grid>
                </MuiGridInvertColor>
              </MuiGridItem>
            </Grid>
        </>
      )}
    </Grid>
  );
};

export default Category;
