import * as React from 'react';
import {
  Grid,
  Container,
  TableContainer,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useTranslations from 'api/hooks/useTranslations';
import {useLocation, useNavigate} from 'react-router-dom';

import internationalization from 'assets/img/internationalization.jpg';
import {useTranslation} from 'components/providers/TranslationProvider';
import Table from '../organizations/common/Table';
import AddOrUpdateTranslation from './AddOrUpdateTranslation';
import DeleteTranslation from './DeleteTranslation';
import {
  MuiButton,
  MuiGridButtonsContainer,
  MuiGridContainer,
  MuiGridImgContainer,
  MuiGridPaper, MuiGridTitle,
  MuiImg, MuiTypography
} from './styles/translations';
import TPagination from "../organizations/common/TPagination";
import {MuiTitle} from "../organizations/organization/styles/organization";

const Translations = () => {
  const {t, lng} = useTranslation();
  const {data, isLoading} = useTranslations();
  const location = useLocation();
  const navigate = useNavigate();

  const columns = ['Key', `${t('language')}: ${lng}`, t('actions')];
  const row = ['key', `${lng}`];

  const [searchTerm, setSearchTerm] = React.useState(new Array(columns.length).fill(''));
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const translateInUseOtherThanESorEN = ( translation ) => lng !== 'ES' || lng !== 'EN' ? translation[lng] : '';

  const filteredData = data
    ? data?.filter((item) => {
      const lowerCaseSearchTerms = searchTerm.map((term) => term.toLowerCase());
      const itemString = `${item.key} ${item.EN} ${item.ES} ${ translateInUseOtherThanESorEN(item) }`.toLowerCase();
      return lowerCaseSearchTerms.every((term) => itemString.includes(term));
    })
    : [];

  const handleDelete = (id, translation) =>
    navigate(`${location.pathname}?delete=${id}`, {
      replace: true,
      state: translation
    });

  const handleEdit = (id, translation) =>
    navigate(`${location.pathname}?edit-translation=${id}`, {
      replace: true,
      state: translation
    });

  const handleSearch = (event, index) => {
    const newSearchTerms = [...searchTerm];
    newSearchTerms[index] = event.target.value;
    setSearchTerm(newSearchTerms);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePreviousPage = () =>
    setPage((prevPage) => Math.max(0, prevPage - 1));

  const handleNextPage = () =>
    setPage((prevPage) => Math.min(Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1, prevPage + 1));

  const handleFirstPage = () => setPage(0);

  const handleLastPage = () => {
    const lastPage = Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1);
    setPage(lastPage);
  };
  const offset = page * rowsPerPage;
  const currentPageData = filteredData.slice(offset, offset + rowsPerPage);

  return (
    <Container style={{marginTop: 47}}>
      <Grid container>
        <MuiGridImgContainer item xs={3} >
          <MuiTitle>{t('translations')}</MuiTitle>
          <MuiGridPaper container >
            <MuiImg src={internationalization} alt={'logo'}/>
          </MuiGridPaper>
        </MuiGridImgContainer>

        <MuiGridContainer item xs={9}>
          {process.env.NODE_ENV === 'development' && (
            <MuiGridButtonsContainer>
              <MuiGridTitle item xs={8}>
                <MuiTypography variant='h6'> {t('translation_list')}</MuiTypography>
              </MuiGridTitle>
              <MuiButton
                variant='outlined'
                color='primary'
                startIcon={<AddIcon/>}
                onClick={() =>
                  navigate(`${location.pathname}?create=translation`, {
                    replace: true,
                  })}
                data-cy="newTranslationForm"
              >
                {t('new_translation')}
              </MuiButton>
            </MuiGridButtonsContainer>
          )}
          <AddOrUpdateTranslation/>
          {process.env.NODE_ENV === 'development' && <DeleteTranslation/>}
          <TableContainer>
            <Table
              handleSearch={handleSearch}
              searchTerm={searchTerm}
              data={currentPageData}
              row={row}
              columns={columns}
              onDelete={handleDelete}
              showDelete={process.env.NODE_ENV === 'development'}
              onEdit={handleEdit}
              showEdit
              isLoading={isLoading}
            />
          </TableContainer>
          <Grid container direction="column" alignItems="flex-start">
            <TPagination
              data={filteredData}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleFirstPage={handleFirstPage}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
              handleLastPage={handleLastPage}
            />
          </Grid>
        </MuiGridContainer>
      </Grid>
    </Container>
  );
};

export default Translations;
