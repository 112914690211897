import * as React from 'react';
import { usePermissions } from 'components/hooks/usePermissions';

import NewUser from './NewUser';
import { MuiGrid } from '../styles/user';
import { Grid } from '@mui/material';
import { MuiGridTitle, MuiTypographyTitle } from '../styles/actionBar';
import {useTranslation} from "../../../providers/TranslationProvider";

const ActionBar = () => {
  const { hasPermissionFor } = usePermissions();
  const { t } = useTranslation();

  return (
    <MuiGrid container >
      <MuiGridTitle item xs={9}>
        <MuiTypographyTitle variant='h6'>
          {t('users_list')}
        </MuiTypographyTitle>
      </MuiGridTitle>
      <Grid item xs={3} sx={{ paddingTop:'0px !important'}} >
        {hasPermissionFor('create_users') && <NewUser />}
      </Grid>
    </MuiGrid>
  );
};

export default ActionBar;
