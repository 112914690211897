import { GLOBAL } from 'utils/constants';
import {
  Grid,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import {lighten} from "@mui/material/styles";

export const MuiGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
}));

export const sectionTitle = {
  fontFamily: 'Raleway',
  fontWeight: 400,
  fontSize: 20,
};

export const priorityVariable = {
  marginRight: 8,
  marginLeft: 24,
}

export const flexAndBoxSizingContentBox = {
  display: 'flex',
  boxSizing: 'content-box',
}

export const itemsSeparator = {
  marginLeft: '16px',
  marginRight: '16px',
};

export const offerSubHeader = {
  fontFamily: 'Raleway',
  fontWeight: 400,
  fontSize: 20,
}

export const recommendedAreaLayerLabel = {
  marginRight: 34
};

export const typography = {};

export const infoIcon = {
  backgroundColor: '#ffffff',
};

export const MuiSwitchRecommendation = styled(Switch)(({ theme  }) => ({
  width: 52,
  height: 32,
  marginLeft: 8,
  padding: 0,
  display: 'flex',
  '&:active .MuiSwitch-thumb': {
    width: 12,
  },
  '&:active .Mui-checked': {
    transform: 'translateX(9px)',
  },
  '.MuiSwitch-switchBase': {
    color: '#4D8094',
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        border: 'none !important',
        opacity: 1,
        backgroundColor: GLOBAL.COLOR_THEME.PRIMARY,
      },
    },
  },
  '.MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    marginTop: 6,
    marginLeft: 4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '.MuiSwitch-track': {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: 20,
    border: '2px solid',
    borderColor: '#4D8094',
    boxSizing: 'border-box',
  },
  '.MuiSwitch-thumbChecked': {
    color: '#FFFFFF',
  },
}));


export const switchFieldRoot = {
  width: 153,
  height: 32,
  marginLeft:8,
  padding: 0,
  display: 'flex',
  '&:active .MuiSwitch-thumb': {
    width: 67,
  },
  '&:active .Mui-checked': {
    transform: 'translateX(9px)',
  },
};

export const switchFieldBase = ( theme ) => ({
  color:theme.palette.primary.main,
  padding: 2,
  '&$checked': {
    transform: 'translateX(74px)',
    color: theme.palette.primary.main,
    '&$checked + $track': {
      border: '1px solid',
      background: '#DFF2FC',
      borderColor: theme.palette.primary.main,
    },
  },
});

export const typographyWithInfoIconTooltipStyles = {
  paddingTop: 5,
  paddingBottom: 23,
  margin: 'auto',
};

export const selectSkeleton = {
  marginTop: -16,
};

export const sliderControl = ( flexbox ) => ({
  display: ({ flexbox }) => flexbox,
  justifyContent: 'space-between',
});

export const MuiSwitchCommon = styled(Switch)(({ theme }) => ({
  width: 52,
  height: 32,
  marginLeft: 8,
  padding: 0,
  display: 'flex',
  '&:active .MuiSwitch-thumb': {
    width: 12,
  },
  '&:active .Mui-checked': {
    transform: 'translateX(9px)',
  },
  '.MuiSwitch-switchBase': {
    color: `${lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.3)}`,
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        border: 'none !important',
        opacity: 1,
        backgroundColor: GLOBAL.COLOR_THEME.PRIMARY,
      },
    },
    '&.Mui-disabled': {
      color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400],
      '&.Mui-checked': {
        color: '#ffffff',
        '& + .MuiSwitch-track': {
          backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300],
          borderColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400],
        },
      },
    },
  },
  '.MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    marginTop: 6,
    marginLeft: 4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    '&.Mui-disabled': {
      color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400],
    },
  },
  '.MuiSwitch-track': {
    background: `${lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.875)}`,
    opacity: 1,
    borderRadius: 20,
    border: '2px solid',
    borderColor: `${lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.5)}`,
    boxSizing: 'border-box',
    '&.Mui-disabled': {
      backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[200],
      borderColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300],
    },
  },
  '.MuiSwitch-thumbChecked': {
    color: '#FFFFFF',
  },
}));

export const removePaddingsX = {
  paddingLeft: 0,
  paddingRight: 0,
};

export const layerName = {
  width: 541,
  height: 56,
};

export const MuiTypography = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
}));

export const visibilityIconSize = {
  width: 16,
  height: 16,
};

export const isGlobalSwitchSpacing = {
  marginTop: 10
};

export const isGlobalSwitch = {
  marginLeft: '1rem'
};

export const allowsRegenerateInput = {
  marginLeft: 39,
  marginRight: 8,
};

export const layerOpacity = {
  width: 123,
  height: 56,
  borderRadius: '4px 4px 0px 0px',
  marginRight: 16,
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '43%',
  }
};

export const colorSystem = {
  fontSize: 16,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
};

export const inputColorFieldStyle = {
  width: 24,
  height: 24
};

export const switchFieldChecked = {};

export const selectStyle = {
  height: '56px',
  width: '90px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#757575',
  },
  '& .MuiListItem-root': {
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

export const divMenuItemStyle = {
  borderRadius: '16px',
  width: '24px',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 4,
};
