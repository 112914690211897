import { useQueryClient, useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';

const exportSchemeBulkWidgets = ({ userId, scheme }, setErrors) => {
  return axios
    .post(apiPaths.exportSchemesBulkWidgets, { user_id: userId, scheme }, status200)
    .then((resp) => resp.data)
    .catch((e) => {
      setErrors(e.data.error);
    });
};

const useExportSchemaBulkWidgets = (setErrors) => {
  const queryClient = useQueryClient();
  return useMutation((values) => exportSchemeBulkWidgets(values, setErrors), {
    onSuccess: (data) => {
      queryClient.setQueryData(['export_scheme_bulk_widgets'], data);
    },
    onError: (error) => {
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['export_scheme_bulk_widgets']);
    },
  });
};

export default useExportSchemaBulkWidgets;
