import { Container, Grid, styled } from '@mui/material';

export const MuiGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 0 ),
  paddingTop: '30px',
  paddingBottom: '24px',
  margin: 0,
  width: '100%',
  marginLeft: 20
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  boxShadow: 'none',
  overflow: 'hidden',
  marginBottom: 35,
  maxWidth:'1350px !important'
}));
