import { useMutation } from 'react-query';

import axios from 'api/axios/axiosInstance';
import apiPaths from '../apiPaths';
import { status200 } from '../status.utils';

const uploadImage = (image) =>
  axios.post(apiPaths.upload_img, image, status200).then((resp) => resp.data.file);

const useUploadImage = () => {
  return useMutation((values) => uploadImage(values), {
    onSuccess: (data) => data,
    onError: (error) => {
      return error;
    },
  });
};
export default useUploadImage;
