import * as React from 'react';
import { Grid, } from '@mui/material';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ListIcon from '@mui/icons-material/List';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CategoryIcon from '@mui/icons-material/Category';
import ParametersIcon from "@mui/icons-material/Tune"
import { useFormContext, Controller } from 'react-hook-form';
import {useSelector} from "react-redux";
import {
  commonIconsWidgetStyle,
  MuiCard,
  MuiCardActionArea, MuiGrid,
  MuiTitle
} from './styles/selectWidgetType';
import { lighten } from '@mui/material/styles';
import { GLOBAL } from '../../../../../../../utils/constants';

const Card = ({ imgSrc, title, active, onClick, disabled }) => {
  return (
    <MuiCard
      disabled={disabled}
      active={active}
      elevation={0}
      style={{ background: disabled ? 'lightgray' : 'initial'}}>
      <MuiCardActionArea disabled={disabled} active={active} onClick={onClick}>
        <MuiGrid active={active} container direction='column'>
          {imgSrc}
          <MuiTitle align='center'>
            {title}
          </MuiTitle>
        </MuiGrid>
      </MuiCardActionArea>
    </MuiCard>
  );
};

const SelectWidgetType = React.forwardRef(({ name, allDisabled}, ref) => {
  const { control } = useFormContext();
  const widgets = useSelector((state) => state.app.widgets);
  const selectInputValues = [
    {
      value: 'recommendations',
      label: 'Recommendations',
      disabled: allDisabled,
      src: <ThumbUpAltIcon style={commonIconsWidgetStyle} />,
    },
    {
      value: 'histogram',
      label: 'Histogram',
      disabled: allDisabled,
      src: <BarChartOutlinedIcon style={commonIconsWidgetStyle} />,
    },
    {
      value: 'category',
      label: 'Category',
      disabled: allDisabled,
      src: <BarChartOutlinedIcon style={{...commonIconsWidgetStyle, transform: 'rotate(90deg)'}} />,
    },
    {
      value: 'list',
      label: 'List',
      disabled: allDisabled,
      src: <ListIcon style={commonIconsWidgetStyle} />,
    },
    {
      value: 'statistics',
      label: 'Statistics',
      disabled: allDisabled,
      src: <ViewStreamIcon style={commonIconsWidgetStyle} />,
    },
    {
      value: 'polygon',
      label: 'Polygon',
      disabled: allDisabled,
      src: <CategoryIcon style={commonIconsWidgetStyle} />,
    },
    {
      value:'parameters',
      label:'Simulation',
      disabled: allDisabled,
      src: <ParametersIcon style={commonIconsWidgetStyle} />,
    }
  ];
  const getDisableAvailability = (i) =>{
    if (i.value === 'parameters'){
      return widgets.some(widget => widget.type === 'parameters');
    }
    return i.disabled
  }

  return (
    <>
      <Controller
        control={control}
        ref={ref}
        name={name}
        render={({ field: { value, onChange } }) => (
          <Grid container sx={{ gap: '19.5px' }}>
            {selectInputValues.map((i) => (
              <Card
                key={i.value}
                active={value === i.value}
                onClick={getDisableAvailability(i) ? undefined : () => onChange(i.value)}
                title={i.label}
                imgSrc={i.src}
                disabled={getDisableAvailability(i)}
              />
            ))}
          </Grid>
        )}
      />
    </>
  );
});

export default SelectWidgetType;
