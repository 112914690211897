import { useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'components/providers/TranslationProvider';

const generatePotentialOffers = (data) => {
    return axios
        .post(apiPaths.generatePotentialOffers, data , status200)
        .then((resp) => resp.data);   
};

const useGeneratePotentialOffers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return useMutation((values) => generatePotentialOffers(values), {
      onError: (error) => {
        dispatch(setOpenToast(true));
        dispatch(setMessageToast( t(error.data.error) ));
      }
    });
  };
  
  export default useGeneratePotentialOffers;