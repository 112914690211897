import { GLOBAL } from 'utils/constants';
import { Grid, styled } from '@mui/material';

export const MuiGridCommon = styled(Grid)(({ theme }) => ({
  margin: 'auto',
  marginBottom: 12,
}));

export const MuiColorPickers = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  paddingLeft: '8px',
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const colorCommonStyle = {
  color: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[800],
  fontFamily: 'Montserrat'
};

export const inputStyleColor = {
  width: 24,
  height: 24
};

export const gridMarginStyle = {
  marginTop: -10,
  marginBottom: 12,
}

export const nameFieldStyle = {
  paddingBottom:0,
  marginBottom:24
}

export const shortNameFieldStyle = {
  marginTop:-10
}

export const gridDividerStyle = {
  marginTop:-8
}

export const customDividerStyle = {
  borderColor: '#EEEEEE'
}
