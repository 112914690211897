import * as React from 'react';
import { Button as MuiButton } from '@mui/material';
import MuiCircularProgress from '@mui/material/CircularProgress';

const Button = React.forwardRef(({ loading, children, ...props }, ref) => (
  <MuiButton
    ref={ref}
    startIcon={loading && <MuiCircularProgress color='inherit' size={20} />}
    variant='contained'
    color='primary'
    disabled={loading}
    {...props}
  >
    {children}
  </MuiButton>
));
export default Button;
