import { API_VERSIONS } from '@deck.gl/carto';
import {GOOGLE_ROADMAP} from "lib/react-geo-tool/packages/react-basemaps/src/basemaps/basemaps";

export const initialState = {
  viewState: {
    latitude: 31.802892,
    longitude: -103.007813,
    zoom: 2,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: GOOGLE_ROADMAP,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
    accessToken: 'access_token'
  },
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,// only required when using a Google Basemap
  bingApiKey: process.env.REACT_APP_BING_API_KEY // only required when using a Bing Basemap
};

export const oauthInitialState = {
  oauthApp: {
    clientId: 'TYPE HERE YOUR OAUTH CLIENT ID',
    scopes: [
      'user:profile', // to load avatar photo
    ],
    authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  },
  token: null,
  userInfo: null,
};
