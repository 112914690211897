import { useMutation } from 'react-query';

import axios from 'api/axios/axiosInstance';
import apiPaths from '../apiPaths';
import { status200 } from '../status.utils';

const uploadFile = (file) =>
  axios.post(apiPaths.upload_file, file, status200).then((resp) => resp.data.file);

const useUploadFile = () => {
  return useMutation((values) => uploadFile(values), {
    onSuccess: (data) => data,
    onError: (error) => {
      return error;
    },
  });
};
export default useUploadFile;
