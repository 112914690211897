import * as React from 'react';
import { Grid } from '@mui/material';

import { useTranslation } from 'components/providers/TranslationProvider';
import { gridStyle, inputStyle, MuiBtnUpload } from './styles/uploadFile';

const UploadFile = ({ handleChange, name }) => {
  const { t } = useTranslation();

  return (
    <Grid container style={gridStyle}>
      <Grid container style={gridStyle}>
        <input
          name={name}
          accept='application/geo+json'
          id={`contained-button-file-logo-${name}`}
          style={inputStyle}
          type='file'
          onChange={handleChange}
        />
        <label htmlFor={`contained-button-file-logo-${name}`}>
          <MuiBtnUpload variant='outlined' color='primary' component='span'>
            {t('upload')}
          </MuiBtnUpload>
        </label>
      </Grid>
    </Grid>
  );
};

export default UploadFile;
