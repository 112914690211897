import { Checkbox, IconButton, List, styled } from '@mui/material';
import { Delete, FilterList } from '@mui/icons-material';
import { GLOBAL } from '../../../../utils/constants';
import { lighten } from '@mui/material/styles';

export const MuiDivRoot = styled('div')(({ theme }) => ({
  width: '100%',
  height: 462,
  '&, & *': {
    '&::-webkit-scrollbar': {
      backgroundColor: '#ebeef2',
      height: 5,
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d3dbe5',
      borderRadius: 3,
    },
  },
}));

export const MuiCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked':{
    color: theme.palette.primary.main,
  },
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  width: '32px',
  height: '32px',
  padding: '10px',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
}));

export const MuiFilterListIcon = styled(FilterList)(({ theme }) => ({
  width: '24px',
  height: '24px',
  marginTop:4,
  color: '#616161',
}));

export const MuiDeleteIcon = styled(Delete)(({ theme }) => ({
  width: '24px',
  height: '24px',
  marginTop:4,
  color: '#616161',
}));

export const MuiList = styled(List)(({ theme }) => ({
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: '8px',
  },
  '& .MuiAvatar-root': {
    color: '#424242',
    backgroundColor: '#F5F5F5'
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '& .MuiListItem-gutters': {
    padding: 0
  },
  '& .MuiList-root , &.MuiListItem-gutters': {
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  '& .MuiListItem-root:first-of-type':{
    mt : 0
  },
  '& .MuiList-root, &.MuiList-padding' : {
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  }
}));

export const search={
  width: '314px',
  '& .MuiInputBase-root': {
    height: '36px',
    borderRadius: '18px',
    border:`1px solid ${localStorage.getItem('colorPrimary')}`,
    fontFamily: 'Montserrat',
    color: '#424242',
    fontSize: '14px',
    '&:hover': {
      border:`1px solid ${localStorage.getItem('colorPrimary')} !important`,
    },
  },
}

export const searchIconStyle = {
  color: '#ffffff',
  height: '24px',
  marginLeft:'-8px',
  padding:'4px',
  backgroundColor:localStorage.getItem('colorPrimary'),
  borderRadius:'50%'
}

export const icon= {
  width: '24px',
  height: '24px',
  color: '#616161',
}

export const button ={
  width: '32px',
  height: '32px',
  padding: '10px',
  borderRadius: '16px',
}

export const list ={
  flexGrow: 1,
  overflowY: 'auto',
}

export const overline = {
  fontSize: 10,
  fontWeight: 'regular',
  fontFamily: 'Montserrat',
  paddingLeft: '35px',
  textTransform: 'uppercase'
}

export const body1 = {
  fontSize: 16,
  fontWeight: 'regular',
  fontFamily: 'Montserrat'
}

export const body2 = {
  fontSize: 14,
  fontWeight: 'regular',
  fontFamily: 'Montserrat',
  display: 'inline',
  color: '#212121'
}
export const paginationStyle = {
  height: '48px'
};

export const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '420px',
  border: '1px solid #E0E0E0',
  borderRadius: '12px',
  width: '100%',
  marginTop: '9px',
};

export const listRow = {
  height: '52px',
}
