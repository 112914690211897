import { Grid, styled } from '@mui/material';
import CustomSkeleton from '../../../../../common/CustomSkeleton';

export const MuiContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const MuiGridPaper = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3, 8),
  width: '100%',
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(2, 0, 4),
  padding: theme.spacing(0, 0, 2),
}));

export const MuiGridSkeleton = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1, 2),
  margin: theme.spacing(2, 0),
}));

export const MuiGridButtons = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const MuiCustomSkeleton = styled(CustomSkeleton)(({ theme }) => ({
  width: '100%',
}));

