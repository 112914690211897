/* eslint-disable camelcase */
import * as React from 'react';
import { Grid, Typography } from '@mui/material';

import SelectorGradient from 'components/common/SelectorGradient';
import { useTranslation } from 'components/providers/TranslationProvider';
import useDefaultGradients from 'api/hooks/useDefaultGradients';
import CustomSkeleton from 'components/common/CustomSkeleton';
import { useTheme } from '../../../../../../../providers/CustomThemeProvider';
import {
  divContainerStyle, keyStyle, MuiDivider,
  MuiInvertColorPallete,
  MuiSelectGradient, MuiSwitch
} from './styles/histogram';

// eslint-disable-next-line no-unused-vars
const Histogram = ({
  gradient,
  setGradient,
  selectedGradientId,
  palette_reverse,
  setPaletteReverse,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(palette_reverse);
  const {theme} = useTheme()

  // eslint-disable-next-line no-unused-vars
  const handleChange = (event) => {
    setChecked(event.target.checked);

  };
  React.useEffect(() => {
    setPaletteReverse(checked);
  }, [checked]);

  const { data: gradients, isSuccess } = useDefaultGradients();

  React.useEffect(() => {
    if (selectedGradientId && Object.keys(gradient).length === 0 && isSuccess) {
      if (gradients.find((g) => g.id === selectedGradientId)){
        setGradient(gradients.find((g) => g.id === selectedGradientId));
      }else{
        setGradient(gradients.find((g) => g.name === 'Gradient1'));
      }
    } else if (Object.keys(gradient).length === 0 && isSuccess) {
      setGradient(gradients[0]);
    }
  }, [isSuccess]);

  return (
    <>
      <Grid container style={{ margin: '16px 0', display: 'flex' }}>
        <Grid>
          <MuiSelectGradient variant='caption'>
            {t('select_gradient_color')}
          </MuiSelectGradient>
          {isSuccess ? (
            <SelectorGradient
              name={t('gradients') || t('histogram_gradient')}
              checked={checked}
              value={gradient && Object.keys(gradient).length !== 0 ? gradient : gradients[0]}
              gradients={gradients}
              onChange={(e) => setGradient(e.target.value)}
            />
          ) : (
            <CustomSkeleton height={28} width={220} />
          )}
        </Grid>
        <Grid style={{ marginTop:24 }}>
          <MuiInvertColorPallete variant='body1'>
            {t('invert_color_palette')}
          </MuiInvertColorPallete>
        </Grid>
        <Grid style={{ marginTop:24 }}>
          <MuiSwitch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid style={{ marginTop:24 }}>
          <div style={divContainerStyle}>
            <Typography
              style={{
                ...keyStyle,
                color: !checked ? theme.palette.primary.main : '#4D8094',
              }}
            >
              {t('key_no')}
            </Typography>
            <MuiDivider>/</MuiDivider>
            <Typography
              style={{
                ...keyStyle,
                color: checked ? theme.palette.primary.main : '#4D8094',
              }}
            >
              {t('key_yes')}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Histogram;
