import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

const CloseIcon = ({ onClose, style }) => {
  return (
    <IconButton
      onClick={onClose}
      style={{
        ...style,
        backgroundColor: 'rgba(202,202,202,0.9)',
        margin: 0,
        padding: 0,
        boxShadow: '0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important',
      }}
    >
      <ClearIcon style={{ color: localStorage.getItem('colorPrimary') }} />
    </IconButton>
  );
};

export default CloseIcon;
