import * as React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import TabForm from './TabForm';

const AddOrUpdateGroup = () => {
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState('create');

  const location = useLocation();
  const navigate = useNavigate();

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  React.useEffect(() => {
    const createQuery = query.get('create');
    const updateQuery = query.get('edit-widgets-group');
    if (createQuery === 'widgets-group') {
      setAction('create');
      setOpen(true);
    }
    if (updateQuery) {
      setAction('update');
      setOpen(true);
    }
  }, [query]);

  const handleClose = (event, reason) => {
    if ( reason === 'backdropClick' || reason === 'escapeKeyDown' ) {
      setOpen( true )
    } else {
      if (action === 'create') query.delete('create');
      if (action === 'update') query.delete('edit-widgets-group');
      setOpen(false);
      navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
    }
  };

  return <>{open && <TabForm action={action} open={open} onClose={handleClose} />}</>;
};

export default AddOrUpdateGroup;
