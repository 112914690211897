import { status200 } from './status.utils';
import axios from './axios/authAxios';
import apiPaths from './apiPaths';

const resetPassword = (data, onError, onSuccess, token) =>
  axios
    .post(
      `${apiPaths.reset_password}${data.token}`,
      { password: data.password, token: data.token },
      {
        headers: {
          Authorization: `Basic ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      status200
    )
    .then((resp) => onSuccess(resp.data))
    .catch((e) => onError(e.error));

export default resetPassword;
