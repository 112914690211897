import { IconButton, styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../utils/constants';

export const MuiDivContainer = styled('div')(({ theme }) => ({
  display: GLOBAL.POSITION.FLEX,
  alignItems: GLOBAL.POSITION.CENTER,
  backgroundColor: theme.palette.common.white,
  borderRadius: 12,
  width: 120,
  height: 45
}));

export const MuiDivider = styled('div')(({ theme }) => ({
  width: '1px',
  height: '24px',
  backgroundColor: '#e0e0e0',
  margin: '0 8px',
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  placeSelf: GLOBAL.POSITION.CENTER,
  display: GLOBAL.POSITION.FLEX,
  justifyContent: GLOBAL.POSITION.SPACEAROUND,
  alignItems: GLOBAL.POSITION.CENTER,
  backgroundColor: theme.palette.common.white,
  minHeight: theme.spacing(4.5),
  margin: '0 1px',
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  padding: 10,
  '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
}));
