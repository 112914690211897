import {checkCredentials, getFiltersToString, makeCall} from './common';
import {MAP_TYPES, API_VERSIONS} from '@deck.gl/carto';
import {getTileJson} from './tilejson';
import {
  InvalidColumnError,
  REQUEST_GET_MAX_URL_LENGTH,
  _assert as assert
} from 'lib/react-geo-tool/packages/react-core/src/';

/**
 * Execute a stats service request.
 *
 * @param { object } props
 * @param { string } props.column - column to get stats for
 * @param { object } props.source - source that owns the column
 * @param { object= } props.opts - Additional options for the HTTP request
 * @param {object} props.parameters - parameters catalog
 */
export async function getStats(props) {
  assert(props.source, 'getStats: missing source');
  assert(props.column, 'getStats: missing column');

  const {source, column, opts, parameters} = props;
  const options = {...opts};
  checkCredentials(source.credentials);

  assert(
    source.credentials.apiVersion === API_VERSIONS.V3,
    'Stats API is a feature only available in CARTO 3.'
  );

  if (source.type === MAP_TYPES.TILESET) {
    const tileJson = await getTileJson({source});
    const tileStatsAttributes = tileJson.tilestats.layers[0].attributes;
    const columnStats = tileStatsAttributes.find(({attribute}) => attribute === column);

    if (!columnStats) {
      throw new InvalidColumnError(`${column} not found in tileset attributes`);
    }

    return columnStats;
  }

  const isQuery = source.type === MAP_TYPES.QUERY;
  const filters = getFiltersToString(source);
  const baseUrl = new URL(
    `${source.credentials.apiBaseUrl}widgets/stats?source=${source.datasetName}`
  );
  const body = {variable: column, filters: filters,parametersCatalog:parameters};

  const queryParams = {};
  if (isQuery) {
    queryParams.q = source.data;
    if (source.queryParameters) {
      queryParams.queryParameters = JSON.stringify(source.queryParameters);
    }
  }

  return makeCall({
    url: baseUrl,
    credentials: source.credentials,
    opts: {
      ...opts,
      method: 'POST',
      body: JSON.stringify(body),
    }
  });
}
