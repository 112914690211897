import React from 'react';
import {
  TextField as MuiTextField,
  Checkbox,
  ThemeProvider,
  Grid,
  Slider, Typography, Switch
} from '@mui/material';
import MuiInput from '@mui/material/Input';
import { useFormContext, Controller } from 'react-hook-form';
import { GLOBAL } from "utils/constants";

import themeIncluia from 'theme/incluia';

import CustomInputColorGradient from './CustomInputColorGradient';
import {lighten} from "@mui/material/styles";
import { OutlinedInput } from './styles/reactHooksFormFields';

const CustomTextField = React.forwardRef(({ error, onChange, name, newStyles, ...props }, ref) => {
  return (
    <MuiTextField
      ref={ref}
      id={name}
      name={name}
      error={Boolean(error)}
      helperText={ typeof error === 'boolean' ? '' : error}
      inputProps={props.multiline ? {} : { style: { height: 35 } }}
      sx={{
        '& .MuiInputBase-root': {
          height: 56,
          fontFamily: 'Montserrat',
          fontWeight: 400,
          fontSize: '16px',
        },
        input: {
          height: 56,
          fontFamily: 'Montserrat !important',
          fontWeight: 400,
          fontSize: '16px'
        },
      }}
      onChange={onChange}
      {...props}
    />
)});

const TextField = React.forwardRef(({ name, ...props }, ref) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      ref={ref}
      control={control}
      defaultValue=''
      fullWidth
      render={({ field }) =>
        <CustomTextField margin={GLOBAL.POSITION.DENSE} type={GLOBAL.TYPE.TEXT} {...field} {...props} />}
    />

  );
  })

const TextFieldCustom = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      fullWidth
      render={({
             field: { onChange, onBlur, value, name, ref },
      }) =>
        <ThemeProvider theme={themeIncluia}>
          <MuiTextField
            value={value}
            onChange={onChange}
            inputRef={ref}
            sx={{
              '& .MuiInputBase-root': {
              height: 56,
              fontFamily: 'Montserrat !important',
              fontWeight: 400,
              fontSize: '16px',
            },
            input: {
              height: 56,
              fontFamily: 'Montserrat !important',
              fontWeight: 400,
              fontSize: '16px'
            },
              ...sx,
            }}
            {...props}
          />
        </ThemeProvider>
      }
    />
  );
};

const CustomColorGradientField = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      render={({ field: { value, onChange } }) => {
        return <CustomInputColorGradient value={value} onChange={(v) => onChange(v)} {...props} />;
      }}
    />
  );
};

const NumericSelect = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      variant={GLOBAL.VARIANTS.OUTLINED}
      fullWidth
      render={({ field }) => (
        <CustomTextField
          InputProps={{
            style: { height: 56, fontFamily: 'Montserrat' },
          }}
          InputLabelProps={{
            style: { color: '#424242', fontSize:12, fontFamily:'Montserrat'}
          }}
          margin={GLOBAL.POSITION.NONE}
          type={GLOBAL.TYPE.NUMBER}
          variant={GLOBAL.VARIANTS.OUTLINED}
          {...field}
          {...props}
        />
      )}
    />
  );
};

const ColorField = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
      <Controller
        name={name}
        control={control}
        defaultValue=''
        fullWidth
        render={({ field }) => <OutlinedInput type={GLOBAL.TYPE.COLOR} {...field} {...props} />}
      />
  );
};

const SelectField = ({ name, error, ...props }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({
                 field: {
                   value,
                   onChange,
                   ref,
                 }
       }) => {
        return (
            <MuiTextField
              id={name}
              name={name}
              error={Boolean(error)}
              helperText={error}
              inputProps={props.multiline ? {} : { style: { height: 35 } }}
              style={{ paddingTop: 5, paddingBottom: error ? 0 : 23 }}
              onChange={onChange}
              value={value}
              select
              {...props}
            />
        );
      }}
    />
  );
};

const CheckboxField = ({ name, error, ...props }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value = false, onChange } }) => {
        return (
          <Checkbox
            checked={value}
            onClick={onChange}
            color={GLOBAL.COLOR.PRIMARY}
            error={error}
            id={name}
            {...props}
          />
        );
      }}
    />
  );
};

const SwitchField = ({ name, error, ...props }) => {
  const { control } = useFormContext();
  return (
      <Controller
        control={control}
        name={name}
        render={({ field: { value = false, onChange } }) => {
          return <Switch checked={value} onClick={onChange} error={error} id={name} {...props} />;
        }}
      />

  );
};

const SwitchFieldWithTheme = ({ name, error, ...props }) => {
  const { control } = useFormContext();
  return (
      <Controller
        control={control}
        name={name}
        render={({ field: { value = false, onChange } }) => {
          return <Switch checked={value} onClick={onChange} error={error} id={name} {...props} />;
        }}
      />
  );
};

const SliderField = ({ name, error, defaultValue, custom=false, ...props }) => {
  const { control, getValues, setValue } = useFormContext();
  const [maxValue, setMaxValue] = React.useState( getValues(name) > (props.customMax || props.max) ? getValues(name) : (props.customMax || props.max) ); // initial max value
  const [stateValue, setStateValue] = React.useState(getValues(name) || defaultValue || 1);

  const calculateStep = (value) => {
    if (value  < props.min+10) return 1;
    if (value < props.min+100) return 10;
    if (value < props.min+1000) return 100;
    return 1000;
  };

  const handleSliderChange = (event, newValue) => {
    setStateValue(newValue);
    setValue(name, stateValue)
    if (props?.customOnChange) {
      props.customOnChange(event, newValue);
    }
  };

  const handleInputChange = (event) => {
    const newValue = Number(event.target.value);
    setStateValue(newValue);
    setValue(name, newValue)
    if (props.customMax && newValue >= maxValue) {
      setMaxValue( parseInt(newValue, 10) )
    }
    if (props?.customOnChange) {
      props.customOnChange(event);
    }
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value = defaultValue, onChange } }) => {
        return (
          custom ? (<Grid container spacing={2} alignItems="center">
            <Grid item xs={ props.gridSliderColumn || 9 }>
              <Slider
                value={stateValue}
                onChange={handleSliderChange}
                error={error}
                id={name}
                aria-labelledby="input-slider"
                max={maxValue}
                step={custom ? calculateStep(value) : 1}
                {...props}
              />
            </Grid>
            <Grid item xs={props.gridSliderInputColumn || 3}>
            <MuiTextField
              value={stateValue}
              size="small"
              onChange={handleInputChange}
              variant='outlined'
              inputProps={{
                step: props.step ? props.step : 1,
                min: props.min,
                type: 'number',
                max: props.max,
                'aria-labelledby': 'input-slider',
              }}
              styles={{
                '& .MuiInputBase-root': {
                  border: '1px solid black',
                  width: '100%',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px', // Cambia el radio de borde según sea necesario
                },
              }}
              id={`${name}_input-slider`}
              {...props}
            />
            </Grid>
          </Grid>) :
          <Slider
          value={value}
          onChange={(e, v) => onChange(v)}
          error={error}
          id={name}
          {...props}
        />
        );
      }}
    />
  );
};
const SliderFieldCustom = ({ name, error, defaultValue, custom=false, isConfigRecomendationsForm=false, ...props }) => {
  const { control, getValues, setValue } = useFormContext();
  const [maxValue, setMaxValue] = React.useState( getValues(name) > (props.customMax || props.max) ? getValues(name) : (props.customMax || props.max) ); // initial max value
  const [stateValue, setStateValue] = React.useState(getValues(name) || defaultValue || 1);

  const calculateStep = (value) => {
    if (value  < props.min+10) return 1;
    if (value < props.min+100) return 10;
    if (value < props.min+1000) return 100;
    return 1000;
  };

  const handleSliderChange = (event, newValue) => {
    setStateValue(newValue);
    setValue(name, stateValue)
    if (props?.customOnChange) {
      props.customOnChange(event, newValue);
    }
  };

  const handleInputChange = (event) => {
    const newValue = Number(event.target.value);
    setStateValue(newValue);
    setValue(name, newValue)
    if (props.customMax && newValue >= maxValue) {
      setMaxValue( parseInt(newValue, 10) )
    }
    if (props?.customOnChange) {
      props.customOnChange(event);
    }
  };
  const stepSize = ( (props.customMax || props.max ) - props.min) / 10;
  const marks = Array.from({ length: 10 }, (_, index) => ({
    value: props.min + stepSize * index
  }));

  const styles = {
    color: lighten( localStorage.getItem('colorPrimary'), 0.7),
    marginLeft:'5px !important',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 4,
      borderRadius: '4px',
      backgroundColor:  localStorage.getItem('colorPrimary'),
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 10,
      fontFamily: 'Montserrat',
      background: 'unset',
      padding: 0,
      width: 36,
      height: 20,
      borderRadius: '8px',
      backgroundColor: lighten( localStorage.getItem('colorPrimary'), 0),
      '&::before': { display: 'none' },
    },
    '& .MuiSlider-mark': {
      color: localStorage.getItem('colorPrimary'),
      backgroundColor: localStorage.getItem('colorPrimary'),
    },
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value = defaultValue, onChange } }) => {
        return (
          custom ? (<Grid container spacing={1} alignItems="center">
              <Grid item xs={ props.gridSliderColumn || 9 }>
                <Slider
                  value={stateValue}
                  onChange={handleSliderChange}
                  error={error}
                  id={name}
                  aria-labelledby="input-slider"
                  max={maxValue}
                  step={custom ? calculateStep(value) : 1}
                  {...props}
                  marks={marks}
                  sx={styles}
                />
              </Grid>
              <Grid item xs={props.gridSliderInputColumn || 3}  style={ isConfigRecomendationsForm ? {justifyContent: "end", display: "flex" } : {}}>
                <MuiInput
                  value={stateValue}
                  size="small"
                  onChange={handleInputChange}
                  disableUnderline
                  sx={{
                    border: '1px solid #417183',
                    borderRadius: '12px',
                    width: '78px',
                    height: '56px',
                    '& input': {
                      padding: 0,
                      marginLeft: '15px',
                      color: '#143440',
                      fontFamily: 'Montserrat',
                      fontSize: '16px'
                    }
                }}
                  inputProps={{
                    step: props.step ? props.step : 1,
                    min: props.min,
                    max: props.max,
                  }}
                  id={`${name}_input-slider`}
                  {...props}
                />
              </Grid>
            </Grid>) :
            <Slider
              value={value}
              onChange={(e, v) => onChange(v)}
              error={error}
              id={name}
              {...props}
            />
        );
      }}
    />
  );
};

const SliderFieldCustomTwo = ({ name, error, defaultValue, ...props }) => {
  const { control, getValues, setValue } = useFormContext();
  const [stateValue, setStateValue] = React.useState(defaultValue || [props.min, props.max]);

  const handleSliderChange = (event, newValue) => {
    setStateValue(newValue);
    setValue(name, stateValue)
    if (props?.customOnChange) {
      props.customOnChange(event, newValue);
    }
  };

  const handleInputChange = (event) => {
    const newValue = Number(event.target.value);
    setStateValue(newValue);
    setValue(name, newValue)
    if (props?.customOnChange) {
      props.customOnChange(event);
    }
  };
  const stepSize = (props.max  - props.min) / 10;
  const marks = Array.from({ length: 10 }, (_, index) => ({
    value: props.min + stepSize * index
  }));

  const styles = {
    color: lighten( localStorage.getItem('colorPrimary'), 0.9),
    height: 8,
    width: '128px',
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 4,
      borderRadius: '4px',
      backgroundColor:  localStorage.getItem('colorPrimary'),
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 10,
      fontFamily: 'Montserrat',
      background: 'unset',
      padding: 0,
      width: 36,
      height: 20,
      borderRadius: '8px',
      backgroundColor: lighten( localStorage.getItem('colorPrimary'), 0),
      '&::before': { display: 'none' },
    },
    '& .MuiSlider-mark': {
      color: localStorage.getItem('colorPrimary'),
      backgroundColor: localStorage.getItem('colorPrimary'),
    },
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => {
        return (
          <Grid container alignItems="center">
              <Grid item xs={ 6  }>
                <Slider
                  value={value}
                  onChange={handleSliderChange}
                  error={error}
                  id={name}
                  aria-labelledby="input-slider"
                  {...props}
                  marks={marks}
                  sx={styles}
                />
              </Grid>
              <Grid item xs={ 6}>
                <MuiInput
                  value={stateValue[0]}
                  size="small"
                  onChange={handleInputChange}
                  disableUnderline
                  sx={{
                    border: '1px solid #E0E0E0',
                    borderRadius: '4px',
                    width: '44px',
                    height: '30px',
                    '& input': {
                      padding: '7px 2px 8px 2px',
                      color: '#143440',
                      fontFamily: 'Montserrat',
                      fontSize: '12px'
                    }
                  }}
                  inputProps={{
                    step: props.step ? props.step : 1,
                    min: props.min,
                    max: props.max,
                  }}
                  id={`${name}_input-slider`}
                  {...props}
                />
                <Typography variant='caption' sx={{fontFamily: 'Montserrat', color: '#616161'}}> a </Typography>
                <MuiInput
                  value={stateValue[1]}
                  size="small"
                  onChange={handleInputChange}
                  disableUnderline
                  sx={{
                    border: '1px solid #E0E0E0',
                    borderRadius: '4px',
                    width: '44px',
                    height: '30px',
                    '& input': {
                      padding: '7px 2px 8px 2px',
                      // marginLeft: '15px',
                      color: '#143440',
                      fontFamily: 'Montserrat',
                      fontSize: '12px'
                    }
                  }}
                  inputProps={{
                    step: props.step ? props.step : 1,
                    min: props.min,
                    max: props.max,
                  }}
                  id={`${name}_input-slider`}
                  {...props}
                />
                <Typography variant='caption' sx={{fontFamily: 'Montserrat', color: '#616161'}}> m2 </Typography>
              </Grid>
            </Grid>
        );
      }}
    />
  );
};
export {
  TextField,
  TextFieldCustom,
  NumericSelect,
  ColorField,
  SelectField,
  CheckboxField,
  SliderField,
  SwitchField,
  SwitchFieldWithTheme,
  CustomColorGradientField,
  SliderFieldCustom,
  SliderFieldCustomTwo
};
