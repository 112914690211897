import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {InputBase, Select, styled} from "@mui/material";

export const MuiExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  marginLeft: theme.spacing(-3.5),
  fontsize: '14px',
}));

export const MuiInputBase = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  borderRadius: 4,
  fontWeight: 400,
  letterSpacing: 0.25,
  position: 'relative',
  fontSize: 14,
  padding: '5px 10px 5px 5px',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  color: 'black',
  // Use the system font instead of the default Roboto font.
  fontFamily: [
    'Montserrat', 
    'sans-serif',
  ].join(','),
  '&:focus': {
    borderRadius: 4,
    borderColor: '#80bdff',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  },
}));