import { Grid } from '@mui/material';
import Toast from 'components/common/Toast';
import { useFormContext } from 'react-hook-form';
import * as React from 'react';
import {
  setAllTabs, setIsValidDescriptiveScenario,
  setLayers,
  setWidgets,
  setRichDescription,
  setShowActionCatalog,
  setShowDownloadLayer,
  setScenarioVisibility,
  setAllowUpgradeOperator,
} from 'store/appSlice';
import DownloadJSON from './Download';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'components/providers/AuthProvider';
import { giveMeId } from 'utils/supportComponents';
import UploadJSON from './Upload';
import { useLocation } from 'react-router';
import { useTranslation } from 'components/providers/TranslationProvider';

const UploadDownloadJson = ({randomId}) => {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('error');
  const [open, setOpen] = React.useState(false);
  const tabs = useSelector((state) => state.app.tabs);
  const layers = useSelector((state) => state.app.layers);
  const widgets = useSelector((state) => state.app.widgets);
  const richDescription = useSelector((state) => state.app.richDescription);

  const scenarioVisibility = useSelector((state) => state.app.scenarioVisibility);
  const allowUpgradeOperator = useSelector((state) => state.app.allowUpgradeOperator);
  const showActionCatalog = useSelector((state) => state.app.showActionCatalog);
  const showDownloadLayer = useSelector((state) => state.app.showDownloadLayer);

  const location = useLocation();
  const { t } = useTranslation();

  const setStateAfterBulkWidgets = (data) => {
    dispatch(setWidgets(data.widgets));
    dispatch(setAllTabs(data.tabs));
    dispatch(setLayers(data.layers));
    dispatch(setIsValidDescriptiveScenario(true));
    dispatch(setRichDescription(data.richDescription));
    dispatch(setShowActionCatalog(data.showActionCatalog));
    dispatch(setShowDownloadLayer(data.showDownloadLayer));
    dispatch(setScenarioVisibility(data.scenarioVisibility));
    dispatch(setAllowUpgradeOperator(data.upgradeOperator));
    setMessage('Success');
    setSeverity('success');
    setOpen(true);
  };

  const setErrors = (data) => {
    let errorMessage = '';
    try {
      errorMessage = data.includes('incluia') ? t(data) : data;
    } catch (error) {
      errorMessage = t('error_importing_file');
    }
    setMessage( errorMessage );
    setSeverity('error');
    setOpen(true);
  };

  const { user } = useAuth();
  const id = giveMeId(location.pathname, user);
  const { getValues } = useFormContext();
  const values = {...getValues(), richDescription, scenarioVisibility, allowUpgradeOperator, showActionCatalog, showDownloadLayer };

  return (
    <Grid container justifyContent='center' style={{ display: 'flex' }}>
      <Toast
        open={open}
        handleClose={() => setOpen((prevState) => !prevState)}
        severity={severity}
        vertical='top'
        horizontal='center'
        title={message}
      />
      <Grid container justifyContent='flex-end' alignContent='center' style={{ marginTop: '40px' }}>
        <DownloadJSON
          id={id}
          values={values}
          layers={layers}
          widgets={widgets}
          tabs={tabs}
          setErrors={setErrors}
        />
        <UploadJSON
          id={id}
          values={values}
          randomId={randomId}
          layers={layers}
          tabs={tabs}
          widgets={widgets}
          setState={setStateAfterBulkWidgets}
          setErrors={setErrors}
        />
      </Grid>
    </Grid>
  );
};

export default UploadDownloadJson;
