import { Box, Divider, Grid, styled } from '@mui/material';
import { GLOBAL } from '../../../utils/constants';
import { hexToRgb } from '../../../utils/colorConverter';

export const MuiBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height'
})(({ theme, width, height }) => ({
  width: width+50,
  height: height,
  margin: theme.spacing(0, 0 , 0, -1),
  borderRadius: 4,
  borderWidth: 0,
  borderStyle: GLOBAL.POSITION.SOLID,
  outlineOffset: GLOBAL.POSITION._2PX,
  display: GLOBAL.POSITION.FLEX,
  justifyContent: GLOBAL.POSITION.CENTER,
  alignItems: GLOBAL.POSITION.CENTER,
  '&:hover': {
    cursor: GLOBAL.POSITION.POINTER,
  },
}));

export const MuiBoxGradient = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height' && prop !== 'selected'
})(({ theme, width, height, selected }) => ({
  width:  width,
  height: height,
  borderRadius: 4,
  margin: '8px 0',
  borderWidth: 0,
  borderStyle: GLOBAL.POSITION.SOLID,
  outlineOffset: selected ? 2 : GLOBAL.POSITION._2PX,
  display: GLOBAL.POSITION.FLEX,
  justifyContent: GLOBAL.POSITION.CENTER,
  alignItems: GLOBAL.POSITION.CENTER,
  background: selected
      ? `rgba( ${hexToRgb(localStorage.getItem('colorPrimary')).join(', ')} ,0.2)`
      : GLOBAL.COLOR.WHITE,
  '&:hover': {
    cursor: GLOBAL.POSITION.POINTER,
  },
}));

export const MuiGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'width'
})(({ theme, width }) => ({
  padding: 10,
  width: width + 100,
  display: GLOBAL.POSITION.FLEX,
  justifyContent: GLOBAL.POSITION.CENTER,
  alignItems: GLOBAL.POSITION.CENTER,
  border: GLOBAL.POSITION.OUTLINESELECTORGRADIENT,
  borderRadius: 4,
}));

export const MuiDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const checkIconStyle = {
  position: 'absolute',
  transform: 'translate(-40%, 5%)',
  color: '#fff',
  borderRadius: '50%',
};
