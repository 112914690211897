import { useQueryClient, useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';

const SyncDataset = (data) => {
  axios.put(`${apiPaths.datasetSync}${data.name}`, {}, status200).then((resp) => resp.data);
};

const useSyncDataset = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => SyncDataset(values), {
    onSuccess: () => {
      queryClient.resetQueries(['data_set']);
    },
    onError: (error) => {
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['data_set']);
    },
  });
};

export default useSyncDataset;
