import * as React from 'react';
import {useSelector, useDispatch} from "react-redux";
import WrapperWidgetUI from "../WrapperWidgetUI";
import * as _ from "lodash";
import {
  Divider,
  FormControl,
  Grid, IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Slider
} from "@mui/material";
import {setParametersCatalog, setWidgets} from "../../../../store/appSlice";
import {useTheme} from "../../../providers/CustomThemeProvider";
import {useState} from "react";
import {useTranslation} from "../../../providers/TranslationProvider";
import {
  MuiGridItem,
  MuiLabelTypography,
  MuiRefreshIcon, switchDisabledStyle, switchEnabledStyle,
  toggleButtonGroupStyle
} from '../styles/parametersWidget';
import {GLOBAL} from "../../../../utils/constants";

const Parameter = ({parameter, updateParameter}) => {

  const {theme} = useTheme();

  return (
    <>
      <Grid container direction='row' alignItems='center'
            style={{ width: '100%', padding: 16}} sx={{columnGap: '10px'}}>
        <MuiGridItem item xs={4}>
          <MuiLabelTypography component='div'>{parameter.label}</MuiLabelTypography>
        </MuiGridItem>
        {parameter.type === 'Numeric' ? (
          <>
            <MuiGridItem item xs={4}>
              <Slider
                value={parameter.value}
                marks={true}
                min={parameter.minValue}
                max={parameter.maxValue}
                step={(parameter.maxValue - parameter.minValue) / 10}
                onChange={(event, value) => {
                  const updatedParameter = {...parameter};
                  updatedParameter.value = value;
                  updateParameter(updatedParameter);
                }}
                style={{width: '100%', color: theme.palette.primary.main}}
              />
            </MuiGridItem>
            <MuiGridItem item xs={3}>
              <FormControl style={{width: '100%'}}>
                <TextField
                  type='number'
                  variant='outlined'
                  value={parameter.value}
                  inputProps={{
                    min: parameter.minValue,
                    max: parameter.maxValue,
                    step: parameter.type === "Boolean" ? 1 : ((parameter.maxValue - parameter.minValue) / 10),
                    style: {padding: '10px 2px 10px 5px'}
                  }}
                  sx={{width: 'auto' , height: '34px' , borderRadius: '8px', mb: '10px'}}
                  onChange={(event) => {
                    const updatedParameter = {...parameter}
                    updatedParameter.value = parseFloat(event.target.value);
                    updateParameter(updatedParameter);
                  }}
                />
              </FormControl>
            </MuiGridItem>
          </>
        ) : (
          <MuiGridItem item>
            <ToggleButtonGroup
              value={Math.round(parameter.value)}
              exclusive
              onChange={(event, value) => {
                const updatedParameter = {...parameter};
                updatedParameter.value = value;
                updateParameter(updatedParameter);
              }}
              style={{
                ...toggleButtonGroupStyle,
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            >
              <ToggleButton value={1} style={{borderRadius: '16px 0 0 16px',...(parameter.value === 1 ? switchEnabledStyle : switchDisabledStyle)}}>
                Yes
              </ToggleButton>
              <ToggleButton value={0}
                            style={{borderRadius: '0 16px 16px 0', ...(parameter.value === 0 ? switchEnabledStyle : switchDisabledStyle)}}>No</ToggleButton>
            </ToggleButtonGroup>
          </MuiGridItem>
        )}
      </Grid>
    </>
  )
}
const ParametersWidget = ({
                            id,
                            type,
                            title,
                            wrapperProps,
                          }) => {
  const {theme} = useTheme();
  const { t } = useTranslation();
  const parameters = useSelector((state) => state.app.parametersCatalog);
  const [localParameters, setLocalParameters] = useState(parameters);
  const dispatch = useDispatch();
  const widgets = useSelector((state) => state.app.widgets);
  const [loading, setLoading] = useState(false);
  const disabled = _.isEqual(localParameters, parameters)
  const layerLoading = useSelector((state) => state.app.layerLoadingState);
  const updateParameter = (parameter) => {
    const updatedParameterList = [...localParameters];
    const index = updatedParameterList.findIndex(p => p.propName === parameter.propName);
    updatedParameterList[index] = parameter;
    setLocalParameters(updatedParameterList);
  }

  const submitParameterSimulation=()=>{
    if (!_.isEqual(localParameters, parameters)) {
      const updatedParameterList = [...localParameters];
      const updatedWidgets = widgets.map(w => {
        if (w.type === 'parameters' && w.id === id) {
          return {...w, parametersCatalog: updatedParameterList}
        }
        return w;
      })
      setLoading(true)
      dispatch(setWidgets(updatedWidgets));
      dispatch(setParametersCatalog(updatedParameterList));
    }
  }
  React.useEffect(()=> {
    const allLoaded= Object.values(layerLoading).every(obj => !obj.loadingState);
    if (allLoaded) setLoading(false);

  },[layerLoading])
  return (
    <WrapperWidgetUI title={title} widgetId={id} isLoading={false}
                     wrapperProps={wrapperProps}>
      <Grid container direction='column' style={{width: '100%', border: '1px solid #E0E0E0', borderRadius: '12px'}} sx={{margin: 0}} spacing={3} >
        {
          localParameters?.map((p, i) => {
            return (
              <React.Fragment key={ p.propName.toLowerCase() }>
                <Parameter parameter={p} updateParameter={updateParameter}
                           key={`parameter-key-${p.propName}`}/>
                {(i < localParameters.length - 1) && (<Divider sx={{margin: '0px 16px 0px 16px'}}/>)}
              </React.Fragment>
            )
          })
        }
        <Grid container item xs={12} justifyContent={'center'} style={{padding: '0 0 16px 0'}}>
          <IconButton
            onClick={()=>submitParameterSimulation()}
            style={{border:`1px solid ${theme.palette.primary.main}`,
              borderRadius:'20px',
              fontFamily: 'Montserrat',
              fontSize: '18px',
              fontWeight: 600,
              color: disabled ? 'gray': `${theme.palette.primary.main}`,
              height: '38px',
            }}
            disabled={disabled}
          >
            <MuiRefreshIcon loading={loading}/>
            {loading ? t('updating') : t('update_simulation')}
          </IconButton>
        </Grid>
      </Grid>

    </WrapperWidgetUI>
  )
}

export default ParametersWidget;
