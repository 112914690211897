import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { setViewState } from 'lib/react-geo-tool/packages/react-redux/src/';
import { GLOBAL } from 'utils/constants';
import {
  MuiDivContainer,
  MuiDivider,
  MuiIconButton, MuiTypography
} from './styles/zoomControl';
import { lighten } from '@mui/material/styles';
import { useTheme } from '../providers/CustomThemeProvider';

function ZoomControl() {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const zoomLevel = useSelector((state) => Math.floor(state.carto.viewState.zoom));

  const increaseZoom = React.useCallback(() => {
    dispatch(setViewState({ zoom: zoomLevel + 1 }));
  }, [dispatch, zoomLevel]);

  const decreaseZoom = React.useCallback(() => {
    dispatch(setViewState({ zoom: zoomLevel - 1 }));
  }, [dispatch, zoomLevel]);

  return (
    <MuiDivContainer>
      <MuiIconButton onClick={decreaseZoom} aria-label={GLOBAL.ARIALABEL.INCREASEZOOM}>
        <RemoveOutlinedIcon style={{width: 14, height: 14, fill: lighten(theme.palette.primary.main, 0.1)}} />
      </MuiIconButton>
      <MuiDivider></MuiDivider>
      <MuiTypography variant='body1' color={GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[900]}>
        {zoomLevel}
      </MuiTypography>
      <MuiDivider></MuiDivider>
      <MuiIconButton onClick={increaseZoom} aria-label={GLOBAL.ARIALABEL.INCREASEZOOM}>
        <AddOutlinedIcon style={{width: 14, height: 14, fill: lighten(theme.palette.primary.main, 0.1)}}/>
      </MuiIconButton>
    </MuiDivContainer>
  );
}

export default ZoomControl;
