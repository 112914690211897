import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'components/providers/TranslationProvider';
import { TextField } from 'components/common/ReactHooksFormFields';
import resetPassword from 'api/resetPassword';
import changePassword from 'api/changePassword';
import Toast from 'components/common/Toast';

import {
  loginField,
  MuiButtonSubmit,
  MuiDivPaper,
  MuiIconButton
} from './styles/login';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('password')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(^.{7,20}).+$/, 'strong_password'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'password_match'),
});

const ResetPassword = () => {
  const { t } = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isPending, setIsPending] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const location = useLocation();
  const navigate = useNavigate();


  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleError = () => {
    setError(t('password_reset_token_error'));
    setOpenToast(true);
    setIsPending(false);
  };

  const handleSuccess = () => {
    navigate('/login');
    setIsPending(false);
  };

  const onSubmit = (data) => {
    setIsPending(true);

    if (token) {
      const item = {
        password: data.password,
        token,
      };
      resetPassword(item, handleError, handleSuccess, token);
    } else {
        const item = {
          user: location.state.user.email,
          password: data.password,
          accessToken: location.state.accessToken,
        };
        changePassword(item, handleError, handleSuccess);
    }
  };

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query) {
      setToken(query.get('token'));
    } else {
      setToken('');
    }
  }, []);

  return (
    <MuiDivPaper>
      <div style={{width: '312px'}}>
      <Typography >
        {t('reset_password')}
      </Typography>
      <Toast message={error} handleClose={handleCloseToast} severity='error' open={openToast} />
      <FormProvider {...methods}>
        <form style={{width: '312px'}} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
                <TextField
                  autoFocus
                  variant='outlined'
                  fullWidth
                  style={loginField}
                  label={t('password')}
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='current-password'
                  error={t(errors.password?.message)}
                />
                <MuiIconButton onClick={handleClickShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </MuiIconButton>

            </Grid>
            <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  style={loginField}
                  name='passwordConfirmation'
                  type={showPassword ? 'text' : 'password'}
                  label={t('confirm_password')}
                  autoComplete='current-password'
                  error={t(errors.passwordConfirmation?.message)}
                />
                <MuiIconButton onClick={handleClickShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </MuiIconButton>

            </Grid>
          </Grid>


            <Grid item xs={12} >
              <MuiButtonSubmit
                loading={isPending}
                isValid={isValid}
                fullWidth
                type='submit'
                disabled={!isValid || isPending}
              >
                {isPending ? t('saving...') : t('save_changes')}
              </MuiButtonSubmit>
            </Grid>

        </form>
      </FormProvider>
      </div>
    </MuiDivPaper>
  );
};

export default ResetPassword;
