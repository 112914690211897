import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { useLocation, Outlet } from 'react-router-dom';
import { useAuth } from 'components/providers/AuthProvider';
import logo from 'assets/img/logo.0c7a85ba.png';
import {
  MuiContainerNavBar, MuiGridContainer,
  MuiGridImgContainer,
  MuiImg
} from "./styles/workspace";

const Workspace = () => {
  const location = useLocation();
  const { user, isLoading } = useAuth();

  const isSpecificationView = location.pathname.split('/').includes('specification')

  return (
    <MuiContainerNavBar>
      <Grid container>
        {!isSpecificationView && (
          <MuiGridImgContainer item xs={3}>
            <Typography variant='h6' sx={{textAlign: 'center', fontWeight: 'bold'}}> {user?.org.name}/{user?.name}</Typography>
            {isLoading ? (
              <>
                <MuiImg src={logo} alt={'logo'}/>
              </>
            ) : (
              <>
                <MuiImg src={user?.org.attrs.profile_img_url} alt={'logo'}/>
              </>
            )}
          </MuiGridImgContainer>
        )}
        <MuiGridContainer item xs={isSpecificationView ? 12 : 9}>
          <Outlet />
        </MuiGridContainer>
      </Grid>
    </MuiContainerNavBar>
  );
};
export default Workspace;
