import { IconButton, styled } from '@mui/material';
import { DeleteSweep } from '@mui/icons-material';
import { GLOBAL } from '../../../../../../../utils/constants';

export const MuiDivRoot = styled('div')(({ theme }) => ({
  marginRight: '0px',
  top: -6,
  right: '0px',
  transform: 'scale(1)',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  borderRadius: theme.spacing(0.5),
  position: 'absolute',
  zIndex: '100',
  marginTop: '25px',
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '10px 16px 10px 16px',
  '&:hover': {
    backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[100], // Cambia el color de fondo a rojo al pasar el mouse
  },
  top: -9,
  position: 'absolute',
  zIndex: 100,
  marginLeft: 6,
  boxShadow: '0px 4px 8px 0px #00000026',
}));

export const MuiDeleteSweepIcon = styled(DeleteSweep)(({ theme }) => ({
  color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900],
  width: 24,
  height: 24,
}));
