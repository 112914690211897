import { Avatar, styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../../utils/constants';

export const MuiAvatar = styled(Avatar)(({ theme }) => ({
  cursor: 'pointer',
  width: theme.spacing(4.5),
  height: theme.spacing(4.5),
  marginLeft: theme.spacing(1),
}));

export const MuiUserInfo = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  margin: 8
}));

export const MuiUserText = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: GLOBAL.COLOR.WHITE,
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:'16px',
}));

export const MuiPermissionText = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: GLOBAL.COLOR.WHITE,
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:'12px',
}));

export const MuiCommonText = styled(Typography)(({ theme }) => ({
  marginLeft:5,
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:'16px',
  color:'#000000'
}));
