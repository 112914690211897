import { useMutation, useQueryClient } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const createDataset = (data, performActionsAfterUpload, setErrors) => {
  return axios
    .post(data.interval ? apiPaths.upload_dataset_sync : apiPaths.upload_dataset, data, status200)
    .then((resp) => {
      performActionsAfterUpload();
      return resp.data;
    })
    .catch((e) => {
      setErrors(e.data.error);
    });
};

const useCreateDataset = (performActionsAfterUpload, setErrors) => {
  const queryClient = useQueryClient();

  return useMutation((values) => createDataset(values, performActionsAfterUpload, setErrors), {
    onSuccess: (success) => {
      queryClient.resetQueries(['data_set']);
      return success;
    },
    onError: (error) => error,
    onSettled: () => {
      queryClient.invalidateQueries(['data_set']);
    },
  });
};
export default useCreateDataset;
