import {
  Checkbox,
  Grid, Link,
  Skeleton,
  styled, TextField,
  Typography
} from '@mui/material';
import { GLOBAL } from '../../../../utils/constants';
import { generateDynamicShades } from '../../../../utils/colors';
import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { lighten } from '@mui/material/styles';

const SearchIcon = () => (
  <SvgIcon>
    <path
      d='M11,4 C14.8659932,4 18,7.13400675 18,11 C18,12.7003211 17.3937669,14.2590489 16.3856562,15.4718279 L19.4748737,18.5606602 L18.0606602,19.9748737 L14.8998887,16.8138615 C13.7854137,17.5629194 12.4437497,18 11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 Z M11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 Z'
      id='-↳Color'
      fill='inherit'
    ></path>
  </SvgIcon>
);

export const MuiGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'filterable' && prop !== 'selectedCategories' && prop !== 'data'
})(({ theme,filterable,selectedCategories, data }) => ({
  cursor: filterable ? 'pointer' : 'default',
  flexWrap: filterable ? 'nowrap' : 'wrap',

  color: (selectedCategories.length > 0 && selectedCategories.indexOf(data.name) === -1)
    ? theme.palette.text.disabled
    : theme.palette.text.primary,

  '& .progressbar': {
    backgroundColor: (selectedCategories.length > 0 && selectedCategories.indexOf(data.name) === -1)
      ? GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[50]
      : theme.palette.background.default,

    '& div': {
      backgroundColor: (selectedCategories.length > 0 && selectedCategories.indexOf(data.name) === -1)
        ? GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400]
        : theme.palette.background.paper,
    },
  },

  '&:hover .progressbar div': {
    backgroundColor: filterable
      ? theme.palette.secondary.dark
      : undefined,
  },

  ...(data.name === '__rest__' && {
    cursor: 'default',

    '& .progressbar div': {
      backgroundColor: theme.palette.text.disabled,
    },
  }),
}));

export const MuiProgressBar = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'unselected' && prop !== 'color'
})(({ theme,unselected, color }) => {
  const colorEnabled = color ? color : theme.palette.primary.main

  return {
  height: theme.spacing(0.5),
  width: '100%',
  margin: theme.spacing(0.5, 0, 1, 0),
  borderRadius: theme.spacing(0.5),
  backgroundColor: unselected ? `${lighten( '#BDBDBD', 0.6)}`
                              : `${lighten( colorEnabled, 0.6)}`,

  '& div': {
    width: 0,
    height: '100%',
    borderRadius: theme.spacing(0.5),
    backgroundColor: generateDynamicShades( theme.palette.primary.main )[500],
    transition: `background-color ${theme.transitions.easing.sharp} ${theme.transitions.duration.shortest}ms,
                   width ${theme.transitions.easing.sharp} ${theme.transitions.duration.complex}ms`
  }
}});

export const MuiCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    ...theme.typography.body2,
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[600],
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '&.Mui-checked':{
    color: `${theme.palette.primary.main} !important`,
  },
  '&.MuiCheckbox-colorSecondary': {
    '&.Mui-checked': {
      color: generateDynamicShades( theme.palette.primary.main )[500]
    },
  },
}));

export const MuiCategoryLabel = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
  width: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}));

export const MuiCategoriesWrapper = styled(Grid)(({ theme }) => ({
  maxHeight: theme.spacing(40),
  overflow: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(0, 1, 1, 0)
}));

export const MuiTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(-0.5),
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: 22,
    height: 42,
    paddingRight: 0,
  },
  '& .MuiInputBase-input': {
    fontSize: 16,
    fontWeight: GLOBAL.FONT_WEIGHTS.REGULAR,
    letterSpacing: 0.5,
    color: generateDynamicShades( theme.palette.primary.main )[900],
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[200]}`
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-adornedEnd': {
    paddingRight: 0,
  }
}));

export const MuiLink = styled(Link)(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: 600,
  cursor: 'pointer',
  color: theme.palette.primary.main,
  '& + hr': {
    margin: theme.spacing(0, 1)
  }
}));

export const MuiOptionsSelectedBar = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  paddingRight: theme.spacing(1),

  '& .MuiTypography-caption': {
    color: theme.palette.text.secondary
  },

  '& .MuiButton-label': {
    ...theme.typography.caption
  }
}));

export const MuiSkeleton = styled(Skeleton)(({ theme }) => ({
  height: theme.spacing(1),
  width: '100%',
  margin: theme.spacing(0.5, 0, 1, 0)
}));

export const MuiElement = styled(Grid)(({ theme }) => ({
  '&$unselected': {
    color: theme.palette.text.disabled,

    '& $progressbar': {
      backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[50]
    },

    '& $progressbar div': {
      backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400]
    }
  },

  '&$rest $progressbar div': {
    backgroundColor: theme.palette.text.disabled
  }
}));

export const MuiDivRoot = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[600],
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const MuiDivAdornment = styled('div')(({ theme }) => ({
  backgroundColor: generateDynamicShades( theme.palette.primary.main )[600],
  padding: 8.25,
  borderRadius: '50%',
  color: GLOBAL.COLOR.WHITE,
  display: 'flex',
  width: 32,
  height: 32,
  justifyContent: 'center',
  alignItems: 'center'
}));

export const MuiSearchIcon = styled(SearchIcon)(({ theme }) => ({
  '& .MuiSvgIcon-root':{
    height: '17.49px !important',
    width: '17.49px !important'
  }
}));
