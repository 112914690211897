import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'api/axios/axiosInstance';
import apiPaths from 'api/apiPaths';
import { status200 } from 'api/status.utils';
import usePublicScheme from 'api/hooks/usePublicScheme';
import { setRefreshOrgInfoPublicEmbeddedMap } from 'store/appSlice';

import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import { giveMeId } from 'utils/supportComponents';

const EmbeddedMap = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useAuth();
  const id = giveMeId(location.pathname, user);
  const [mapUrl, setMapUrl] = React.useState(null);
  const hasPublic = location.pathname.split('/')[1] === 'public';
  const schemeId = hasPublic ? location.pathname.split('/')[4] : location.pathname.split('/')[6];

  const onError = React.useCallback(() => navigate('/404'), [navigate]);

  const { data: publicScheme, isSuccess: isSuccessPublicScheme } = usePublicScheme({
    user_id: id,
    scheme_id: schemeId,
    enabled: hasPublic,
    onError,
  });

  if (!hasPublic)
    axios.post(apiPaths.scenario, { user_id: id, scheme_id: schemeId }, status200).then((resp) => {
      if (resp.data.scheme) {
        setMapUrl(resp.data.scheme.map);
      }
    });

  React.useEffect(() => {
    if (isSuccessPublicScheme) {
      setMapUrl(publicScheme.scheme.map);
      const rnd = Math.random();
      dispatch(setRefreshOrgInfoPublicEmbeddedMap(rnd));
    }
  }, [isSuccessPublicScheme, publicScheme?.scheme.map, dispatch]);

  return (
    <div style={{height: '94vh', position: 'relative', left: '-60px',}}>
      <iframe title={t('map_view')} src={mapUrl} height='100%' width='104%' />
    </div>
  );
};

export default EmbeddedMap;
