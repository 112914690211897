import {Container, Grid, styled, Tabs, Toolbar} from "@mui/material";

export const MuiContainerNavBar = styled(Container)(({ theme }) => ({
  boxShadow: 'none',
  overflow: 'hidden',
  marginTop: 47,
  marginBottom: 24,
  maxWidth: '1400px !important',
}));

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: '40% !important',
  backgroundColor: 'white',
}));

export const MuiGridBox = styled(Grid)(({ theme }) => ({
  marginTop: '5px !important',
}));

export const MuiTabs = styled(Tabs)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  '& .Mui-selected': {
    fontSize: 15,
    fontWeight: 700,
    color: theme.palette.primary.main,
    transition: 'font-size 10ms, font-weight 10ms',
  },
}));

export const MuiGridImgContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));

export const MuiGridPaper = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
}));

export const MuiImg  = styled("img")(({ theme }) => ({
  width: 248,
  backgroundColor: 'white',
  height: 248,
  borderRadius:'19px',
  border:'3px solid',
  borderColor: theme.palette.primary.main,
  objectFit: 'scale-down',
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  paddingTop: 20,
}));
