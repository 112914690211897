import { _executeModel } from 'lib/react-geo-tool/packages/react-api/src/';
import { wrapModelCall } from './utils';

export function getPolygon(props) {
  return wrapModelCall(props, null, fromRemote);
}

async function fromRemote(props) {
    const { source, abortController, ...params } = props;
  
    return await _executeModel({
      model: 'polygon',
      source,
      params,
      opts: { abortController }
    }).then((res) => res);
  }
