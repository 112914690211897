import zIndex from '@material-ui/core/styles/zIndex';
import { BorderColor } from '@mui/icons-material';
import { GLOBAL } from 'utils/constants';
import { Box, Grid, IconButton, styled } from '@mui/material';

export const MuiGridRoot = styled(Grid)(({ theme }) => ({
  height: 'calc(100vh - 64px)',
}));

export const MuiGridControlsBottom = styled(Grid)(({ theme }) => ({
  position: 'relative',
  bottom: theme.spacing(process.env.NODE_ENV === 'development' ? 12 :7),
  left: '15px',
  width: '50%'
}));

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  width: '434px',
  position: 'absolute',
  top: '0%',
  right: 0,
}));

export const MuiBoxDrawer = styled(Box)(({ theme }) => ({
  justifyItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 64px)',
  borderRadius: '20px 0px 0px 20px',
  border: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
  backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[50]
}));

export const MuiGridWrapper = styled(Grid)(({ theme }) => ({
  position: 'relative',
  flex: 1,
  overflow: 'hidden',

  // Fix Mapbox attribution button not clickable
  '& #deckgl-wrapper': {
    '& #deckgl-overlay': {
      zIndex: 1,
    },
    '& #view-default-view > div': {
      zIndex: 'auto !important',
    },
  },
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 48,
  backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[50],
  position: 'absolute',
  borderRadius: 12,
  display: 'flex',
  margin: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  top: '50%',
  zIndex: 1,
  '&:hover': {
    backgroundColor: '#ffffff',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50%',
    height: '50%',
    borderTop: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
    borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
    borderTopLeftRadius: 12,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '50%',
    height: '50%',
    borderBottom: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
    borderLeft: `1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[300]}`,
    borderBottomLeftRadius: 12,
  },
}));
