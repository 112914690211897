import { Popover, styled } from '@mui/material';

export const MuiPopover = styled(Popover)(({ theme }) => ({
  width: 200,
  minHeight: 120,
  '& .MuiPopover-paper': { display: 'inline-table' },
}));

export const MuiDiv = styled('div',{
  shouldForwardProp: (prop) => prop !== 'isValid' && prop !== 'isWidgetsPanelVisible'
})(({ theme,isWidgetsPanelVisible }) => ({
  backgroundColor: theme.palette.grey[50],
  position: 'relative',
  height: `calc(100% - ${theme.spacing(1)}px)`,
  width: isWidgetsPanelVisible ? `calc(100vw - 414px)` : '100vw',
  '@media (max-width: 600px)': {
    height: 'calc(100% - 95px)',
  },

  '@media (min-width: 600px)': {
    height: '100%',
    '& .mapboxgl-map, & #deckgl-overlay, & > div': {
      borderRadius: '4px',
    },
  },
}));
