import * as React from 'react';
import { Grid, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { giveMeId } from 'utils/supportComponents';
import { useAuth } from 'components/providers/AuthProvider';
import MapCustom from 'components/common/Map';
import { useLayers } from 'components/providers/LayersProvider';
import ZoomControl from 'components/common/ZoomControl';
import Toast from 'components/common/Toast';
import useChangeMapState from 'api/hooks/useChangeMapState';
import {
  cleanSchemeState,
  setListFilterPolygonData,
  setListPolygonWidgetForDelete,
  setMessageToast,
  setOpenToast,
  setSelectWidgetId,
  setSendMapStateToServer,
  setSeverityToast,
  setIsWidgetsPanelVisible,
} from 'store/appSlice';

import WidgetsTabs from './WidgetsTabs';
import {
  MuiBoxContainer,
  MuiBoxDrawer,
  MuiGridControlsBottom,
  MuiGridWrapper,
  MuiIconButton
} from './styles/descriptiveMap';
import Layer from './Layer';
import BaseMapSelector from './BaseMapSelector';
import Legend from './Legend';
import SearchPlace from "./SearchPlace";
import FeatureSelectionLayer from '../../../../../common/widgets/selection/FeatureSelectionLayer';
import { removeSpatialFilter } from 'lib/react-geo-tool/packages/react-redux/src';
import { GlobalStyles } from "@mui/system";
import {messageSeverity} from 'utils/constants';
import AppliedFiltersOnMap from './AppliedFiltersOnMap';
import Loader from "../../../common/Loader";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import ReferencesPoint from './ReferencesPoint';
import {
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
} from '@mui/icons-material';
import WidgetModal from './widget-modal/WidgetModal';

const DescriptiveMap = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setIsLoading] = React.useState(true);
  const polygonDataLoading = useSelector((state) => state.app.polygonDataLoading)
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const id = giveMeId(location.pathname, user);
  const { getLayers, removeLayers, layers, polygonLayers, widgets, layerLoadingState, setSchemeFetched, sources } =
    useLayers();

  const basemap = useSelector((state) => state.carto.basemap);
  const sendMapStateToServer = useSelector((state) => state.app.sendMapStateToServer);
  const openToast= useSelector((state) => state.app.openToast);
  const messageToast = useSelector((state) => state.app.messageToast);
  const severityToast = useSelector((state) => state.app.severityToast);
  const mapId = (location.pathname.split('/'))[6];
  const [isLoaded, setIsLoaded] = React.useState(false);
  const viewState = useSelector((state) => state.carto.viewState);
  const isPermitSaveMapState = user?.type !== 'level_0' ? (location.pathname.split('/'))[1] !== 'public' ? true : false : false;
  const { t } = useTranslation();
  const mutate = useChangeMapState(setOpen);
  const isWidgetsPanelVisible = useSelector((state) => state.app.isWidgetsPanelVisible);

  const getFilters = ((w) => {
    const filter = sources[w.layer]?.filters;
    const field = w.params.calculator ? w.params.alias : w.params.field;
    if (!filter?.[field]) return [];

    if (filter?.[field].between) {
      return filter?.[field].between.owner === w.id ? filter : [];
    } else if (filter?.[field].in) {
      return filter?.[field].in.owner === w.id ? filter : [];
    }
  })

  const getViewState = (() => {
    return viewState
  })

  React.useEffect(() => {
    if (layers?.length && basemap && sendMapStateToServer) {
      const mapState = {
        user_id: id,
        baseMap: basemap,
        owner_id: user.id,
        view: getViewState(),
        layers: layers?.map((l) => ({ layerId: l.id, visible: l.visible || false })) || [],
        widgets: widgets.length
          ? widgets.map((w) => ({
              widgetId: w.id,
              name: w.name,
              type: w.type,
              field: w.params.calculator ? w.params.alias : w.params.field,
              description: w.description || 'not_description',
              active_state: w.visible,
              folded_state: w.folded_state || false,
              active_tab: w.active_tab,
              visible: w.visible,
              tags: w.tags,
              filters: getFilters(w),
              parametersCatalog:w.parametersCatalog
            }))
          : [],
      };
      const newMapState = {
        user_id: id,
        scheme_id: location.pathname.split('/')[6],
        mapState,
      };
      setSchemeFetched((prevState) => ({
        ...prevState,
        scheme: { ...prevState.scheme, map_state: [mapState] },
      }));
      if (isPermitSaveMapState) mutate.mutateAsync(newMapState);
    }
  }, [layers, widgets, basemap, viewState]);

  React.useEffect(() => {
    if (mutate.isError) setOpen(true);
  }, [mutate.status]);

  React.useEffect(() => {
    if (!sendMapStateToServer) dispatch(setSendMapStateToServer(true))
  }, [viewState]);

  React.useEffect(() => {
    dispatch(removeSpatialFilter());
    dispatch(setListFilterPolygonData({}));
    dispatch(setSelectWidgetId(null));
    dispatch(setListPolygonWidgetForDelete({}));
  }, [mapId]);

  React.useEffect(() => {
    return () => {
      removeLayers();
      dispatch(cleanSchemeState());
      dispatch(removeSpatialFilter());
      dispatch(setSendMapStateToServer(false));
    };
  }, []);

  React.useEffect(() => {
    const isAnyLayerLoading = Object.keys(layerLoadingState).some(key => {
      const layer = layerLoadingState[key];
      if (!layer){
        return false
      }
      const layerDetails = layers.find(l => l.id === key);
      return layer.loadingState && layerDetails?.visible;
    });
    const loadedLayersCount = Object.keys(layerLoadingState).reduce((count, key) => {
      const layer = layerLoadingState[key];
      if (layer && !layer.loadingState) {
        return count + 1;
      }
      return count;
    }, 0);
    setIsLoading(isAnyLayerLoading || loadedLayersCount < layers.length);
  }, [layerLoadingState]);

  const closeToast = () =>{
    dispatch(setOpenToast(false));
    dispatch(setMessageToast(''));
    setSeverityToast(messageSeverity.ERROR);
  }

  React.useEffect(() => {
    if (document.querySelector('#gmaps') && !isLoaded && window.google){
      setIsLoaded(true);
    }
  },[window?.google]);

  const togglePanel = () => {
    dispatch( setIsWidgetsPanelVisible( !isWidgetsPanelVisible ));
  };

  return (
    <Grid container style={{ height: 'calc(100vh - 64px)' }}>
      <GlobalStyles
        styles={{
          '.MuiDataGrid-root .Mui-checked': {
            color: `${localStorage.getItem('colorPrimary')} !important`,
          },
        }}
      />
      <Toast
        severity= {severityToast}
        message={messageToast}
        open={openToast}
        handleClose={() => closeToast()}
        horizontal='right'
        vertical='top'
      />

      <Loader
        loading={loading}
        polygonDataLoading={polygonDataLoading}
        headerMessage={''}
        titleMessage={t('generating_map')}
        notificationMessage={''}
        loaderType={'infinite'}
        progress={0}
      />

      <MuiGridWrapper item xs={ 12 }>
        {isLoaded && (
          <Box>
            <SearchPlace basemap={basemap} />
            <AppliedFiltersOnMap />
            <ReferencesPoint />
          </Box>
        )}

        <MapCustom layers={[...getLayers, FeatureSelectionLayer()]}/>
        <MuiGridControlsBottom container spacing={1}>
          <Grid item >
            <Legend />
          </Grid>
          <Grid item >
            <BaseMapSelector />
          </Grid>
          <Grid item >
            <ZoomControl />
          </Grid>
        </MuiGridControlsBottom>
        <MuiIconButton
          onClick={ togglePanel }
          style={{ right: isWidgetsPanelVisible ? '420px' : '-10px'  }}
        >
          { isWidgetsPanelVisible ? <ArrowRightIcon /> : <ArrowLeftIcon /> }
        </MuiIconButton>
        <MuiBoxContainer style={{ display: isWidgetsPanelVisible ? 'block' : 'none' }}>
          <MuiBoxDrawer>
            <WidgetsTabs />
            {layers?.map((layer) => (
            <React.Fragment key={layer.id}>
              <Layer layer={layer} />
            </React.Fragment>
            ))}
            {polygonLayers?.map((layer) => (
              <React.Fragment key={layer.id}>
                <Layer layer={layer} />
              </React.Fragment>
            ))}
          </MuiBoxDrawer>
        </MuiBoxContainer>
      </MuiGridWrapper>
      <WidgetModal />
    </Grid>
  );
};

export default DescriptiveMap;
