import { Button, Grid, Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { MuiContainer, MuiGrid } from './styles/error';

function NotFound() {

  return (
    <MuiContainer>
      <MuiGrid
        container
        direction='column'
        spacing={2}
        justifyContent='center'
        alignContent='space-between'
      >
        <Grid item>
          <Typography variant='h5'>Error 404</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h3'>
            Whoops!
            <br />
            You’re lost at sea
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            The map you’re looking for doesn’t exist.
            <br />
            Use Location Intelligence to find your way home.
          </Typography>
        </Grid>
        <Grid item style={{marginTop: '24px'}}>
          <Link to='/' component={NavLink} underline='none'>
            <Button variant='contained' color='primary' size='large'>
              Take me home
            </Button>
          </Link>
        </Grid>
      </MuiGrid>
    </MuiContainer>
  );
}

export default NotFound;
