import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const MuiHeaderMessage = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontSize: 35,
  fontFamily: 'Raleway',
  textAlign: 'center'
}));

export const MuiTitleMessage = styled(Typography)(({ theme }) => ({
  color:'#ffffff',
  fontSize: 20,
  fontWeight:'bold' ,
  fontFamily: 'Montserrat',
  textAlign: 'center'
}));

export const MuiNotificationMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color:'#ffffff',
  fontSize: 16,
}));

export const MuiProgress = styled('span')(({ theme }) => ({
  fontWeight:'bold',
  color: localStorage.getItem('colorPrimary')
}));
