/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { IconButton } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from 'components/common/DeleteOutlinedIcon';

import { usePermissions } from 'components/hooks/usePermissions';
import {
  iconStyle,
  linkStyle,
  MuiCard,
  MuiCardActionArea,
  MuiCardMedia, MuiDescription, MuiIconButton, MuiName, MuiTypographyName
} from './styles/card';

const CardOrganization = ({ organization }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();

  return (
      <MuiCard elevation={0}>
        <MuiCardActionArea>
            <div style={{margin: 16}}>
            <Link to={`/orgs/${organization._id}/maps`} style={linkStyle} data-cy="organization-link">
                <MuiCardMedia
                  image={organization.attrs.profile_img_url}
                  title={organization.name}
                />
              </Link>
              <MuiDescription>
                <MuiName>
                 <MuiTypographyName>
                  {organization.name}
                </MuiTypographyName>
                </MuiName>
                <div style={{display: 'flex'}}>
                {hasPermissionFor('update_org') && (
                    <IconButton
                      onClick={( e ) => {
                        e.stopPropagation();
                        navigate(`${location.pathname}?edit-organization=${organization._id}`, {
                          replace: true,
                          state: organization,
                        })
                      }}
                      color='primary'
                    >
                      <EditIcon style={iconStyle}/>
                    </IconButton>
                )}
                {hasPermissionFor('delete_org') && (
                    <MuiIconButton
                      onClick={ ( e ) => {
                        e.stopPropagation();
                        navigate(`${location.pathname}?delete=${organization._id}`, {
                          replace: true,
                          state: organization,
                        })
                      }}
                      style={{ color: '#ff1744' }}
                    >
                      <DeleteOutlinedIcon size={17}/>
                    </MuiIconButton>
                )}
                </div>
              </MuiDescription>
            </div>
        </MuiCardActionArea>
      </MuiCard>
  );
};

export default CardOrganization;
