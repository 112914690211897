import { Box } from '@mui/material';
import AppliedFilterDisplay from './AppliedFilterDisplay';
import {
  MuiBoxContainer,
  MuiTypographyHeader
} from './styles/indicatorOfFiltersApplied';

  function IndicatorOfFiltersAppliedOnAlayer( props ) {
      const { header, widgetsFilteringTargetDemmand } = props;
      const formatInformation = ({ widgetName, filter }) => {
        return `${widgetName}${!!filter ? ':' : ''} ${filter || ''}`
      }

      return (
          <Box>
              <MuiTypographyHeader> { header } </MuiTypographyHeader>
              <Box>
                {
                  widgetsFilteringTargetDemmand?.map( widgetsFiltering => (
                    <MuiBoxContainer display="inline-flex">
                      <AppliedFilterDisplay
                        information={ formatInformation({ widgetName: widgetsFiltering.widgetName, filter: widgetsFiltering.filter }) }
                        iconBgColor={ widgetsFiltering.layerColor }
                        widgetType={ widgetsFiltering.type }
                      />
                    </MuiBoxContainer>
                  ))
                }
              </Box>
          </Box>
      );
  }

  export default IndicatorOfFiltersAppliedOnAlayer;
