import {
  Button as MuiButton,
  Grid,
  Button,
} from '@mui/material';
import {
  DirectionsBike,
  DirectionsCar,
  DirectionsWalk,
} from '@mui/icons-material';
import {SliderFieldCustom} from '../../ReactHooksFormFields';
import React from 'react';
import Modal from '../../Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTheme } from '../../../providers/CustomThemeProvider';
import {useTranslation} from 'components/providers/TranslationProvider';
import {
  MuiAccessTime,
  MuiBoxContainer, MuiCommonTypography, MuiLandscape, MuiLocationCity,
  MuiSquareFoot,
  MuiToggleButtonDistance,
  MuiToggleButtonGroup,
  MuiToggleButtonGroupLocation, MuiToggleButtonGroupRouting,
  MuiToggleButtonItem,
  MuiToggleButtonRural,
  MuiToggleButtonTime,
  MuiToggleButtonUrban,
  toggle3,
} from '../styles/isochroneModal';
import { btnCancel } from './DeleteIsochroneModal';

const routingProfileOptions = ['walking', 'cycling' , 'driving' ];

function IsochroneModal(props) {

  const {open, isochrone, action, handleClose, saveOnSubmitIsochrone, id } = props;
  const {t} = useTranslation()
  const { theme } = useTheme();
  const [routingProfile, setRoutingProfile] =
    React.useState( action === 'update'? isochrone.mode :  routingProfileOptions[1]);
  const [region, setRegion] = React.useState('urban');
  const [type, setType] = React.useState(action === 'update'? isochrone.isochroneType : 'distance');
  const schema = yup.object().shape({
    time: yup.number(),
    distanceKm: yup.number(),
    isochroneType: yup.string().default('distance'),
    isRural: yup.boolean()
  });

  const getDefaultValues = () => {

    return action === 'update'
      ? {
        time: isochrone.timeMin,
        routing: isochrone.mode,
        distanceKm: isochrone.distanceKm * 1000,
        isochroneType: isochrone.isochroneType,
        isRural: isochrone.generalize ,

      } : {
        time: 10,
        routing: routingProfileOptions[1],
        distanceKm: 500,
        isochroneType: 'distance',
        isRural: 'false',
        circuchroneTable: ''
      }
  }

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const handleChangeRoutingProfile = (event,routingP) => {
    setRoutingProfile(routingP);
  };


  const onSubmit = handleSubmit(async (data) =>
    action === 'update' ?
      await saveOnSubmitIsochrone({...data, routingProfile, isochrone, action}):
    await saveOnSubmitIsochrone({...data, routingProfile, action})
  );

  const handleType = (event, newType) => {
    if (newType !== null) {
      setType(newType);
      methods.setValue('isochroneType', newType)
    }
  };

  const handleRegion = (event, newRegion) => {
    if (newRegion !== null) {
      setRegion(newRegion);
      methods.setValue('isRural', newRegion === 'rural')
    }
  };

  const formIsochrone = (
    <Grid container style={{width: '312px', height: '100%'}}>
      <Grid container justifyContent='center' direction={'row'}>
        <Grid container>
          <MuiCommonTypography variant='body1'>
            {t('metric_type')}
          </MuiCommonTypography>
          <MuiCommonTypography variant='caption'>
            {t('metric_type_info')}
          </MuiCommonTypography>
        </Grid>
        <Grid style={{marginTop: 16}}>
          <MuiBoxContainer>
            <MuiToggleButtonGroup
              value={type}
              exclusive
              onChange={handleType}
              style={{padding: '2px'}}
            >
              <MuiToggleButtonDistance value="distance">
                <MuiSquareFoot/>{t('distance')}
              </MuiToggleButtonDistance>
              <MuiToggleButtonTime value="time">
                <MuiAccessTime/>{t('time')}
              </MuiToggleButtonTime>
            </MuiToggleButtonGroup>
          </MuiBoxContainer>
        </Grid>
        <Grid container justifyContent='flex-start' direction={'row'}>
          {type === 'time' && (
            <Grid item>
              <div style={{width: 300, marginTop: '20px'}}>
                <SliderFieldCustom
                  name='time'
                  defaultValue={10}
                  step={5}
                  min={5}
                  customMax={720}
                  valueLabelDisplay='auto'
                  custom
                />
              </div>
            </Grid>
          )}
          {type === 'distance' && (
          <Grid item>
            <div style={{width: 300 , marginTop: '20px'}}>
              <SliderFieldCustom
                name='distanceKm'
                defaultValue={200}
                step={1}
                min={200}
                customMax={20000}
                custom
                valueLabelDisplay='auto'
              />
            </div>
          </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <hr style={{ border: '0.5px solid #E0E0E0' , marginTop: 16, marginBottom: 16}}/>
        </Grid>
        <Grid container>
          <Grid item>
            <MuiCommonTypography variant='body1'>
              {t('routing_profile')}
            </MuiCommonTypography>
            <MuiCommonTypography variant='caption'>
              {t('routing_profile_info')}
            </MuiCommonTypography>
          </Grid>
          <Grid style={{marginTop: 16 , width: '100%'}}>
            <MuiToggleButtonGroupRouting
                name='routing'
                value={routingProfile}
                exclusive
                onChange={handleChangeRoutingProfile}
                aria-label="Platform"
            >
                <MuiToggleButtonItem
                  value={routingProfileOptions[0]} style={toggle3}>
                  <DirectionsWalk/>
                </MuiToggleButtonItem>
                <MuiToggleButtonItem
                  value={routingProfileOptions[1]} style={toggle3}>
                  <DirectionsBike />
                </MuiToggleButtonItem>
                <MuiToggleButtonItem
                  value={routingProfileOptions[2]} style={toggle3}>
                  <DirectionsCar />
                </MuiToggleButtonItem>
              </MuiToggleButtonGroupRouting>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr style={{ border: '0.5px solid #E0E0E0' , marginTop: 16, marginBottom: 16}}/>
        </Grid>
          <Grid container justifyContent='flex-start' direction={'row'} style={{marginBottom: '56px'}}>
            <Grid item>
              <MuiCommonTypography variant='body1'>
                {t('is_rural')}
              </MuiCommonTypography>
              <MuiCommonTypography variant='caption'>
                {t('is_rural_info')}
              </MuiCommonTypography>
            </Grid>
            <Grid item style={{marginTop: 16}}>
              <MuiBoxContainer>
                <MuiToggleButtonGroupLocation
                  value={region}
                  exclusive
                  onChange={handleRegion}
                >
                  <MuiToggleButtonUrban value="urban">
                    <MuiLocationCity />{t('urban')}
                  </MuiToggleButtonUrban>
                  <MuiToggleButtonRural value="rural">
                    <MuiLandscape/>{t('rural')}
                  </MuiToggleButtonRural>
                </MuiToggleButtonGroupLocation>
              </MuiBoxContainer>
            </Grid>
          </Grid>
      </Grid>
    </Grid>
  );

  const actions = (
    <>
      <MuiButton
        sx={btnCancel}
        color='primary'
        variant='text'
        onClick={handleClose}>
        {'cancel'}
      </MuiButton>
      <Button
        sx={btnCancel}
        color='primary'
        loading={false}
        disabled={action === 'update' ?  (!isDirty && routingProfile === isochrone.mode) : !isValid }
        style={{marginRight: '15px', borderRadius: '20px', border: '1px solid'}}
        onClick={onSubmit}
      >
        {action}
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={handleClose}
          title={t('add_isochrone')}
          actions={actions}
          widthInPixels={360}
          disableEscapeKeyDown={true}
        >
          {formIsochrone}
        </Modal>
      </form>
    </FormProvider>
  )
}

export default IsochroneModal;
