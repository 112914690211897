import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import WidgetForm from './WidgetForm';

const AddOrUpdateWidget = () => {
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState('create');

  const location = useLocation();
  const navigate = useNavigate();

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  React.useEffect(() => {
    const createQuery = query.get('create');
    const updateQuery = query.get('edit-widget');
    if (createQuery === 'widget') {
      setAction('create');
      setOpen(true);
    }
    if (updateQuery) {
      setAction('update');
      setOpen(true);
    }
  }, [query]);

  const handleClose = (event, reason) => {
    if ( reason === 'backdropClick' || reason === 'escapeKeyDown' ) {
      setOpen( true )
    } else {
      if (action === 'create') query.delete('create');
      if (action === 'update') query.delete('edit-widget');
      query.delete('group-id');
      setOpen(false);
      navigate(`${location.pathname}`, { replace: true });
    }

    
  };

  return (
    <>
      {open && (
        <WidgetForm
          action={action}
          open={open}
          onClose={handleClose}
          widgetId={query.get('edit-widget')}
          groupId={query.get('group-id')}
        />
      )}
    </>
  );
};
export default AddOrUpdateWidget;
