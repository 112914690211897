import { Grid, IconButton, styled } from '@mui/material';
import { GLOBAL } from '../../../../../../../utils/constants';

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  color:GLOBAL.COLOR_THEME.PRIMARY,
  backgroundColor: theme.palette.common.white,
  padding: 10,
  '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
  borderRadius: 12,
  width: '40px',
  height: '40px',
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'left',
  marginLeft: 12,
  marginTop: 12
}));

export const MuiDivControl = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 12,
  border: '2px solid transparent',
  padding: 4,
  '&.selected': {
    borderColor: GLOBAL.COLOR_THEME.PRIMARY,
    width: '175px !important'
  },
  cursor: 'pointer',
}));

export const popoverContainerStyle = {
  padding: 12,
  '& .MuiPopover-paper': {
    display: 'inline-table' ,
    borderRadius: '12px !important' },
};

export const baseMapImageStyle = {
  width: 48,
  height: 48,
  borderRadius: 8,
  marginRight: 8
};
