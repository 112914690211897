import { useMutation } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'components/providers/TranslationProvider';

const dropTableQuery = ( datasetName ) => {
  let spatialQuery = `TRUNCATE TABLE ${datasetName}`;
  return axios
    .get(`${apiPaths.query}/${spatialQuery}`, status200)
    .then((resp) => resp.data);
};

const useDataQueryDrop = () => {
  return useMutation( (datasetName) => dropTableQuery( datasetName ), {
    onSuccess: data => {
      return data
    },
    onError: error => {
      return error
    },
  });
};

export default useDataQueryDrop;
