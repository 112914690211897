import { Box, styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../../../utils/constants';
import { BarChart, Category, Tune, ThumbUp } from '@mui/icons-material';

export const MuiBarChartIcon = styled(BarChart)(({ theme }) => ({
  width: 16,
  height: 16,
  color: GLOBAL.COLOR.WHITE,
  transform: 'rotate(90deg)',
  padding: 2,
}));

export const MuiPolygonIcon = styled(Category)(({ theme }) => ({
  width: 16,
  height: 16,
  color: GLOBAL.COLOR.WHITE,
  padding: 2,
}));

export const MuiTuneIcon = styled(Tune)(({ theme }) => ({
  width: 16,
  height: 16,
  color: GLOBAL.COLOR.WHITE,
  padding: 2,
}));

export const MuiThumbUpIcon = styled(ThumbUp)(({ theme }) => ({
  width: 16,
  height: 16,
  color: GLOBAL.COLOR.WHITE,
  padding: 2,
}));

export const MuiBarChart = styled(BarChart)(({ theme }) => ({
  width: 16,
  height: 16,
  color: GLOBAL.COLOR.WHITE,
  padding: 2,
}));

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  minHeight: 24,
  height: '100%',
  background: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[200],
  padding: '6px 8px 6px 6px',
  borderRadius: 15,
}));

export const MuiBox = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  display: "flex",
  width: 16,
  height: 16,
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 8,
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[800],
  lineHeight: 1.2,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '290px',
  cursor: 'default',
}));
