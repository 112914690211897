import React from 'react';
import { checkIfSourceIsDroppingFeature } from 'lib/react-geo-tool/packages/react-redux/src/';
import { NoDataAlert } from 'lib/react-geo-tool/packages/react-ui/src/';
import { useSelector } from 'react-redux';
import { defaultDroppingFeaturesAlertProps } from './constants';

export default function WidgetWithAlert({
                                          dataSource,
                                          droppingFeaturesAlertProps = defaultDroppingFeaturesAlertProps,
                                          showDroppingFeaturesAlert = true,
                                          noDataAlertProps = {},
                                          warning,
                                          global = false,
                                          children
                                        }) {
  const isDroppingFeatures = useSelector((state) =>
    checkIfSourceIsDroppingFeature(state, dataSource)
  );

  return (!global && isDroppingFeatures && showDroppingFeaturesAlert) ||
  warning ||
  !children ? (
    <NoDataAlert
      {...(isDroppingFeatures
        ? droppingFeaturesAlertProps
        : warning
          ? { ...noDataAlertProps, body: warning }
          : noDataAlertProps)}
    />
  ) : (
    children
  );
}
