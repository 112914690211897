import { styled, Grid} from '@mui/material';

export const MuiGridCommonButton = styled(Grid)(({ theme }) => ({
  marginTop: '3px',
  display:'flex'
}));

export const transparentButtonsStyle= {
    padding: '7.5px 2.5px', // Adjusted for a more balanced padding
    borderRadius: '5px', // This gives a pill-like rounded shape
    margin: '5px', // Uniform margin on all sides
    border: '1px solid black', // This includes style and color
    width: '80px',
    backgroundColor:'transparent', // Purple background color similar to the buttons in the image
    color: 'black', // Assuming the text color is white
    fontSize: '0.8rem', // Adjust font size as needed
    cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
    outline: 'none', // Remove outline to maintain the flat design on focus
    // boxShadow: 'none', // No shadow for a flat design, uncomment if you want to remove existing shadows
  }
export const buttonCalculadoraStyle = {
    padding: '7.5px 2.5px', // Adjusted for a more balanced padding
    borderRadius: '5px', // This gives a pill-like rounded shape
    margin: '5px', // Uniform margin on all sides
    width: '80px',
    border: 'none', // No border for a flat design
    //backgroundColor: '#7A4DFF', // Purple background color similar to the buttons in the image
    color: 'white', // Assuming the text color is white
    fontSize: '0.8rem', // Adjust font size as needed
    cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
    outline: 'none', // Remove outline to maintain the flat design on focus
    // boxShadow: 'none', // No shadow for a flat design, uncomment if you want to remove existing shadows
  };

export const buttonCommonStyles = {
  width:111,
  height:36,
  fontSize:15,
  fontFamily:'Montserrat',
  fontWeight:500,
  borderRadius:4,
  border:'none',
  margin: '3px',
  padding:0,
  color:'#212121',
  backgroundColor:'#E0E0E0'
}

export const buttonOperationStyles = {
  width:110,
  color:'#FFFFFF',
  height:59,
  fontSize:30
}
