import {lighten} from "@mui/material/styles";
import {
  Button,
  styled,
} from '@mui/material';

export const twoLinesPerRows = {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    whiteSpace: 'break-spaces',
    height: 51,
    lineHeight: 1.7,
    '-webkit-line-clamp': 2,
    fontFamily: 'Montserrat'
};

export const listItem = {
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
};

export const cell = {
    '&:focus': {
      outline: 'none !important',
    },
};

export const columnHeader = {
    '&:focus': {
      outline: 'none !important',
    },
    fontFamily: 'Montserrat !important',
}

export const addRemoveWidget = {
    display: 'block',
    position: 'fixed',
    bottom: 20,
    right: '7.1%',
    zIndex: 99,
    height: 45,
    width: 180,
}

export const MuiButton = styled(Button)(({ theme }) => ({
  fontSize: 14,
  borderRadius: '20px',
  backgroundColor: localStorage.getItem('colorPrimary'),
  padding: '10px 24px 10px 24px ',
  fontFamily: 'Montserrat',
  '&:hover': {
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.26), 0px 1px 2px 0px rgba(0, 0, 0, 0.4)',
    backgroundColor: lighten( localStorage.getItem('colorPrimary') , 0.4),
  },
  color: '#ffffff'
}));