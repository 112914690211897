import axios from './axios/authAxios';
import apiPaths from './apiPaths';

const changePassword = (data, onError, onSuccess) => {
  const { user, password, accessToken } = data;
  axios
    .post(
      apiPaths.changePassword,
      { password, user },
      {
        headers: {
          Authorization: `Basic ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((resp) => onSuccess(resp.data))
    .catch((e) => {
      onError(e.error);
    });
};

export default changePassword;
