import { InvalidColumnError } from 'lib/react-geo-tool/packages/react-core/src/';

/**
 * Return more descriptive error from API
 */
export function dealWithApiError({ response, data }) {
  if (data.error === 'Column not found') {
    throw new InvalidColumnError(`${data.error} ${data.column_name}`);
  }

  if (data.error?.includes('Missing columns')) {
    throw new InvalidColumnError(data.error);
  }

  switch (response.status) {
    case 401:
      throw new Error('Unauthorized access. Invalid credentials');
    case 403:
      throw new Error('Forbidden access to the requested data');
    default:
      throw new Error(`${JSON.stringify(data?.hint || data.error?.[0])}`);
  }
}

export function checkCredentials(credentials) {
  if (!credentials || !credentials.apiBaseUrl || !credentials.accessToken) {
    throw new Error('Missing or bad credentials provided');
  }
}

export async function makeCall({ url, credentials, opts }) {
  let response;
  let data;
  const isPost = opts?.method === 'POST';
  try {
    response = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${credentials.accessToken}`,
        ...(isPost ? { 'Content-Type': 'application/json' } : {})
      },
      ...(isPost
        ? {
            method: opts?.method,
            body: opts?.body
          }
        : {}),
      signal: opts?.abortController?.signal,
      ...opts?.otherOptions
    });
    data = await response.json();
  } catch (error) {
    if (error.name === 'AbortError') throw error;

    throw new Error(`Failed request: ${error}`);
  }

  if (!response.ok) {
    dealWithApiError({ response, data });
  }

  return data;
}

export const CLIENT_ID = 'carto-for-react';

export function getFiltersToString (source) {
  let filter = ''
    if (source.filters){
      if (Object.keys(source.filters).length > 0) {
        Object.keys(source.filters).forEach(keyProp => {
          if (source.filters[keyProp].between && source.filters[keyProp].between.values.length > 0){
            const size = source.filters[keyProp].between.values.length - 1;
            const values = source.filters[keyProp].between.values;
            filter += filter == '' ? '' : ` ${source.filtersLogicalOperator} `;
            if (values[0][0] == null){
              filter += `${keyProp} <= ${values[size][1] ? values[size][1] : values[size][0]} `
            }else{
              filter += `ROUND(${keyProp},3) >= ${values[0][0] != null ? values[0][0] : values[0][1]} `;
              filter += `and ROUND(${keyProp},3) <= ${values[size][1] ? values[size][1] : values[size][0]} `
            }
          }
          if (source.filters[keyProp].in && source.filters[keyProp].in.values.length > 0){
            filter += filter == '' ? '' : ` ${source.filtersLogicalOperator} `;
            filter += `${keyProp} in (`;
            source.filters[keyProp].in.values.forEach((x, index) => {
              const clave = typeof x == 'string' ? `'${x}'` : x;
              filter += index == source.filters[keyProp].in.values.length - 1 ? `${clave}) ` : `${clave}, `;
            })
          }
        })
      }
    }
    return filter;
}

export function getPolygonsParametersToQuery(polygons) {
  let query = '';
  polygons.forEach(element => {
    query += '&polygon_id='+element;
  });
  return query;
}
