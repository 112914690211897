import { Grid, styled, Tab, Tabs, Typography } from '@mui/material';
import { SwitchFieldWithTheme } from '../../../../../common/ReactHooksFormFields';
import {GLOBAL} from "../../../../../../utils/constants";

export const MuiTab = styled(Tab)(({ theme }) => ({
  minWidth: 'auto',
  padding: theme.spacing(0),
  marginLeft: theme.spacing(1),
}));

export const MuiTabs = styled(Tabs)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  '& .Mui-selected': {
    fontSize: 14,
    fontFamily:'Montserrat',
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

export const MuiSwitchOthers = styled(SwitchFieldWithTheme)(({ theme }) => ({
  '&.MuiSwitch-root': {
    marginBottom: 10,
    padding: 0,
  },
  '& .MuiSwitch-switchBase': {
    color: '#757575',
    paddingLeft: '5px',
    '&.Mui-checked': {
      color: '#FFFFFF',
      transform: 'translateX(18px)',
      '& + .MuiSwitch-track': {
        border: 'none',
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-track': {
    background: '#FFFFFF',
    border: '1px solid',
    borderColor: '#757575',
    borderRadius: 12,
    marginTop: 12,
    width: 43,
    height: 24,
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    marginTop: 8,
  },
}));

export const MuiOptionsTypography = styled(Typography)(({ theme }) => ({
  color:'#212121',
  fontFamily: 'Montserrat',
  letterSpacing:'0.4px',
  fontWeight:400,
  fontSize:12
}));

export const MuiSwitchTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Montserrat',
  textTransform:'uppercase',
  letterSpacing:'1.5px',
  fontWeight:400,
  fontSize:10
}));

export const MuiGridOthersTab = styled(Grid)(({ theme }) => ({
  display: 'flex',
  maxHeight: 40,
  alignItems: 'center',
  justifyContent:'space-between',
  marginLeft: 16,
  borderBottom:'1px solid',
  borderColor:'#E0E0E0'
}));

export const containerTabsStyle = {
  height: 250,
  marginTop:'-10px'
}

export const containerSwitchStyle = {
  display:'flex',
  marginTop:'18px'
}

export const dividerStyle = {
  marginLeft:'5px',
  fontSize:'10px',
  marginRight:'5px'
}
