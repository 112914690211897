import * as React from 'react';
import { Grid } from '@material-ui/core';
import PieChart from '@material-ui/icons/DonutLarge';
import CategoryChart from '@material-ui/icons/Reorder';
import HistogramChart from '@material-ui/icons/BarChart';
import FunctionsIcon from '@material-ui/icons/Functions';
import TableChart from '@material-ui/icons/ListAlt';
import CategoryIcon from '@material-ui/icons/Category';

const TypeCell = ({ row }) => (
  <Grid container justifyContent='center' alignContent='center' alignItems='center'>
    {row.type === 'list' && (
      <>
        <TableChart color='secondary' style={{ marginRight: 4 }} />
      </>
    )}
    {row.type === 'histogram' && (
      <>
        <HistogramChart color='secondary' style={{ marginRight: 4 }} />
      </>
    )}
    {row.type === 'category' && (
      <>
        <CategoryChart color='secondary' style={{ marginRight: 4 }} />
      </>
    )}
    {row.type === 'pie' && (
      <>
        <PieChart color='secondary' style={{ marginRight: 4 }} />
      </>
    )}
    {row.type === 'statistics' && (
      <>
        <FunctionsIcon color='secondary' style={{ marginRight: 4 }} />
      </>
    )}
    {row.type === 'polygon' && (
      <>
        <CategoryIcon color='secondary' style={{ marginRight: 4 }} />
      </>
    )}
  </Grid>
);
export default TypeCell;
