import { Tooltip } from '@mui/material';
import { widgetType as widgetTypeConstant } from 'utils/constants';
import {
  MuiBarChart,
  MuiBarChartIcon, MuiBox, MuiBoxContainer,
  MuiPolygonIcon, MuiThumbUpIcon,
  MuiTuneIcon, MuiTypography
} from './styles/appliedFilterDisplay';

function AppliedFilterDisplay( props ) {
    const { information, iconBgColor="orange", widgetType, additionalClasses = {} } = props;

    const renderIconByWidgetType = ( widgetType = widgetTypeConstant.HISTOGRAM ) => {
        if ( widgetType === widgetTypeConstant.CATEGORY ) {
            return <MuiBarChartIcon />;
        }
        if ( widgetType === widgetTypeConstant.POLYGON ) {
            return <MuiPolygonIcon />;
        }
        if ( widgetType === widgetTypeConstant.PARAMETERS ) {
            return <MuiTuneIcon />;
        }
        if ( widgetType === widgetTypeConstant.RECOMMENDATIONS ) {
            return <MuiThumbUpIcon />;
        }
        return <MuiBarChart />;
    }

    return (
        <MuiBoxContainer
            display="inline-flex"
            sx={ `${additionalClasses || ''}`}
            alignItems="center"
        >
            <MuiBox style={{ backgroundColor: iconBgColor }}>
                { renderIconByWidgetType( widgetType ) }
            </MuiBox>
            <Tooltip title={information}>
                <MuiTypography variant='overline'>
                    { information }
                </MuiTypography>
            </Tooltip>
        </MuiBoxContainer>
    );
}

export default AppliedFilterDisplay;
