import { Box, styled } from '@mui/material';

export const MuiBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0.5),
  borderRadius: 4,
  borderWidth: 0,
  outlineOffset: '2px',
  '&:hover': {
    outline: '1.5px ridge black',
    outlineOffset: '2px',
  },
}));
