import {
  useState,
  useMemo,
} from 'react';

import {
    Box,
    Typography,
    Popover,
    IconButton,
  } from '@mui/material';
  import { useSelector, useDispatch } from 'react-redux';
  import { Close as CloseIcon } from "@mui/icons-material";
  import { notificationsType } from 'utils/constants';

  import {
    getWidgetsWithFiltersByLayerId,
    getPolygonWidgetsWithFilters,
  } from 'utils/supportComponents';

  import { setActiveNotification, setPointsOfReference } from 'store/appSlice';
  import AppliedFilterDisplay from 'components/common/widgets/recommendations/AppliedFilterDisplay';
  import { useTranslation } from 'components/providers/TranslationProvider';
  import useChangeMapState from 'api/hooks/useChangeMapState';
  import { useLocation } from 'react-router-dom';
import {
  appliedFilterDisplayContainer,
  closeIconStyle,
  MuiArrowDropDownIcon,
  MuiButton,
  MuiButtonGroup,
  MuiPushPinIcon, MuiReferencePointTitle, referencePointContainerStyle
} from './styles/referencesPoint';

  function ReferencesPointButton( ) {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const handleClickPopover = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const layers = useSelector((state) => state.app.layers);
    const sources = useSelector((state) => state.carto.dataSources);
    const widgets = useSelector((state) => state.app.widgets);
    const listFilterPolygon = useSelector((state) => state.app.listFilterPolygonData);
    const pointsOfReference = useSelector((state) => state.app.pointsOfReference);
    const parametersCatalog = useSelector((state) => state.app.parametersCatalog);
    const isWidgetsPanelVisible = useSelector((state) => state.app.isWidgetsPanelVisible);

    const dispatch = useDispatch();
    const mutate = useChangeMapState();
    const location = useLocation();

    const splittedPathname = useMemo(() => location.pathname.split('/'), [location.pathname]);
    const orgId = splittedPathname[2] === 'workspace' ? user.id : splittedPathname[2];

    const formatParameterWidgetCatalog = ( parameterWidgetCatalog ) => {
      return parameterWidgetCatalog.map(item => item.propName).join(',')
    }

    const filtersOnMap = useMemo(
      () => {
        const appliedFilters = [];
        layers.forEach(layer => {
          appliedFilters.push(
            ...getWidgetsWithFiltersByLayerId(
                layer.id,
                sources,
                layers,
                widgets,
                listFilterPolygon
              )
          );
        });
        appliedFilters.push(
          ...getPolygonWidgetsWithFilters(
              widgets,
              listFilterPolygon
            )
        );
          const parameterWidget = widgets.find( w => w.type === 'parameters' );
          if (parameterWidget) {
            const parametersCatalogParsed = parametersCatalog.map( ( p ) => ({
                propName: p.propName,
                value: p.value,
              })
            );
            appliedFilters.push( {
              type: 'parameters',
              layerColor: '#00BBD4',
              widgetName: parameterWidget.name,
              widgetId: parameterWidget.id,
              filter: formatParameterWidgetCatalog( parameterWidget.parametersCatalog ),
              column: '',
              layerId: '',
              layerIcon: '',
              parameters: [
                ...parametersCatalogParsed
              ]
            } );
          }
          return appliedFilters;
      },
      [ layers, listFilterPolygon, parametersCatalog ]
    );

    const saveReferencePointToMapState = ( referencePoint ) => {
      const updateToMapState = {
        user_id: orgId,
        scheme_id: location.pathname.split('/')[6],
        mapState: {
          pointsOfReference: referencePoint,
        },
      };
      mutate.mutateAsync( updateToMapState );
    }

    const handleClick = () => {
      dispatch( setPointsOfReference( filtersOnMap ) );
      dispatch( setActiveNotification( notificationsType.REFERENCE_POINT ) );
      saveReferencePointToMapState( filtersOnMap );
    }

    const formatInformation = ({ widgetName, filter }) => {
      return `${widgetName}${!!filter ? ':' : ''} ${filter || ''}`
    }

    return (
      <>
        <MuiButtonGroup
          style={{ right: isWidgetsPanelVisible ? '446px' : '12px'  }}
          variant="contained"
          ref={anchorEl}
          aria-label="split button"
        >
          <MuiButton onClick={handleClick}>
            <MuiPushPinIcon />
          </MuiButton>
          <MuiButton
            style={{marginLeft:'-25px'}}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleClickPopover}
          >
            <MuiArrowDropDownIcon/>
          </MuiButton>
        </MuiButtonGroup>
          <Popover
            id="split-button-menu"
            sx={referencePointContainerStyle}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
              <Box display='inline-flex' justifyContent='space-between' style={{width: '100%'}}>
                <MuiReferencePointTitle>
                  { t('reference_point') }
                </MuiReferencePointTitle>
                <IconButton size="small" onClick={handleClose}>
                  <CloseIcon sx={closeIconStyle}/>
                </IconButton>
              </Box>
              <Box style={{marginTop: 16,}}>
                <Typography variant='overline'>
                  { t('map_filters') }
                </Typography>
                <Box>
                {
                  pointsOfReference.filter( pr => pr.type !== 'parameters' ).map( pointOfReference  => (
                    <AppliedFilterDisplay
                      information={ formatInformation({ widgetName: pointOfReference.widgetName, filter: pointOfReference.filter }) }
                      iconBgColor={ pointOfReference.layerColor }
                      widgetType={ pointOfReference.type }
                      additionalClasses={ appliedFilterDisplayContainer }
                      key={ pointOfReference.widgetId }
                    />
                  ))
                }
                </Box>
              </Box>
              <Box style={{marginTop: 16}}>
                <Typography variant='overline' >
                  { t('simulation_parameters') }
                </Typography>
                <Box>
                {
                  pointsOfReference.filter( pr => pr.type === 'parameters' ).map( pointOfReference  => (
                    <AppliedFilterDisplay
                      information={ formatInformation({ widgetName: pointOfReference.widgetName, filter: pointOfReference.filter }) }
                      iconBgColor={ pointOfReference.layerColor }
                      widgetType={ pointOfReference.type }
                      additionalClasses={ appliedFilterDisplayContainer }
                      key={ pointOfReference.widgetId }
                    />
                  ))
                }
                </Box>
              </Box>
        </Popover>
      </>
    )
  }

  function ReferencesPoint( props ) {
      return (<ReferencesPointButton />);
  }

  export default ReferencesPoint;
