import {useMutation} from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import {
  setUpdatePolygonWidget
} from '../../store/appSlice';
import {useDispatch, useSelector} from 'react-redux';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useTranslation } from 'components/providers/TranslationProvider';


const fetchDataIsochrone = (path, data, setPolygonDataLoading, setLayerIsochrones = null) => {
  setPolygonDataLoading(true);
  return axios
    .post(path, Array.isArray(data) ? data : {...data}, status200)
    .then((resp) => {
      return resp;
    }).finally( () => setPolygonDataLoading(false));
};

const typeFunction = (data, setLayerIsochrones, setPolygonDataLoading) => {
  switch (data.type) {
    case 'create' :  return fetchDataIsochrone(apiPaths.createIsochrone, data.iscData, setPolygonDataLoading, setLayerIsochrones);
    case 'update' :  return fetchDataIsochrone(apiPaths.updateIsochrone, data.iscData, setPolygonDataLoading, setLayerIsochrones);
    case 'delete' :  return fetchDataIsochrone(apiPaths.deleteIsochrone, data.iscData, setPolygonDataLoading);
  }
}

const useDataIsochrone = (setLayerIsochrones, setPolygonDataLoading) => {
  const dispatch = useDispatch();
  const updatePolygonWidget = useSelector((state) => state.app.updatePolygonWidget);
  const { t } = useTranslation();
  return useMutation((data) => typeFunction(data, setLayerIsochrones, setPolygonDataLoading), {
    onSuccess: () => {
      dispatch(setUpdatePolygonWidget (!updatePolygonWidget));
    },
    onError: (error) => {
      dispatch(setOpenToast(true));
      dispatch(setMessageToast(t(error.data.error)));
      return error;
    },
  });
}
export default useDataIsochrone;

