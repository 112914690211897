import {useMutation} from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import {useDispatch, useSelector} from 'react-redux';
import { setMessageToast, setOpenToast, setUpdatePolygonWidget } from 'store/appSlice';
import { useTranslation } from 'components/providers/TranslationProvider';

const fetchDataDrawed = (path, data) => {
  switch (data.action) {
    case 'create' :  return axios.post(path, data.data, status200).then((resp) => resp.data);
    case 'update' :  return axios.put(path, data.data, status200).then((resp) => resp.data);
  }
};

const typeFunction = (data) => {
  switch (data.action) {
    case 'create' :  return fetchDataDrawed(apiPaths.createPolygon, data);
    case 'update' :  return fetchDataDrawed(apiPaths.editPolygon, data);
  }
}

const useDataPolygonDrawed = (data) => {
  const dispatch = useDispatch();
  const updatePolygonWidget = useSelector((state) => state.app.updatePolygonWidget);
  const { t } = useTranslation();
  return useMutation((data) => typeFunction(data), {
    onSuccess: () => {
      dispatch(setUpdatePolygonWidget(!updatePolygonWidget));
    },
    onError: (error) => {
      dispatch(setOpenToast(true));
      dispatch(setMessageToast(t(error.data.error)));
      return error;
    },
  });

}
export default useDataPolygonDrawed;