import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  SwipeableDrawer,
  Grid,
  Hidden,
  Portal,
  Snackbar,
  Toolbar,
  useTheme,
  useMediaQuery,
  Alert
} from '@mui/material';
import { BASEMAPS } from 'lib/react-geo-tool/packages/react-basemaps/src/basemaps/basemaps';

import ZoomControl from 'components/common/ZoomControl';
import { setBottomSheetOpen, setError } from 'store/appSlice';
import cartoLogoMap from 'assets/img/carto-logo-map.svg';

import {
  bottomSheet,
  closed,
  drawerPaper, MuiDiv, MuiExpandLessIcon, MuiFab, MuiGrid, MuiImg,
  MuiNav
} from './styles/main';

const Main = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileContainer = React.useRef(null);
  const desktopContainer = React.useRef(null);
  const dispatch = useDispatch();
  const error = useSelector((state) => state.app.error);
  const bottomSheetOpen = useSelector((state) => state.app.bottomSheetOpen);
  const isGmaps = useSelector((state) => BASEMAPS[state.carto.basemap].type === 'gmaps');
  const navigate = useNavigate();

  const zoomControlStyle = {
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(4),
    zIndex: 1,

    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(4),
      left: theme.spacing(2),
    },
  }

  React.useEffect(() => {
    navigate('/orgs');
  }, [navigate]);

  const handleClose = () => dispatch(setError(null));

  const handleWidgetsDrawerToggle = () => dispatch(setBottomSheetOpen(!bottomSheetOpen));

  return (
    <Grid container direction='row' alignItems='stretch' item xs>
      <MuiNav>
        <Portal container={isMobile ? mobileContainer.current : desktopContainer.current}>
          <Outlet />
        </Portal>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: drawerPaper,
            }}
            variant='permanent'
            PaperProps={{
              elevation: 8,
            }}
            open
          >
            <Toolbar variant='dense' />
            <Grid container item xs ref={desktopContainer} />
          </Drawer>
        </Hidden>
        <Hidden smUp implementation='css'>
          <SwipeableDrawer
            variant='persistent'
            anchor='bottom'
            open={bottomSheetOpen}
            onOpen={handleWidgetsDrawerToggle}
            onClose={handleWidgetsDrawerToggle}
            PaperProps={{
              className: `${bottomSheet} ${!bottomSheetOpen ? closed : ''}`,
              elevation: 8,
            }}
          >
            <MuiDiv ref={mobileContainer}/>
          </SwipeableDrawer>
          <MuiFab
            variant='extended'
            size='small'
            color='inherit'
            aria-label={bottomSheetOpen ? 'Hide' : 'Show'}
            bottomSheetOpen={bottomSheetOpen}
            onClick={handleWidgetsDrawerToggle}
          >
            <MuiExpandLessIcon />
            {bottomSheetOpen ? 'Hide' : 'Show'}
          </MuiFab>
        </Hidden>
      </MuiNav>

      <MuiGrid item isGmaps={isGmaps}>
        <Hidden xsDown>
          <ZoomControl sx={zoomControlStyle} />
        </Hidden>
        {!isGmaps && <MuiImg src={cartoLogoMap} alt='CARTO' />}
      </MuiGrid>

      <Snackbar open={!!error} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity='error'>{error}</Alert>
      </Snackbar>
    </Grid>
  );
};

export default Main;
