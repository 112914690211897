import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import FormModal from './FormModal';

const AddOrUpdateTranslation = () => {
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState('create');

  const location = useLocation();
  const navigate = useNavigate();

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  React.useEffect(() => {
    const createQuery = query.get('create');
    const updateQuery = query.get('edit-translation');
    if (createQuery === 'translation') {
      setAction('create');
      setOpen(true);
    }
    if (updateQuery) {
      setAction('update');
      setOpen(true);
    }
  }, [query]);

  const handleClose = (event, reason) => {
    if ( reason === 'backdropClick' || reason === 'escapeKeyDown' ) {
      setOpen( true )
    } else {
      if (action === 'create') query.delete('create');
      if (action === 'update') query.delete('edit-translation');
      setOpen(false);
      navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
    }
  };

  return open && <FormModal action={action} open={open} onClose={handleClose} />;
};
export default AddOrUpdateTranslation;
