import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'components/providers/TranslationProvider';
import { usePermissions } from 'components/hooks/usePermissions';

import FormModal from '../FormModal';
import {MuiButton} from "../styles/dataset";
import { Typography } from '@material-ui/core';
import { MuiCommonTypography } from '../styles/scenario';

const NewScenario = (props) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const createQuery = query.get('create');
    setOpen(createQuery === 'scheme');
  }, [query]);

  const handleClose = () => {
    query.delete('create');
    navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
  };

  return (
    <>
      {usePermissions('create_schemes') && (
        <MuiButton
          variant='outlined'
          color='primary'
          startIcon={<AddIcon sx={{
            width: '18px !important',
            height: '18px !important',
            color:'#616161'
          }} />}
          onClick={() => navigate(`${location.pathname}/create/specification`)}
        >
          <MuiCommonTypography variant='body2'>
            {t('new_scenario')}
          </MuiCommonTypography>
        </MuiButton>
      )}
      {open && <FormModal action='create' open={open} onClose={handleClose} />}
    </>
  );
};
export default NewScenario;
