import * as React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  ListSubheader,
  Checkbox,
  Chip,
  Accordion,
  AccordionSummary,
  Badge,
} from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TabIcon from '@material-ui/icons/LocalOffer';
import WidgetsIcon from '@material-ui/icons/Widgets';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TvIcon from '@material-ui/icons/Tv';
import Tab from '@material-ui/icons/Tab';
import PieChart from '@material-ui/icons/DonutLarge';
import CategoryChart from '@material-ui/icons/Reorder';
import HistogramChart from '@material-ui/icons/BarChart';
import FunctionsIcon from '@material-ui/icons/Functions';
import TableChart from '@material-ui/icons/ListAlt';
import CategoryIcon from '@material-ui/icons/Category';

import { hexToRgb } from 'utils/colorConverter';

const ListItemFilters = ({ filters, setFilterActive, tagsColor }) => {
  return (
    <List
      subheader={<li />}
      style={{
        width: '100%',
        maxWidth: 360,
        backgroundColor: 'white',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 415,
      }}
    >
      {filters.map((f, i) => (
        <Accordion
          style={{ padding: 0, marginRight: 4, overflow: 'hidden' }}
          sx={{
            '&.Mui-expanded': {
              margin: 0,
            },
            '&.MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
          key={f.type}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            style={{ padding: 0 }}
          >
            <ListSubheader disableGutters style={{ display: 'flex' }}>
              <ListItemIcon style={{ margin: 0, padding: 0, minWidth: 35 }}>
                {f.type === 'layer' && <LayersIcon />}
                {f.type === 'type' && <WidgetsIcon />}
                {f.type === 'tags' && <TabIcon />}
                {f.type === 'tabs' && <Tab />}
                {f.type === 'visibility' && <TvIcon />}
              </ListItemIcon>
              <Badge color='secondary' badgeContent={f.items.filter((fa) => fa.active).length}>
                <ListItemText
                  inset
                  primary={
                    f.type === 'tags'
                      ? 'Categories'
                      : f.type.charAt(0).toUpperCase() + f.type.slice(1)
                  }
                  style={{ padding: 0 }}
                />
              </Badge>
            </ListSubheader>
          </AccordionSummary>
          {f.items
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((item) => (
              <ListItem
                button
                key={`${f.type}-${item.name}`}
                style={{
                  paddingLeft: 10,
                  paddingTop: 2,
                  paddingBottom: 0,
                  borderRadius: 2,
                  background:
                    item.active &&
                    `rgb(${
                      hexToRgb(localStorage.getItem('colorPrimary'))?.join(', ') || '127, 127, 127 '
                    },0.1)`,
                }}
                onClick={() => setFilterActive(i, item)}
              >
                {f.type === 'type' && (
                  <ListItemIcon style={{ margin: 0, padding: 0, minWidth: 35 }}>
                    {item.name === 'list' && <TableChart color='disabled' />}
                    {item.name === 'histogram' && <HistogramChart color='disabled' />}
                    {item.name === 'category' && <CategoryChart color='disabled' />}
                    {item.name === 'pie' && <PieChart color='disabled' />}
                    {item.name === 'statistics' && <FunctionsIcon color='disabled' />}
                    {item.name === 'polygon' && <CategoryIcon color='disabled' />}
                  </ListItemIcon>
                )}
                {f.type === 'visibility' && (
                  <ListItemIcon style={{ margin: 0, padding: 0, minWidth: 35 }}>
                    {item.name === 'visible' && <VisibilityIcon color='disabled' />}
                    {item.name === 'not visible' && <VisibilityOffIcon color='disabled' />}
                  </ListItemIcon>
                )}
                {f.type === 'tags' ? (
                  <ListItemText
                    id={item.name}
                    primary={(
                      <Chip
                        label={`${item.name.charAt(0).toUpperCase()}${item.name.slice(1)}`}
                        style={{
                          height: 20,
                          background: `rgb(${hexToRgb(tagsColor[item.name]).join(', ')},0.5)`,
                          textOverflow: 'ellipsis',
                          maxWidth: 200,
                        }}
                      />
                    )}
                  />
                ) : (
                  <ListItemText
                    id={item.name}
                    primary={`${item.name.charAt(0).toUpperCase()}${item.name.slice(1)}`}
                  />
                )}

                <ListItemSecondaryAction>
                  <Checkbox
                    edge='end'
                    onChange={() => setFilterActive(i, item)}
                    checked={item.active}
                    icon={<></>}
                    inputProps={{ 'aria-labelledby': item.name }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </Accordion>
      ))}
    </List>
  );
};
export default ListItemFilters;
