import { Grid, Box, styled } from '@mui/material';

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  bgcolor: 'background.paper',
  display: 'flex',
  height: 280,
  width:'100%'
}));

export const MuiGridTabContent = styled(Grid)(({ theme }) => ({
  borderBottomRightRadius:'12px',
  borderColor:'#E0E0E0'
}));

export const MuiContainerContent = styled("div")(({ theme }) => ({
  background: '#FAFAFA',
  height: '100%',
  width:'100%',
  overflowY: 'auto'
}));

export const dragHandleStyle = {
  cursor: 'row-resize',
  marginRight: 8,
  marginTop: '5px',
}

export const customTabStyle = {
  minWidth: '425px',
  borderTopLeftRadius:'12px',
  borderBottomLeftRadius:'12px',
  marginLeft: '5px',
  paddingRight: 0,
  opacity: 1,
}

export const nameTabStyle = {
  textAlign: 'initial',
  fontWeight: 500,
  fontFamily:'Montserrat',
  fontSize:14,
  letterSpacing:'0.1px',
}

export const customActionsStyle = {
  marginTop: '-1px',
  width: '26px',
  height: '26px',
  padding: '5px',
}

export const customVisibilityStyle = {
  marginTop:'-10px',
  marginLeft:'15px',
}


