import * as React from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { GLOBAL } from 'utils/constants';

const Toast = ({ message, handleClose, severity, open = false, showTime = 6000, title = '' }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={showTime}
      onClose={handleClose}
      anchorOrigin={{
        vertical: GLOBAL.POSITION.TOP,
        horizontal: GLOBAL.POSITION.RIGHT,
      }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ backgroundColor: '#e4ffe5' }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
