import { Box, Grid, Popover } from '@mui/material';
import * as React from 'react';
import {
  gridColumnStyle,
  MuiTypographySubTitle,
  MuiTypographyTitle, popoverContainerStyle
} from './styles/calculatorLegend';

const CalculatorLegend = ({open, anchorEl, setAnchorEl}) => {

  const handlePopoverClose = () => setAnchorEl(null);

  return(
    <Popover
      id="mouse-over-popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Box sx={popoverContainerStyle}>
        <Grid container>
          <Grid item xs={12}>
            <MuiTypographyTitle variant='body1'>OPERADORES MATEMÁTICOS</MuiTypographyTitle>
          </Grid>
          <Grid item style={gridColumnStyle}>
              <MuiTypographySubTitle variant='caption'>
                + (Suma)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                - (Sustracción)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                * (Multiplicación)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                / (División)
              </MuiTypographySubTitle>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 24 }}>
            <MuiTypographyTitle variant='body1'>FUNCIONES MATEMÁTICAS</MuiTypographyTitle>
          </Grid>
          <Grid item style={gridColumnStyle}>
              <MuiTypographySubTitle variant='caption'>
                CEIL (Redondeo por exceso)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                FLOOR (Redondeo por defecto)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                POW (Potencia)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                SQRT (Raíz cuadrada)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                MIN (Mínimo)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                MAX (Máximo)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                LOG (Logaritmo en base 10)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                ABS (Valor absoluto, elimina signo y lo hace positivo)
              </MuiTypographySubTitle>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 24 }}>
            <MuiTypographyTitle variant='body1'>OPERADORES LÓGICOS</MuiTypographyTitle>
          </Grid>
          <Grid item style={gridColumnStyle}>
              <MuiTypographySubTitle variant='caption'>
                {'<'} (Menor que)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                {'>'} (Mayor que)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                {'<='} (Menor o igual a)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                {'>='} (Mayor o igual a)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                == (Compara si dos elementos son iguales)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                != (Compara si dos elementos son distintos)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                IN (El valor está en el conjunto de valores especificados)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                NOT (Niega una expresión)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                IS
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                AND
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                OR
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                START WITH (Evalúa si comienza con una serie de caracteres)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                END WITH (Evalúa si termina con una serie de caracteres)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                IS EMPTY (Evalúa si vacío)
              </MuiTypographySubTitle>
              <MuiTypographySubTitle variant='caption'>
                BETWEEN (Evalua si el valor está dentro del rango especificado)
              </MuiTypographySubTitle>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}

export default CalculatorLegend;
