/* eslint-disable camelcase */
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { giveMeId } from 'utils/supportComponents';

import useDeleteScheme from 'api/hooks/useDeleteScheme';
import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import ModalDelete from '../common/ModalDelete';
import { MuiTypographyContent } from '../common/styles/modalDelete';

const DeleteScenario = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { user } = useAuth();

  const deleteMutation = useDeleteScheme();
  const id = giveMeId(location.pathname, user);

  const handleSubmit = () => {
    const scheme_id = location.state.id;
    const data = {
      user_id: id,
      scheme_id,
    };
    deleteMutation.mutateAsync(data);
  };

  const content = (
    <>
      <MuiTypographyContent>
        {t('delete_scenario')}
      </MuiTypographyContent>
      <p style={{textAlign:'center', marginTop:'-10px'}}>
        <strong>{location.state?.name}</strong>
      </p>
    </>
  )

  return (
    <ModalDelete
      deleteMutation={deleteMutation}
      onSubmit={handleSubmit}
      content={content}
      title={t('delete_scenario_title')}
      name={location.state?.name}
    />
  );
};

export default DeleteScenario;
