import {
  AppBar,
  Button,
  Container, Grid,
  IconButton,
  styled,
  Toolbar
} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';

export const MuiAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  zIndex: 1250,
  overflow: 'hidden',
}));

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  height: 64,
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0, 0.75, 0, -1.25),
  '& + hr': {
    marginRight: theme.spacing(1.5),
  },
}));

export const MuiMapIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '50%',
  border: '2px solid white',
  padding: '5px',
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  width: 30,
  height: 30
}));

export const MuiPublicIcon = styled(PublicIcon)(({ theme }) => ({
  marginLeft: 0,
  color: 'white',
  width: '14px !important',
  height: '14px !important'
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  maxWidth: '100vw !important',
}));

export const MuiSelectorContainer = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  inlineSize: 'max-content',
  justifyContent: 'space-evenly',
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  verticalAlign: 'bottom',
  flexGrow: 1,
  display: 'flex',
  color: theme.palette.common.white,
}));

export const MuiDivContainer = styled('div')(({ theme }) => ({
  height: '95%',
  paddingTop: 0,
  marginTop: '-10px !important',
  paddingBottom: 4,
  marginLeft: 20,
  marginRight: 15
}));

export const linkHeaderStyle = {
  marginRight: 24,
  display: 'flex'
};

const styles = (theme) => ({
  navBar: {
    boxShadow: 'none',
    zIndex: 1250,
    overflow: 'hidden',
  },
  navTabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.common.white,
    },
  },
  divider: {
    border: '1px solid #ffffff',
  },
  menuButton: {
    margin: theme.spacing(0, 0.75, 0, -1.25),

    '& + hr': {
      marginRight: theme.spacing(1.5),
    },
  },
  drawer: {
    minWidth: 260,
  },
  title: {
    '& h1': {
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.common.white,

      '& img': {
        height: `${theme.typography.subtitle1.lineHeight}em`,
        marginRight: theme.spacing(1.5),
        verticalAlign: 'bottom',
      },
    },
  },
  languageSelectorContainer: {
    alignItems: 'center',
    inlineSize: 'max-content',
    justifyContent: 'space-evenly',
  },
  loginButton: {
    verticalAlign: 'bottom',
    flexGrow: 1,
    display: 'flex',
    color: theme.palette.common.white,
  },
  toggleButton: {
    borderRadius: 25,
    border: 0,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0, 2),
    backgroundColor: '#2c303200',
    '&:focus': {
      color: 'rgba(0, 0, 0, 0)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  invertColorsIcon: {
    color: '#ffffff',
  },
  container: {
    maxWidth: ({ disabledContainer }) => (disabledContainer ? '100vw' : '100vw'),
  },
  toolbar: {
    height: 64,
  },
  dividerContainer: {
    height: '100%',
    paddingTop: 4,
    paddingBottom: 4,
    marginLeft: 20,
    marginRight: 15
  },
  displayFlex: {
    display: 'flex'
  },
  mapIconButton: {
    borderRadius: '50%',
    border: '2px solid white',
    padding: '5px',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    width: 30,
    height: 30
  },
  mapIcon: {
    marginLeft: 0,
    color: 'white',
    width: '14px !important',
    height: '14px !important'
  },
});
export default styles;
