export const iconsName  = [
    {"name": "circle"},
    {"name": "aerialway"},
    {"name": "airfield"},
    {"name": "airport"},
    {"name": "alcohol-shop"},
    {"name": "american-football"},
    {"name": "amusement-park"},
    {"name": "aquarium"},
    {"name": "arrow"},
    {"name": "art-gallery"},
    {"name": "attraction"},
    {"name": "bakery"},
    {"name": "bank-JP"},
    {"name": "bank"},
    {"name": "bar"},
    {"name": "barrier"},
    {"name": "baseball"},
    {"name": "basketball"},
    {"name": "bbq"},
    {"name": "beach"},
    {"name": "beer"},
    {"name": "bicycle-share"},
    {"name": "bicycle"},
    {"name": "blood-bank"},
    {"name": "bowling-alley"},
    {"name": "bridge"},
    {"name": "building-alt1"},
    {"name": "building"},
    {"name": "bus"},
    {"name": "cafe"},
    {"name": "campsite"},
    {"name": "car-rental"},
    {"name": "car-repair"},
    {"name": "car"},
    {"name": "casino"},
    {"name": "castle-JP"},
    {"name": "castle"},
    {"name": "caution"},
    {"name": "cemetery-JP"},
    {"name": "cemetery"},
    {"name": "charging-station"},
    {"name": "cinema"},
    {"name": "circle-stroked"},
    {"name": "city"},
    {"name": "clothing-store"},
    {"name": "college-JP"},
    {"name": "college"},
    {"name": "commercial"},
    {"name": "communications-tower"},
    {"name": "confectionery"},
    {"name": "construction"},
    {"name": "convenience"},
    {"name": "cricket"},
    {"name": "cross"},
    {"name": "dam"},
    {"name": "danger"},
    {"name": "defibrillator"},
    {"name": "dentist"},
    {"name": "diamond"},
    {"name": "doctor"},
    {"name": "dog-park"},
    {"name": "drinking-water"},
    {"name": "elevator"},
    {"name": "embassy"},
    {"name": "emergency-phone"},
    {"name": "entrance-alt1"},
    {"name": "entrance"},
    {"name": "farm"},
    {"name": "fast-food"},
    {"name": "fence"},
    {"name": "ferry-JP"},
    {"name": "ferry"},
    {"name": "fire-station-JP"},
    {"name": "fire-station"},
    {"name": "fitness-centre"},
    {"name": "florist"},
    {"name": "fuel"},
    {"name": "furniture"},
    {"name": "gaming"},
    {"name": "garden-centre"},
    {"name": "garden"},
    {"name": "gate"},
    {"name": "gift"},
    {"name": "globe"},
    {"name": "golf"},
    {"name": "grocery"},
    {"name": "hairdresser"},
    {"name": "harbor"},
    {"name": "hardware"},
    {"name": "heart"},
    {"name": "heliport"},
    {"name": "highway-rest-area"},
    {"name": "historic"},
    {"name": "home"},
    {"name": "horse-riding"},
    {"name": "hospital-JP"},
    {"name": "hospital"},
    {"name": "hot-spring"},
    {"name": "ice-cream"},
    {"name": "industry"},
    {"name": "information"},
    {"name": "jewelry-store"},
    {"name": "karaoke"},
    {"name": "landmark-JP"},
    {"name": "landmark"},
    {"name": "landuse"},
    {"name": "laundry"},
    {"name": "library"},
    {"name": "lift-gate"},
    {"name": "lighthouse-JP"},
    {"name": "lighthouse"},
    {"name": "lodging"},
    {"name": "logging"},
    {"name": "marker-stroked"},
    {"name": "marker"},
    {"name": "mobile-phone"},
    {"name": "monument-JP"},
    {"name": "monument"},
    {"name": "mountain"},
    {"name": "museum"},
    {"name": "music"},
    {"name": "natural"},
    {"name": "observation-tower"},
    {"name": "optician"},
    {"name": "paint"},
    {"name": "park-alt1"},
    {"name": "park"},
    {"name": "parking-garage"},
    {"name": "parking-paid"},
    {"name": "parking"},
    {"name": "pharmacy"},
    {"name": "picnic-site"},
    {"name": "pitch"},
    {"name": "place-of-worship"},
    {"name": "playground"},
    {"name": "police-JP"},
    {"name": "police"},
    {"name": "post-JP"},
    {"name": "post"},
    {"name": "prison"},
    {"name": "racetrack-boat"},
    {"name": "racetrack-cycling"},
    {"name": "racetrack-horse"},
    {"name": "racetrack"},
    {"name": "rail-light"},
    {"name": "rail-metro"},
    {"name": "rail"},
    {"name": "ranger-station"},
    {"name": "recycling"},
    {"name": "religious-buddhist"},
    {"name": "religious-christian"},
    {"name": "religious-jewish"},
    {"name": "religious-muslim"},
    {"name": "religious-shinto"},
    {"name": "residential-community"},
    {"name": "restaurant-bbq"},
    {"name": "restaurant-noodle"},
    {"name": "restaurant-pizza"},
    {"name": "restaurant-seafood"},
    {"name": "restaurant-sushi"},
    {"name": "restaurant"},
    {"name": "road-accident"},
    {"name": "roadblock"},
    {"name": "rocket"},
    {"name": "school-JP"},
    {"name": "school"},
    {"name": "scooter"},
    {"name": "shelter"},
    {"name": "shoe"},
    {"name": "shop"},
    {"name": "skateboard"},
    {"name": "skiing"},
    {"name": "slaughterhouse"},
    {"name": "slipway"},
    {"name": "snowmobile"},
    {"name": "soccer"},
    {"name": "square-stroked"},
    {"name": "square"},
    {"name": "stadium"},
    {"name": "star-stroked"},
    {"name": "star"},
    {"name": "suitcase"},
    {"name": "swimming"},
    {"name": "table-tennis"},
    {"name": "teahouse"},
    {"name": "telephone"},
    {"name": "tennis"},
    {"name": "theatre"},
    {"name": "toilet"},
    {"name": "toll"},
    {"name": "town-hall"},
    {"name": "town"},
    {"name": "triangle-stroked"},
    {"name": "triangle"},
    {"name": "tunnel"},
    {"name": "veterinary"},
    {"name": "viewpoint"},
    {"name": "village"},
    {"name": "volcano"},
    {"name": "volleyball"},
    {"name": "warehouse"},
    {"name": "waste-basket"},
    {"name": "watch"},
    {"name": "water"},
    {"name": "waterfall"},
    {"name": "watermill"},
    {"name": "wetland"},
    {"name": "wheelchair"},
    {"name": "windmill"},
    {"name": "zoo"}
  ]
