import { useQueryClient, useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';

const deleteUser = async ({ _id }) =>
  axios.delete(`${apiPaths.delete_user}${_id}`, status200).then((resp) => resp.data);

const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => deleteUser(values), {
    onSuccess: ( data ) => {
      queryClient.resetQueries(['users']);
      return data;
    },
    onError: (error) => {
      
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};

export default useDeleteUser;
