/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import {
  twoLinesPerRows,
  cell,
  columnHeader,
  addRemoveWidget,
  MuiButton
} from '../styles/widgetModal'
import { 
  Button,
  Grid,
  Box,
  TextField,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { removeLayer, removeSource } from 'lib/react-geo-tool/packages/react-redux/src/';
import _ from 'lodash';

import CustomModal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import {
  setSendMapStateToServer,
  setWidgets as setWidgetsToRedux,
  cleanSchemeState,
  setLayers
} from 'store/appSlice';
import generateRandomColors from 'utils/generateRandomColors';
import { useLayers } from 'components/providers/LayersProvider';

import ListItemFilters from './ListItemFilter';
import TypeCell from './TypeCell';
import TagCell from './TagCell';
import TabCell from './TabCell';
import { useAuth } from '../../../../../../providers/AuthProvider';
import { status200 } from '../../../../../../../api/status.utils';
import axios from '../../../../../../../api/axios/axiosInstance';
import apiPaths from '../../../../../../../api/apiPaths';

const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const QuickSearchToolbar = (props) => {
  const { value, onChange, clearSearch } = props;
  return (
    <Box
      style={{
        p: 0.5,
        pb: 0,
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      <TextField
        variant='standard'
        value={value}
        onChange={onChange}
        placeholder='Search…'
        InputProps={{
          startAdornment: <SearchIcon fontSize='small' />,
          endAdornment: (
            <IconButton
              title='Clear'
              aria-label='Clear'
              size='small'
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize='small' />
            </IconButton>
          ),
        }}
        style={{
          maxWidth: 300,
        }}
      />
    </Box>
  );
};

const WidgetModal = () => {
  const activeLayers = useSelector((state) => state.app.layers.map((l) => l.id));
  const { user } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const splittedPathname = React.useMemo(() => location.pathname.split('/'), [location.pathname]);
  const id = splittedPathname[2] === 'workspace' ? user.id : splittedPathname[2];
  const { setRefresh, setSchemeFetched, layers, mapRefreshCounter, setMapRefreshCounter } = useLayers();
  const showActionCatalog = useSelector((state) => state.app.showActionCatalog);

  const [open, setOpen] = React.useState(false);
  const handleClose = async (widgets) => {
    if (widgets?.length) {
      const newMapState = {
        user_id: id,
        scheme_id: location.pathname.split('/')[6],
        mapState: {
          widgets: widgets.map((w) => ({
            active_state: true,
            active_tab: w.active_tab,
            description: w.description,
            folded_state: w.folded_state,
            name: w.name,
            tags: w.tags,
            type: w.type,
            visible: w.visible,
            widgetId: w.id,
          })),
        },
      };
      await axios.post(apiPaths.save_map_state, { ...newMapState }, status200);
      const schemeFetchedCustom = await axios.post(apiPaths.scheme, { user_id: id, scheme_id: splittedPathname[6] }, status200);
      const layersState = schemeFetchedCustom.data.scheme.map_state.find((ms) => ms.owner_id === user.id)
        ? schemeFetchedCustom.data.scheme.map_state.find((ms) => ms.owner_id === user.id).layers.reduce(
          (acc, l) => ({ ...acc, [l.layerId]: l.visible }),
          {})
        : [];
      setSchemeFetched((pS) => ({
        ...pS,
        scheme: {
          ...pS.scheme,
          layers: schemeFetchedCustom.data.scheme.layers,
          widgets,
          map_state: [
            {
              ...schemeFetchedCustom.data.scheme.map_state.find((ms) => ms.owner_id === user.id),
              widgets: widgets.map((w) => ({
                active_state: true,
                active_tab: w.active_tab,
                description: w.description,
                folded_state: w.folded_state,
                name: w.name,
                tags: w.tags,
                type: w.type,
                visible: w.visible,
                widgetId: w.id,
              })),
            },
          ],
        },
      }));
      dispatch(
        setLayers(
          schemeFetchedCustom.data.scheme.layers.map((l, index) => ({
            ...l,
            accessToken: l.access_token,
            geomName: l.geometryName,
            index,
            visible: layersState[l.id],
            hide: layersState[l.id],
          }))
        )
      );
      setMapRefreshCounter(mapRefreshCounter + 1);
      layers.forEach((layer) => {
        dispatch(removeLayer(layer.id));
        dispatch(removeSource(layer.id));
      });
      dispatch(setWidgetsToRedux(widgets));
      dispatch(cleanSchemeState());
      setRefresh((prevS) => !prevS);
    }
    setOpen(false);
  };
  return (
    <div>
      {showActionCatalog && (
      <MuiButton
        variant='contained'
        onClick={() => setOpen((prevSt) => !prevSt)}
        disabled={!activeLayers?.length}
        sx={addRemoveWidget}
      >
        Add / Remove
      </MuiButton>)}
      {activeLayers?.length > 0 && open && <Modal open={open} handleClose={handleClose} />}
    </div>
  );
};

const colors = generateRandomColors(70);

const Modal = ({ open, handleClose }) => {

  const layers = useSelector((state) => state.app.layers);

  const tabs = useSelector((state) => state.app.tabs);

  const maxVisibleWidgets = useSelector((state) => state.app.maxVisibleWidgets);

  const allWidgets = useSelector((state) => state.app.widgets);

  const [searchText, setSearchText] = React.useState('');

  const [rows, setRows] = React.useState([]);

  const [error, setError] = React.useState('');
  const [openToast, setOpenToast] = React.useState(false);

  const rowDisabled = [];

  const [widgets, setWidgets] = React.useState(
    allWidgets.map((w) => ({
      ...w,
      tab: tabs.find((t) => t.id === w.active_tab).name,
      layerName: layers.find((l) => l.id === w.layer)?.name,
    }))
  );

  const tags = _.uniq(_.flattenDepth(allWidgets.map((w) => w.tags)));

  const tagsColor = tags.reduce((acc, tag, i) => ({ ...acc, [tag]: colors[i] }), {});

  const type = _.uniq(widgets.map((w) => w.type));

  const initialFilters = [
    {
      type: 'visibility',
      active: false,
      items: [
        {
          name: 'visible',
          type: 'visibility',
          active: false,
        },
        {
          name: 'not visible',
          type: 'visibility',
          active: false,
        },
      ],
    },
    {
      type: 'tags',
      active: false,
      items: tags.map((t) => ({
        name: t,
        type: 'tags',
        active: false,
      })),
    },
    {
      type: 'layer',
      active: false,
      items: layers.map((l) => ({ name: l.name, type: 'layerName', active: false })),
    },
    {
      type: 'type',
      active: false,
      items: type.map((t) => ({
        name: t,
        type: 'type',
        active: false,
      })),
    },
    {
      type: 'tabs',
      active: false,
      items: tabs.map((t) => ({ name: t.name, type: 'tab', active: false })),
    },
  ];

  const [filters, setFilters] = React.useState(initialFilters);

  const columns = React.useMemo(
    () => [
      {
        field: 'visible',
        headerName: 'lolo',
        type: 'actions',
        width: 50,
        editable: false,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={(
                <>
                  {params.row.visible ? (
                    <VisibilityIcon style={{fill: localStorage.getItem('colorPrimary')}} />
                  ) : (
                    <VisibilityOffIcon color='disabled' />
                  )}
                </>
              )}
              onClick={() => {
                setWidgets((prevS) =>
                  prevS.map((w) => (w.id === params.row.id ? { ...w, visible: !w.visible } : w))
                );
                setRows((prevS) =>
                  prevS.map((r) => (r.id === params.row.id ? { ...r, visible: !r.visible } : r))
                );
              }}
              label='Navigate'
              color='primary'
            />,
          ];
        },
      },
      {
        width: 130,
        field: 'tab',
        headerName: 'tab'.toUpperCase(),
        hide: false,
        renderCell: ({ row }) => (
          <TabCell row={row} tabs={tabs} setRows={setRows} setWidgets={setWidgets} />
        ),
      },
      {
        width: 175,
        field: 'name',
        headerName: 'name'.toUpperCase(),
        hide: false,
        renderCell: ({ row }) => (
          <Tooltip title={row.name}>
            <span className={twoLinesPerRows} style={{ fontFamily: 'Montserrat' }}>{row.name}</span>
          </Tooltip>
        ),
      },
      {
        width: 190,
        field: 'tags',
        headerName: 'Categories'.toUpperCase(),
        hide: false,
        renderCell: ({ row }) => <TagCell row={row} tagsColor={tagsColor} />,
      },
      {
        field: 'type',
        headerName: 'Type'.toUpperCase(),
        width: 80,
        editable: false,
        renderCell: ({ row }) => <TypeCell row={row} />,
      },
      {
        width: 130,
        field: 'layerName',
        headerName: 'layer'.toUpperCase(),
        hide: false,
        renderCell: ({ row }) => (
          <Tooltip title={row.layerName}>
            <span
              style={{
                maxWidth: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontFamily: 'Montserrat'
              }}
            >
              {row.layerName}
            </span>
          </Tooltip>
        ),
      },
      {
        width: 650,
        field: 'description',
        headerName: 'description'.toUpperCase(),
        hide: false,
        renderCell: ({ row }) => {
          return (
            <Tooltip title={row.description}>
              <span className={twoLinesPerRows} style={{ fontFamily: 'Montserrat' }}>{row.description}</span>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = widgets.filter((row) => {
      return Object.keys(row).some((field) => {
        return (
          (field === 'name' || field === 'description') && searchRegex.test(row[field].toString())
        );
      });
    });
    setRows(filteredRows || []);
  };

  const handleSubmit = () => {
    const filteredWidgets = widgets.filter((w) => w.visible);
    if (filteredWidgets.length - 1 > maxVisibleWidgets) {
      setError(`You can't have more than ${maxVisibleWidgets} widgets visible at the same time`);
      setOpenToast(true);
      return;
    }
    handleClose(widgets);
  };

  const setFilterActive = (i, item) => {
    setFilters((prevSt) =>
      prevSt.map((p, j) =>
        i === j
          ? {
              ...p,
              items: p.items.map((fi) =>
                fi.name === item.name ? { ...fi, active: !fi.active } : fi
              ),
            }
          : p
      )
    );
  };

  React.useEffect(() => {
    setRows(widgets);
  }, [open]);

  React.useEffect(() => {
    setSearchText('');
    const filterActive = filters
      .map((f) => f.items.filter((i) => i.active))
      .filter((i) => i.length > 0);

    const candidate = (f, w, results) => {
      if (f.length === 0) return results;

      const currentFilter = f[0];
      const nextFilters = _.drop(f);

      let elements = {};
      if (currentFilter.type === 'tags') {
        elements = w.reduce(
          (acc, i) =>
            i[currentFilter.type].includes(currentFilter.name)
              ? { ...acc, accepted: [...acc.accepted, i] }
              : { ...acc, rejected: [...acc.rejected, i] },
          { accepted: [], rejected: [] }
        );
      } else if (currentFilter.type === 'visibility') {
        elements = w.reduce(
          (acc, i) => {
            if (currentFilter.name === 'visible')
              return i.visible
                ? { ...acc, accepted: [...acc.accepted, i] }
                : { ...acc, rejected: [...acc.rejected, i] };
            return !i.visible
              ? { ...acc, accepted: [...acc.accepted, i] }
              : { ...acc, rejected: [...acc.rejected, i] };
          },
          { accepted: [], rejected: [] }
        );
      } else {
        elements = w.reduce(
          (acc, i) =>
            i[currentFilter.type] === currentFilter.name
              ? { ...acc, accepted: [...acc.accepted, i] }
              : { ...acc, rejected: [...acc.rejected, i] },
          { accepted: [], rejected: [] }
        );
      }

      const newResult = [...elements.accepted, ...results];
      return candidate(nextFilters, elements.rejected, newResult);
    };

    const filterWidgets = (f, w) => {
      if (f.length === 0) return w;

      const currentFilters = f[0];
      const nextFilters = _.drop(f);

      const newWidgets = candidate(currentFilters, w, []);

      return filterWidgets(nextFilters, newWidgets);
    };

    const newRows = filterWidgets(filterActive, widgets);

    setRows(newRows);
  }, [filters]);

  const actions = (
    <>
      <MuiButton variant='text' color='primary' onClick={handleClose} style={{ marginRight: 16 }}>
        Cancel
      </MuiButton>
      <MuiButton variant='contained' color='primary' onClick={handleSubmit}>
        Save
      </MuiButton>
    </>
  );

  return (
    <CustomModal
      actions={actions}
      open={open}
      onClose={handleClose}
      title='Catalogue'
      width='xl'
      height='800px'
    >
      <Toast
        open={openToast}
        onClose={() => setOpenToast((prevSt) => !prevSt)}
        message={error}
        title='Error'
        severity='error'
        vertical='center'
        horizontal='top'
      />
      <Grid container style={{ height: 480 }}>
        <Grid container justifyContent='center' style={{ height: 25 }}>
          <Typography variant='body1' color='initial' sx={{ fontFamily: 'Montserrat' }}>
            Search, add or remove widgets by changing their visibility.
          </Typography>
        </Grid>
        <Grid container justifyContent='center' style={{ height: 455 }}>
          <Grid item xs={2} style={{ paddingRight: 8 }}>
            <Typography variant='h6' color='initial' style={{ marginTop: 8, marginBottom: 8, fontFamily: 'Montserrat' }}>
              Filters
            </Typography>
            <ListItemFilters
              filters={filters}
              setFilterActive={setFilterActive}
              tagsColor={tagsColor}
            />
          </Grid>
          <Grid item xs={10}>
            <DataGrid
              sx={{
                '& .MuiDataGrid-columnHeader': columnHeader,
                '& .MuiDataGrid-cell': cell,
              }}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                },
              }}
              isRowSelectable={(row) => !rowDisabled?.includes(row.row.id)}
              rows={rows}
              columns={columns}
              pageSize={25}
              rowsPerPageOptions={[25]}
              density='standard'
              disableColumnMenu
              disableSelectionOnClick
              scrollbarSize={2}
              initialState={{ pinnedColumns: { left: ['actions'] } }}
              onCellClick={(params, event) => {
                event.defaultMuiPrevented = true;
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default WidgetModal;
