import { Container, styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../../../utils/constants';

export const MuiContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 8,
  marginBottom: 10
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  alignSelf: 'center',
  fontFamily: 'Montserrat'
}));

export const MuiRecommendationsLabel = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  alignSelf: 'center',
  marginTop: 8,
  marginBottom: 4,
  fontFamily: 'Montserrat'
}));
export const MuiDemandLabel = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  marginBottom: 16.5,
  fontFamily: 'Montserrat'
}));

export const MuiRecommendationsExpansionCapacityLabel = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  fontWeight: 500,
  fontSize: 12,
  marginTop: 25,
  marginBottom: 6,
  fontFamily: 'Montserrat'
}));

export const MuiRecommendationsExpansionCostLabel = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  fontWeight: 500,
  fontSize: 12,
  marginBottom: 6,
  marginTop: 15,
  fontFamily: 'Montserrat'
}));

export const MuiVariableLabel = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  margin: 'auto',
  marginLeft: 4,
  wordWrap: 'break-word',
  overflow: 'hidden',
  maxWidth: 60,
  fontFamily: 'Montserrat'
}));

export const MuiVariableNameCommon = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  margin: 'auto',
  marginLeft: 4,
  fontFamily: 'Montserrat'
}));

export const MuiSwitchTypography = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  margin: 'auto',
  marginLeft: 10,
  marginRight: 11,
  fontFamily: 'Montserrat'
}));

export const MuiSwitchLabel = styled(Typography)(({ theme }) => ({
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  margin: 'auto',
  fontFamily: 'Montserrat'
}));

export const marginStyle = {
  margin: 'auto',
};

export const customSwitchLabelStyle = {
  marginLeft: '0px !important',
  marginRight: '10px !important',
  '& .MuiTypography-root': {
    fontFamily: 'Montserrat !important',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19.5px',
    letterSpacing: '0.5px',
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700]
  },
  '& .MuiFormControlLabel-root': {
    marginLeft: '0px !important',
  }
};

export const customSwitchLabel= {
  marginLeft: '0px !important',
  marginRight: '10px !important',
  '& .MuiTypography-root': {
    fontFamily: 'Montserrat !important',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19.5px',
    letterSpacing: '0.5px',
    color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700]
  },
  '& .MuiFormControlLabel-root': {
    marginLeft: '0px !important',
  }
};
