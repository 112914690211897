/**
 * Setup the global elements of the react app:
 *  - redux store
 *  - react router
 *  - main component: App
 */
import 'react-app-polyfill/stable';
import './config/firebase';
import React from 'react';
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createCartoSlice, createOauthCartoSlice } from 'lib/react-geo-tool/packages/react-redux/src/';

import { initialState, oauthInitialState } from 'store/initialStateSlice';

import App from './App';
import configureAppStore from './store/store';

const store = configureAppStore();
const container = document.getElementById('root');
const root = createRoot(container);

store.reducerManager.add('carto', createCartoSlice(initialState));
store.reducerManager.add('oauth', createOauthCartoSlice(oauthInitialState));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
