import {
    Grid,
} from '@material-ui/core';
import { useTranslation } from 'components/providers/TranslationProvider';
import {
  TextField as TextFieldCustom,
} from 'components/common/ReactHooksFormFields';

import {
  MuiGrid,
} from './styles/recommendations';

const RecommendationsVisualization = ( props ) => {
    const { t } = useTranslation();

    return (
      <MuiGrid item xs={12}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={6}>
            <TextFieldCustom
              name='coverageRadiusLabel'
              type='text'
              margin='dense'
              label={t('coverage_radius')}
              fullWidth
              variant='outlined'
              color='primary'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldCustom
              name='costLabel'
              type='text'
              margin='dense'
              label={t('cost')}
              fullWidth
              variant='outlined'
              color='primary'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldCustom
              name='recommendationsOfferCapacityLabel'
              type='text'
              margin='dense'
              label={t('recommendations_offer_capacity')}
              fullWidth
              variant='outlined'
              color='primary'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldCustom
              name='recommendationsBudgetLabel'
              type='text'
              margin='dense'
              label={t('recommendations_budget')}
              fullWidth
              variant='outlined'
              color='primary'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldCustom
              name='recommendationsExpansionCapacityLabel'
              type='text'
              margin='dense'
              label={t('recommendations_expansion_capacity')}
              fullWidth
              variant='outlined'
              color='primary'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldCustom
              name='recommendationsExpansionCostLabel'
              type='text'
              margin='dense'
              label={t('recommendations_expansion_cost')}
              fullWidth
              variant='outlined'
              color='primary'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldCustom
              name='recommendationsDemandDensityLabel'
              type='text'
              margin='dense'
              label={t('recommendations_demand_density')}
              fullWidth
              variant='outlined'
              color='primary'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldCustom
              name='variableWelfareLabel'
              type='text'
              margin='dense'
              label={t('variable_welfare')}
              fullWidth
              variant='outlined'
              color='primary'
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldCustom
              name='recommendationsFormulaPriorizationVariableLabel'
              type='text'
              margin='dense'
              label={t('recommendations_formula_priorization_variable')}
              fullWidth
              variant='outlined'
              color='primary'
            />
          </Grid>
        </Grid>
      </MuiGrid>
    );
};

export default RecommendationsVisualization;