import { Grid, Link, styled } from '@mui/material';
import { generateDynamicShades } from '../../../../utils/colors';

export const MuiOptionsSelectedBar = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),

  '& .MuiTypography-caption': {
    color: theme.palette.text.secondary,
  },

  '& .MuiButton-label': {
    ...theme.typography.caption,
  },
}));

export const MuiLink = styled(Link)(({ theme }) => ({
  fontSize: 12,
  letterSpacing: '0.4px',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 600,
  cursor: 'pointer',
  color: generateDynamicShades( theme.palette.primary.main )[700],
}));
