import { Grid, Button, styled } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { lighten } from '@mui/material/styles';
import CheckCircleOutlineRoundedIcon
  from '@mui/icons-material/CheckCircleOutlineRounded';
import {Refresh} from "@material-ui/icons";
import {GLOBAL} from "../../../../../../../../utils/constants";

export const MuiCasesContainer = styled('div')(({ theme }) => ({
  display:'flex',
  border:'1px dashed #E0E0E0',
  padding:8,
  width:'930px'
}));

export const MuiAddCase = styled(Button)(({ theme }) => ({
  padding:'10px 24px 10px 16px',
  borderRadius: '20px',
  color:'#6793A5',
  borderColor:lighten(theme.palette.primary.main, 0.1),
  height:'38px',
  marginRight:20,
  marginTop: '-10px',
  width:'253px',
  '&:hover': {
    borderColor:lighten(theme.palette.primary.main, 0.1),
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
}));

export const MuiAddDefaultCase = styled(Button)(({ theme }) => ({
  padding:'10px 24px 10px 16px',
  borderRadius: '20px',
  color:'#6793A5',
  borderColor:'#6793A5',
  height:'38px',
  width:'339px',
  '&:hover': {
    borderColor:'#6793A5',
  }
}));

export const MuiGridButtonPanel = styled(Grid)(({ theme }) => ({
  borderTop:'2px solid #E0E0E0',
  marginLeft:'-5px',
  height:'180px',
  maxWidth:'none',
  display:'flex',
}));

export const MuiCaseItems = styled(Grid)(({ theme }) => ({
  width: '100%',
  marginBottom:16
}));

export const MuiGridAdd = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom:'10px',
}));

export const MuiContainer = styled(Grid)(({ theme }) => ({
  height:'180px',
  maxHeight:'180px',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginBottom: '5px',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  width: '100%'
}));

export const MuiCheckCircleIcon = styled(CheckCircleOutlineRoundedIcon)(({ theme, disabled }) => ({
  marginTop:15,
  marginLeft:8,
  width: '34px !important',
  height: '34px !important',
  fontSize: '24px !important',
  padding: 5,
  borderRadius:'50%',
  border: `1px solid ${lighten(theme.palette.primary.main, 0.6)}`,
  align: 'center',
  cursor: disabled ? ' default !important' : 'pointer !important',
  '&:hover': {
    backgroundColor: disabled ? 'transparent' : lighten(theme.palette.primary.main, 0.7),
  },
  backgroundColor: disabled ? lighten(theme.palette.primary.main, 0.9) : 'transparent',
  color: disabled ? lighten(theme.palette.primary.main, 0.5) : theme.palette.primary.main,
}));
export const MuiRefreshIcon = styled(Refresh)(({ theme }) => ({
  marginTop:15,
  marginLeft:8,
  width: '34px !important',
  height: '34px !important',
  fontSize: '24px !important',
  padding: 5,
  borderRadius:'50%',
  border: `1px solid ${lighten(theme.palette.primary.main, 0.6)}`,
  align: 'center',
  cursor:'pointer',
  backgroundColor: 'transparent',
  color:theme.palette.primary.main,
  animation: 'rotate 2s linear infinite',
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

export const MuiDeleteIcon = styled(DeleteOutlinedIcon)(({ theme }) => ({
  color: '#F63B2B',
  marginTop:15,
  marginLeft:8,
  minWidth: 25,
  width: '34px !important',
  height: '34px !important',
  fontSize: '24px !important',
  padding: 5,
  borderRadius:'50%',
  border: `1px solid ${lighten('#F63B2B', 0.6)}`,
  align: 'center',
  cursor:'pointer',
  '&:hover': {
    backgroundColor: lighten('#F63B2B', 0.7),
  },
  backgroundColor: 'transparent'
}));

export const containerStyle = {
  width:'930px'
}

export const caseContainerStyle = {
  marginRight:8
}

export const validateCaseStyle = {
  marginTop:0,
  color:'#6793A5',
  border:'none'
}
