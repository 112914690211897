import { ToggleButtonGroup, styled, ToggleButton } from '@mui/material';
import { generateDynamicShades } from '../../../utils/colors';
import { GLOBAL } from '../../../utils/constants';
import { lighten } from '@mui/material/styles';

export const MuiToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  height: '32px',
  width: '110px',
  display:'flex',
  justifyContent:'space-between',
  borderRadius: 16,
  padding: '4px 8px 4px 6px',
  border: `1px solid ${generateDynamicShades( theme.palette.primary.main )[500]}`,
  backgroundColor: `${generateDynamicShades( theme.palette.primary.main )[50]}`,
  '& .MuiToggleButtonGroup-grouped':{
    height: 24,
    borderRadius: 16,
    padding: '4px 8px 4px 8px',
    backgroundColor: `${generateDynamicShades( theme.palette.primary.main )[50]}`,
  },
  '& .Mui-selected':{
    padding: '4px 8px 4px 8px',
    borderRadius: '12px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: 'auto',
  height: '24px',
  borderRadius: '12px',
  border: 'none',
  color: `${theme.palette.primary.main} !important`,
  '& .Mui-selected':{
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white !important",
  },
}));

export const groupedHorizontalStyle = {
  borderRadius: '16px !important'
};
