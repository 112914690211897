import React from 'react';

const SettingsIconHover = ({ color = 'white', size = 30 }) => (
<svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill={color} fillOpacity="0.2"/>
<rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke={color}/>
<g clipPath="url(#clip0_921_18342)">
<path d="M20.3551 15.705C20.3851 15.48 20.4001 15.2475 20.4001 15C20.4001 14.76 20.3851 14.52 20.3476 14.295L21.8701 13.11C22.0051 13.005 22.0426 12.8025 21.9601 12.6525L20.5201 10.1625C20.4301 9.99755 20.2426 9.94505 20.0776 9.99755L18.2851 10.7175C17.9101 10.4325 17.5126 10.1925 17.0701 10.0125L16.8001 8.10755C16.7701 7.92755 16.6201 7.80005 16.4401 7.80005H13.5601C13.3801 7.80005 13.2376 7.92755 13.2076 8.10755L12.9376 10.0125C12.4951 10.1925 12.0901 10.44 11.7226 10.7175L9.93008 9.99755C9.76508 9.93755 9.57758 9.99755 9.48758 10.1625L8.05508 12.6525C7.96508 12.81 7.99508 13.005 8.14508 13.11L9.66758 14.295C9.63008 14.52 9.60008 14.7675 9.60008 15C9.60008 15.2325 9.61508 15.48 9.65258 15.705L8.13008 16.89C7.99508 16.995 7.95758 17.1975 8.04008 17.3475L9.48008 19.8375C9.57008 20.0026 9.75758 20.055 9.92258 20.0025L11.7151 19.2825C12.0901 19.5675 12.4876 19.8075 12.9301 19.9875L13.2001 21.8925C13.2376 22.0725 13.3801 22.2 13.5601 22.2H16.4401C16.6201 22.2 16.7701 22.0725 16.7926 21.8925L17.0626 19.9875C17.5051 19.8075 17.9101 19.5675 18.2776 19.2825L20.0701 20.0025C20.2351 20.0625 20.4226 20.0026 20.5126 19.8375L21.9526 17.3475C22.0426 17.1825 22.0051 16.995 21.8626 16.89L20.3551 15.705ZM15.0001 17.7C13.5151 17.7 12.3001 16.485 12.3001 15C12.3001 13.515 13.5151 12.3 15.0001 12.3C16.4851 12.3 17.7001 13.515 17.7001 15C17.7001 16.485 16.4851 17.7 15.0001 17.7Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_921_18342">
<rect width="18" height="18" fill={color} transform="translate(6 6)"/>
</clipPath>
</defs>
</svg>
);

export default SettingsIconHover;
