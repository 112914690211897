import { Deck } from '@deck.gl/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {setOnClick} from '../../../../../../store/appSlice';

export function BingMap(props) {
  const {
    basemap,
    viewState,
    layers,
    onResize,
    onViewStateChange,
    apiKey,
    onClick,
    style,
    width = '100%',
    height = '100%',
  } = props;

  const dispatch = useDispatch();
  const containerRef = useRef();
  const deckMap = useRef();
  const bingMap = useRef();
  const [isMapScriptLoaded, setMapScriptLoaded] = useState(false);
  const isMapInteraction = useRef(false);

  const containerStyle = {
    position: 'absolute',
    zIndex: 0,
    left: 0,
    top: 0,
    width,
    height,
    ...style,
  };

  const loadMap = () => {
    let options = {
      center: new window.Microsoft.Maps.Location(
            viewState.latitude,
            viewState.longitude
          ),
      zoom: viewState.zoom,
      mapTypeId: window.Microsoft.Maps.MapTypeId[basemap.options.mapTypeId],
      showDashboard: false,
      credentials: apiKey,
    };
    if (!window.Microsoft || !containerRef.current) {
      return;
    }

    bingMap.current = new window.Microsoft.Maps.Map(containerRef.current, options)

    window.Microsoft.Maps.Events.addHandler( bingMap.current, 'viewchange', () => {
      isMapInteraction.current = true;
      updateViewMapDeck()
    });

    window.Microsoft.Maps.Events.addHandler(bingMap.current, 'click', function(e) {
      onClick({
        latitude: e.location.latitude,
        longitude: e.location.longitude
      })
      if (!deckMap.current) {
        return;
      }  
      const info = deckMap.current.pickObject(e.point);  
      if (info && info.object) {
        info.layer.props.onClick(info)
      }
    });
    
    if (!deckMap.current) {
      deckMap.current = new Deck({
        canvas: createDeckGLCanvas(containerRef.current),
        initialViewState: {...viewState, zoom: viewState.zoom-1},
        controller: false,
        layers: layers,
      });}else{
        deckMap.current.setProps({
        viewState: {latitude: viewState.latitude, longitude: viewState.longitude, zoom: viewState.zoom -1}, layers:layers});
    
      }
  };

  const updateViewMapDeck = () => {  
      const center =  bingMap.current.getCenter();
      const zoom = bingMap.current.getZoom();      
      const newViewState = {
        longitude: center.longitude,
        latitude: center.latitude,
        zoom: zoom,
      };
      if (isMapInteraction.current){
        bingMap.bingViewState = newViewState 
        onViewStateChange && onViewStateChange({ viewState: newViewState });        
  
        if (deckMap.current) {
          deckMap.current.setProps({
            viewState: {latitude: center.latitude, longitude: center.longitude, zoom: zoom -1}, layers});
        }
      }      
    };

  // Crear canvas para Deck.gl
  function createDeckGLCanvas(container) {
    const canvas = document.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.top = container.style.top;
    canvas.style.left = container.style.left;
    canvas.style.width = container.offsetWidth + 'px';
    canvas.style.height = container.offsetHeight + 'px';
    canvas.style.zIndex = container.zIndex + 1;
    canvas.style.pointerEvents = 'none';
    canvas.id = 'deckBing'
    container.appendChild(canvas);
  
    return canvas;
  }


  useEffect(() => {
    window.loadBingMaps = function() {
      setMapScriptLoaded(true);
    }
    if (!document.querySelector('#bingmaps')  && !isMapScriptLoaded){
      const script = document.createElement(`script`);
      script.id = 'bingmaps';
      script.src = `https://www.bing.com/api/maps/mapcontrol?callback=loadBingMaps&key=${apiKey}`;
      // script.async = true;
      // script.defer = true;
      document.body.appendChild(script);
    }else if(document.querySelector('#bingmaps')  && window.Microsoft) {
        loadMap();    
    }
    
    return () => {
      window.loadBingMaps = null;
    };
  }, [apiKey, isMapScriptLoaded]);


  useEffect(() => {
    if (isMapInteraction.current){  
      isMapInteraction.current = false
  }else if (bingMap.current && isMapScriptLoaded && JSON.stringify(bingMap.bingViewState) !== JSON.stringify(viewState)) {
      bingMap.current.setView({
        center: new window.Microsoft.Maps.Location(
          viewState.latitude,
          viewState.longitude
        ),
        zoom: viewState.zoom,
      });
      bingMap.bingViewState = { ...viewState };
      if (deckMap.current) {
        deckMap.current.setProps({
          viewState: {
            longitude:  viewState.longitude ,
            latitude: viewState.latitude,
            zoom: viewState.zoom-1,
          },
          layers: layers});
      }
    }
  }, [viewState]);

  useEffect(() => {
      if (deckMap.current) {
        deckMap.current.setProps({layers});
      }
  }, [layers]);
  
  if(deckMap.current){
    deckMap.current.setProps({layers: layers });
  }

  useEffect(() => {
    return () => {
      if (deckMap.current) {
        deckMap.current.finalize();
        deckMap.current = null;
      }
      bingMap.current = null ;
      containerRef.current = null;
      const bingScript = document.getElementById('bingmaps');
      const deckOverlay = document.getElementById('deckBing');
      if (bingScript) {
        bingScript.remove();
      }
      if (deckOverlay) {
        deckOverlay.remove()
      }
    };
  }, []);

  return <div ref={containerRef} style={containerStyle}></div>;
}