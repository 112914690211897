import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useTranslation } from 'components/providers/TranslationProvider';
import {useDispatch} from 'react-redux';

const fetchSchemes = (id) =>
  axios.post(apiPaths.schemes, { user_id: id }, status200).then((resp) => resp.data);

const useSchemes = (id) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery(['schemes', id], () => fetchSchemes(id), {
    enabled: Boolean(id),
    onError: (error) => {
      dispatch(setOpenToast(true));
      dispatch(setMessageToast(t(error.data.error)));
      return error;
    },
  });
}

export default useSchemes;
