export const messageSeverity = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
}

export const processJobStatus = {
  INPROGRESS: 'in_progress',
  SUCCESS: 'success',
  FAILED: 'failed',
  WAITING: 'waiting',
}

export const providerCodes = {
  GOOGLE: 'google',
  MICROSOFT: 'microsoft',
}

export const GLOBAL = {
  POSITION: {
    CENTER: 'center',
    FLEX: 'flex',
    SPACEAROUND: 'space-around',
    TOP: 'top',
    RIGHT: 'right',
    LEFT: 'left',
    BOTTOM: 'bottom',
    DENSE: 'dense',
    NONE: 'none',
    SOLID: 'solid',
    POINTER: 'pointer',
    SCALEDOWN: 'scale-down',
    COLUMN: 'column',
    ROW: 'row',
    _2PX: '2px',
    _10PX: '10px',
    _25PX: '25px',
    _30PX: '30px',
    OUTLINESELECTORGRADIENT: '1px ridge rgba(200, 200, 200, 1)',
    OUTLINEHOVERPRIMARY: `2px ridge ${localStorage.getItem('colorPrimary')}`,
    OUTLINEHOVERSECONDARY: `2px ridge ${localStorage.getItem('colorSecondary')}`
  },
  TYPE: {
    TEXT: 'text',
    NUMBER: 'number',
    COLOR: 'color',
  },
  VARIANTS: {
    BODY1: 'body1',
    OUTLINED: 'outlined',
    TEXT: 'text',
  },
  COLOR: {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    INITIAL: 'initial',
    INHERIT: 'inherit',
    WHITE: '#fff',
    BLACK: 'black',
    BLUE: 'blue',
    GREEN: 'green',
    YELLOW: 'yellow',
    RED: 'red',
  },
  TYPOGRAPHY: {
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
  },
  ARIALABEL: {
    INCREASEZOOM: 'Increase zoom',
    DECREASEZOOM: 'Decrease zoom',
    MENU: 'menu',
    BREADCRUMB: 'breadcrumb',
  },
  ALT: {
    LOGO: 'logo',
  },
  TITLE: {
    MAPINFO: 'Map info',
  },
  ROLES: {
    LEVEL0: 'level0',
    LEVEL1: 'level1',
    LEVEL2: 'level2',
    LEVEL3: 'level3',
    ADMIN: 'admin',
    SUPERADMIN: 'super_admin',
  },
  CODECONSTANTS: {
    SIMPLEPOPOVER: 'simple-popover',
  },
  CUSTOM_PRIMARY_COLOR_PALETTE: {
    50: '#E0F7FA',
    100: '#B2EBF2',
    200: '#80DEEA',
    300: '#4DCFE1',
    400: '#26C5DA',
    500: '#00BBD4',
    600: '#00ABC1',
    700: '#0096A7',
    800: '#00828F',
    900: '#005F64',
  },
  CUSTOM_SECONDARY_COLOR_PALETTE: {
    50: '#FBFAE6',
    100: '#F5F1C1',
    200: '#EFE898',
    300: '#E9DF71',
    400: '#E5DA55',
    500: '#E1D33A',
    600: '#DFC235',
    700: '#DCAC2D',
    800: '#D89525',
    900: '#D26F12',
  },
  CUSTOM_GRAY_BLUE_COLOR_PALETTE: {
    50: '#DFF2FC',
    100: '#C0DBE5',
    200: '#A1C1CF',
    300: '#80A7B7',
    400: '#6793A5',
    500: '#4D8094',
    600: '#417183',
    700: '#325D6C',
    800: '#254957',
    900: '#143440',
  },
  CUSTOM_GRAY_COLOR_PALETTE: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  CUSTOM_ERROR_COLOR_PALETTE: {
    50: '#FFECEE',
    100: '#FFCFD2',
    200: '#FA9C98',
    300: '#F47570',
    400: '#FF5549',
    500: '#FF4429',
    600: '#F63B2B',
    700: '#E42F25',
    800: '#D7271D',
    900: '#C71A0F',
  },
  FONT_WEIGHTS: {
    THIN: 100,
    ULTRA_LIGHT: 200,
    LIGHT: 300,
    REGULAR: 400,
    MEDIUM: 500,
    SEMI_BOLD: 600,
    BOLD: 700,
    ULTRA_BOLD: 800,
    HEAVY: 900
  },
  COLOR_THEME: {
    PRIMARY: localStorage.getItem('colorPrimary') || '#00BBD4',
    SECONDARY: localStorage.getItem('colorSecondary'),
  }
}

export const widgetType = {
  RECOMMENDATIONS: 'recommendations',
  POLYGON: 'polygon',
  CATEGORY: 'category',
  HISTOGRAM: 'histogram',
  LIST: 'list',
  STATISTICS: 'statistics',
  PARAMETERS: 'parameters'
}

export const notificationsType = {
  NONE: 'none',
  REFERENCE_POINT: 'referenece_point',
}
export const languageMap = {
  EN: 'english',
  ES: 'spanish',
  PR: 'portuguese',
  FR: 'french'
};
