import { Box, styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../../../utils/constants';

export const MuiTypographyHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  color: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[800],
  marginBottom: 10,
}));

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  margin: 3
}));
