import { Grid, styled } from '@mui/material';
import { SwitchField } from '../../../../../common/ReactHooksFormFields';

export const MuiGridField = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  padding: theme.spacing(1, 2),
}));

export const MuiSwitchField = styled(SwitchField)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: theme.palette.primary.dark,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  '& .MuiSwitch-track': {},
}));
