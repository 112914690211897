import { IconButton, styled } from '@mui/material';
import { GLOBAL } from '../../../../../../../utils/constants';


export const MuiDivRoot = styled('div')(({ theme }) => ({
  marginLeft: '-70px',
  transform: 'scale(0.7)',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  borderRadius: theme.spacing(0.5),
  position: 'fixed',
  zIndex: '100',
  marginTop: '10px',
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  right: '35px',
  zIndex:'1000',
  background: GLOBAL.COLOR_THEME.PRIMARY,
  border: 'solid 1px gray',
  height: '30px',
  width:'30px',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: GLOBAL.COLOR_THEME.PRIMARY,
    color: theme.palette.common.white,
  },
}));

export const MuiIconButtonSearch = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  zIndex: '100',
  left: '10px',
  marginTop: '10px',
  backgroundColor: '#8b878763',
  height: '45px',
  width:'45px',
  borderRadius: '10px !important'
}));

export const imgContainerStyle = {
  textAlign: 'right',
  padding: '0.25rem 0.5rem',
};

export const imgGoogleMapsStyle = {
  width: 'auto',
  height: '20px'
};

export const searchInputStyle = {
  width: '500px',
  maxWidth: '100%',
  height: '45px',
  outline: 'none',
  fontSize: '20px',
  border: '1px solid lightgray',
  boxShadow: '0px 0px 5px 2px lightgrey',
  paddingLeft: '5px',
  color: 'rgb(81 80 80)'
};
