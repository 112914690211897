import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  GOOGLE_HYBRID,
  GOOGLE_SATELLITE,
  GOOGLE_ROADMAP,
  GOOGLE_CUSTOM, BING_AERIAL , GOOGLE_CUSTOM_2
} from 'lib/react-geo-tool/packages/react-basemaps/src';

import { useTranslation } from 'components/providers/TranslationProvider';
import { TextField, SelectField } from 'components/common/ReactHooksFormFields';
import useUpdateScheme from 'api/hooks/useUpdateScheme';
import useCreateScheme from 'api/hooks/useCreateScheme';
import Toast from 'components/common/Toast';
import {
  setLayers,
  setAllTabs,
  setWidgets,
  setPrivateLoading,
  setRichDescription,
  setScenarioVisibility,
  setAllowUpgradeOperator,
  setShowActionCatalog,
  setShowDownloadLayer,
} from 'store/appSlice';
import { useLayers } from 'components/providers/LayersProvider';
import useRandomId from 'components/hooks/useRandomId';

import SkeletonSpecification from './Skeleton';
import Actions from './Actions';
import DeleteLayer from './layer/DeleteLayer';
import {
  descriptionLabelStyle,
  iconButtonStyle, labelSelectStyle,
  MuiGridContainer,
  MuiGridField,
  MuiSubTitle,
  MuiTextAreaField,
  MuiTitle
} from './styles/descriptiveMap';
import AddOrUpdateLayer from './layer/AddOrUpdateLayer';
import AddOrUpdateWidget from './widget/form/AddOrUpdateWidget';
import TabsInternals from './TabsInternals';
import AddOrUpdateTooltip from './tooltip/AddOrUpdateTooltip';
import AddOrUpdateTabs from './widget/group/form/AddOrUpdateTabs';
import DeleteWidget from './widget/form/DeleteWidget';
import DeleteTooltip from './tooltip/DeleteTooltip';
import DeleteTab from './widget/group/DeleteTab';
import UploadDownloadJson from './UploadDownloadJson';
import { IconButton, InputAdornment, Grid, MenuItem} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  inputSelectStyle,
  inputStyle,
  labelInputStyle,
} from './widget/form/styles/widgetForm';

const schema = yup.object().shape({
  name: yup.string().min(4, 'min_4_characters').max(80, 'max_80_characters').required('required'),
  baseMap: yup.string().required('required'),
});

const baseMaps = [
  {
    name: 'Prosperia Light',
    baseMap: GOOGLE_CUSTOM,
  },
  {
    name: 'Prosperia Clear',
    baseMap: GOOGLE_CUSTOM_2,
  },
  {
    name: 'Google Road',
    baseMap: GOOGLE_ROADMAP,
  },
  {
    name: 'Google Hybrid',
    baseMap: GOOGLE_HYBRID,
  },
  {
    name: 'Google Satellite',
    baseMap: GOOGLE_SATELLITE,
  },
  {
    name: 'Bing Aerial',
    baseMap: BING_AERIAL,
  }
];

const DescriptiveMap = ({ type, isCreate, scenario, id, schemeId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [randomId, ] = React.useState(useRandomId());

  const { removeLayers } = useLayers();

  const layers = useSelector((state) => state.app.layers);
  const widgets = useSelector((state) => state.app.widgets);
  const tabs = useSelector((state) => state.app.tabs);
  const isValidDescriptiveScenario = useSelector((state) => state.app.isValidDescriptiveScenario);
  const richDescription = useSelector((state) => state.app.richDescription);
  const oldRichDescription = scenario?.data?.scheme?.richDescription || '';

  const storedScenarioVisibility = useSelector((state) => state.app.scenarioVisibility);
  const storedShowActionCatalog = useSelector((state) => state.app.showActionCatalog);
  const storedShowDownloadLayer = useSelector((state) => state.app.showDownloadLayer);
  const storedAllowUpgradeOperator = useSelector((state) => state.app.allowUpgradeOperator);
  const storedShowTooltipOnClick = useSelector((state) => state.app.isTooltipOnClick);

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [actionSave, setActionSave] = React.useState(null);
  const [actionSaveAndView, setActionSaveAndView] = React.useState(null);
  const [createId, setCreateId] = React.useState('');

  const {
    mutateAsync: updateScheme,
    isError: updateIsError,
    isSuccess: updateIsSuccess,
    error: updateError,
    isLoading: updateIsLoading,
  } = useUpdateScheme();
  const {
    mutateAsync: createScheme,
    isError: createIsError,
    isSuccess: createIsSuccess,
    error: createError,
    isLoading: createIsLoading,
  } = useCreateScheme();

  const getDefaultValues = () => {
    return location.state
      ? {
          name: location.state.name,
          baseMap: location.state.baseMap,
          richDescription: location.state.richDescription,
        }
      : {
          baseMap: baseMaps[0].baseMap,
          richDescription: '',
        };
  };
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });
  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = methods;

  const updScheme = (data) => {
    const schemeToUpdate = {
      scheme: {
        id: schemeId,
        name: data.name,
        baseMap: data.baseMap,
        scenarioVisibility: storedScenarioVisibility,
        upgradeOperator: storedAllowUpgradeOperator,
        showActionCatalog: storedShowActionCatalog,
        showDownloadLayer: storedShowDownloadLayer,
        richDescription,
        layers,
        widgets,
        tabs,
        type,
        showTooltipOnClick: storedShowTooltipOnClick,
      },
      userId: id,
    };
    updateScheme(schemeToUpdate);
  };

  const newScheme = (data) => {
    const schemeToCreate = {
      scheme: {
        id: randomId,
        name: data.name,
        baseMap: data.baseMap,
        scenarioVisibility: storedScenarioVisibility,
        upgradeOperator: storedAllowUpgradeOperator,
        showActionCatalog: storedShowActionCatalog,
        showDownloadLayer: storedShowDownloadLayer,
        layers,
        tabs,
        widgets,
        type,
        showTooltipOnClick: storedShowTooltipOnClick,
        richDescription,
      },
      userId: id,
    };
    createScheme(schemeToCreate);
    setCreateId(randomId);
  };

  const onSubmit = handleSubmit((data) => (isCreate ? newScheme(data) : updScheme(data)));

  const handleSave = () => {
    dispatch(setPrivateLoading(true));
    onSubmit();
    setActionSaveAndView(false);
    setActionSave(true);
  };

  const handleSaveAndView = () => {
    dispatch(setPrivateLoading(true));
    if (isDirty || isValidDescriptiveScenario || richDescription !== oldRichDescription) {
      onSubmit();
    } else {
      navigate(`${location.pathname.split('/').splice(0, 4).join('/')}/view/custom/${schemeId}`);
    }
    setActionSaveAndView(true);
    setActionSave(false);
  };

  const handleCloseToast = () => setOpenToast(false);

  const resetStoredItems = () => {
    dispatch(setScenarioVisibility(false));
    dispatch(setAllowUpgradeOperator(false));
    dispatch(setShowActionCatalog(false));
    dispatch(setShowDownloadLayer(false));
    dispatch(setRichDescription(''));
  }

  React.useEffect(() => {
    if (actionSaveAndView && (updateIsSuccess || createIsSuccess)) {
      dispatch(setPrivateLoading(false));
      if (isCreate)
        navigate(`${location.pathname.split('/').splice(0, 4).join('/')}/view/custom/${createId}`);
      else
        navigate(`${location.pathname.split('/').splice(0, 4).join('/')}/view/custom/${schemeId}`);
    }

    if (actionSave && (updateIsSuccess || createIsSuccess))
      navigate(location.pathname.split('/').splice(0, 4).join('/'));

    if (updateIsSuccess || createIsSuccess) scenario.remove();
    // eslint-disable-next-line
  }, [updateIsSuccess, createIsSuccess, actionSaveAndView, actionSave]);

  React.useEffect(() => {
    if (updateIsError) {
      dispatch(setPrivateLoading(false));
      setError(updateError.data?.error ? t(updateError.data?.error) : JSON.stringify(updateError));
      setOpenToast(true);
    }
  }, [updateIsError, updateError, t]);

  React.useEffect(() => {
    if (createIsError) {
      dispatch(setPrivateLoading(false));
      setError(createError.data?.error ? t(createError.data.error) : JSON.stringify(createIsError));
      setOpenToast(true);
    }
  }, [createIsError, createError, t]);

  React.useEffect(() => {
    if (scenario.isSuccess && !isCreate) {
      dispatch(setPrivateLoading(false));
      dispatch(setLayers(scenario.data.scheme.layers));
      dispatch(setAllTabs(scenario.data.scheme.tabs || []));
      dispatch(setWidgets(scenario.data.scheme.widgets || []));
      reset({
        name: scenario.data.scheme.name,
        scenarioType: scenario.data.scheme.scenarioType,
        baseMap: scenario.data.scheme.baseMap,
        showTooltipOnClick: scenario.data.scheme.isTooltipOnClick,
      });
      dispatch(setRichDescription(scenario.data.scheme.richDescription || ''));

      dispatch(setScenarioVisibility(scenario.data.scheme.scenarioVisibility));
      dispatch(setAllowUpgradeOperator(scenario.data.scheme.upgradeOperator));
      dispatch(setShowActionCatalog(scenario.data.scheme.showActionCatalog));
      dispatch(setShowDownloadLayer(scenario.data.scheme.showDownloadLayer));

    }
  }, [scenario.isSuccess, isCreate, scenario.data, dispatch, reset]);

  React.useEffect(() => {
    if (isCreate) scenario.remove();
    else scenario.refetch();
    return () => {
      removeLayers();
      scenario.remove();
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (isCreate) {
      resetStoredItems();
    }
  }, [isCreate]);

  return (
    <Grid container>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      {!isCreate && scenario.isLoading ? (
        <SkeletonSpecification />
      ) : (
        <FormProvider {...methods}>
          <Grid container>
            <Grid container item xs={8} display='flex' direction='column' style={{ width: 868 }}>
              <MuiTitle variant='h6' sx={{ marginBottom: '36px' }}>
                {t('setting')}
              </MuiTitle>
              <MuiSubTitle variant='h6' sx={{ marginBottom: '32px' }}>
                {t('initial_settings')}
              </MuiSubTitle>
              <form>
                <Grid container style={{ width: '100%' }}>
                  <MuiGridContainer item xs={12}>
                    <MuiGridField item xs={6} style={{ paddingRight: 8 }}>
                      <TextField
                        autoFocus
                        name='name'
                        margin='dense'
                        type='text'
                        label={t('scenario_name')}
                        variant='outlined'
                        InputLabelProps={{ style: labelInputStyle }}
                        sx={inputStyle}
                        fullWidth
                        error={Boolean(t(errors.name?.message))}
                      />
                    </MuiGridField>
                    <MuiGridField
                      item
                      xs={6}
                      style={{ paddingLeft: 8, paddingBottom:'4px' }}
                    >
                      <SelectField
                        style={{ paddingBottom: '0px !important' }}
                        name='baseMap'
                        error={t(errors.BaseMap?.message)}
                        variant='outlined'
                        label={t('select_base_map')}
                        fullWidth
                        InputLabelProps={{ style: labelSelectStyle }}
                        sx={inputSelectStyle}
                      >
                        {baseMaps.map((b) => (
                          <MenuItem key={b.name} value={b.baseMap}>
                            {b.name}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </MuiGridField>
                  </MuiGridContainer>
                  <MuiSubTitle variant='h6' sx={{ marginBottom: '24px', marginTop: '32px' }}>
                    {t('map_setting')}
                  </MuiSubTitle>
                  <TabsInternals
                    id={id}
                    schemeId={schemeId}
                    mapId={randomId}
                    watchScenarioVisibility={storedScenarioVisibility}
                    watchShowTooltipOnClick={storedShowTooltipOnClick}
                    richDescription={richDescription}
                    scenarioVisibility={storedScenarioVisibility}
                    showActionCatalog={storedShowActionCatalog}
                    showDownloadLayer={storedShowDownloadLayer}
                    allowUpgradeOperator={storedAllowUpgradeOperator}
                  />
                  <Grid item xs={12} style={{ margin: '115px 0px 0px 0px' }}>
                    <Actions
                      isLoading={createIsLoading || updateIsLoading}
                      isValid={isValid}
                      isDirty={
                        isDirty || isValidDescriptiveScenario || richDescription !== oldRichDescription
                      }
                      handleSave={handleSave}
                      handleSaveAndView={handleSaveAndView}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={4}>
              <UploadDownloadJson randomId={randomId} />
              <Grid container justifyContent='center'>
                <MuiTextAreaField
                  id="outlined-multiline-static"
                  name='richDescription'
                  label='Description'
                  multiline
                  rows={4}
                  maxRows={4}
                  variant='outlined'
                  onChange={(e) => dispatch(setRichDescription(e.target.value))}
                  defaultValue={richDescription}
                  InputLabelProps={{
                    shrink: true,
                    style: labelInputStyle
                  }}
                  InputProps={{
                    classes: { root: descriptionLabelStyle },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" size='small'
                                    aria-label="edit"
                                    sx={iconButtonStyle}>
                          <EditIcon fontSize="small"/>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      )}
      <AddOrUpdateLayer />
      <AddOrUpdateWidget />
      <AddOrUpdateTabs />
      <AddOrUpdateTooltip />
      <DeleteLayer />
      <DeleteWidget />
      <DeleteTooltip />
      <DeleteTab />
    </Grid>
  );
};

export default DescriptiveMap;
