import { Container, Typography,  Grid, Button, styled } from '@mui/material';

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
  marginBottom: '7px',
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  justifySelf: 'end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: theme.palette.primary,
  borderColor: theme.palette.primary,
  padding:'5px 12px 5px 12px'
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  maxWidth: '1051px !important',
  minHeight: '715px !important',
  backgroundColor:'#FAFAFA',
  borderRadius:'28px',
  '& .MuiContainer-root': {
    maxWidth: '1360px !important',
  },
}));

export const MuiGridButtonsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(0, 2, 2, 2),
  justifyContent: 'flex-end',
}));

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  paddingLeft:'0px !important',
  paddingTop:'0px !important'
}));

export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize:21,
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:500,
  color:'#212121'
}));

export const MuiCommonTypography = styled(Typography)(({ theme }) => ({
  fontSize:'14px',
  fontFamily:'Montserrat',
  textAlign:'center',
  fontWeight:500,
  color:'#616161'
}));
