import useCartoPiaToken from 'api/hooks/useCartoPiaToken';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';

const CartoPiaAuthContext = createContext();

const CartoPiaAuthProvider = ({ children }) => {
  const { data: cartoPiaToken, status: cartoPiaTokenLoadingStatus, refetch: refetchCartoPiaToken } = useCartoPiaToken();

  const [cartoPiaAuthToken, setCartoPiaAuthToken] = useState(
    localStorage.getItem('cartoPiaAuthToken') || ''
  );

  useEffect(() => {
    const updateCartoPiaAuthTokenInLocalStorage = (token) => {
      localStorage.setItem('cartoPiaAuthToken', token.accessToken);
      localStorage.setItem('cartoPiaAuthTokenDate', token.expirationDate);
    };

    const handleTokenUpdate = (token) => {
      setCartoPiaAuthToken(token.accessToken);
      updateCartoPiaAuthTokenInLocalStorage(token);
    };

    if (cartoPiaTokenLoadingStatus === 'success' && cartoPiaToken) {
      handleTokenUpdate(cartoPiaToken);
    } else if (cartoPiaTokenLoadingStatus === 'error') {
      refetchCartoPiaToken().then(({ data, status }) => {
        if (status === 'success'&&data) {
          handleTokenUpdate(data);
        }
      });
    }
  }, [cartoPiaToken, cartoPiaTokenLoadingStatus, refetchCartoPiaToken]);

  useEffect(() => {
    if (cartoPiaAuthToken) {
      localStorage.setItem('cartoPiaAuthToken', cartoPiaAuthToken);
    }
  }, [cartoPiaAuthToken]);

  const getCartoPiaAuthenticationToken = () => {
    const localToken=localStorage.getItem('cartoPiaAuthToken');
    let expirationDate = localStorage.getItem('cartoPiaAuthTokenDate');
    expirationDate = new Date(expirationDate);

    if(expirationDate< Date.now()) {
      localStorage.removeItem('cartoPiaAuthToken');
      localStorage.removeItem('cartoPiaAuthTokenDate');
      refetchCartoPiaToken();
    }
    if(!cartoPiaAuthToken&&!!localToken){
      setCartoPiaAuthToken(localToken);
      return localToken;
    }
    return cartoPiaAuthToken;
  }

  const value = useMemo(
    () => ({
      getCartoPiaAuthenticationToken,
    }),
    [
      cartoPiaAuthToken,
    ]
  );

return <CartoPiaAuthContext.Provider value={value}>{children}</CartoPiaAuthContext.Provider>;

};

const useAuthCartoPia = () => useContext(CartoPiaAuthContext);

export { CartoPiaAuthContext, useAuthCartoPia };
export default CartoPiaAuthProvider;
