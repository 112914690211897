import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Toast from 'components/common/Toast';
import { useTranslation } from 'components/providers/TranslationProvider';
import { MuiGridLink, MuiIconButton } from './styles/copyToClipBoard';

const CopyToClipBoard = ({ id, schemeId, type }) => {
  const { t } = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);

  const handleCloseToast = () => setOpenToast(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://${process.env.REACT_APP_HOST_URL}/public/${id}/${type}/${schemeId}`
    );
    setOpenToast(true);
  };
  return (
    <MuiGridLink item xs={11} >
      <Toast
        message={t('copied!')}
        handleClose={handleCloseToast}
        severity='success'
        horizontal='center'
        vertical='top'
        open={openToast}
        showTime={2000}
      />
      <Grid item xs={11} style={{ alignSelf: 'center' }}>
        <Typography noWrap>
          {`https://${process.env.REACT_APP_HOST_URL}/public/${id}/${type}/${schemeId}`}
        </Typography>
      </Grid>
      <Grid item xs={1} style={{ alignSelf: 'center' }}>
        <MuiIconButton onClick={handleCopy} >
          <FileCopyIcon />
        </MuiIconButton>
      </Grid>
    </MuiGridLink>
  );
};

export default CopyToClipBoard;
