import { Button, Container, Grid, styled, Typography } from '@mui/material';
import {GLOBAL} from "../../../../utils/constants";

export const MuiContainer = styled(Container)(({ theme }) => ({
  maxWidth: '1051px !important',
  minHeight: '715px !important',
  backgroundColor:'#FAFAFA',
  borderRadius:'28px',
  '& .MuiContainer-root': {
    maxWidth: '1360px !important',
  },
  width: 1051,
}));

export const MuiGridButtonsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(0, 2, 2, 2),
  justifyContent: 'flex-end',
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(2),
  justifyContent: 'flex-end',
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  alignItems: 'left',
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700],
  borderColor: '#BDBDBD',
  padding:'10px 24px 10px 16px',
  height: '32px',
  '&:hover': {
    backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[100],
    borderColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400],
  }
}));

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  paddingLeft:'0px !important',
  paddingTop:'0px !important'
}));

export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize:21,
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:500
}));
