import { useMutation, useQueryClient } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const newUser = async (data) => {
  const { user } = data;
 return axios.post(apiPaths.create_user, user, status200).then((resp) => resp.data).catch( (error) => error );
};

const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => newUser(values), {
    onSuccess: () => {
      queryClient.resetQueries(['users']);
    },
    onError: (error) => {
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};
export default useCreateUser;
