/* eslint-disable camelcase */
import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import {useDispatch} from 'react-redux';

const fetchSchemes = ({ user_id, scheme_id, isAuthenticated }) => {
  if (isAuthenticated){
    return axios.post(apiPaths.scheme, { user_id, scheme_id }, status200).then((resp) => resp.data);
  }
}


const useScheme = (data) => {
  return useQuery(['scheme', data.scheme_id], () => fetchSchemes(data), {
    enabled: data.enabled,
    refetchOnWindowFocus: false,
  });
}


export default useScheme;
