import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setIsValidLayerForm } from 'store/appSlice';

import LayerForm from './form/LayerForm';

const AddOrUpdateLayer = () => {
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState('create');
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  React.useEffect(() => {
    const createQuery = query.get('create');
    const updateQuery = query.get('edit-layer');
    if (createQuery === 'layer') {
      setAction('create');
      setOpen(true);
    }
    if (updateQuery) {
      setAction('update');
      setOpen(true);
    }
  }, [query]);

  const handleClose = (event, reason) => {
    if ( reason === 'backdropClick' || reason === 'escapeKeyDown' ) {
      setOpen( true )
    } else {
      if (action === 'create') query.delete('create');
      if (action === 'update') query.delete('edit-layer');
      navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
      dispatch(setIsValidLayerForm(false));
      setOpen(false);
    }
  };

  return (
    <>
      {open && (
        <LayerForm
          action={action}
          open={open}
          onClose={handleClose}
          layerId={query.get('edit-layer')}
        />
      )}
    </>
  );
};
export default AddOrUpdateLayer;
