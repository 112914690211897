import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { cartoThemeOptions } from 'lib/react-geo-tool/packages/react-ui/src/';

const theme = createTheme(cartoThemeOptions);

export const responsive = {
  breakpoints: cartoThemeOptions.breakpoints.keys,
  disableAlign: false,
  factor: 2,
  variants: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'button',
    'caption',
    'overline',
  ],
};
const themeCarto = responsiveFontSizes(theme, responsive);

export default themeCarto;
