import * as React from 'react';
import { useLocation } from 'react-router-dom';

import useDeleteUser from 'api/hooks/useDeleteUser';
import { useTranslation } from 'components/providers/TranslationProvider';
import ModalDelete from '../common/ModalDelete';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import {useDispatch} from 'react-redux';
import { MuiTypographyContent } from '../common/styles/modalDelete';

const DeleteUser = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const deleteMutation = useDeleteUser();

  React.useEffect(() => {
    const removedUser = deleteMutation?.data?.value;
    if ( removedUser?.removedOrganization ) {
      dispatch(setOpenToast(true));
      let message = t('relocated_user')
        .replace('{{organization_name}}', removedUser.removedOrganization);
      if ( removedUser.secondary_organizations.length > 0 ) {
        message = message.replace('{{organizations_names}}', removedUser.secondary_organizations.join(', '))
        dispatch(setMessageToast( message ));
      }
      else {
        dispatch(setMessageToast( message ));
      }
    }
  }, [ deleteMutation.data ]);

  const handleSubmit = () => {
    const data = location.state;
    deleteMutation.mutateAsync(data);
  };
  const content = (
    <>
      <MuiTypographyContent>
        {t('delete_user_title')}
      </MuiTypographyContent>
      <p style={{textAlign:'center', marginTop:'-10px'}}>
        <strong>{location.state?.email}</strong>
      </p>
    </>
  )

  return (
    <ModalDelete
      deleteMutation={deleteMutation}
      onSubmit={handleSubmit}
      content={content}
      title={t('delete_user')}
      name={location.state?.email}
    />
  );
};

export default DeleteUser;
