import * as React from 'react';
import { CardContent } from '@mui/material';
import {
  linkStyle,
  MuiCard,
  MuiCardActionArea, MuiCustomSkeleton,
  MuiMediaSkeleton
} from './styles/card';

const CardSkeleton = () => {
  return (
    <MuiCard>
      <MuiCardActionArea>
        <div style={linkStyle}>
         <MuiMediaSkeleton variant='rect' height={200} width={219}/>
          <CardContent>
            <MuiCustomSkeleton variant='text' height={40}/>
          </CardContent>
        </div>
      </MuiCardActionArea>
    </MuiCard>
  );
};
export default CardSkeleton;
