import { Container, Grid, styled } from '@mui/material';
import background404 from '../../../assets/img/404.svg';

export const MuiContainer = styled(Container)(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  backgroundImage: `url("${background404}")`,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  height: '100%',
}));
