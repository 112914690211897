import React from 'react';
import { Navigate } from 'react-router-dom';
import { OAuthCallback } from 'lib/react-geo-tool/packages/react-auth/src/';

import Main from 'components/views/Main';
import NotFound from 'components/views/NotFound';
import Error from 'components/views/Error';
import Login from 'components/views/login/Login';
import Organizations from 'components/views/organizations/Organizations';
import Users from 'components/views/organizations/users/Users';
import Scenarios from 'components/views/organizations/scenarios/Scenarios';
import DescriptiveMap from 'components/views/organizations/scenarios/maps/custom/DescriptiveMap';
import EmbeddedMap from 'components/views/organizations/scenarios/maps/EmbeddedMap';
import Organization from 'components/views/organizations/organization/Organization';
import Dataset from 'components/views/organizations/dataset/Dataset';
import ForgotPassword from 'components/views/login/ForgotPassword';
import ResetPassword from 'components/views/login/ResetPassword';
import LoginLayout from 'components/views/login/LoginLayout';
import Workspace from 'components/views/organizations/workspace/Workspace';
import Translations from 'components/views/trasnlations/Translations';
import MapEvents from 'components/views/organizations/scenarios/mapsHandlers/MapEvents';

const commonRouters = [
  { path: 'error', element: <Error /> },
  { path: 'oauthCallback', element: <OAuthCallback /> },
  { path: 'orgs', element: <Organizations /> },
  { path: 'orgs/myWorkspace/scenarios/view/:id', element: <DescriptiveMap /> },
  { path: 'public/:idOrg/public/:id', element: <EmbeddedMap />},
  { path: 'public/:idOrg/custom/:id', element: <DescriptiveMap />},
  { path: '404', element: <NotFound /> },
  { path: '', element: <Navigate to='/404' /> },

];

const scenariosMaps = [
  { path: 'dataset', element: <Dataset /> },
  { path: 'maps', element: <Scenarios />, },
  { path: 'maps/:idScenarios/specification',  element: <MapEvents />},
];

const superAdmin = [
  { path: '', element: <Main /> },
  { path: '/orgs/:id', element: <Organization />, exact: true, children: [...scenariosMaps, { path: 'users', element: <Users /> }]},
  { path: 'orgs/workspace', element: <Workspace />, children: [...scenariosMaps], },
  { path: 'orgs/:idOrg/maps/view/custom/:id', element: <DescriptiveMap /> },
  { path: `orgs/:idOrg/maps/view/public/:id`, element: <EmbeddedMap /> },
  { path: 'translations', element: <Translations /> },
  ...commonRouters,
];

const levelUser = ( orgId, nonAdminUsersOrgsIdsAccessPermissions ) => {
  const defaultOrg = [
    { path: `orgs/${orgId}`,element: <Organization />, exact: true, children: [...scenariosMaps]},
    { path: `orgs/${orgId}/maps/view/custom/:id`, element: <DescriptiveMap /> },
    { path: `orgs/${orgId}/maps/view/public/:id`, element: <EmbeddedMap /> },
    { path: 'public/:idOrg/custom/:id', element: <DescriptiveMap /> },
    { path: '', element: <Navigate to={`/orgs/${orgId}/maps`} /> },
  ];
  if ( nonAdminUsersOrgsIdsAccessPermissions.length === 0 ) {
    return defaultOrg;
  }
  let organizationAccess = [];

  nonAdminUsersOrgsIdsAccessPermissions.map((i) => {
    const pathOrg = {
      path: `orgs/${i}`,
      element: <Organization />,
      exact: true,
      children: [...scenariosMaps, { path: 'users', element: <Users /> }],
    };
    const pathCustomMap = [
      { path: `orgs/${i}/maps/view/custom/:id`, element: <DescriptiveMap /> },
      { path: `orgs/${i}/maps/view/public/:id`, element: <EmbeddedMap /> },
    ];
    organizationAccess = [...organizationAccess, pathOrg, ...pathCustomMap];
    return organizationAccess;
  });

  return [ ...commonRouters, ...organizationAccess, ...defaultOrg ];
};

const admin = (adminRights) => {
  let organizationAccess = [];
  adminRights.map((i) => {
    const pathOrg = {
      path: `orgs/${i}`,
      element: <Organization />,
      exact: true,
      children: [...scenariosMaps, { path: 'users', element: <Users /> }],
    };
    const pathCustomMap = [
      { path: `orgs/${i}/maps/view/custom/:id`, element: <DescriptiveMap /> },
      { path: `orgs/${i}/maps/view/public/:id`, element: <EmbeddedMap /> },
    ];
    organizationAccess = [...organizationAccess, pathOrg, ...pathCustomMap];
    return organizationAccess;
  });

  const myWorkspace = [
    { path: 'orgs/workspace', element: <Workspace />, children: [...scenariosMaps] },
  ];
  return [...commonRouters, ...organizationAccess, ...myWorkspace];
};

export const secureRoutes = (type, orgId, adminRights, nonAdminUsersOrgsIdsAccessPermissions) => {
  switch (type) {
    case 'level_0':
      return levelUser( orgId, nonAdminUsersOrgsIdsAccessPermissions );
    case 'level_1':
      return levelUser( orgId, nonAdminUsersOrgsIdsAccessPermissions );
    case 'level_2':
      return levelUser( orgId, nonAdminUsersOrgsIdsAccessPermissions );
    case 'level_3':
      return levelUser( orgId, nonAdminUsersOrgsIdsAccessPermissions );
    case 'admin':
      return admin(adminRights);
    case 'super_admin':
      return superAdmin;
    default:
      return null;
  }
};

export const routes = [
  { path: 'public/:idOrg/public/:id', element: <EmbeddedMap /> },
  { path: 'public/:idOrg/custom/:id', element: <DescriptiveMap /> },
  { path: '404', element: <NotFound /> },
  { path: '', element: <LoginLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset', element: <ResetPassword /> },
    ],
  },
];
