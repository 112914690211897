import { Tooltip } from '@mui/material';

  import {
    FiberManualRecord as FiberManualRecordIcon,
  } from '@mui/icons-material';

  import {useDispatch, useSelector} from 'react-redux';
  import {
    removeFilter,
    removeSpatialFilter
  } from "lib/react-geo-tool/packages/react-redux/src/";
  import { ReactSVG } from 'react-svg';
  import {
    setListFilterPolygonData,
    setListPolygonWidgetForDelete,
    setListRecommendationsWidgetForDelete,
  } from 'store/appSlice';
  import {useLayers} from "../../../../../providers/LayersProvider";

  import { widgetType as widgetTypeConstant } from 'utils/constants';
import { MuiChip } from './styles/filterOnMap';

  function FilterOnMap( props ) {
      const dispatch = useDispatch();
      const { information, layerColor="orange", layerId, column, layerIcon, widgetType, widgetId } = props;
      const layers = useSelector((state) => state.app.layers);
      const { changeVisible} = useLayers();

      const removeSelectedFilter = (params) => {
        const { widgetType, layerId, column }  = params;
        if ( widgetType === widgetTypeConstant.POLYGON ) {
          dispatch(removeSpatialFilter());
          dispatch(setListFilterPolygonData({}));
          dispatch(setListPolygonWidgetForDelete({id:widgetId, selection:[]}));

          let prevStateLayers = layers.filter(layer => layer.visible===true);
          prevStateLayers.forEach((layer) => {
            if (layer.geometry === 'Point'){
              changeVisible(`${layer.id}-pointCircle`, false , layer.isRecommendation);
              changeVisible(layer.id, false , layer.isRecommendation);
            } else changeVisible(layer.id, false , layer.isRecommendation);
            setTimeout(() => {
              if (layer.geometry === 'Point'){
                changeVisible(`${layer.id}-pointCircle`, true , layer.isRecommendation);
                changeVisible(layer.id, true, layer.isRecommendation);
              } else  changeVisible(layer.id, true, layer.isRecommendation);
            }, 50);
          });
        } else if ( widgetType === widgetTypeConstant.RECOMMENDATIONS ) {
          dispatch(removeSpatialFilter());
          dispatch(setListFilterPolygonData({}));
          dispatch(setListRecommendationsWidgetForDelete({id:widgetId, selection:[]}));
        }
        else {
          dispatch(removeFilter({ id:  layerId, column }) )
        }
      }
       const getIconStyle = () => {
        return {
          '& .MuiChip-icon':{
            backgroundColor: layerColor || 'red',
            borderRadius: layerIcon === 'circle.svg' || layerIcon === '' ? '50%' : 14,
            width: layerIcon === 'circle.svg' || layerIcon === '' ? 8 : 16,
            height: layerIcon === 'circle.svg' || layerIcon === '' ? 8 : 16,
            padding: layerIcon === 'circle.svg' || layerIcon === '' ? 0 : '2px',
          }
        }
       }

      return (
        <Tooltip title={information}>
        <MuiChip
            label = {information }
            sx={getIconStyle()}
            icon={
              layerIcon === 'circle.svg' || layerIcon === '' ? (
                <FiberManualRecordIcon style={{ fill: layerColor }} sx={{ fontSize:'12px' }} />
              ) : (
                <ReactSVG
                  src={process.env.PUBLIC_URL + '/maki-icons/' + layerIcon }
                  beforeInjection={(svg) => {
                    svg.getElementsByTagName('path')[0].setAttribute('fill', 'white');
                    svg.setAttribute('style', 'width: 12px; height: 12px; display: flex');
                  }}
                />
              )
            }
            onDelete={ () => removeSelectedFilter({ layerId, column, widgetType  }) }
        />
        </Tooltip>
      );
  }

  export default FilterOnMap;
