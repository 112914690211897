import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import {useDispatch} from 'react-redux';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useTranslation } from 'components/providers/TranslationProvider';

const fetchUsers = (id) =>
  axios.post(apiPaths.users_organization, { org_id: id }, status200).then((resp) => resp.data);

const useUsers = (id) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery('users', () => fetchUsers(id), {
    enabled: Boolean(id),
    onError: (error) => {
      dispatch(setOpenToast(true));
      dispatch(setMessageToast(t(error.data.error)));
      return error;
    },
  });
}

export default useUsers;
