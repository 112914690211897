import axios from 'axios';
const baseUrl = process.env.REACT_APP_CARTO_PIA_URL;



const axiosCartoInstance = axios.create({
  baseURL: baseUrl,
});

export default axiosCartoInstance;
