import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchDefaultGradients = () =>
  axios.get(apiPaths.defaultGradients, status200).then((resp) => resp.data);

const useDefaultGradients = () =>
  useQuery(['default-gradients'], () => fetchDefaultGradients(), {
    enabled: true,
  });

export default useDefaultGradients;
