import { Button, ButtonGroup, styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../../../../../utils/constants';
import { ArrowDropDown, PushPin } from '@mui/icons-material';

export const MuiButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  top: 10,
  width: 48,
  height: 40,
  zIndex: 100,
  borderRadius: 12,
  position: 'absolute',
  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRight: 'none',
  },
  '& .MuiButtonGroup-grouped:not(:first-of-type)': {
    marginLeft: '0',
  },
  '& .MuiButtonGroup-grouped': {
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
  },
  '&:hover': {
    backgroundColor: 'white',
  },
  '& .MuiButton-root': {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  boxShadow: '0px 4px 8px 0px #00000026',
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  minWidth: 24,
  padding: 0,
  borderRadius: 12,
}));

export const MuiPushPinIcon = styled(PushPin)(({ theme }) => ({
  fontSize: '24px',
  zIndex: 1,
  left: 9,
  fill: `${GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]} !important`,
  position: 'absolute'
}));

export const MuiArrowDropDownIcon = styled(ArrowDropDown)(({ theme }) => ({
  fontSize: '16px',
  marginLeft: 10,
  fill: `${GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]} !important`,
}));

export const MuiReferencePointTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[900],
  alignSelf: 'center',
}));

export const referencePointContainerStyle = {
  '& .MuiPopover-paper': {
    width: 227,
    maxHeight: 356,
    minHeight: 155,
    backgroundColor: 'white',
    padding: '12px !important',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: '12px',
  }
};

export const closeIconStyle = {
  fontSize: '16px',
  color: '#212121'
};

export const appliedFilterDisplayContainer = {
  marginTop: 8,
  maxWidth: 181,
};
