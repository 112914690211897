import { useMutation, useQueryClient } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const updateScheme = ({ scheme, userId }) =>
  axios
    .post(apiPaths.update_scheme, { scheme, user_id: userId }, status200)
    .then((resp) => resp.data);

const useUpdateScheme = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => updateScheme(values), {
    onSuccess: () => {
      queryClient.resetQueries('schemes');
    },
    onError: (error) => {
      
      return error.message;
    },
    onSettled: () => {
      queryClient.invalidateQueries('schemes');
    },
  });
};

export default useUpdateScheme;
