const apiPaths = {
  changePassword: '/auth/change-password',
  changeVisibility: '/schemes/change-visibility',
  create_scenario: '/schemes/add-scheme-or-col',
  create_user: '/users/',
  dataSetCarto: '/carto/datasets',
  importFromCarto: '/carto/datasets/import-from-carto',
  datasetSync: '/carto/datasets/force-sync/',
  defaultGradients: '/carto/default-gradients',
  delete_map: '/schemes/remove-map',
  delete_dataset: '/carto/datasets/',
  query: '/carto/sql',
  delete_scenario: '/schemes/remove-scheme-or-col',
  delete_user: '/users/',
  forgotPassword: '/auth/forgot',
  login: '/auth/login',
  logout: '/auth/logout',
  orgs: '/orgs',
  org: '/orgs/find-one?org_id=',
  refreshToken: '/auth/refresh',
  reset_password: '/auth/reset/:',
  save_map_state: '/schemes/save-map-state',
  scenario: '/schemes/get-scheme',
  publicScenario: '/schemes/get-public-scheme',
  schemes: '/schemes/list-schemes',
  scheme: '/schemes/get-scheme',
  schemeNative: 'manage/scheme-native',
  schemesBulkWidgets: '/schemes/import-bulk-widgets',
  exportSchemesBulkWidgets: '/schemes/export-bulk-widgets',
  templateDefinitions: '/carto/template-definitions',
  translations: '/translations',
  users_organization: '/users/list-users',
  update_user: '/users/',
  update_scheme: '/schemes/save-scheme',
  upload_map: '/schemes/add-map',
  upload_img: '/users/upload-image',
  upload_file: '/users/upload-file',
  upload_dataset: '/carto/datasets/upload',
  upload_dataset_sync: '/carto/datasets/upload-sync',
  get_dataset_usage: '/carto/datasets/',
  copy_scheme: '/schemes/copy-schemes',
  dataStatisticsWidget: 'carto/widgets/statistics',
  dataListWidget: '/carto/widgets/list',
  dataPolygonWidget: '/carto/widgets/polygon',
  importJob: '/import-job',
  loginSocial: '/auth/login-social',
  createIsochrone: '/carto/widgets/add-polygon',
  updateIsochrone: '/carto/widgets/update-polygon',
  deleteIsochrone: '/carto/widgets/delete-polygon',
  createPolygon: '/carto/widgets/polygon/drawed',
  editPolygon:'/carto/widgets/polygon/drawed',
  download_layer: '/schemes/download-layer',
  generatePotentialOffers: '/recommendations/generate-potential-offers',
  generateRecommendedOffers: '/recommendations/compute-recommendations',
  processJob: '/process-job',
  refreshCartoPiaToken: '/auth/refresh-carto-pia-token',
  validateExpression: '/widgets/validate-expression',

};

export default apiPaths;
