import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'components/providers/TranslationProvider';

const fetchDataQuery = (datasetName , columns = '*', filter = undefined, limit = undefined) => {
  let spatialQuery = `SELECT ${columns} FROM ${datasetName}`;
  if ( filter ) {
    spatialQuery += ` WHERE ${filter}`;
  }
  if ( limit ) {
    spatialQuery += ` LIMIT ${limit}`;
  }
  return axios
    .get(`${apiPaths.query}/${spatialQuery}`, status200)
    .then((resp) => resp.data);
};

const useDataQuery = (datasetName, columns = '*', filter = undefined, limit = undefined) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery(
    ['queryData', datasetName, columns],
    () => fetchDataQuery(datasetName, columns, filter, limit),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        dispatch(setOpenToast(true));
        dispatch(setMessageToast(t(error.data.error)));
        return error;
      },
    }
  );
};

export default useDataQuery;
