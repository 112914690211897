import { Button, styled } from '@mui/material';
import { GLOBAL } from '../../../../../../../utils/constants';

export const MuiBtnClose = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
}));

export const MuiBtnAction = styled(Button)(({ theme }) => ({
  padding:'10px 16px 10px 16px',
  borderRadius: '20px',
  color:'#005F64',
  borderColor:'#005F64',
  height:'37px',
  width:'105px',
  '&:hover': {
    borderColor:'#005F64',
  },
}));
