import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCategoryWidgets } from 'store/appSlice';
import PropTypes from 'prop-types';
import {
  Grid,
  InputAdornment,
  Typography,
  Tooltip,
  Skeleton
} from '@mui/material';

import { animateValues } from '../utils/animations';
import {
  MuiCategoriesWrapper,
  MuiCategoryLabel,
  MuiCheckbox, MuiDivAdornment, MuiDivRoot, MuiElement,
  MuiGridContainer, MuiLink, MuiOptionsSelectedBar,
  MuiProgressBar, MuiSearchIcon, MuiSkeleton, MuiTextField
} from '../styles/categoryWidgetUI';
import { useTheme } from '../../../providers/CustomThemeProvider';
import { lighten } from '@mui/material/styles';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const REST_CATEGORY = '__rest__';

function CategoryWidgetUI(props) {
  const {
    data = null,
    formatter = (v) => v,
    labels = {},
    maxItems = 5,
    order = CategoryWidgetUI.ORDER_TYPES.RANKING,
    selectedCategories = [],
    animation = true,
    filterable = true,
    searchable = true,
    color,
    widgetId,
    widgetType,
  } = props;

  const dispatch = useDispatch();

  const [sortedData, setSortedData] = useState([]);
  const [maxValue, setMaxValue] = useState(1);
  const [showAll, setShowAll] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [blockedCategories, setBlockedCategories] = useState([]);
  const [, setTempBlockedCategories] = useState(false);
  const [animValues, setAnimValues] = useState([]);
  const requestRef = useRef();
  const prevAnimValues = usePrevious(animValues);
  const referencedPrevAnimValues = useRef();

  const {
    enabled: colorEnabled,
    type: colorType,
    simpleColor = '#ff0000',
    customColors = [],
  } = props.color;
  const colorMap = new Map(customColors.map((d) => [d.value, d.color]));

  const colorScale = (name) => {
    if (colorEnabled) {
      if (colorType === 'simple') {
        return simpleColor;
      }
      return colorMap.get(name.toString()) || simpleColor;
    }
    return null;
  };

  // Get blockedCategories in the same order as original data
  const sortBlockedSameAsData = (blockedCategories) =>
    sortedData.reduce((acum, elem) => {
      if (blockedCategories.includes(elem.name)) acum.push(elem.name);
      return acum;
    }, []);

  const handleCategorySelected = (name) => {
    if (name !== REST_CATEGORY) {
      let categories;

      if (selectedCategories.indexOf(name) < 0) {
        categories = [...selectedCategories, name];
      } else {
        categories = selectedCategories.filter((c) => c !== name);
      }

      if (props.onSelectedCategoriesChange) {
        props.onSelectedCategoriesChange(categories);
      }
    }
  };

  const handleClearClicked = () => {
    props.onSelectedCategoriesChange([]);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.currentTarget.value);
  };

  const compressList = useCallback(
    (list) => {
      return searchValue
        ? list.filter((elem) => {
          return (
            elem.name !== null &&
            elem.name !== undefined &&
            (String(elem.name).toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
              (labels[elem.name]
                ? String(labels[elem.name])
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) !== -1
                : false))
          );
        })
        : list;
    },
    [labels, searchValue]
  );

  const getCategoriesCount = useCallback(() => {
    const blocked = blockedCategories.length;
    return blocked ? data.length - blocked : data.length - maxItems;
  }, [data, maxItems, blockedCategories]);

  const getCategoryLabel = useCallback(
    (name) => {
      if (name === REST_CATEGORY) {
        return `Others ${searchable ? '' : `(${getCategoriesCount()})`}`;
      } else {
        return labels[name] || `${name}`;
      }
    },
    [getCategoriesCount, labels, searchable]
  );

  const getProgressbarLength = useCallback(
    (value) => {
      return value >= maxValue
        ? value != null
          ? '100%'
          : 0
        : `${((value || 0) * 100) / maxValue}%`;
    },
    [maxValue]
  );

    useEffect(() => {
      setShowAll(true);
      setTempBlockedCategories([...selectedCategories]);

    }, []);

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setBlockedCategories([]);
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (data) {
      // Ranking
      if (order === CategoryWidgetUI.ORDER_TYPES.RANKING) {
        const sorted = [...data].sort((a, b) => b.value - a.value);
        const compressed = compressList(sorted);
        compressed.length ? setMaxValue(compressed[0].value) : setMaxValue(1);
        setSortedData(compressed);

        // Fixed order
      }
      else if (order === CategoryWidgetUI.ORDER_TYPES.ALPHABETICAL) {
        const sorted = [...data].sort((a, b) => String(a.name).localeCompare(String(b.name)));
        const compressed = compressList(sorted);
        compressed.length ? setMaxValue(compressed[0].value) : setMaxValue(1);
        setSortedData(compressed);
      }
      else if (order === CategoryWidgetUI.ORDER_TYPES.FIXED) {
        setMaxValue(
          Math.max.apply(
            Math,
            data.map((e) => e.value)
          )
        );
        const compressed = compressList(data);
        setSortedData(compressed);
      }
    }
  }, [
    blockedCategories,
    compressList,
    data,
    labels,
    maxItems,
    order,
    searchValue,
    showAll
  ]);

  useEffect(() => {
    referencedPrevAnimValues.current = prevAnimValues;
  }, [prevAnimValues]);

  useEffect(() => {
    if (animation) {
      animateValues({
        start: referencedPrevAnimValues.current || [],
        end: sortedData,
        duration: 500,
        drawFrame: (val) => setAnimValues(val),
        requestRef
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      return () => cancelAnimationFrame(requestRef.current);
    } else {
      setAnimValues(sortedData);
    }
  }, [animation, sortedData]);

  useEffect(() => {
    if (animValues.length > 0 && widgetType == 'category' && color.type == 'by_value') {
      dispatch(setCategoryWidgets({widgetId, customCategoryColors: animValues}));
    }
  }, [JSON.stringify(animValues)]);

  // Separated to simplify the widget layout but inside the main component to avoid passing all dependencies
  const CategoryItem = (props) => {
    const { data, onCategoryClick, color } = props;
    const value = formatter(data.value || 0);
    const [isOverflowed, setIsOverflowed] = useState(false);
    const textElementRef = useRef();
    const unselected = selectedCategories.length > 0 && selectedCategories.indexOf(data.name) === -1;
    const {theme} = useTheme()

    const compareSize = () => {
      const compare =
        textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth;
      setIsOverflowed(compare);
    };

    useEffect(() => {
      compareSize();
      window.addEventListener('resize', compareSize);
      return () => {
        window.removeEventListener('resize', compareSize);
      };
    }, []);

    return (
      <MuiGridContainer
        container
        direction='row'
        spacing={1}
        onClick={filterable ? onCategoryClick : () => {}}
        filterable={filterable}
        selectedCategories={selectedCategories}
        data={data}
      >
        <Grid item style={{paddingLeft:'0px', marginLeft:'-3px'}}>
            <MuiCheckbox
              checked={selectedCategories.indexOf(data.name) !== -1}
              color="secondary"
            />
        </Grid>
        <Grid container item xs={12} style={{overflowX: 'auto'}}>
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            wrap='nowrap'
            style={{overflowX: 'hidden'}}
          >
            <Tooltip
              title={getCategoryLabel(data.name)}
              disableHoverListener={!isOverflowed}
            >
              <MuiCategoryLabel
                variant='body2'
                noWrap
                ref={textElementRef}
              >
                {getCategoryLabel(data.name)}
              </MuiCategoryLabel>
            </Tooltip>
            {typeof value === 'object' && value !== null ? (
              <span>
                {value.prefix}
                {value.value}
                {value.suffix}
              </span>
            ) : (
              <span>{value}</span>
            )}
          </Grid>
          <MuiProgressBar item unselected={unselected} color={color}>
            <div
              style={{
                width: getProgressbarLength(data.value),
                backgroundColor: unselected ? '#BDBDBD' : color,
              }}></div>
          </MuiProgressBar>
        </Grid>
      </MuiGridContainer>
    );
  };

  const CategoryItemSkeleton = () => (
    <>
      <MuiOptionsSelectedBar
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography variant='caption'>
          <Skeleton variant='text' width={100} />
        </Typography>
      </MuiOptionsSelectedBar>
      <MuiCategoriesWrapper container item >
        {[...Array(4)].map((_, i) => (
          <MuiElement key={i} container direction='row' spacing={1}>
            <Grid container item xs zeroMinWidth>
              <Grid container item direction='row' justifyContent='space-between'>
                <Typography variant='body2' noWrap>
                  <Skeleton variant='text' width={100} />
                </Typography>
                <Typography variant='body2'>
                  <Skeleton variant='text' width={70} />
                </Typography>
              </Grid>
              <MuiSkeleton variant='text'/>
            </Grid>
          </MuiElement>
        ))}
      </MuiCategoriesWrapper>
    </>
  );

  return (
    <MuiDivRoot>
      {data?.length > 0 ? (
        <>
          {filterable && sortedData.length > 0 && (
            <MuiOptionsSelectedBar
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='caption'>
                {selectedCategories.length ? selectedCategories.length : 'All'} selected
              </Typography>
              { selectedCategories.length > 0 && (
              <Grid container direction='row' justifyContent='flex-end' item xs>
                <MuiLink underline='always' onClick={handleClearClicked}>
                  Clear
                </MuiLink>
              </Grid> )}
            </MuiOptionsSelectedBar>
          )}
          {data.length > maxItems && (
            <MuiOptionsSelectedBar
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <MuiTextField
                size='small'
                placeholder='Search...'
                variant={'outlined'}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <MuiDivAdornment>
                        <MuiSearchIcon/>
                      </MuiDivAdornment>
                    </InputAdornment>
                  ),
                }}
              />
            </MuiOptionsSelectedBar>
          )}
          <MuiCategoriesWrapper container item >
            {animValues.length ? (
              animValues.map((d, i) => (
                <CategoryItem
                  key={i}
                  data={d}
                  color={colorScale(d.name)}
                  onCategoryClick={() =>
                    handleCategorySelected(d.name)
                  }
                />
              ))
            ) : (
              <>
                <Typography variant='body2'>No results</Typography>
                <Typography variant='caption'>
                  Your search "{searchValue}" didn't match with any value.
                </Typography>
              </>
            )}
          </MuiCategoriesWrapper>
        </>
      ) : (
        <CategoryItemSkeleton />
      )}
    </MuiDivRoot>
  );
}

CategoryWidgetUI.ORDER_TYPES = {
  RANKING: 'ranking',
  ALPHABETICAL: 'alphabetical',
  FIXED: 'fixed'
};

CategoryWidgetUI.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
        .isRequired,
      value: PropTypes.number
    })
  ),
  formatter: PropTypes.func,
  labels: PropTypes.object,
  maxItems: PropTypes.number,
  selectedCategories: PropTypes.array,
  onSelectedCategoriesChange: PropTypes.func,
  order: PropTypes.oneOf(Object.values(CategoryWidgetUI.ORDER_TYPES)),
  animation: PropTypes.bool,
  filterable: PropTypes.bool,
  searchable: PropTypes.bool
};

export default CategoryWidgetUI;
