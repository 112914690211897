import prosperiaStyles from '../../../../../../components/common/styles/ProsperiaLigthStyles';
/** @constant
    @type {string}
    @default
*/
import prosperiaStylesUnlabel from '../../../../../../components/common/styles/ProsperiaUnlabelledStyles';
export const GOOGLE_ROADMAP = 'roadmap';
/** @constant
    @type {string}
    @default
*/
export const GOOGLE_SATELLITE = 'satellite';
/** @constant
    @type {string}
    @default
*/
export const GOOGLE_HYBRID = 'hybrid';
/** @constant
    @type {string}
    @default
*/
export const GOOGLE_CUSTOM = 'custom';
/** @constant
    @type {string}
    @default
*/
export const BING_AERIAL = 'aerial';
export const GOOGLE_CUSTOM_2 = 'unlabelled';
/** @constant
 @type {string}
 @default
 */

export const BASEMAPS = {
  [GOOGLE_ROADMAP]: {
    type: 'gmaps',
    options: {
      mapTypeId: 'roadmap'
    }
  },
  [GOOGLE_SATELLITE]: {
    type: 'gmaps',
    options: {
      mapTypeId: GOOGLE_SATELLITE
    }
  },
  [GOOGLE_HYBRID]: {
    type: 'gmaps',
    options: {
      mapTypeId: 'hybrid'
    }
  },
  [GOOGLE_CUSTOM]: {
    type: 'gmaps',
    options: {
      mapTypeId: 'roadmap',
      styles : prosperiaStyles
    }
  },
  [BING_AERIAL]: {
    type: 'bingmaps',
    options: {
      mapTypeId: 'aerial',
    }
  },
  [GOOGLE_CUSTOM_2]: {
    type: 'gmaps',
    options: {
      mapTypeId: 'roadmap',
      styles : prosperiaStylesUnlabel
    }
  }
};
