import { useQueryClient, useMutation } from 'react-query';

import apiPaths from 'api/apiPaths';
import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import {
  setMessageToast,
  setOpenToast,
  setPolygonDataLoading
} from 'store/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'components/providers/TranslationProvider';

const exportLayerSelection = (data) => {
  const request  = {'spatialFilter': data['spatialFilter'] , 'source': data['source'],'filters': data['filters'], 'parameters': data['parameters']};
  return axios
    .post(apiPaths.download_layer, request , status200)
    .then((resp) => resp.data);
};

const useExportLayer = (setToast) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation((values) => exportLayerSelection(values), {
    onError: (error) => {
      dispatch(setOpenToast(true));
      dispatch(setMessageToast( t(error.data.error) ));
      dispatch(setPolygonDataLoading(false));
      console.log(error);
    }
  });
};

export default useExportLayer;
