import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'components/providers/TranslationProvider';

import LayersSort from './LayersSort';
import {
  MuiAddIcon,
  MuiCommonButtonAdd,
  MuiCommonTypographyAdd
} from '../styles/common';
import { Typography, Grid, Paper  } from '@mui/material';
import { MuiGridContainer } from './styles/layersSort';

const Layers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const layers = useSelector((state) => state.app.layers);

  const handleAddLayer = () => {
    navigate(`${location.pathname}?create=layer`, {
      replace: true,
    });
  };

  return (
    <>
      <Grid container justifyContent='flex-end' alignContent='center'>
        <Grid item xs={10}>
          <Typography
            variant='subtitle1'
            fontSize={16}
            color={'#616161'}
            marginTop={1}
            letterSpacing={0.15}
            fontWeight={400}
            fontFamily={'Raleway'}
          >
            {t('layers')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <MuiCommonButtonAdd
            variant='outlined'
            color='primary'
            startIcon={<MuiAddIcon/>}
            onClick={handleAddLayer}
            data-cy={'add_layer'}
          >
            <MuiCommonTypographyAdd> {t('add_layer')} </MuiCommonTypographyAdd>
          </MuiCommonButtonAdd>
        </Grid>
      </Grid>
      <MuiGridContainer container component={Paper}>
        {layers.length > 0 && <LayersSort />}
      </MuiGridContainer>
    </>
  );
};

export default Layers;
