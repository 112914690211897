import * as React from 'react';

import swap from 'lodash-move';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { useSelector, useDispatch } from 'react-redux';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { setLayers, updateLayer, setIsValidDescriptiveScenario } from 'store/appSlice';

import { useLocation, useNavigate } from 'react-router';
import { ReactSVG } from 'react-svg';
import {
  Button,
  Collapse, Divider,
  Icon,
  ListItem,
  ListItemIcon, ListItemText,
  Grid, Box, Checkbox
} from '@mui/material';
import {
  dragHandleIconStyles,
  MuiExpandLess,
  MuiExpandMore,
  MuiDeleteIcon,
  MuiEditIcon,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon,
  layerPointBoxStyles,
  expandedButtonStyles,
  MuiTypographyLayerName,
  legendContainerStyles,
  listItemIconStyles,
  legendsBoxStyles,
  primaryTypographyStyles,
  legendsIconStyles,
  collapseStyles,
  boxStyles, MuiGrid, checkStyles
} from './styles/layersSort';

const DragHandle = SortableHandle(() => (
  <DragHandleIcon style={dragHandleIconStyles} />
));

const SortableItem = SortableElement(({ item, setVisibility }) => {
  const [expanded, setExpanded] = React.useState(false);
  const collapseByValue = React.createRef();

  const navigate = useNavigate();
  const location = useLocation();
  const handleDeleteLayer = (id, layer) => {
    navigate(`${location.pathname}?delete-layer=${id}`, { replace: true, state: layer });
  };

  const handleEditLayer = (id) => {
    navigate(`${location.pathname}?edit-layer=${id}`, { replace: true });
  };

  const controls = (
    <MuiGrid item xs={3} >
      <Checkbox
        checked={item.visible}
        name='visibility'
        onClick={(e) => setVisibility(e.target.checked, item.id)}
        color='primary'
        icon={<MuiVisibilityOffIcon />}
        checkedIcon={<MuiVisibilityIcon />}
        sx={checkStyles}
      />
      <MuiEditIcon onClick={() => handleEditLayer(item.id)} />
      <MuiDeleteIcon onClick={() => handleDeleteLayer(item.id, item)}/>
    </MuiGrid>
  );

  const handleExpanded = () => setExpanded(!expanded);

  const LegendItems = () => {
    return (
      <Box sx={legendContainerStyles}>{item.legend?.map((i) => (
        <ListItem key={i.id}>
          <ListItemIcon sx={listItemIconStyles}>
            <Box style={{
              ...legendsBoxStyles,
              background: i.color,
            }}>
              <ReactSVG
                src={process.env.PUBLIC_URL + '/maki-icons/' + i.icon}
                afterInjection={(svg) => {
                  svg.getElementsByTagName('path')[0]
                    .setAttribute('fill', 'white');
                }}
                beforeInjection={(svg) => {
                  svg.classList.add('svg-class-name');
                }} style={legendsIconStyles} />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={i.name}
            primaryTypographyProps={primaryTypographyStyles}
          />
        </ListItem>
      ))}</Box>
    )
  }

  const SimpleIcon = ({ customIcon, color }) => (
    <ReactSVG
      src={`${process.env.PUBLIC_URL}/maki-icons/${customIcon}`}
      afterInjection={(svg) => {
        svg.getElementsByTagName('path')[0].setAttribute('fill', color);
      }}
      beforeInjection={(svg) => {
        svg.classList.add('svg-class-name');
      }}
      style={{ width: 20, height: 20, marginTop: '4px' }}
    />
  );

  const LegendIcon = ({ legendIcon }) => (
    <Box style={layerPointBoxStyles}>
      <ReactSVG
        src={`${process.env.PUBLIC_URL}/maki-icons/${legendIcon}`}
        afterInjection={(svg) => {
          svg.getElementsByTagName('path')[0].setAttribute('fill', 'white');
        }}
        beforeInjection={(svg) => {
          svg.classList.add('svg-class-name');
        }}
        style={{ width: 20, height: 20, marginTop: 1 }}
      />
    </Box>
  );

  const DefaultIcon = ({ customIcon, color }) => (
    <Box style={boxStyles}>
      <ReactSVG
        src={`${process.env.PUBLIC_URL}/maki-icons/${customIcon}`}
        afterInjection={(svg) => {
          svg.getElementsByTagName('path')[0].setAttribute('fill', color);
        }}
        beforeInjection={(svg) => {
          svg.classList.add('svg-class-name');
        }}
        style={{ width: 20, height: 20, marginTop: '-5px' }}
      />
    </Box>
  );

  const renderIcon = () => {
    if (item.geometry === 'Point' || item.isPointDataSet) {
      if (item.legendType === 'simple') {
        return <SimpleIcon customIcon={item.customIcon} color={item.color} />;
      } else {
        return <LegendIcon legendIcon={item.legend[0].icon} />;
      }
    } else {
      return <DefaultIcon customIcon={item.customIcon} color={item.color} />;
    }
  }

  return (
    <Grid container>
      <Grid item xs={8} style={{ display: 'flex', alignItems:'center' }}>
        <DragHandle />
        {renderIcon()}
        <Button
          style={expandedButtonStyles}
          endIcon={item.legendType === 'by_value' &&
             ( <Icon>
                {expanded ? (
                  <MuiExpandLess/>
                ) : (
                  <MuiExpandMore/>
                )}
              </Icon> )
          }
          onClick={handleExpanded}
        >
          <MuiTypographyLayerName noWrap variant='caption'>
            {item.name}
          </MuiTypographyLayerName>
        </Button>
      </Grid>
      {controls}
      <Collapse ref={collapseByValue} in={expanded && item.legendType === 'by_value'}
                timeout='auto' unmountOnExit sx={collapseStyles}>
        <Divider sx={{ borderColor: '#E0E0E0', width:'100%' }}/>
        <LegendItems/>
      </Collapse>
    </Grid>
  );
});

const SortableList = SortableContainer(({ items }) => {
  const dispatch = useDispatch();
  const setVisibility = (value, layerId) => {
    const layer = items.filter((l) => l.id === layerId)[0];
    dispatch(updateLayer({ ...layer, visible: value }));
  };

  return (
    <Grid container justifyContent='flex-start'>
      {items?.map((item, index) => (
        <SortableItem key={item.id} index={index} item={item} setVisibility={setVisibility} />
      ))}
    </Grid>
  );
});

const LayersSort = () => {
  const layers = useSelector((state) => state.app.layers);
  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(setIsValidDescriptiveScenario(true));
    dispatch(setLayers(swap(layers, oldIndex, newIndex)));
  };

  return <SortableList items={layers} onSortEnd={onSortEnd} useDragHandle />;
};

export default LayersSort;
