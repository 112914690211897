import { styled, Typography, Button } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { GLOBAL } from '../../../../../../utils/constants';
import AddIcon from '@mui/icons-material/Add';

export const MuiCommonButtonAdd = styled(Button)(({ theme }) => ({
  margin: '8px 0px',
  borderColor:lighten(theme.palette.primary.main, 0.1),
  borderRadius:15,
  height:'30px',
  width:'127px',
  padding:'4px 12px 4px 8px',
  '& .MuiButton-startIcon':{
    marginRight:'4px !important'
  },
  '&:hover': {
    borderColor:lighten(theme.palette.primary.main, 0.1),
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
}));

export const MuiCommonTypographyAdd = styled(Typography)(({ theme }) => ({
  color: lighten(theme.palette.primary.main, 0.1),
  textAlign:'center',
  fontFamily: 'Montserrat',
  fontSize: 11,
  fontWeight: 500,
  letterSpacing: 0.25
}));

export const MuiAddIcon = styled(AddIcon)(({ theme }) => ({
  color: lighten(theme.palette.primary.main, 0.2),
  height:'18px',
  width:'18px',
}));
