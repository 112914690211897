import { Button, styled, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { GLOBAL } from '../../../../../../utils/constants';

export const MuiUploadDownloadTypography = styled(Typography)(({ theme }) => ({
  color: lighten(theme.palette.primary.main, 0.1),
  textAlign:'center',
  fontFamily: 'Montserrat',
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: 0.25
}));

export const MuiCommonButton = styled(Button)(({ theme }) => ({
  marginRight: 16,
  borderColor:lighten(theme.palette.primary.main, 0.1),
  borderRadius:20,
  height:'38px',
  width:'332px',
  padding:'10px 24px 10px 16px',
  gap:8,
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    borderColor:lighten(theme.palette.primary.main, 0.1),
  },
}));
