import { 
    Grid,
    Divider,
    MenuItem,
    Box,
    Typography,
} from '@material-ui/core';
import {
    ColorField,
    TextField as TextFieldCustom,
    CheckboxField,
  } from 'components/common/ReactHooksFormFields';
import {ReactSVG} from 'react-svg';
import { useTranslation } from 'components/providers/TranslationProvider';
import {
  textFieldCommonStyle
} from '../../../layer/form/styles/layerForm';

import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput
} from '@mui/material';
import { iconsName } from '../../../../mapsHandlers/layer/form/iconsName';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTheme } from 'components/providers/CustomThemeProvider';

import {
  MuiGrid,
  removePaddingsX,
  MuiTypography,
  recommendedAreaLayerLabel,
  typography,
  itemsSeparator,
  layerName,
  layerOpacity,
  visibilityIconSize,
  isGlobalSwitchSpacing,
  allowsRegenerateInput,
  offerSubHeader,
  MuiSwitchCommon,
  colorSystem,
  inputColorFieldStyle,
  selectStyle,
  divMenuItemStyle
} from './styles/recommendations';

const RecommendationsVisualization = ( props ) => {
    const {
        isFormulaFieldSelected,
        setValue,
        isOfferSelected,
        recommendedPointLayerColor,
        action,
        defaultIcon,
        valueRecommendedIcons,
        handleInputIcon,
        isGlobal,
        allowsRegenerateServicePoint,
        progress,
      } = props;

    const { t } = useTranslation();
    const { theme } = useTheme();

    return (
      <MuiGrid item xs={12} sx={ removePaddingsX }>
                    <Grid container spacing={1} alignItems='center' sx={removePaddingsX}>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <TextFieldCustom
                              name='recommendedPointLayerName'
                              type='text'
                              margin='dense'
                              label={t('recommendations_points_layer_name')}
                              fullWidth
                              variant='outlined'
                              style={layerName}
                              color='primary'/>
                          </Box>
                          <Box>
                            <TextFieldCustom
                                fullWidth
                                style={{ ...textFieldCommonStyle, ...itemsSeparator }}
                                name="recommendedPointLayerColor"
                                label={t('background_color')}
                                variant="outlined"
                                InputLabelProps={{
                                  style: { color: '#424242' }
                                }}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <ColorField
                                      name="recommendedPointLayerColor"
                                      style={{ ...inputColorFieldStyle, overflow: 'visible' }}
                                      sx={{
                                        '& input': {
                                          width: 24,
                                          height: 24
                                        }, 
                                      }}
                                    />
                                  ),
                                  endAdornment: (
                                    <Typography variant="body1" sx={colorSystem}>HEX</Typography>
                                  ),
                                }}
                              />
                          </Box>
                          <Box>
                            <TextFieldCustom
                              name='recommendedPointLayerOpacity'
                              type='number'
                              min={0}
                              max={100}
                              margin='dense'
                              label={t('recommendations_layer_opacity')}
                              fullWidth
                              variant='outlined'
                              style={{ ...layerOpacity }}
                              InputProps={{
                                endAdornment: (
                                  <MuiTypography>%</MuiTypography>
                                ),
                                min: 0,
                                max: 100,
                                defaultValue: 100,
                              }}
                              color='primary'/>
                          </Box>
                          <Box>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel id="icon-select-label"
                                            htmlFor="icon-select"
                                            sx={{ color: '#424242' }}
                                >{t('select')}</InputLabel>
                                <Select
                                  labelId="icon-select-label"
                                  id="icon-select"
                                  defaultValue={ action === 'create' ? defaultIcon : valueRecommendedIcons.split('.')[0] }
                                  onChange={handleInputIcon}
                                  input={<OutlinedInput label={ t('choose_an_icon') } />}
                                  fullWidth
                                  style={selectStyle}
                                  label="Icono"
                                >
                                {iconsName.map((key) => (
                                  <MenuItem value={key.name}>
                                    <div
                                    style={{
                                      ...divMenuItemStyle,
                                      background: action === 'create' ? theme.palette.primary.main
                                                                      : recommendedPointLayerColor,
                                    }}
                                    >
                                      <ReactSVG
                                        src={`${process.env.PUBLIC_URL}/maki-icons/${key.name}.svg`}
                                        beforeInjection={(svg) => {
                                          svg.classList.add('svg-class-name');
                                          svg.setAttribute('style',
                                            'margin-top:6px; width: 100%; height: 100%;');
                                        }}
                                        afterInjection={(svg) => {
                                          const path = svg.querySelector('path');
                                          if (path) {
                                            path.setAttribute('fill', 'white');
                                          }
                                        }}
                                      />
                                    </div>
                                  </MenuItem>
                                ))}
                              </Select>
                              </FormControl>
                            </Box>
                        </Box>
                      </Grid>
                    </Grid>


                    <Grid container spacing={1} alignItems='center'>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                          <Box sx={layerName} display="flex" alignItems="center">
                              <Typography sx={{ ...recommendedAreaLayerLabel, ...typography, ...layerName }}> 
                                {t('recommendations_layer_area')}
                              </Typography>
                          </Box>
                          <Box>
                          <TextFieldCustom
                                fullWidth
                                style={{ ...textFieldCommonStyle, ...itemsSeparator }}
                                name="recommendedAreaLayerColor"
                                label={t('background_color')}
                                variant="outlined"
                                InputLabelProps={{
                                  style: { color: '#424242' }
                                }}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <ColorField
                                      name="recommendedAreaLayerColor"
                                      style={{ ...inputColorFieldStyle, overflow: 'visible' }}
                                      sx={{
                                        '& input': {
                                          width: 24,
                                          height: 24
                                        }, 
                                      }}
                                    />
                                  ),
                                  endAdornment: (
                                    <MuiTypography>HEX</MuiTypography>
                                  ),
                                }}
                              />
                          </Box>
                          <Box justifyContent="center">
                            <TextFieldCustom
                              name='recommendedAreaLayerOpacity'
                              type='number'
                              min={0}
                              max={100}
                              margin='dense'
                              label={t('recommendations_layer_opacity')}
                              fullWidth
                              variant='outlined'
                              style={{ ...layerOpacity, paddingTop: 0, paddingBottom: 0 }}
                              InputProps={{
                                endAdornment: (
                                  <MuiTypography>%</MuiTypography>
                                ),
                              }}
                              color='primary'/>
                          </Box>
                          <Box>
                            <CheckboxField
                              name='recommendedAreaLayerVisibility'
                              icon={<VisibilityOffIcon style={{ ...visibilityIconSize, fill: theme.palette.primary.main}} />}
                              checkedIcon={<VisibilityIcon style={{ ...visibilityIconSize, fill: theme.palette.primary.main }} />}
                              style={{ borderRadius: '50%', border: `1px solid ${theme.palette.primary.main}`, padding: '5px' }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item xs={12}>
                        <Divider  style={{ marginBottom: '32px', marginTop: '32px' }}/>
                      </Grid>
                    </Grid>
                    {isOfferSelected && (
                      <Grid container spacing={1} alignItems='center'>
                        <Grid item xs={12}>
                        <Typography style={offerSubHeader}>{ t('offer_sub_header') }</Typography>
                          <Box display="flex" alignItems="center">
                            <Box sx={layerName} display="flex" alignItems="center">
                              <Typography
                                  style={{ ...recommendedAreaLayerLabel, ...typography, ...layerName, marginRight: 60 }}>{ t('current_offer_layer_area_label') }</Typography>
                            </Box>
                            <Box>
                            <TextFieldCustom
                                fullWidth
                                style={{ ...textFieldCommonStyle, ...itemsSeparator }}
                                name="currentOfferAreaLayerColor"
                                label={t('background_color')}
                                variant="outlined"
                                InputLabelProps={{
                                  style: { color: '#424242' }
                                }}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <ColorField
                                      name="currentOfferAreaLayerColor"
                                      style={{ ...inputColorFieldStyle, overflow: 'visible' }}
                                      sx={{
                                        '& input': {
                                          width: 24,
                                          height: 24
                                        }, 
                                      }}
                                    />
                                  ),
                                  endAdornment: (
                                    <MuiTypography>HEX</MuiTypography>
                                  ),
                                }}
                              />
                            </Box>
                            <Box justifyContent="center">
                              <TextFieldCustom
                                name='currentOfferAreaLayerOpacity'
                                type='number'
                                margin='dense'
                                label={t('recommendations_layer_opacity')}
                                fullWidth
                                variant='outlined'
                                style={{ paddingTop: 0, paddingBottom: 0, ...layerOpacity }}
                                InputProps={{
                                  endAdornment: (
                                    <MuiTypography>%</MuiTypography>
                                  ),
                                }}
                                color='primary'
                              />
                            </Box>
                            <Box>
                            <CheckboxField
                              name='currentOfferAreaLayerVisibility'
                              icon={<VisibilityOffIcon style={visibilityIconSize} />}
                              checkedIcon={<VisibilityIcon style={visibilityIconSize} />}
                              style={{ borderRadius: '50%', border: `1px solid ${theme.palette.primary.main}`, padding: '5px' }}
                            />
                            </Box>
                          </Box>
                          <Divider style={{ marginBottom: 32, marginTop: 32 }} />
                        </Grid>
                      </Grid>)}
                    <Grid container
                          spacing={1} alignItems='center'>
                      <Grid xs={6} item>
                        <Box display="flex" alignItems="center" marginRight="39px">
                          <Typography>{t('recommendations_is_global')} </Typography>
                          <MuiSwitchCommon
                            name='isGlobal'
                            checked={isGlobal}
                            onChange={(e) => setValue("isGlobal", e.target.checked)}
                            inputProps={{'aria-label': 'controlled'}}
                            color='primary'
                            disabled={action === 'update' || progress > 0}
                            defaultChecked
                            sx={{ marginLeft: '1rem', marginTop: 0 }}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={6} item>
                      <Box display="flex" alignItems="center">
                      <Typography
                          sx={{ ...allowsRegenerateInput }}>{t('recomendation_allows_regenerate_service_point')} </Typography>
                        <MuiSwitchCommon
                            name='allowsRegenerateServicePoint'
                            checked={allowsRegenerateServicePoint}
                            onChange={(e) => setValue("allowsRegenerateServicePoint", e.target.checked)}
                            inputProps={{'aria-label': 'controlled'}}
                            color='primary'
                            defaultChecked
                            sx={{ marginLeft: '1rem', marginTop: 0 }}
                          />
                      </Box>
                      </Grid>
                    </Grid>
                  </MuiGrid>
    );
};

export default RecommendationsVisualization;