import { Grid, styled, Switch, Typography } from '@mui/material';
import { SwitchField } from '../../../../../../../common/ReactHooksFormFields';
import {GLOBAL} from "../../../../../../../../utils/constants";
import { lighten } from '@mui/material/styles';

export const MuiSwitchField = styled(SwitchField,{
  shouldForwardProp: (prop) => prop !== 'checked '
})(({ theme , checked, translation }) => ({
  width: 153,
  height: 32,
  marginLeft: 8,
  padding: 0,
  display: 'flex',
  '&:active .MuiSwitch-thumb': {
    width: 67,
  },
  '&:active .Mui-checked': {
    transform: 'translateX(9px)',
  },
  '.MuiSwitch-switchBase': {
    color: theme.palette.primary.main,
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(74px)',
      color: theme.palette.primary.main,
      '& + .MuiSwitch-track': {
        border: '1px solid',
        background: lighten( theme.palette.primary.main, 0.9),
        borderColor: theme.palette.primary.main,
      },
    },
  },
  '.MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 67,
    height: 24,
    borderRadius: 12,
    marginTop: 2,
    marginLeft: 4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    '&:before': {
      content: !checked ? `"${translation('simple')}"`
                        : `"${translation('by_value')}"`,
      color: 'white',
      position: 'absolute',
      left: !checked ? 20 : 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
  },
  '.MuiSwitch-track': {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: 20,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: lighten( theme.palette.primary.main, 0.9),
    boxSizing: 'border-box',
    position: 'relative',
    '&:before': {
      content: `"${translation('simple')}"`,
      color: theme.palette.primary.main,
      position: 'absolute',
      left: 20,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
    '&:after': {
      content: `"${translation('by_value')}"`,
      position: 'absolute',
      right: 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  '.MuiSwitch-thumbChecked': {
    color: '#FFFFFF',
  },
}));

export const MuiSwitchCommon = styled(Switch)(({ theme  }) => ({
  width: 52,
  height: 32,
  marginLeft: 8,
  padding: 0,
  display: 'flex',
  '&:active .MuiSwitch-thumb': {
    width: 12,
  },
  '&:active .Mui-checked': {
    transform: 'translateX(9px)',
  },
  '.MuiSwitch-switchBase': {
    color: '#4D8094',
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        border: 'none !important',
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '.MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    marginTop: 6,
    marginLeft: 4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '.MuiSwitch-track': {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: 20,
    border: '2px solid',
    borderColor: '#4D8094',
    boxSizing: 'border-box',
  },
  '.MuiSwitch-thumbChecked': {
    color: '#FFFFFF',
  },
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 0.5),
  width: '50%',
  alignItems: 'center'
}));

export const styles = (theme) => ({
  elementContainer: {
    padding: theme.spacing(0, 0.5),
    width: '50%',
    alignItems: 'center'
  },
  categoriesContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 0,
    paddingLeft: 12,
    height: 250,
    overflow: 'scroll',
    marginTop: 20
  },
  root: {
    width: 153,
    height: 32,
    marginLeft:8,
    padding: 0,
    display: 'flex',
    '&:active $thumb': {
      width: 67,
    },
    '&:active $switchBaseChecked': {
      transform: 'translateX(9px)',
    },
  },
  switchBase: {
    color:theme.palette.primary.main,
    padding: 2,
    '&$checked': {
      transform: 'translateX(74px)',
      color: theme.palette.primary.main,
      '&$checked + $track': {
        border: '1px solid',
        background: '#DFF2FC',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 67,
    height: 24,
    borderRadius: 12,
    marginTop:2,
    marginLeft:4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    '&:before': {
      content: ({ checkedColorType }) =>  `"${!checkedColorType ? 'Simple' : 'By value'}"`,
      color: 'white',
      position: 'absolute',
      left: ({ checkedColorType }) => !checkedColorType ? 20 : 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
  },
  track: {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: '20px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    position: 'relative',
    '&:before': {
      content: '"Simple"',
      color:theme.palette.primary.main,
      position: 'absolute',
      left: 20,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
    '&:after': {
      content: '"By value"',
      position: 'absolute',
      right: 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
      color:theme.palette.primary.main,
    },
  },
  thumbChecked: {
    color: '#FFFFFF',
  },
  checked: {},
  switchBaseChecked: {},
});

export const switchCommonStyles = (theme) => ({
  root: {
    width: 52,
    height: 32,
    marginLeft:8,
    padding: 0,
    display: 'flex',
    '&:active $thumb': {
      width: 12,
    },
    '&:active $switchBaseChecked': {
      transform: 'translateX(9px)',
    },
  },
  switchBase: {
    color:'#4D8094',
    padding: 2,
    '&$checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '&$checked + $track': {
        border:'none !important',
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    marginTop:6,
    marginLeft:4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  track: {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: '20px',
    border:'2px solid',
    borderColor:'#4D8094',
    boxSizing: 'border-box',
  },
  thumbChecked: {
    color: '#FFFFFF',
  },
  checked: {},
  switchBaseChecked: {},
});

export const MuiTypographyCommon = styled(Typography)(({ theme }) => ({
  fontSize:12,
  fontWeight:400,
  fontFamily:'Montserrat',
  color:'#000000'
}));

export const MuiGridColorMap = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: -7
}));

export const MuiGridSelectColor = styled(Grid)(({ theme }) => ({
  marginTop:20,
  marginLeft:0
}));

export const MuiCustomColorGradientField = styled(Grid)(({ theme }) => ({
  marginTop:8,
  marginLeft: 0
}));

export const MuiGridItem = styled(Grid)(({ theme }) => ({
  display:'flex',
  flexDirection:'column'
}));

export const MuiGridSelectorGradient = styled(Grid)(({ theme }) => ({
  marginLeft: 8,
  marginTop:8
}));

export const MuiGridInvertColor = styled(Grid)(({ theme }) => ({
  marginLeft: 5,
  marginTop:8,
  display:'flex'
}));

export const switchColorTypeStyle = {
  marginTop:8,
  marginLeft:-10
}

export const colorMapStyle = {
  marginLeft: 16,
  marginTop: 8
}

export const divColorMapStyle = {
  display:'flex',
  marginTop:8
}
