import { useMutation, useQueryClient } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const updateUser = async (data) =>
 await axios.put(apiPaths.update_user, data, status200).then((resp) => resp.data);

const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => updateUser(values), {
    onSuccess: () => {
      queryClient.resetQueries(['users']);
    },
    onError: (error) => {
      
      return error.message;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};

export default useUpdateUser;
