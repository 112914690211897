import * as React from 'react';
import swap from 'lodash-move';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import { setIsValidLayerForm } from 'store/appSlice';

import {
  divColorValueStyle,
  divContainerStyle,
  divMenuItemStyle, dragHandleStyle,
  gridDeleteStyle,
  inputTextStyle, MuiGridItem,
  outlinedColorStyle, OutlinedInput, OutlinedInputColor,
  outlinedInputStyle,
  selectStyle
} from './styles/legendItems';
import { ReactSVG } from 'react-svg';
import { InputAdornment, Select,Grid, MenuItem } from '@mui/material';
import { iconsName } from './iconsName';
import { MuiDeleteIcon } from '../styles/layersSort';

const iconCustomDefault = 'circle.svg';
const DragHandle = SortableHandle(() => (
  <DragHandleIcon color='primary' style={dragHandleStyle} />
));

const SortableItem = SortableElement(
  ({ item, handleDeleteItem, handleEditName, handleEditColor, handleEditIcon, isPointDataset}) => {
    const iconName = item.icon.split('.')[0]
    const defaultIcon = iconsName.find(i => i.name === iconName)?.name;

    return (
      <Grid style={{marginTop:25}} >
        <div style={divContainerStyle}>
          <MuiGridItem item >
            <DragHandle />
            <OutlinedInput
              variant='outlined'
              type='text'
              defaultValue={item.id === 'other' ? item.value : item.name}
              style={outlinedInputStyle}
              disabled={item.id === 'other'}
              onBlur={(e) => handleEditName(item.id, e.target.value)}
              inputProps={{
                style: inputTextStyle
              }}
            />
            <OutlinedInputColor
              margin='none'
              type='color'
              style={outlinedColorStyle}
              defaultValue={item.color}
              onBlur={(e) => handleEditColor(item.id, e.target.value)}
              endAdornment={
                <InputAdornment position="end" >
                  <div style={divColorValueStyle}>
                    <span>
                      {item.color}
                    </span>
                  </div>
                </InputAdornment>
              }
            />
            {isPointDataset && (
              <Select
                labelId="icon-select-label"
                id="icon-select"
                defaultValue={defaultIcon}
                onChange={(e) => handleEditIcon(item.id, e.target.value)}
                fullWidth
                sx={selectStyle}
                renderValue={(selected) => (
                  <div style={{
                    ...divMenuItemStyle,
                    background:item.color,
                  }}>
                    <ReactSVG
                      src={`${process.env.PUBLIC_URL}/maki-icons/${selected}.svg`}
                      beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name');
                        svg.setAttribute('style', 'width: 24px; height: 24px;');
                      }}
                      afterInjection={(svg) => {
                        const path = svg.querySelector('path');
                        if (path) {
                          path.setAttribute('fill', 'white');
                        }
                      }}
                    />
                  </div>
                )}
              >
                {iconsName.map((key) => (
                  <MenuItem value={key.name} key={key.number}>
                    <div style={{
                      ...divMenuItemStyle,
                      background:item.color,
                    }}>
                      <ReactSVG
                        src={`${process.env.PUBLIC_URL}/maki-icons/${key.name}.svg`}
                        beforeInjection={(svg) => {
                          svg.classList.add('svg-class-name');
                          svg.setAttribute('style', 'width: 20px; height: 20px;');
                        }}
                        afterInjection={(svg) => {
                          const path = svg.querySelector('path');
                          if (path) {
                            path.setAttribute('fill', 'white');
                          }
                        }}
                      />
                    </div>
                  </MenuItem>
                ))}
              </Select>
            )}
          </MuiGridItem>
          <Grid item style={gridDeleteStyle}>
            <MuiDeleteIcon  onClick={(e) => item.id !== 'other' && handleDeleteItem(item.id)}/>
          </Grid>
        </div>
      </Grid>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, handleDeleteItem, handleEditName, handleEditColor, handleEditIcon, showOthers, isPointDataset}) => {

    const [, setIconValueOther] = React.useState(iconCustomDefault);

    const handleInputEditIcon = (value, iconName) => {
      if (iconName !== '') handleEditIcon(value, iconName + '.svg');
      if (showOthers && value === 'other') setIconValueOther(iconName + '.svg');
    };

    return (
      <Grid
        container
        justifyContent='flex-start'
        direction='column'
        alignContent='flex-start'
      >
        {items?.map((item, index) => (
          <SortableItem
            key={item.id}
            index={index}
            item={item}
            handleDeleteItem={handleDeleteItem}
            handleEditName={handleEditName}
            handleEditColor={handleEditColor}
            handleEditIcon={handleInputEditIcon}
            isPointDataset={isPointDataset}
          />
        ))}
      </Grid>
    );
  }
);

const ItemSort = ({ items, setItems, showOthers, isPointDataset, action }) => {
  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(swap(items, oldIndex, newIndex));
    dispatch(setIsValidLayerForm(true));
  };

  const handleDeleteItem = (id) => {
    setItems((prevVal) => prevVal.filter((item) => item.id !== id));
    dispatch(setIsValidLayerForm(true));
  };

  const handleEditName = (id, name) => {
    setItems((prevVal) => prevVal.map((i) => (i.id === id ? { ...i, name } : i)));
    dispatch(setIsValidLayerForm(true));
  };

  const handleEditColor = (id, color) => {
    setItems((prevVal) => prevVal.map((i) => (i.id === id ? { ...i, color } : i)));

    dispatch(setIsValidLayerForm(true));
  };

  const handleEditIcon = (id, icon) => {
    setItems((prevVal) => prevVal.map((i) => (i.id === id ? { ...i, icon } : i)));
    dispatch(setIsValidLayerForm(true));
  }

  return (
      <SortableList
        items={items}
        onSortEnd={onSortEnd}
        useDragHandle
        helperClass='helper-class'
        handleDeleteItem={handleDeleteItem}
        handleEditName={handleEditName}
        handleEditColor={handleEditColor}
        showOthers={showOthers}
        handleEditIcon={handleEditIcon}
        isPointDataset={isPointDataset}
      /> );
};

export default ItemSort;
