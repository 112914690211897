import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {  Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import { TextField } from 'components/common/ReactHooksFormFields';
import { useTranslation } from 'components/providers/TranslationProvider';
import Modal from 'components/common/Modal';
import {
  MuiButtonClose, MuiButtonDelete, MuiTypographyContent,
  MuiWarningIcon
} from 'components/views/organizations/common/styles/modalDelete';
import { setIsValidDescriptiveScenario, deleteTab } from 'store/appSlice';

const schema = yup.object().shape({
  name: yup.string().required(),
  nameConfirmation: yup.string().oneOf(['delete','eliminar','supprimer','excluir',null], 'confirm_to_operation'),
});

const DeleteTab = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tab = location.state;

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const {
    formState: { errors, isValid },
    setValue,
    reset,
  } = methods;

  const handleClose = () => {
    query.delete('delete-widgets-group');
    reset({ name: '', nameConfirmation: '' });
    navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
  };

  const handleSubmit = () => {
    dispatch(deleteTab(tab.id));
    dispatch(setIsValidDescriptiveScenario(true));
    handleClose();
  };

  React.useEffect(() => {
    const deleteQuery = query.get('delete-widgets-group');
    setOpen(deleteQuery !== null);
    setValue('name', location.state?.name);
  }, [query, location.state, setValue]);

  const actions = (
    <>
      <MuiButtonClose
        onClick={handleClose}
        color='primary'
        variant='text'
        focusRipple
      >
        {t('cancel_btn')}
      </MuiButtonClose>
      <MuiButtonDelete
        onClick={handleSubmit}
        type='submit'
        disabled={!isValid}
      >
        {t('delete')}
      </MuiButtonDelete>
    </>
  );

  const contentModal = (
    <FormProvider {...methods}>
      <Grid container>
        <Grid item xs={3} display='flex'>
          <MuiWarningIcon />
        </Grid>
        <Grid item xs={9}>
          <MuiTypographyContent>
            {t('delete_widget_group_content')}
          </MuiTypographyContent>
        </Grid>
      </Grid>
      <TextField
        autoFocus
        name='nameConfirmation'
        margin='dense'
        type='text'
        label={t('confirm_to_operation')}
        variant='outlined'
        fullWidth
        error={t(errors.nameConfirmation?.message)}
      />
    </FormProvider>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        title={t('delete_widget_group')}
        isDelete
        actions={actions}
      >
        {contentModal}
      </Modal>
    </>
  );
};

export default DeleteTab;
