import * as React from 'react';
import {
  Grid,
  Tab,
  Checkbox,
  Tabs
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import WidgetSort from './WidgetSort';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {
  setAllTabs,
  setIsValidDescriptiveScenario
} from '../../../../../../../store/appSlice';
import swap from 'lodash-move';
import { useNavigate } from 'react-router';

import {
  checkStyles,
  MuiDeleteIcon,
  MuiEditIcon,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon
} from '../../layer/styles/layersSort';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { useTheme } from '../../../../../../providers/CustomThemeProvider';
import {
  customActionsStyle, customTabStyle,
  dragHandleStyle, MuiBoxContainer,
  MuiContainerContent, MuiGridTabContent, nameTabStyle
} from './styles/widgetsTabs';

const WidgetsTabs = ({ setTab }) => {

  const tabs = useSelector((state) => state.app.tabs);
  const widgets = useSelector((state) => state.app.widgets);
  const layers = useSelector((state) => state.app.layers);
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = React.useState(tabs[0]?.id || '');

  const customTabsStyle = {
    height: '100%',
    overflowY: 'auto',
    borderRight: 1,
    borderColor: 'divider',
    '& .MuiTabs-indicator': {
      maxWidth: 3,
      height: '28px !important',
      marginTop: '19px',
      borderRadius: '2px',
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  }

  const handleDelete = (id, item) =>
    navigate(`${location.pathname}?delete-widgets-group=${id}`, { replace: true, state: item });

  const handleEdit = (id, item) =>
    navigate(`${location.pathname}?edit-widgets-group=${id}`, { replace: true, state: item });

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(setIsValidDescriptiveScenario(true));
    dispatch(setAllTabs(swap(tabs, oldIndex, newIndex)));
  };

  React.useEffect(() => {
    setTab(activeTab);
  }, [activeTab, setTab]);

  React.useEffect(() => {
    setActiveTab(tabs[0]?.id);
  }, [tabs]);

  const tabContent = () => (
    <MuiContainerContent key={`tab-content-${activeTab}`}>
      {layers?.length > 0 && widgets?.length > 0 && <WidgetSort tabId={activeTab} />}
    </MuiContainerContent>
  )

  const DragHandle = sortableHandle(({ id }) => (
    <DragHandleIcon
      color='primary'
      style={{
        ...dragHandleStyle,
        color: id === activeTab ? theme.palette.primary.main : '#757575'
      }}
    />
  ));

  const SortableTabs = SortableContainer(({ children }) => {
    return (
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activeTab}
        aria-label="Vertical tabs example"
        sx={customTabsStyle}
      >
        {children}
      </Tabs>
    );
  });

  const SortableTab = SortableElement(({ value, label }) => (
    <Tab
      onChange={handleChange}
      value={value}
      key={value}
      label={label}
      style={{
        ...customTabStyle,
        background: value === activeTab ? '#FAFAFA' :'#FFFFFF'
      }}
    />
  ));

  return (
    <>
      {tabs.length > 0 && activeTab && (
         <MuiBoxContainer>
              <Grid item xs={6}>
                <SortableTabs onSortEnd={onSortEnd} useDragHandle>
                  {tabs.map((tab, index) => (
                    <SortableTab
                      value={tab.id}
                      key={tab.id}
                      index={index}
                      label={
                        <Grid container alignItems="center">
                          <Grid item xs={1}>
                            <DragHandle id={tab.id}/>
                          </Grid>
                          <Grid item xs={7}
                                style={{
                                  ...nameTabStyle,
                                  color: tab.id === activeTab ? theme.palette.primary.main
                                                              : '#757575'
                                }}
                          >
                            {tab.name}
                          </Grid>
                          <Grid item xs={3} style={{ display: 'contents' }}>
                            <Checkbox
                              checked={true}
                              name='visibility'
                              color='primary'
                              style={{marginLeft:'1.4rem'}}
                              icon={<MuiVisibilityOffIcon sx={{ padding: '5px', width: '26px', height: '26px' }}/>}
                              checkedIcon={<MuiVisibilityIcon style={{ padding: '5px', width: '26px', height: '26px' }}/>}
                            />
                            <MuiEditIcon
                              style={customActionsStyle}
                              onClick={() => handleEdit(tab.id, tab)}
                            />
                            <MuiDeleteIcon
                              style={customActionsStyle}
                              onClick={() => handleDelete(tab.id, tab)}
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                  ))}
                </SortableTabs>
              </Grid>
              <MuiGridTabContent item xs={6}>
                {tabContent()}
              </MuiGridTabContent>
            </MuiBoxContainer>
      )}
    </>
  );
};

export default WidgetsTabs;
