import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

export const MuiChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#ffffff',
  height: 28,
  maxWidth: 250,
  padding: '6px 8px 6px 14px',
  margin: '0px 0px 3px 3px',
  '& .MuiChip-label':{
    fontSize: 10,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 400,
  },
  '& .MuiChip-deleteIcon':{
    width: 16,
    height: 16,
    fill: '#254957',
  },
}));
